/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Row } from 'reactstrap';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import Webcam from '@uppy/webcam';
import AwsS3 from '@uppy/aws-s3';
import uppyLocales from '../../services/uppyPlugins/locales';
import { apiMethods } from '../../services/apiService';
import { updateMedia, getMediaByPublicId } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import { utils } from '../../services/utilsService';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '../MediaPages/VideouploadPage.css';
import { mediaGroup } from '../../services/enums';

const variables = {
  bucket: 'aspire123',
  folder: 'example'
};

class ImageUploadModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      uppy: null,
      isOpen: false,
      editMode: false,
      isPageReady: false
    };
    variables.bucket = this.props.bucket || 'aspire123';
    variables.folder = this.props.folder || 'example';
    // if its an upload media page
    this.state.uppy = new Uppy({
      id: 'uppy1',
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 50,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', 'video/mp4', 'video/mpeg', 'video/webm', 'application/pdf']
      }
    })
      .use(Webcam, {
        modes: ['picture', 'video-audio']
      })
      .use(AwsS3, {
        async getUploadParameters (file) {
          // Send a request to our PHP signing endpoint.
          const params = {
            bucket: variables.bucket,
            folder: variables.folder,
            fileName: file.name,
            fileType: file.type
          };
          const query = utils.buildQuery(params);
          const response = await apiMethods.media.getPreSignedUrl(query);
          return {
            method: 'PUT',
            url: response.signedRequest,
            headers: {
              'Content-Type': response.fileType
            }
          };
        }
      })
      .on('complete', (result) => {
        if (result.successful.length > 0) {
          this.uploadImagesAutomatically(result.successful);
        }
      });
  }

  async componentDidMount () {
    this.setState({ isPageReady: true });
  }

  componentWillUnmount () {
    variables.bucket = null;
    variables.folder = null;
    this.state.uppy.close();
  }

  getResourceType (fileType) {
    if (fileType.match(/^image\//)) {
      return 'image';
    } if (fileType.match(/^video\//)) {
      return 'video';
    } if (fileType.match(/^application\//)) {
      return 'form';
    }
    return 'auto';
  }

  handleModalClick () {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  configAwsFile (obj) {
    return {
      source: obj.uploadURL,
      sourceId: obj.uploadURL.split('/').pop(),
      size: obj.size,
      name: obj.name,
      type: this.getResourceType(obj.type),
      extension: obj.type.split('/').pop(),
      format: obj.type,
      mediaGroup: this.props.mediaGroup || mediaGroup.other,
      searchKeywords: obj.name.toLowerCase().replace('.', ' ')
    };
  }

  async uploadImagesAutomatically (results) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const body = {
        completedUploads: results.map(result => this.configAwsFile(result))
      };
      const mediaResults = await apiMethods.media.saveUploadedFiles(body);
      if (this.props.returnResults) this.props.returnResults(mediaResults);
      this.handleModalClick();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }

  render () {
    return (
      <Fragment>
        { this.state.isPageReady && (
          <Row>
            <div className="flex-100 layout-row layout-align-center-center">
              <button className="btn btn-info" onClick={() => this.handleModalClick()}>
                {this.state.isOpen ? 'Close dashboard' : 'Open dashboard'}
              </button>
              <DashboardModal
                uppy={this.state.uppy}
                open={this.state.isOpen}
                target={document.body}
                allowMultipleUploads
                showProgressDetails
                proudlyDisplayPoweredByUppy={false}
                plugins={['Webcam']}
                onRequestClose={() => this.setState({ isOpen: false })}
              />
            </div>
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  mediaDetails: state.mediaR.mediaDetails,
  alert2Props: state.appR.alert2Props
});

const mapDispatchToProps = dispatch => ({
  clearMyMedia: () => dispatch({ type: actionTypes.CLEAR_MEDIA_DETAILS }),
  updateMedia: body => dispatch(updateMedia(body)),
  getMediaByPublicId: body => dispatch(getMediaByPublicId(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadModal);
