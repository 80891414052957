import { actionTypes } from '../actions/actionTypes';
import { storeUtils } from '../../services/utilsService';

const initialState = {
  workoutLogsKV: {},
  workoutLogsIds: [],
  workoutSessionData: {},
  // sessionPublicId
  // workoutProgramInfo
  // isSmartSession
  // sessionsListKV
  // sessionsListIds
  // session {}
  // workoutProgramInfo  {}
  // workoutLogs: []
  // sessionChart: []
  // keyLifts: []
  // sessionUser: []
  // exercisesList: [] exerciseId/equipmentId
  // notificationMode: false
  carouselPosition: 0
  // currentWorkoutLog: null // for notification
  // currentActiveLog: null  // for notification
};

const workoutSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WORKOUT_SESSIONS: {
      return { ...state, sessionsListKV: { ...action.payload.sessionsListKV }, sessionsListIds: [...action.payload.sessionsListIds] };
    }
    case actionTypes.CLEAR_ALL_WORKOUTS_PAGE_DATA: {
      return { ...state, sessionsListKV: {}, sessionsListIds: [] };
    }
    case actionTypes.SET_PROGRAM_SESSION_DETAILS:
      return { ...state, ...action.payload }; /* workoutLogsKV, workoutLogsIds, workoutProgramInfo, isSmartSession, sessionUser, exercisesList */
    case actionTypes.SET_SESSION_DETAILS: {
      // session {},  workoutProgramInfo  {},  workoutLogs: [], sessionChart: [] , keyLifts: [] --> has two end points ... different props are coming
      const sessionPublicId = action.payload.session.public_id;
      return { ...state, ...action.payload, sessionPublicId };
    }
    case actionTypes.SET_SESSION_WORKOUTLOGS:
      return {
        ...state,
        workoutLogsKV: action.payload.listKV,
        workoutLogsIds: action.payload.dataIds
      };
    case actionTypes.UPDATE_SESSION_LIST:
      return { ...state, sessionsListKV: action.payload.sessionsListKV, sessionsListIds: action.payload.sessionsListIds };
    case actionTypes.REMOVE_SESSION: {
      const workoutLogsKV = storeUtils.removeFromObjectInStore(state.sessionsListKV, action.payload);
      const workoutLogsIds = storeUtils.removeFromArrayInStore(state.sessionsListIds, action.payload);
      return { ...state, workoutLogsIds, workoutLogsKV };
    }
    case actionTypes.CLEAR_SESSION:
      return {
        ...state, workoutSessionData: {}, sessionPublicId: null, isSmartSession: null, workoutLogsKV: {}, workoutLogsIds: []
      };
    case actionTypes.CLEAR_SESSION_DETAILS:
      return {
        ...state, session: {}, workoutProgramInfo: {}, workoutLogs: [], sessionChart: [], keyLifts: []
      };
    case actionTypes.UPDATE_NOTIFICATION_WORKOUT_LOG:
      return { ...state, currentWorkoutLog: action.payload.currentWorkoutLog, currentActiveLog: action.payload.currentActiveLog };
    case actionTypes.UPDATE_CAROUSEL_POSITION:
      return { ...state, carouselPosition: action.payload.position, currentWorkoutLog: action.payload.currentWorkoutLog };
    default:
      return state;
  }
};

export default workoutSessionReducer;
