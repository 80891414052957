/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { actionTypes } from '../../store/actions/actionTypes';
import './modalStyle.css';

class AlertModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isPageReady: false
    };
  }

  async componentDidMount () {
    this.setState({ isPageReady: true });
  }

  closeAlert () {
    this.props.hideAlert();
    if (this.props.alertProps.closeAlertBtn && this.props.alertProps.closeAlertBtn.onClick) this.props.alertProps.closeAlertBtn.onClick();
    if (this.props.hideAlertBtn) this.props.hideAlertBtn();
  }

  render () {
    const { title, content} = this.props.alertProps;
    return (
      <div className="h-100 layout-row layout-wrap layout-align-start-start flex-100 m-auto position-relative">
        <Modal isOpen toggle={() => this.props.hideAlertBtn()} backdrop className=" AlertModal modalCenterScreen">
          { this.state.isPageReady && (
            <Fragment>
              <ModalBody className="width100 layout-row layout-wrap layout-align-start-start">
                <div className="flex-100 layout-row layout-align-center-start text-center text-center mb-4">
                  <h4 className="primary width100 text-capitalize">{ title }</h4>
                </div>
                <div className="flex-100 layout-row layout-align-center-start text-center mb-4">
                  <p className="mb-0 width100">{ content }</p>
                </div>
                <div className="flex-100 layout-row layout-align-start-start mt-4 directionRtl">
                  { this.props.alertProps.buttonRight && this.props.alertProps.buttonRight.text && (
                    <button className="btn btn-primary flex sideMargin5px text-center text-capitalize fontWeight600" type="button" {...this.props.alertProps.buttonRight}>{ this.props.alertProps.buttonRight.text }</button>
                  )}
                  { this.props.alertProps.closeAlertBtn && (
                    <button className="btn btn-outline-secondary flex sideMargin5px text-center text-capitalize fontWeight600" type="button" onClick={() => this.closeAlert()}>{this.props.alertProps.closeAlertBtn.text}</button>
                  )}
                </div>
              </ModalBody>
            </Fragment>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  mediaDetails: state.mediaR.mediaDetails
});

const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
