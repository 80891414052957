import React, { Component } from 'react';
import nanoid from 'nanoid';
import { ReactSVG } from 'react-svg';
import videoJs from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-http-source-selector';
import 'videojs-landscape-fullscreen';
import Player from '@vimeo/player';
import { securePostMsgUtils, styleUtils } from '../../services/utilsService';
import { postMsgTypes } from '../../services/postMsgTypes';
import fsIcon from '../../assets/icons/expand.svg';
import pauseIcon from '../../assets/icons/timer/pause.svg';
import playIcon from '../../assets/icons/timer/play.svg';
import muteIcon from '../../assets/icons/media/mute.svg';
import unmuteIcon from '../../assets/icons/media/volume.svg';
import loader from '../../assets/svg/aspireLine.svg';
import './VideoPlayer.css';
import 'video.js/dist/video-js.css';

class VideoPlayer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      player: null,
      randomId: nanoid(5),
      isLandscape: this.props.isLandscape || false,
      isPlaying: this.props.isAutoPlay,
      errorCounter: 0,
      isMute: true,
      videoPlayer: null,
      videoElementId: null,
      dimensions: {
        isPortrait: false,
        height: null,
        width: null
      }
    };
  }

  componentDidMount () {
    if (this.props.media && this.props.media.source.indexOf('vimeo') !== -1) {
      this.activateVimeo();
    } else if (this.state.videoElementId) {
      let sources = [];
      const sourceHlsKeys = this.props.media && this.props.media.sourceHls && Object.keys(this.props.media.sourceHls);
      if (sourceHlsKeys && sourceHlsKeys.length > 0) {
        sources = [{
          src: this.props.media.sourceHls.master || this.props.media.sourceHls.default,
          type: 'application/x-mpegURL'
        }];
      } else {
        sources = [{
          src: this.props.media && this.props.media.source,
          type: 'video/mp4'
        }];
      }
      const videoJsOptions = {
        autoplay: this.props.isAutoPlay,
        muted: true,
        loop: true,
        controls: this.props.controls,
        techOrder: ['html5'],
        plugins: {
          httpSourceSelector: { default: 'auto' }
        },
        // playbackRates: [0.5, 1, 1.5, 2],
        controlBar: {
          playToggle: true, // Hides the replay button for VOD
          playbackRateMenuButton: false,
          pictureInPictureToggle: false, // Hides the PiP button
          RemainingTimeDisplay: false,
          DurationDisplay: false
          // fullscreenToggle: false
        },
        sources
      };
      this.state.videoPlayer = videoJs(this.videoNode, videoJsOptions, function onPlayerReady () {
        // console.log('onPlayerReady', this);
      });
      this.state.videoPlayer.httpSourceSelector();
      this.state.videoPlayer.on('error', (error) => {
        if (this.state.errorCounter > 2) {
          console.log('reached errorCounter', error)
          return;
        }
        this.state.errorCounter += 1
        const videoPlayer = videoJs(this.videoNode);
        videoPlayer.src([{
          src: this.props.media && this.props.media.source,
          type: 'video/mp4'
        }])
      });
    }
    this.setState({ ...this.state });
  }

  componentWillUnmount () {
    if (this.state.videoPlayer) {
      this.state.videoPlayer.dispose();
    }
  }

  getDimensionsForPlayers () {
    try {
      const { player } = this.state;
      this.timeOut = setTimeout(() => {
        this.state.dimensions = { isPortrait: false, height: null, width: null };
        Promise.all([player.getVideoWidth(), player.getVideoHeight()])
          .then((dimensions) => {
            this.state.dimensions.width = dimensions[0];
            this.state.dimensions.height = dimensions[1];
            if (dimensions[1] > dimensions[0]) {
              this.state.dimensions.isPortrait = true;
            }
          });
        clearTimeout(this.timeOut);
      }, 500);
    } catch (e) {
      this.isLoading = false;
    }
  }

  handleOrientation () {
    if (this.isLoading) return;
    this.isLoading = true;
    if (this.props.handleOrientation) { // outsideFunc
      this.props.handleOrientation(this.state.dimensions.isPortrait);
      this.isLoading = false;
    } else if (this.props.handleModalFullScreen) { // outsideFunc
      this.props.handleModalFullScreen(this.props.media, this.state.dimensions.isPortrait);
      this.isLoading = false;
    } else {
      if (securePostMsgUtils.listenToValidMessage()) {
        if (this.state.isLandscape) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.LOCK_ORIENTATION_PORTRAIT }));
        } else if (!this.state.dimensions.isPortrait) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ key: postMsgTypes.LOCK_ORIENTATION_LANDSCAPE }));
        }
      }
      this.setState(prevState => ({ isLandscape: !prevState.isLandscape }));
      this.isLoading = false;
    }
  }

  async playVimeoVideo () {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      if (this.state.isPlaying) {
        await this.state.player.pause();
        this.isLoading = false;
      } else {
        await this.state.player.play();
        this.isLoading = false;
      }
      this.setState(prevState => ({ isPlaying: !prevState.isPlaying }));
    } catch (e) {
      this.isLoading = false;
      switch (e.name) {
        case 'PasswordError':
          console.log('PasswordError');
          break;
        case 'PrivacyError':
          console.log('PrivacyError');
          break;
        default:
          console.log('SomeError');
          break;
      }
    }
  }

  playVideo (publicId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      // const player = document.getElementById(`video_player_${publicId}_${this.state.randomId}`);
      if (this.state.isPlaying) {
        this.state.videoPlayer.pause();
        this.isLoading = false;
      } else {
        this.state.videoPlayer.play();
        this.isLoading = false;
      }
      this.setState(prevState => ({ isPlaying: !prevState.isPlaying }));
    } catch (e) {
      this.isLoading = false;
      console.error('player play error');
    }
  }

  muteVimeoVideo () {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      this.setState(prevState => ({ isMute: !prevState.isMute }), async () => {
        await this.state.player.setMuted(this.state.isMute);
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      console.log('handle error', e.message);
    }
  }

  muteVideo (publicId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      this.setState(prevState => ({ isMute: !prevState.isMute }), () => {
        const player = document.getElementById(`video_player_${publicId}_${this.state.randomId}`);
        if (this.state.isMute) player.muted = 'mute';
        if (this.state.isMute) player.muted = false;
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      console.log('handle error', e.message);
    }
  }

  activateVimeo () {
    const iframe = document.querySelector(`#${this.props.id} #videoPlayerFrame`);
    this.state.player = new Player(iframe);
    this.getDimensionsForPlayers();
    this.state.player.on('loaded', (info) => {
      this.setState({ isLoaded: true });
    });
  }

  renderVimeoVideo (height) {
    const {
      id, media, isMuteBtn, fullScreenBtn, playPauseBtn, locale, paddingControls
    } = this.props;
    const iframeSrc = `${media.source}?${this.props.disableAutoPlay ? '' : 'autoplay=1'}&loop=1${media.user ? '' : '&background=1'}${this.props.params || ''}`;
    return (
      <div id={id} className="videoContainer positionRelative width100 positionRelative">
        <div className={`layout-column ${locale === 'en' ? 'layout-align-end-start' : 'layout-align-end-end'} width100 positionAbsoluteTopLeft backgroundImage`} style={{ height, zIndex: 0, ...styleUtils.imageBackground(media.thumbnail || '') }}>
          <div className="flex-initial loader" />
        </div>
        <iframe id="videoPlayerFrame" src={iframeSrc} className={`positionRelative opacity0 ${this.state.isLoaded ? 'show' : ''}`} style={{ zIndex: 2 }} width="100%" height={height} frameBorder="0" />
        <div id="transparentCover" style={{ height }} />
        <div className="video-controls layout-row" style={{ top: `${height - (paddingControls || 30)}px` }}>
          { media.user && isMuteBtn && (
            <button type="button" className={`btn-sm btn-primary ${!fullScreenBtn ? 'sideMargin5px' : ''}`} onClick={() => this.muteVimeoVideo()}>
              <ReactSVG src={this.state.isMute ? muteIcon : unmuteIcon} className="controlsIcon" />
            </button>
          )}
          { fullScreenBtn && (
            <button type="button" className="btn-sm btn-primary sideMargin5px" onClick={() => this.handleOrientation()}>
              <ReactSVG src={fsIcon} className="controlsIcon" />
            </button>
          )}
          { playPauseBtn && (
            <button type="button" className="btn-sm btn-primary" onClick={() => this.playVimeoVideo()}>
              <i className="la la-play font28" />
            </button>
          )}
        </div>
      </div>
    );
  }

  renderVideo (height) {
    const {
      id, media, isMuteBtn, fullScreenBtn, playPauseBtn, paddingControls, showThumbnailLoader
    } = this.props;
    this.state.videoElementId = `video_player_${media.public_id}_${this.state.randomId}`;
    return (
      <div id={id} className="videoContainer positionRelative width100 positionRelative">
        <div className="width100 backgroundImage positionAbsoluteTopLeft" style={{ ...styleUtils.imageBackground(media.thumbnail), height, zIndex: 0 }} />
        <div data-vjs-player className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative zIndex5">
          <video
            ref={element => this.videoNode = element}
            id={`video_player_${media.public_id}_${this.state.randomId}`}
            height={height}
            width="100%"
            className="video video-js vjs-big-play-centered"
            loop
            muted
            playsInline
            controls={this.props.controls}
            autoPlay={this.props.isAutoPlay}
            controlsList="nodownload nofullscreen"
            disablePictureInPicture>
            <source src={`${(media.sourceHls && (media.sourceHls.master || media.sourceHls.default)) || media.source}`} type={(media.sourceHls && (media.sourceHls.master || media.sourceHls.default)) ? 'application/x-mpegURL' : 'video/mp4'} />
            {/*  <source src={`${media.sourceWebM || media.source}`} type="video/webm" /> */}
          </video>
        </div>
        { !this.props.controls && (
          <div className="video-controls layout-row positionAbsolute" style={{ top: `${height - (paddingControls || 30)}px` }}>
            { media.user && isMuteBtn && (
              <button type="button" className={`btn-sm btn-primary ${!fullScreenBtn ? 'sideMargin5px' : ''}`} onClick={() => this.muteVideo(media.public_id)}>
                <ReactSVG src={this.state.isMute ? muteIcon : unmuteIcon} className="controlsIcon" />
              </button>
            )}
            { false && fullScreenBtn && (
              <button type="button" className="btn-sm btn-primary sideMargin5px" onClick={() => this.handleOrientation()}>
                <ReactSVG src={fsIcon} className="controlsIcon" />
              </button>
            )}
            { playPauseBtn && (
              <button type="button" className="btn-sm p-1" onClick={() => this.playVideo(media.public_id)}>
                <i className={`la ${ this.state.isPlaying ? 'la-pause' : 'la-play'} font16`} />
              </button>
            )}
          </div>
        )}
      </div>
    );
  }

  renderNewVideo (height, autoPlay) {
    const { id, media } = this.props;
    if (media && media.source) {
      return (
        <div id={id} className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
          { media.source.indexOf('vimeo') !== -1 ? (
            this.renderVimeoVideo(height)
          ) : (
            this.renderVideo(height, autoPlay)
          )}
        </div>
      );
    }
    return (
      <div className="flex-100 layout-column text-center layout-align-center-center height200px">
        <img src="https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogo_250x225.png" className="aspireIcon" style={{ width: '80px' }} />
        <h2 className="text-uppercase fontWeight600">Video</h2>
      </div>
    );
  }

  render () {
    const { height, media, isLandscape } = this.props;
    const videoPlayer = this.renderNewVideo(height);
    return (
      <div className={`MediaDisplayUI VideoPlayer width100 layout-row layout-wrap layout-align-center-center ${isLandscape ? 'isLandscape' : ''}`}>
        { media && media.source.indexOf('vimeo') !== -1 ? (
          this.renderVimeoVideo(height)
        ) : (
          <div className="flex-100 layout-row layout-wrap layout-align-center-center">
            { videoPlayer }
          </div>
        )}
        { (isLandscape || this.state.isLandscape) && !media.user && !this.props.noLabel && (
          <div className="flex-100 layout-row layout-wrap layout-align-center-center fullScreenLogo">
            <div className="layout-column width100 layout-align-center-center">
              <div className="flex-initial layout-row layout-wrap layout-align-start-start">
                <ReactSVG evalScripts="never" src={loader} />
              </div>
              <div className="flex-100 layout-row layout-wrap layout-align-center-center">
                <h2 className="text-uppercase fontWeight600">Aspire</h2>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default VideoPlayer;
