import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import './SquareCard.css';
import Input from '../UI/Input';

const ProgramsToAssign = (props) => {
  const { program, locale, checkProgram, isChecked } = props;
  const workoutLogsLength = program.workoutLogs && Array.isArray(program.workoutLogs) ? program.workoutLogs.length : program.workoutLogs;
  const [toggle, setToggle] = useState(isChecked);

  const handleCheckProgram = () => {
    setToggle(!toggle);
    checkProgram(!toggle);
  }
  return (
    <div className="ProgramsToAssign flex-100 layout-row layout-wrap layout-align-start-center SquareCard ProgramCardInPlan mb-2">
      <div className="flex-initial layout-row layout-wrap layout-align-space-between p-2">
        <Input
          noLabel
          name="isChecked"
          label="isChecked"
          elementType="checkBox"
          clickText
          locale={locale}
          initialValue={toggle}
          handleChange={(event) => { handleCheckProgram(event); }}>
        </Input>
      </div>
      <div className="flex layout-row layout-wrap layout-align-space-between" onClick={() => handleCheckProgram()}>
        <div className="flex-initial layout-row layout-wrap layout-align-start-center pt-1">
          <p className="flex-initial font16 mb-0 textLimitHeight title text-capitalize" style={{ maxHeight: `${48}px` }}>{program.title}</p>
          { workoutLogsLength && (
            <span className="flex-100 font13 exercisesNum text-muted text-capitalize">{ workoutLogsLength } <span><Trans i18nKey="exercises" /> </span></span>
          )}
        </div>
      </div>
    </div>
  );
};

ProgramsToAssign.propTypes = {
  data: PropTypes.shape({}),
  locale: PropTypes.string
};

export default ProgramsToAssign;
