/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import AlertModal from './AlertModal';
import MediaUploadModal from './MediaUploadModal';
import MediaLibrary from './MediaLibrary';
import LocationModal from './LocationModal'
import ProfessionModal from './ProfessionModal'
import GetSocialMediaModal from './GetSocialMediaModal'
import AspireLibraryModal from './AspireLibraryModal'
import VerifyPhonePage from './VerifyPhonePage'
import WelcomeModal from './WelcomeModal'
import CopyPlanPage from '../CreateWorkoutPage/CopyPlanPage'
import OrderReviewModal from '../SubscriptionPage/OrderReviewModal'
import SuperSetExerciseOptions from './SuperSetExerciseOptions'


class ModalsContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  openModal () {
    switch (this.props.alertProps.id) {
      case eventActions.ALERT_MODAL:
        return <AlertModal alertProps={this.props.alertProps} hideAlertBtn={() => this.props.hideAlert()} />;
      case eventActions.MEDIA_LIBRARY:
        return <MediaLibrary {...this.props.alertProps} />;
      case eventActions.MEDIA_UPLOAD:
        return <MediaUploadModal {...this.props.alertProps} />
      case eventActions.GET_SOCIAL_MEDIA:
        return <GetSocialMediaModal {...this.props.alertProps} />;
      case eventActions.GET_ASPIRE_LIBRARY:
        return <AspireLibraryModal {...this.props.alertProps} />;
      case eventActions.LOCATION_MODAL:
        return <LocationModal {...this.props.alertProps} />;
      case eventActions.PROFESSION_MODAL:
        return <ProfessionModal {...this.props.alertProps} />;
      case eventActions.WELCOME_MODAL:
        return <WelcomeModal {...this.props.alertProps} />;
      case eventActions.ORDER_REVIEW_MODAL:
        return <OrderReviewModal {...this.props.alertProps} />;
      case eventActions.VERIFY_PHONE:
        return <VerifyPhonePage alertProps={this.props.alertProps} hideAlertBtn={() => this.props.hideAlert()} />;
      case eventActions.SUPERSET_EXERCISE_OPTIONS:
        return (<SuperSetExerciseOptions isModalPage locale={this.props.locale} data={this.props.alertProps.data} hideAlertBtn={() => this.props.hideAlert()} />);
      case eventActions.ASSIGN_PLAN_PAGE:
        return <CopyPlanPage locale={this.props.locale} history={this.props.history} alertProps={this.props.alertProps} hideAlertBtn={() => this.props.hideAlert()} />;
      default:
        // return <MsgModal {...this.props.alertProps} />;
    }
  }

  render () {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        { this.props.alertProps && this.props.alertProps.isOpen && this.openModal() }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  alertProps: state.appR.alertProps,
  notificationProps: state.appR.notificationProps
});


const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsContainer);
