
const listSelection = {
  listSingleSelect: (list, chosenList, publicId) => {
    let tempChosen = [...chosenList];
    const tempList = { ...list };
    if (tempChosen && tempChosen.length > 0 && tempList[tempChosen[0]]) tempList[tempChosen[0]].chosen = false;
    tempChosen = [publicId];
    if (!tempList[publicId].chosen) tempList[publicId].chosen = false;
    tempList[publicId].chosen = true;
    return { chosenList: tempChosen, list: tempList };
  },
  listMultiSelect: (list, chosenList, publicId) => {
    let tempChosen = [...chosenList];
    const tempList = { ...list };
    if (tempChosen.indexOf(publicId) === -1) {
      tempChosen.push(publicId);
      if (!tempList[publicId].chosen) tempList[publicId].chosen = false;
      tempList[publicId].chosen = true;
    } else {
      tempChosen = tempChosen.filter(pubId => (
        pubId !== publicId
      ));
      tempList[publicId].chosen = false;
    }
    return { chosenList: tempChosen, list: tempList };
  },
  clearChosenList: (list, keys) => {
    const tempList = { ...list };
    keys.forEach((key) => {
      if (tempList[key]) {
        tempList[key].chosen = false;
      }
    });
    return tempList;
  }
};

export default listSelection;
