/* eslint-disable no-underscore-dangle */
import { mediaActionTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse } from './index';

const setMediaGallery = data => ({
  type: mediaActionTypes.SET_MEDIA_GALLERY,
  payload: data
});

const joinMediaList = data => ({
  type: mediaActionTypes.JOIN_MEDIA_LIST,
  payload: data
});

const setMediaDetails = data => ({
  type: mediaActionTypes.SET_MEDIA_DETAILS,
  payload: data
});

export const splitMedia = publicId => ({
  type: mediaActionTypes.REMOVE_MEDIA,
  payload: publicId
});

export const getAspireGallery = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.media.getAspireGallery(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getMediaGallery = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.media.getMediaGallery(query);
    dispatch(joinMediaList(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getMediaDetails = mediaId => async (dispatch) => {
  try {
    const data =  await apiMethods.media.getMediaDetails(mediaId);
    dispatch(setMediaDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getMediaByPublicId = publicId => async (dispatch) => {
  try {
    return await apiMethods.media.getMediaDetails(publicId);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};


export const createMedia = body => async (dispatch) => {
  try {
    return await apiMethods.media.create(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateMedia = body => async (dispatch) => {
  try {
    return await apiMethods.media.update(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeMedia = publicId => async (dispatch) => {
  try {
    const body = { publicIds: [publicId] };
    await apiMethods.media.remove(body);
    dispatch(splitMedia(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const addMediaTo = (model, publicId, data) => async (dispatch) => {
  try {
    const body = { publicId, media: [] };
    if (Array.isArray(data) && data.length > 0) {
      body.media = data.map(media => media._id);
    } else {
      body.media.push(data._id);
    }
    await apiMethods[model].addMedia(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeMediaFrom = (model, modelId, media) => async (dispatch) => {
  try {
    const body = {
      publicId: modelId,
      media: media._id
    };
    await apiMethods[model].removeMedia(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
