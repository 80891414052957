import { articleTypes } from '../actions/actionTypes';
import { storeUtils } from '../../services/utilsService';

const initialState = {
  articleList: [],
  articleDetails: {},
  topArticles: [],
  trainerArticles: [],
  trainerDetails: {},
  articles: [],
  category: {},
  categories: [],
};

const achievementReducer = (state = initialState, action) => {
  switch (action.type) {
    case articleTypes.SET_ARTICLES:
      return { ...state, articleList: action.payload };
    case articleTypes.SET_BLOG_CATEGORIES:
      return { ...state, categories: action.payload };
    case articleTypes.SET_ARTICLE_DATA:
      return {
        ...state,
        articleDetails: action.payload
      };
    case articleTypes.CLEAR_ARTICLE_DATA:
      return {
        ...state,
        articleDetails: null
      };
    case articleTypes.SET_TRAINERS_ARTICLES:
      return {
        ...state,
        topArticles: action.payload.topArticles,
        trainerArticles: action.payload.articles,
        trainerDetails: action.payload.userData
      };
    case articleTypes.SET_CATEGORY_ARTICLES:
      return {
        ...state,
        category: action.payload.category,
        articles: action.payload.articles,
      };
    case articleTypes.CLEAR_TRAINER_ARTICLES:
      return {
        ...state,
        topArticles: null,
        trainerArticles: null,
        trainerDetails: null
      };
    case articleTypes.CLEAR_ARTICLES:
      return {
        ...state,
        articles: null,
        articleList: [],
      };
    case articleTypes.REMOVE_ARTICLE: {
      const articleList = storeUtils.removeFromArrayInStoreByPublicId(state.articleList, action.payload);
      return { ...state, articleList };
    }
    default:
      return state;
  }
};

export default achievementReducer;
