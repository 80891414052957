/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Element, scroller } from 'react-scroll';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Field } from 'formik';
import {
  FormGroup, Label
} from 'reactstrap';
import {
  FormikReactSelect,
  FormikTagsInput,
  FormikSwitch
} from './FormikFields';
import { utils } from '../../services/utilsService';
import { langs } from '../../langs';
import { actionTypes } from '../../store/actions/actionTypes';
import QuillTextEditor from './QuillTextEditor';
import './formikStyle.css'

const Input = ({ name, type, id, label, value, onChange, className, placeholder, initValue, validation,  ...props }) => {
  return (
    <input
      name={name}
      id={id}
      className="form-control"
      type={type}
      {...validation}
      defaultValue={initValue}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
      label={label || name}
      />
  );
};

const TextareaInput = ({ name, id, label, value, onChange, className, placeholder,initValue, ...props }) => {
  return (
    <textarea
      name={name}
      id={id}
      className="form-control"
      defaultValue={initValue}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
      label={label || name}
      />
  );
};

class FormikInputs extends Component {
  constructor (props) {
    super(props);
    this.state = {
      inputKeys: [],
      inputInitialValues: this.props.initialValues || {},
      dateToday: moment().format('YYYY-MM-DD')
    };
  }

  handleChange (e, key, formProps) {
    if (e && e.target && (e.target.type === 'checkbox' || e.target.type === 'radio')) {
      formProps.setFieldValue(key , e.target.checked)
    } else if (e && e.target && e.target.type === 'number'){
      formProps.setFieldValue(key , Number(e.currentTarget.value))
    } else {
      const value = e && e.currentTarget ? e.currentTarget.value : e;
      formProps.setFieldValue(key , value);
    }
    if (this.props && this.props.checkChanges) {
      if (this.props.formHasChanged) this.props.formHasChanged();
      else this.props.hasChanged = true;
    }
  }

  handleReactSelect (selectedOption, key, formProps) {
    formProps.setFieldValue(key , selectedOption);
    if (this.props && this.props.checkChanges) {
      if (this.props.formHasChanged) this.props.formHasChanged();
      else this.props.hasChanged = true;
    }
  }

  validateInputs (value, prop) {
    const { validation, label} = prop;
    const { locale } = this.props;
    let error = undefined;
    if(!validation) return error;
    if ((!value || value === "") && (validation && validation.required)) {
      error = langs[locale].isRequired(langs[locale][label]);
    } else if (value && validation) {
      if (validation.minLength && (value && value.length) < validation.minLength) {
        error = langs[locale].tooShort(langs[locale][label], validation.minLength);
      } else if (validation.maxLength && (value && value.length) > validation.maxLength) {
        error = langs[locale].tooLong(langs[locale][label], validation.minLength);
      } else if (prop.type === 'number' && validation.min && value < validation.min) {
        error = langs[locale].rangeUnderflow(langs[locale][label], validation.min);
      } else if (prop.type === 'number' && validation.max && value > validation.max) {
        error = langs[locale].rangeOverflow(langs[locale][label], validation.max);
      } else if (prop.type === 'number' && (validation.max && validation.min) && (value > validation.max || value < validation.min)) {
        error = langs[locale].rangeBetween(langs[locale][label], validation.min, validation.max );
      }
    }
    if (error && error.length > 3) {
      if(this.timeoutForm) clearTimeout(this.timeoutForm);
      this.timeoutForm = setTimeout(() => {
        const errorElement = document.getElementById('errorTip');
        if (errorElement) {
          scroller.scrollTo('errorTip', {
            duration: 600,
            delay: 100,
            smooth: true,
            offset: -150
          });
        }
        clearTimeout(this.timeoutForm)
      },400)
    }
    return error;
  };

  renderInput (prop, key, formProps) {
    const elementType = prop.elementType || 'input';
    const type = prop.type || 'text';
    const config = prop.config || null;
    const validation = prop.validation || null;
    const isMulti = prop.isMulti ? prop.isMulti : false;
    this.state.inputInitialValues[key] = prop.initValue || formProps.values && formProps.values[key] ? (prop.initValue || formProps.values[key]) : null;
    let {locale} = this.props;
    if (!locale || (locale !== 'en' && locale !== 'he')) locale = 'en';
    const placeholder = langs && langs[locale] && langs[locale][prop.placeholder || prop.label || prop.name] ? langs[locale][prop.placeholder || prop.label || prop.name] : (prop.placeholder || prop.label || prop.name);
    switch (elementType) {
      case ('input'):
        return (
          <Field
            name={key}
            component={Input}
            id={key}
            className="form-control"
            label={prop.label || key}
            placeholder={placeholder}
            validation={prop.validation}
            type={type}
            initValue={(formProps.values && formProps.values[key]) || prop.initValue}
            value={(formProps.values && formProps.values[key])}
            key={`i_${key}`}
            {...config}
            validate={(value) => this.validateInputs(value, prop)}
            onChange={(e) => this.handleChange(e, key, formProps)}
            onBlur={formProps.setFieldTouched}
            />
        );
      case ('textarea'):
        return (
          <Field
            name={key}
            component={TextareaInput}
            id={key}
            className="form-control"
            label={prop.label || key}
            placeholder={placeholder}
            type={type}
            initValue={(formProps.values && formProps.values[key]) || prop.initValue}
            value={(formProps.values && formProps.values[key])}
            key={`i_${key}`}
            {...config}
            validate={(value) => this.validateInputs(value, prop)}
            onChange={(e) => this.handleChange(e, key, formProps)}
            onBlur={formProps.setFieldTouched}
          />
        );
      case ('select'):
        return (
          <div className="flex-100 layout-row layout-align-center-center positionRelative inputSelect selectContainer">
            <select
              id={key}
              className="form-control minimal"
              key={`i_${key}`}
              defaultValue={(formProps.values && formProps.values[key]) || prop.initValue}
              onChange={(e) => this.handleChange(e, key, formProps)}>
              {prop.options && Array.isArray(prop.options) ? utils.getOptionsByArray(prop.options, locale) : utils.getOptionsByObject(prop.options, locale)}
            </select>

          </div>
        );
      case ('multiSelect'):
        return (
          <FormikReactSelect
            name={key}
            id={key}
            value={(formProps.values && formProps.values[key]) || prop.initValue}
            isMulti={isMulti}
            options={prop.options || this.props.multiSelectOptions}
            onChange={formProps.setFieldValue}
            onBlur={formProps.setFieldTouched}
            key={`i_${key}`}
          />
        );
      case ('ReactSelect'): {
        const style = {
          control: () => ({
            borderRadius: '0.1rem',
            outline: 'initial !important',
            boxShadow: 'initial !important',
            fontsize: '0.95rem',
            padding: '0.3rem',
            lineHeight: 1.5,
            border: '1px solid #d7d7d7',
            background: 'white',
            color: '#303030',
            flexDirection: 'row',
            display: 'flex'
          })
        };
        const originalArray = prop.options || this.props.multiSelectOptions;
        let selectOptions = [];
        if (originalArray && originalArray.length > 0){
          selectOptions = originalArray.map((obj) => {
            return {
              label: this.props.locale ? obj.title[this.props.locale] : obj.title,
              value: obj._id,
              _id: obj._id
            };
          });
        }
        return (
          <Select
            className="width100 ReactSelect layout-row layout-wrap layout-align-center-center"
            options={selectOptions}
            styles={style}
            isMulti={isMulti}
            defaultValue={this.props.multiSelectDefaultValue}
            closeMenuOnSelect={!isMulti}
            formatGroupLabel={utils.formatGroupLabel}
            onChange={(selectedOption) => { this.handleReactSelect(selectedOption, key, formProps) }} />
        );
      }
      case ('ReactSelectAsync'): {
        const style = {
          control: () => ({
            borderRadius: '0.1rem',
            outline: 'initial !important',
            boxShadow: 'initial !important',
            fontsize: '0.95rem',
            padding: '0.3rem',
            lineHeight: 1.5,
            border: '1px solid #d7d7d7',
            background: 'white',
            color: '#303030',
            flexDirection: 'row',
            display: 'flex'
          })
        };
        return (
          <AsyncSelect
            className="width100 ReactSelect layout-row layout-wrap layout-align-center-center mb-3"
            options={prop.options || this.props.multiSelectProps}
            cacheOptions
            defaultOptions
            loadOptions={(value) => {this.props.loadaAsyncSelectOption(value)}}
            styles={style}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            onChange={(selectedOption) => { this.handleReactSelect(selectedOption, key, formProps) }} />
        );
      }
      case ('tags'):
        return (
          <FormikTagsInput
            name={key}
            value={(formProps.values && formProps.values[key]) || prop.initValue}
            onChange={(e) => this.handleChange(e, key, formProps)}
            onBlur={formProps.setFieldTouched}
          />
        );
      case ('switch'):
        return (
          <FormikSwitch
            name={key}
            className="custom-switch custom-switch-primary"
            value={(formProps.values && formProps.values[key]) || prop.initValue}
            onChange={(e) => this.handleChange(e, key, formProps)}
            onBlur={formProps.setFieldTouched}
          />
        );
      case ('checkBox'):
        let isChecked = {};
        if(key.indexOf('.')) {
          const props = key.split('.');
          if (!isChecked[props[0]]) {
            isChecked[props[0]] = {};
          }
          isChecked = formProps.values[props[0]] && formProps.values[props[0]][props[1]];
        } else {
          isChecked = (formProps.values && formProps.values[key])
        }
        return (
          <div key={`i_${key}`} className="flex-100 layout-row layout-wrap layout-align-start-center">
            <div className="flex-100 layout-row layout-align-start-center checkBox">
              <div className="checkbox-container">
                <input
                  key={`i_${key}`}
                  id={key}
                  name={key}
                  {...config}
                  {...validation}
                  type="checkbox"
                  label={prop.label || key}
                  checked={isChecked || prop.isSelected}
                  {...validation}
                  onChange={(e) => { this.handleChange(e, key, formProps); }} />
              </div>
              <p className={`flex paddStart15px margin0 ${prop.checkBoxClass || ''}`} onClick={(event) => { prop.clickText && this.handleChange(this.state[key], key); }}>
                {prop.label}
              </p>
            </div>
          </div>
        );
      case ('radio'):
        return (
          <div key={`i_${key}`} className="flex-100 layout-row layout-wrap layout-align-start-center">
            <div className="flex-100 layout-row layout-align-start-center checkBox">
              <div className="checkbox-container">
                <input
                  key={`i_${key}`}
                  id={key}
                  name={prop.group}
                  {...config}
                  {...validation}
                  type="radio"
                  label={prop.label || key}
                  defaultValue={(formProps.values && formProps.values[key]) || prop.initValue}
                  value={(formProps.values && formProps.values[key]) || prop.initValue}
                  ref={input => this.state[key] = input}
                  {...validation}
                  onChange={(e) => { this.handleChange(e, key, formProps); }} />
              </div>
              <p className={`flex paddStart15px margin0 ${prop.checkBoxClass || ''}`} onClick={(event) => { prop.clickText && this.handleChange(this.state[key], key); }}>
                {this.props.children}
              </p>
            </div>
          </div>
        );
      case ('datePicker'):
          return (
            <div className="datePickerInput flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
              <input
                id={key}
                name={key}
                {...config}
                {...validation}
                label={prop.label || key}
                defaultValue={(formProps.values && formProps.values[key]) || prop.initValue}
                type="date"
                ref={input => this.state[key] = input}
                key={`i_${key}`}
                placeholder={placeholder}
                onChange={(e) => { this.handleChange(e, key, formProps); }}
                className="form-control" />
            </div>
          );
      case ('quill-text'):
        return (
          <div className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
            <QuillTextEditor value={(formProps.values && formProps.values[key]) || prop.initValue} onChange={(value) => { this.handleChange(value, key, formProps); }} />
          </div>
);
      case ('none'):
        return (<div className="datePickerInput flex-initial layout-row layout-wrap layout-align-start-start positionRelative" />);
      default:
        return (<div className="datePickerInput flex-initial layout-row layout-wrap layout-align-start-start positionRelative" />);
    }
  }

  /* {
       handleSubmit,
       setFieldValue,
       setFieldTouched,
       handleChange,
       handleBlur,
       values,
       errors,
       touched,
       isSubmitting
     }
  */

  namePropErrorCheck(name, formProps){
    if (name.indexOf('.') !== -1) {
      const nameArr = name.split('.')
      if ((formProps.errors[nameArr[0]] && formProps.errors[nameArr[0]][nameArr[1]]) && (formProps.touched[nameArr[0]] && formProps.touched[nameArr[0]][nameArr[1]])) {
        return formProps.errors[nameArr[0]][nameArr[1]];
      }
    } else if (formProps.errors[name] && formProps.touched[name]) return formProps.errors[name]
    return false
  }

  renderForm (formProps) {
    const { formId, model, commentClasses, noLabel, formGroupClasses, editMode} = this.props;
    return model.map((prop) => {
      const key = prop.name;
      if (editMode && prop.noEditMode) return null;
      if (this.state.inputKeys.indexOf(prop.name) === -1) { this.state.inputKeys.push(prop.name); }
      const error = this.namePropErrorCheck(key, formProps)
      return (
        <div key={key} id={`${formId || ''}_${prop.name}_id`} className={`renderInput ${prop.classes}`}>
          <FormGroup className={`error-l-100 ${formGroupClasses || ''}`}>
            { !noLabel && !prop.labelBottom && (
              <Label className={`mb-1 fontWeight600 text-capitalize ${prop.labelClasses}`}>
                <Trans i18nKey={prop.label || key} />
              </Label>
            )}
            <div className="width100 layout-row layout-align-center-center positionRelative">
              {this.renderInput(prop, key, formProps)}
              { prop.sideText && (
                <span className="sideInputText layout-row layout-align-center-center">
                  <p className="flex-initial colorGrey font15 margin0 "><Trans i18nKey={prop.sideText || ''} /></p>
                </span>
              )}
            </div>
            { error ? (
              <div id="errorTip" className="invalid-feedback d-block">
                <Element key="errorTip" name="errorTip" className="flex layout-row layout-wrap layout-align-start-start">
                  { error }
                </Element>
              </div>
            ) : null}
            { prop.labelBottom && (
              <Label className={`mb-2 text-capitalize ${prop.labelClasses}`}>
                <Trans i18nKey={prop.label || key} />
              </Label>
            )}
            {prop.comment && (
              <span className={`font12 colorGrey ${commentClasses || ''}`}><Trans i18nKey={prop.comment} /></span>
            )}
          </FormGroup>
        </div>
      );
    });
  }

  render () {
    const { formProps } = this.props;
    this.state.inputKeys = [];
    const formInputs = this.renderForm(formProps);
    return formInputs
  }
}

const mapStateToProps = state => ({
  locale: state.appR.locale
});

const mapDispatchToProps = dispatch => ({
  dateInputChange: value => dispatch({ type: actionTypes.DATE_INPUT_CHANGE, payload: value })
});

export default connect(mapStateToProps, mapDispatchToProps)(FormikInputs);
