/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { actionTypes } from '../../store/actions/actionTypes';
import TrainerWelcomePage from '../WelcomeUserPage/TrainerWelcomePage'
import './modalStyle.css';

class WelcomeModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isPageReady: false,
      profession: this.props.alertProps.userType,
      hasChanged: false
    };
  }

  async componentDidMount () {
    this.setState({ isPageReady: true });
  }

  toggle() {
    this.props.hideAlert();
  }

  formHasChanged() {
    this.state.hasChanged = true;
  }

  finishOnBoarding(data){
    if (this.isLoading) { return; }
    this.isLoading = true;
    try {
      console.log('finishOnBoarding: ', data);
      localStorage.setItem('welcomeOnBoarding', 'true');
      this.toggle()
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }

  render () {
    const { inputs, userType } = this.state;
    return (
      <Modal isOpen backdrop className={`modalCenterScreen ${this.props.alertProps.className}`}>
        { this.state.isPageReady && (
          <ModalBody className="width100 layout-row layout-wrap layout-align-start-start">
            <TrainerWelcomePage finishOnBoarding={(data) => { this.finishOnBoarding(data)} } />
          </ModalBody>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);
