/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import Confetti from 'react-confetti';
import { connect } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';
import { Trans } from 'react-i18next';
import { actionTypes } from '../../store/actions/actionTypes';
import OrderTemplate from './invoiceTemplate';
import '../Modals/modalStyle.css';
import AspireBtnLoader from '../../Components/UI/AspireBtnLoader';

class OrderReviewModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      updatedPlace: false,
      isPageReady: false,
      order: {},
    };
  }

  async componentDidMount () {
    this.setState({ isPageReady: true, order: this.props.alertProps.order  });
  }

  toggle() {
    this.props.hideAlert();
  }

  render () {

    const windowSize = { height: window.outerHeight, width: window.innerWidth };
    const confetti = (
      <Confetti
        numberOfPieces={100}
        width={windowSize.width}
        height={windowSize.height}
        colors={['#384ab4', '#5855a0', '#824187', '#eb4b93', '#d74c3b', '#e1774a', '#eea04f']} />
    );
    return (
      <Modal isOpen toggle={() => this.toggle()} backdrop className="modalCenterScreen OrderReviewModal">
        <div className="width100 layout-row layout-wrap layout-align-start-start fullSizeWindow positionAbsoluteTopLeft content-start zIndex5">
          {confetti}
        </div>
        { this.state.isPageReady && (
          <ModalBody className="width100 layout-row layout-wrap layout-align-start-start" style={{position:'relative', zIndex: 10}}>
            <form name="orderForm" onSubmit={(event) => { this.toggle(event); }} className="flex-100 layout-row layout-wrap layout-align-start-start">
              <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                  <OrderTemplate order={this.state.order} locale={this.props.locale} />
                </div>
                <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                  <div className="flex layout-row layout-wrap layout-align-center-center pt-3">
                    <button id="confirm_submitBtn" className="btn btn-primary flex-gt-md-40 flex-md-50 flex-sm-50 flex-xs-100 text-center fontWeight600" type="submit"><Trans i18nKey="Continue" /></button>
                  </div>
                  {  this.props.alertProps.cancelBtn && (
                    <div className="flex-50 layout-row layout-wrap layout-align-center-start mb-3">
                      <AspireBtnLoader
                        btnText="Switch to free Service"
                        containerClassName="flex-initial layout-row layout-wrap layout-align-center-center"
                        classNameText="font15 aspire-text text-capitalize"
                        className="height50px whiteBg"
                        btnClicked={() => (this.props.alertProps.cancelSubscription())} />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </ModalBody>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderReviewModal);
