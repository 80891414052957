import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import LoadingDots from './LoadingDots';
import './UIStyle.css';

class BtnLoader extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      shouldUpdate: false
    };
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !!((this.props.pageHeader && this.props.pageHeader.validationError) || nextState.shouldUpdate || nextProps.shouldUpdate);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

  }

  toggleLoading () {
    this.setState({ isLoading: true, shouldUpdate: true });
    if (this.props.btnClicked) this.props.btnClicked();
  }

  render () {
    if (this.props.pageHeader && this.props.pageHeader.validationError) this.state.isLoading = false;
    const { containerClassName, className, classNameText } = this.props;
    return (
      <div
        className={`BtnLoader ${this.state.isLoading ? 'isLoading' : ''}  ${containerClassName || 'flex-initial layout-row layout-wrap layout-align-center-center'} positionRelative`}>
        <button
          type={this.props.type || 'button'}
          className={`flex-100 text-center fontWeight600 ${className || 'btn btn-primary'}`}
          onClick={() => {
            this.toggleLoading();
          }}>
          <span className={`text ${classNameText || ''}`}>
            <Trans i18nKey={this.props.btnText || 'next'} default={this.props.btnText || 'next'} />
          </span>
          <div className="flex-initial positionAbsoluteTopLeft isLoadingDots padd10 width100">
            <LoadingDots />
          </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pageHeader: state.appR.pageHeader // all the permissions
});

export default connect(mapStateToProps)(BtnLoader);
