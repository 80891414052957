/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { CardHeader } from 'reactstrap';
import { getExercisesFilter, getPopularExercises } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import ExercisesList from '../../Components/WorkoutList/ExercisesList';
import Filter from '../../Components/Filter/Filter';
import './ModalStyles.css';

class ExercisesListSearchPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      title: this.props.title || 'addExercise',
      description: this.props.description || 'chooseExercises_sub',
      isLoading: true,
      isChanged: false
    };
    const storeProps = { ...this.props };
    storeProps.setCurrentPage();
  }

  async componentDidMount () {
    if (this.props.exerciseIds && this.props.exerciseIds.length === 0) {
      await this.props.getExercisesFilter();
      this.setState({ ...this.state, isPageReady: true });
    }
    window.addEventListener('resize', this.resizeVirtualList);
  }

  exerciseSelected () {
    this.state.isChanged = true;
  }

  clickNext() {
    if (this.props.clickNext) this.props.clickNext();
  }

  getSelectedExercises (exercises) {
    this.props.getSelectedExercises(exercises);
    if (this.props.isModalPage) this.props.hideAlert();
  }

  renderNavTopForModal () {
    return (
      <div className="NavTop flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
        <div className="content-header flex-100 layout-row layout-wrap layout-align-start-start directionChangeRtl">
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <div className="flex-100 layout-row layout-wrap layout-align-space-between-start">
              <CardHeader className="width100 pt-3 pb-0 sp-0">
                <div className="flex-100 layout-row layout-wrap layout-align-space-between-start">
                  <div className="flex-initial layout-row layout-wrap layout-align-start-start sp-2">
                    { this.props.isModal && this.props.alertProps && this.props.alertProps.buttonRight && this.props.alertProps.buttonRight.text ? (
                      <button
                        type="button"
                        className="flex-initial textIcon btn-transparent"
                        onClick={() => this.props.alertProps.buttonRight.onClick()}>
                        { this.props.alertProps.buttonRight.text }
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="flex-initial padd0 btn-transparent"
                        onClick={() => this.props.clickBack()}>
                        <i className="la la-arrow-left font24" />
                      </button>
                    )}
                  </div>
                  <div className="flex layout-row layout-wrap layout-align-start-start">
                    <h4 className="mb-1 text-capitalize"><Trans i18nKey={this.state.title} /></h4>
                    <p className="text-muted font13 fontWeight600 mb-0"><Trans i18nKey={this.state.description} /></p>
                  </div>
                  { this.props.showNextBtn && (
                    <div className="flex-initial layout-row layout-wrap layout-align-center-center">
                      <button
                        type="button"
                        className="width100 textIcon btn btn-transparent sp-3 text-center nextBtn"
                        onClick={() => this.clickNext()}>
                        <p className="margin0 font16"><Trans i18nKey="next" /></p>
                      </button>
                    </div>
                  )}
                </div>
              </CardHeader>

            </div>
            <div id="filterContainerId" className="width100 layout-row layout-wrap layout-align-start-start FilterContainer mb-2">
              <Filter filterIcon />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render () {
    const isModalPage = this.renderNavTopForModal();
    return (
      <div className="ExercisesListSearchPage flex-100 layout-row layout-wrap layout-align-start-start fullSizeWindow content-start whiteBackground">
        { isModalPage }
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <ExercisesList
            locale={this.props.locale}
            checkChanges
            showCheckBox
            showSkeleton
            isPopulate
            markSelected
            virtualListHieght={window.innerHeight - 120}
            hasChanged={() => this.exerciseSelected()}
            getSelectedExercises={workoutLog => this.getSelectedExercises(workoutLog)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  exerciseListKV: state.exerciseR.exerciseListKV,
  exerciseIds: state.exerciseR.exerciseIds,
  locale: state.appR.locale,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  setCurrentPage: () => dispatch({ type: actionTypes.SET_CURRENT_PAGE, payload: 'createWorkout' }),
  getPopularExercises: (query) => dispatch(getPopularExercises(query)),
  getExercisesFilter: () => dispatch(getExercisesFilter()),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});
export default connect(mapStateToProps, mapDispatchToProps)(ExercisesListSearchPage);
