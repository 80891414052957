import { defaultDirection } from '../constants/defaultValues';
import localStorage from "local-storage";

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    let A = a[key],
      B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  const direction = localStorage.get('direction') || defaultDirection
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};


export const setTheme = () => {
  const theme = localStorage.get('__theme_color')
  if (theme.indexOf('dark') !== -1) document.body.classList.add('dark');
};
