import { actionTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import localStorage from 'local-storage';
import { handleErrorResponse } from './appActions';

export const loginUserSuccess = user => ({
  type: actionTypes.LOGIN_USER_SUCCESS,
  payload: user
});

export const loginUserError = message => ({
  type: actionTypes.LOGIN_USER_ERROR,
  payload: { message }
});

export const forgotPasswordSuccess = forgotUserMail => ({
  type: actionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = message => ({
  type: actionTypes.FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPasswordSuccess = newPassword => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = message => ({
  type: actionTypes.RESET_PASSWORD_ERROR,
  payload: { message }
});

export const registerUserSuccess = user => ({
  type: actionTypes.REGISTER_USER_SUCCESS,
  payload: user
});
export const registerUserError = message => ({
  type: actionTypes.REGISTER_USER_ERROR,
  payload: { message }
});

export const loginWithEmailPassword = body => async (dispatch) => {
  try {
    const authUser = await apiMethods.firebaseAuth.signInWithEmailAndPassword(body);
    if (!authUser.message) {
      localStorage.set('Aspire-auth', authUser.user.uid);
      dispatch(loginUserSuccess(authUser.user));
      return true;
    }
    dispatch(loginUserError(authUser.message));
    return false;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const registerUserWithEmailAndPassword = body => async (dispatch) => {
  try {
    const registerUser = await apiMethods.firebaseAuth.createUserWithEmailAndPassword(body);
    if (!registerUser.message) {
      localStorage.set('Aspire-auth', registerUser.user.uid);
      dispatch(registerUserSuccess(registerUser.user));
      return true;
    }
    dispatch(registerUserError(registerUser.message));
    return false;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const signOutUser = body => async (dispatch) => {
  try {
    await apiMethods.firebaseAuth.signOut();
    localStorage.remove('Aspire-auth');
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const forgotUserPassword = email => async (dispatch) => {
  try {
    const forgotPasswordStatus = await apiMethods.firebaseAuth.forgotPassword(email);
    if (!forgotPasswordStatus) {
      dispatch(forgotPasswordSuccess('success'));
      return true;
    }
    dispatch(forgotPasswordError(forgotPasswordStatus.message));
    return false;
  } catch (e) {
    dispatch(forgotPasswordError(e));
  }
};

export const resetUserPassword = body => async (dispatch) => {
  try {
    const resetPasswordStatus = await apiMethods.firebaseAuth.resetPassword(body.resetCode, body.newPassword);
    if (!resetPasswordStatus) {
      dispatch(resetPasswordSuccess('success'));
      return true;
    }
    dispatch(resetPasswordError(resetPasswordStatus.message));
    return false;
  } catch (e) {
    dispatch(registerUserError(e));
  }
};
