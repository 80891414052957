const subscriptionAccessControl = {
  trial: {
    allAccess: true
  },
  basic: {
    user: {
      // create other user roles like:  manager(gym/studio) - sales - costume agents ets....
      profileAccess: true,
      programsAccess: true,
      achievementsAccess: true,
      statisticsAccess: true,
      actionsAccess: true,
      calendarAccess: true,
      paperworkAccess: true,
      settingsAccess: true,
      inviteClient: true,
      startSession: true
    },
    programs: {
      basicProgram: true, // access to accessAdvanced programs page - multi programs
      multiProgram: false, // access to accessAdvanced programs page - multi programs
      programProgress: false, // access to accessAdvanced programs page - multi programs
      myExercises: false,
    },
    myExercises: {
      access: true, // access to plans page
      actions: {
        create: false, // Permission to create programs
      }
    },
    statistics: {
      bodyStats: true,
      performance: true
    },
    achievements: {
      goals: true,
      achievements: true
    },
    paperwork: {
      access: true, // access to plans page
      documents: true,
      survey: true,
      actions: {
        create: false, // Permission to create programs
      }
    }
  },
  pro: {
    allAccess: true,
    user: {
      // create other user roles like:  manager(gym/studio) - sales - costume agents ets....
      profileAccess: true,
      programsAccess: true,
      achievementsAccess: true,
      statisticsAccess: true,
      actionsAccess: true,
      calendarAccess: true,
      paperworkAccess: true,
      settingsAccess: true,
      inviteClient: true,
      startSession: true
    },
    programs: {
      basicProgram: true, // access to accessAdvanced programs page - multi programs
      multiProgram: true, // access to accessAdvanced programs page - multi programs
      programProgress: true, // access to accessAdvanced programs page - multi programs
      myExercises: true,
    },
    statistics: {
      bodyStats: true,
      performance: true
    },
    achievements: {
      goals: true,
      achievements: true
    },
    paperwork: {
      access: true, // access to plans page
      documents: true,
      survey: true,
      actions: {
        create: true, // Permission to create programs
      }
    }
  }
};

module.exports = subscriptionAccessControl;
