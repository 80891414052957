import React from 'react';
import { Card, Button } from 'reactstrap';
import { Trans } from 'react-i18next';
import { styleUtils } from '../../services/utilsService';
import { aspirePlaceholder } from '../../services/enums';

const Album = props => (
  <Card className="flex-100 layout-row layout-wrap layout-align-start-start bordered mb-3" onClick={() => props.onClick()}>
    <div className="flex-100 position-relative">
      <div
        className="backgroundImage height130px width100 layout-row layout-wrap layout-align-start-start borderRadius top"
        style={styleUtils.imageBackground(props.thumbnail, 'rgba(0, 0, 0, 0.6)', 'rgba(0, 0, 0, 0.3)', null, 0, aspirePlaceholder[0])} />
    </div>
    <div className="flex-100 layout-row layout-wrap layout-align-start-start sp-md-3 sp-xs-2 pt-md-3 pt-xs-2 pb-0">
      <p className="mb-2 flex-100 fontWeight600 textLimitWidth" style={{ width: '44px' }}>{props.name}</p>
      <p className="mb-2 flex-100 font13 text-muted">
        {`${props.count} `}<Trans i18nKey="photos" />
      </p>
      <div className="flex-100 fontWeight600 text-uppercase">
        <Button
          color="transparent"
          className="width100 text-center sp-md-3 sp-xs-0">
          <p className="mb-0 flex-100 fontWeight600 text-uppercase"><Trans i18nKey="details" /></p>
        </Button>
      </div>
    </div>
  </Card>
);

export default Album;
