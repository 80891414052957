/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import AlertModal from './AlertModal';

class ModalsContainerThird extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  openModal () {
    switch (this.props.alert3Props.id) {
      case eventActions.ALERT_MODAL_3:
        return <AlertModal alertProps={this.props.alert3Props} hideAlertBtn={this.props.hideAlert3} />;
    }
  }

  render () {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start alert3Props">
        { this.props.alert3Props && this.props.alert3Props.isOpen && this.openModal() }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  alert3Props: state.appR.alert3Props,
  notificationProps: state.appR.notificationProps
});

const mapDispatchToProps = dispatch => ({
  hideAlert3: () => dispatch({ type: actionTypes.HIDE_ALERT3 })
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsContainerThird);
