import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import i18n from './services/translationService';
import * as serviceWorker from './serviceWorker';
import App from './Containers/App/App';
import store from './store/store';
import 'moment/locale/he';
import 'moment/locale/en-il';
import '@mobiscroll/react/dist/css/mobiscroll.react.scss';



ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
