/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import nanoid from 'nanoid';
import { updateClientPassword, updateEvent, updateFollowingEvents, updateAllEvents, removeEvent, removeFollowingEvents } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import Input from '../../Components/UI/Input';
import BtnLoader from '../../Components/UI/BtnLoader';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


export const editEventOptions = [
  { containerClass: 'flex-100 mb-2', name: 'single', group: 'editEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'thisEventOnly', conditionType: 'single' },
  { containerClass: 'flex-100 mb-2', name: 'following', group: 'editEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'thisEventAndFollowing', conditionType: 'following' },
  { containerClass: 'flex-100 mb-2', name: 'allEvents', group: 'editEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'allEvents', conditionType: 'allEvents' }
];

export const deleteEventOptions = [
  { containerClass: 'flex-100 mb-2', name: 'single', group: 'deleteEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'thisEventOnly', conditionType: 'single' },
  { containerClass: 'flex-100 mb-2', name: 'following', group: 'deleteEventOptions', elementType: 'radio', value: false, classes: 'flex-initial paddEnd5px', text: 'thisEventAndFollowing', conditionType: 'following' },
];

class EditEventOptionsModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      options: this.props.editEvent ? editEventOptions : deleteEventOptions,
      editEvent: this.props.editEvent,
      chosenOption: 'single',
    };
  }

  setOption(value) {
    this.setState({ chosenOption: value });
  }

  async updateEvent () {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { chosenOption } = this.state;
      const { event, editEvent } = this.props;
      let data = null;
      switch (chosenOption) {
        case 'following':{
          if (editEvent) await this.props.updateFollowingEvents(event);
          else await this.props.removeFollowingEvents(event);
          this.props.onEditDone();
          this.props.hideAlertBtn();
          break;
        }
        case 'allEvents':{
          data = await this.props.updateAllEvents(event);
          this.props.onEditDone();
          this.props.hideAlertBtn();
          break;
        }
        default: {
          if (editEvent) await this.props.updateEvent(event);
          else await this.props.removeEvent(event);
          this.props.onEditDone();
          this.props.hideAlertBtn();
          break;
        }
      }
    } catch (e) {
      this.setState({ error: true });
      this.isLoading = false;
    }
  }

  render () {
    const { options } = this.state;
    return (
      <Modal isOpen toggle={() => this.props.hideAlertBtn()} backdrop className="socialModal" modalClassName="centerModal">
        <div className="flex-100 layout-row layout-align-start-start">
          <div className="flex-100 layout-row layout-wrap layout-align-center-center ">
            <ModalHeader className="width100 p-3 layout-row layout-align-center-center text-center">
              <div className="flex-100 layout-row layout-align-center-center">
                <div className="title boldText text-capitalize">{ this.props.title }</div>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="flex-100 layout-row layout-wrap layout-align-start-start padd20">
                <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                  { options.map((input) => (
                    <div key={input.name} className={`layout-row layout-align-start-center positionRelative ${input.containerClass ? input.containerClass : ''}`}>
                      <Input
                        noLabel
                        id={`${input.name}_${nanoid(4)}`}
                        key={input.name}
                        name={input.name}
                        label={input.name}
                        group={input.group}
                        elementType={input.elementType}
                        locale={this.props.locale}
                        initialValue={this.state.chosenOption === input.conditionType}
                        className="width100"
                        handleChange={() => { this.setOption(input.conditionType); }}>
                        <div className="flex-100 layout-row layout-align-start-center">
                          <p className="margin0"><Trans i18nKey={input.text} /></p>
                        </div>
                      </Input>
                    </div>
                  ))}
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="width100">
              <div className="flex-100 layout-row layout-wrap layout-align-center-center directionRtl">
                <BtnLoader
                  containerClassName="flex layout-row layout-wrap layout-align-center-center alertBtnBg"
                  className="flex padd0 fontWeight600"
                  type="button"
                  btnText="confirm"
                  btnClicked={() => this.updateEvent()} />
                <button type="button" className="flex btn-transparent layout-row layout-wrap layout-align-center-center fontWeight600 text-uppercase font15 p-3" onClick={() => this.props.hideAlertBtn()}><Trans i18nKey="cancel" /></button>
              </div>
            </ModalFooter>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.appR.locale
});

const mapDispatchToProps = dispatch => ({
  updateEvent: data => dispatch(updateEvent(data)),
  removeEvent: data => dispatch(removeEvent(data)),
  updateFollowingEvents: data => dispatch(updateFollowingEvents(data)),
  removeFollowingEvents: data => dispatch(removeFollowingEvents(data)),
  updateAllEvents: data => dispatch(updateAllEvents(data)),
  updateClientPassword: body => dispatch(updateClientPassword(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEventOptionsModal);
