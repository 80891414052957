/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { showAlert } from '../../store/actions';
import { ModalBody } from 'reactstrap';
import { actionTypes } from '../../store/actions/actionTypes';
import ExercisesListSearchPage from './ExercisesListSearchPage';
import ExercisesDnD from '../../Components/SelectedExercises/ExercisesDnD';
import { newLogObj } from '../../services/storeDoll';
import ModalRight from '../Modals/ModalRight';
import './AddExerciseToSession.css';
import NavBottom from '../../Components/NavBottom/NavBottom';
import { allowedFieldsByWorkoutTypes, navigationActions } from '../../services/enums';

class SuperSetExerciseOptions extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isPageReady: false,
      exercises: this.props.alertProps.exercises ? [...this.props.alertProps.exercises] : [],
      trashList: [],
      stage: this.props.data.defaultStage || 1,
      logs: { ...newLogObj(this.props.settings) },
      navBottom: {
        config: [
          {
            key: navigationActions.CHOOSE_EXERCISE,
            text: <Trans i18nKey="done" />
          }
        ]
      }
    };
  }

  async componentDidMount () {
    try {
      this.setState({ isPageReady: true });
    } catch (e) {
      console.log('handle error');
    }
  }

  handleSubmit () {
    this.props.alertProps.onDone(this.state.exercises);
    this.props.hideAlert();
  }

  getSelectedExercises(exercises){
    const exercise = this.modifyExercises(exercises[0])
    this.state.exercises.push(exercise);
    this.setState({ stage: 2 });
  }

  modifyExercises (exercises) { // addWorkoutLog
    const modify = (exercise, index) => {
      const currentEx = exercise;
      if (currentEx && !currentEx.chosenEquipment) {
        currentEx.chosenEquipment = currentEx.defaultEquipment ? currentEx.defaultEquipment : currentEx.equipments[0]; // choosing default if none chosen
      } else if (currentEx && !currentEx.equipments) {
        currentEx.chosenEquipment = currentEx.equipments[0]; // choosing default if none chosen
      }
      // media check
      if (currentEx.chosenEquipment.media && !Array.isArray(currentEx.chosenEquipment.media)) currentEx.chosenEquipment.media = [currentEx.chosenEquipment.media];
      currentEx.order = this.state.exercises.length + index + 1;
      const { exerciseType } = currentEx;
      currentEx.allowedFields = { ...allowedFieldsByWorkoutTypes[exerciseType] };
      return currentEx;
    }
    let exercisesModified = [];
    if (Array.isArray(exercises)) {
      exercisesModified = exercises.map((exercise, index) => {
        return modify(exercise, index);
      });
    } else {
      exercisesModified = modify(exercises, 0);
      const { title, allowedFields, chosenEquipment, equipments, exerciseType, notes, order, public_id, remove, thumbnail, _id} = exercisesModified;
      // clean object;
      exercisesModified = { title, thumbnail, chosenEquipment, exerciseType, equipments, allowedFields, notes, order, public_id, remove, _id }
    }
    return exercisesModified;
  }

  updateExerciseOrder(exercises) {
    this.state.exercises = exercises;
  }

  updateTrashList (trashList) {
    this.state.trashList = trashList;
  }

  closeModal () {
    this.props.hideAlert();
  }

  clickNext () {
    this.setState({ stage: 2 });
  }

  goBack () {
    if (this.props.data.defaultStage < this.state.stage) {
      this.setState({ stage: 1 });
    } else {
      this.closeModal();
    }
  }

  renderNavTopForModal () {
    return (
      <div className="NavTop flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
        <div className="content-header flex-100 layout-row layout-wrap layout-align-start-start directionChangeRtl">
          <div className="flex-100 layout-row layout-wrap layout-align-start-start pt-2 pb-2">
            <div className="flex layout-row layout-wrap layout-align-start-start sp-1 NavTop-label-direction">
              <div className="flex-100 layout-row layout-wrap layout-align-start-start ">
                <h3 className="flex-100 title-header fadeInLeft-anime margin0 text-capitalize"><Trans i18nKey="reorderExercises" /></h3>
              </div>
              <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                <p className="fadeInLeft-anime subtitle-header margin0 textLimitWidth">
                  <Trans i18nKey="reorderExercises_sub" />
                </p>
              </div>
            </div>
            <div className="flex-initial layout-row layout-wrap layout-align-start-start sidePadd10px">
              <button
                type="button"
                className="flex-initial padd0 btn-transparent"
                onClick={() => this.goBack()}>
                <i className="la la-arrow-left iconFontSize30 colorWhite" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render () {
    const isModalPage = this.renderNavTopForModal();
    return (
      <ModalRight isOpen
                  toggle={() => this.props.hideAlert()}
                  direction={this.props.locale === 'he' ? 'rtl' : 'ltr'}
                  className="SuperSetExerciseOptions">
        <div className="SuperSetExerciseOptions flex-100 layout-row layout-wrap layout-align-start-start content-start ">
          <ModalBody className="width100 layout-row layout-wrap layout-align-start-start p-0">
            <div className="SuperSetExerciseOptions flex-100 layout-row layout-wrap layout-align-start-start content-start">
              { this.state.isPageReady && this.state.stage === 1 && (
                <div className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
                  <ExercisesListSearchPage
                    locale={this.props.locale}
                    title="selectExercises"
                    showNextBtn
                    clickNext={() => { this.clickNext(); }}
                    clickBack={() => { this.closeModal(); }}
                    getSelectedExercises={exercises => this.getSelectedExercises(exercises)} />
                </div>
              )}
              { this.state.isPageReady && this.state.stage === 2 && (
                <div className="flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                    <ExercisesDnD
                      title="reorderExercises"
                      exercises={this.state.exercises}
                      locale={this.props.locale}
                      disableDeleteNotice
                      showBackBtn
                      goBack={() => { this.goBack(); }}
                      updateExerciseOrder={(exercises) => { this.updateExerciseOrder(exercises); }}
                      updateTrashList={(trashList) => { this.updateTrashList(trashList); }} />
                  </div>
                  <NavBottom config={this.state.navBottom.config} chosenOption={(key) => { this.handleSubmit(key); }} />
                </div>
              )}
            </div>
          </ModalBody>
        </div>
      </ModalRight>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  workoutLogsKV: state.sessionR.workoutLogsKV,
  workoutLogsIds: state.sessionR.workoutLogsIds,
  alertProps: state.appR.alertProps,
  locale: state.appR.locale,
  settings: state.userR.settings
});

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperSetExerciseOptions);
