import { actionTypes, products } from '../actions/actionTypes';
import { toast } from 'react-toastify';
import React from 'react';

const initialState = {
  locale: 'en',
  navBottom: { showBottomNav: false, configBottom: [] },
  alertProps: {},
  alert2Props: {}
  // userType
  // navBottomKey: '',
  // chosenPublicId
  // currentPage: ''
  // activityList: []
  // unReadActivitiesList: []
  // searchResults: {},
  // categories: []
  // categoryDetails: {}
  // uploads: {}
  // aspireServices: []
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VALIDATION_ERROR: { // validation Error only
      toast(<span className="fontWeight600">{ action.payload.join(', ') }</span>)
      return { ...state, pageHeader: { ...state.pageHeader, validationError: action.payload } };
    }
    case actionTypes.CLEAR_VALIDATION_ERROR: { // validation Error only
      return { ...state, pageHeader: { ...state.pageHeader, validationError: null } };
    }
    case actionTypes.SAVE_USER_CREDENTIALS:
      return {
        ...state,
        userType: action.payload.user.userType
      };
    case actionTypes.REFRESH_BOTTOM_NAV:
      return { ...state, navBottom: action.payload, navBottomKey: null };
    case actionTypes.BOTTOM_NAV_CLICKED:
      return { ...state, navBottomKey: action.payload };
    case actionTypes.HIDE_ALERT:
      return { ...state, alertProps: { isOpen: false } };
    case actionTypes.SHOW_ALERT:
      return { ...state, alertProps: action.payload };
    case actionTypes.HIDE_ALERT2:
      return { ...state, alert2Props: { isOpen: false } };
    case actionTypes.HIDE_ALERT3:
      return { ...state, alert3Props: { isOpen: false } };
    case actionTypes.SHOW_ALERT2:
      return { ...state, alert2Props: action.payload };
    case actionTypes.SHOW_ALERT3:
      return { ...state, alert3Props: action.payload };
    case actionTypes.HIDE_BOTTOM_NAV:
      return { ...state, navBottom: { showBottomNav: false } };
    case actionTypes.SHOW_BOTTOM_NAV:
      return { ...state, navBottom: { showBottomNav: true } };
    case actionTypes.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case actionTypes.SET_APP_LANG: {
      let locale = action.payload;
      if (!locale || (locale !== 'en' && locale !== 'he' && locale !== 'es')) locale = 'en';
      return { ...state, locale };
    }
    case actionTypes.SET_EXTRA_TITLE:
      return { ...state, extraTitle: action.payload };
    case actionTypes.SET_ACTIVITY_LIST:
      return { ...state, activityList: action.payload };
    case actionTypes.SET_UNREAD_ACTIVITY_LIST:
      return { ...state, unReadActivitiesList: action.payload };
    case actionTypes.SET_DYNAMIC_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload };
    case actionTypes.SET_ALL_CATEGORIES:
      return { ...state, categories: action.payload };
    case actionTypes.SET_CATEGORY_DETAILS:
      return { ...state, categoryDetails: action.payload };
    case actionTypes.SET_UPLOADS_PROGRESS: {
      const uploads = state.uploads || {};
      uploads[action.payload.id] = action.payload.progress
      return { ...state, uploads };
    }
    case products.SET_ASPIRE_SERVICES: {
      return { ...state, aspireServices: action.payload };
    }
    default:
      return state;
  }
};

export default appReducer;
