import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse } from './index';


// Contacts
export const getContacts = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.contacts.getContactsList(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getContactById = id => async (dispatch) => {
  try {
    return await apiMethods.contacts.getContactById(id);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateContact = body => async (dispatch) => {
  try {
    return await apiMethods.contacts.update(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
export const updateReadContact = body => async (dispatch) => {
  try {
    return await apiMethods.contacts.updateReadContact(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeContact = id => async (dispatch) => {
  try {
    const body = {id};
    await apiMethods.contacts.remove(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
