import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import playIcon from '../../assets/icons/timer/play.svg';
import pauseIcon from '../../assets/icons/timer/pause.svg';
import resetIcon from '../../assets/icons/timer/reset.svg';
import CircularProgressBar from '../UI/CircularProgressBar';
import { secondsToText } from '../../services/utilsService';

let offset = null,
  interval = null;

class SimpleTimer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      initialTime: this.props.continueTime || this.props.initialTime || 0,
      isStopWatch: this.props.isStopWatch,
      percentage: this.props.isStopWatch ? 0 : 100,
      time: '00:00',
      options: {
        delay: 500
      },
      timerState: 'INITED'
    };
  }

  componentDidMount () {
    this.play();
  }

  componentWillUnmount () {
    this.pause();
  }

  clickedPlay () {
    if (this.props.activeTimer && interval) this.pause();
    else this.play();
  }

  pause () {
    if (interval) {
      clearInterval(interval);
      interval = null;
      this.setState({ timerState: 'PAUSED' });
    }
  }

  play () {
    if (!interval) {
      offset = Date.now();
      this.state.timerState = 'PLAYING';
      interval = setInterval(() => { this.update(); }, this.state.options.delay);
    }
  }

  reset () {
    const clockReset = this.props.isStopWatch ? 0 : (this.props.continueTime || this.props.initialTime);
    const time = secondsToText(clockReset / 1000);
    clearInterval(interval);
    interval = null;
    this.setState({ initialTime: clockReset, time, timerState: 'INITED' });
  }

  update () {
    let newTime = this.props.continueTime || this.state.initialTime;
    let timeDone = 0;
    if (this.props.isStopWatch) {
      newTime += this.calculateOffset();
      timeDone = newTime;
      if (this.props.circleSize) {
        this.state.percentage = (newTime * 100) / this.props.initialTime;
        if (this.state.percentage >= 100 && this.props.stopOnHundred) {
        }
      }
    } else {
      newTime -= this.calculateOffset();
      this.state.percentage = (newTime * 100) / this.props.initialTime;
      timeDone = this.props.initialTime - newTime;
      if (newTime <= 0) {
        newTime = 0;
        clearInterval(interval);
        interval = null;
      }
    }
    const time = secondsToText(newTime / 1000);
    if (this.props.handleSessionTime) this.props.handleSessionTime(newTime);
    this.setState({ time, initialTime: newTime });
  }

  calculateOffset () {
    const now = Date.now();
    const newOffset = now - offset;
    offset = now;
    return newOffset;
  }

  render () {
    return (
      <div className={`SimpleTimer flex-100 layout-row layout-wrap layout-align-center-center positionRelative ${this.props.className ? this.props.className : ''}`}>
        <div className="flex-100 layout-column layout-wrap layout-align-center-center">
          { this.props.circleSize && (
            <div className="width100 positionAbsoluteTopLeft layout-row layout-wrap layout-align-center-center">
              <CircularProgressBar
                strokeWidth={this.props.strokeWidth || 7}
                sqSize={this.props.circleSize || 120}
                percentage={this.state.percentage && this.state.percentage > 0 ? this.state.percentage : 0}
                clickedPlay={() => this.clickedPlay()}
              />
            </div>
          )}
          <div className="flex-initial layout-row layout-wrap layout-align-center-center sidePadd5px height50px" style={{ height: `${this.props.circleSize || this.props.timeHeight}px` }}>
            {/* TIME TEXT WILL BE SET */}
            <p className="font20 fontWeight400 margin0">{this.state.time}</p>
          </div>
        </div>
        { this.props.showControls && (
          <div className="flex-100 layout-row layout-wrap layout-align-center-center timerControls">
            <div className="flex-90 layout-row layout-wrap layout-align-center-center">
              {(this.state.timerState === 'INITED' || this.state.timerState === 'PAUSED') && (
                <button
                  type="button"
                  onClick={() => { this.play(); }}
                  className="flex-initial layout-row layout-align-center-center padd5 btn-transparent">
                  <ReactSVG src={playIcon} className="timeIcon" />
                </button>
              )}
              {(this.state.timerState === 'PLAYING') && (
                <button
                  type="button"
                  onClick={() => { this.pause(); }}
                  className="flex-initial layout-row layout-align-center-center padd5 btn-transparent">
                  <ReactSVG src={pauseIcon} className="timeIcon" />
                </button>
              )}
              <button
                type="button"
                onClick={() => { this.reset(); }}
                className="flex-initial layout-row layout-align-center-center padd5 btn-transparent">
                <ReactSVG src={resetIcon} className="timeIcon" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SimpleTimer;
