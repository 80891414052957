/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Trans } from 'react-i18next';
import { apiMethods } from '../../services/apiService';
import { mediaGroup } from '../../services/enums';
import { actionTypes } from '../../store/actions/actionTypes';
import GoogleMapUI from '../../Components/Maps/GoogleMapUI'
import Input from '../../Components/UI/Input'
import { googlePlacesApi } from '../../constants/defaultValues';
import { makeABlob, handleUpload, saveUploadedFiles } from '../../services/urlUploadService';
import './modalStyle.css';

class LocationModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      location: this.props.alertProps.location || {},
      googlePlace: {},
      googleMapProps: {
        zoom: 16,
        center: { lat: 32.1650603, lng: 34.8239397 },
        description: null
      },
      updatedPlace: false,
      isPageReady: false,
      inputs: [
        {
          name: 'title',
          label: 'title',
          description: 'location title',
          placeholder: 'write location name',
          type: 'text',
          elementType: 'input',
          validation: {
            required: true,
            minLength: 2
          },
          classes: 'flex-100'
        },
        {
          name: 'description',
          label: 'description',
          placeholder: 'write description',
          description: 'location description',
          elementType: 'textarea',
          validation: {
            rows: 3,
            required: true
          },
          classes: 'flex-100',
        }
      ]
    };
  }

  async componentDidMount () {
    this.setState({ isPageReady: true, editMode: (this.props.location && this.props.location._id)  });
  }

  toggle() {
    this.props.hideAlert();
  }

  reLocateMarker(mapValues) {
    const { googlePlace } = this.state;
    googlePlace.location = mapValues;
    this.setState({ googleMapProps: { zoom: 16, center: mapValues }, googlePlace})
  }

  async setValue(result) {
    if (this.isLoading) { return; }
    this.isLoading = true;
    try {
      const resultAddress = await apiMethods.app.googleGeoCodeByAddress(result.label)
      const googlePlace = {
        title: result.value.structured_formatting.main_text,
        mainText: result.value.structured_formatting.main_text, // "קניון שבעת הכוכבים"
        secondary_text: result.value.structured_formatting.secondary_text, // "Shiv'at Hakohavim Boulevard, Herzliya, Israel"
        place_id: result.value.place_id, // "ChIJKwmJgGRIHRURsC09f7Pw91k"
        description: result.value.description, // "קניון שבעת הכוכבים, Shiv'at Hakohavim Boulevard, Herzliya, Israel"
        terms: result.value.terms.map((term) => term.value),
        formatted_address: resultAddress.results[0].formatted_address, // "Shiv'at Hakohavim Blvd 8, Herzliya, Israel"
        location: resultAddress.results[0].geometry.location // { lat:32.1650603, lng:34.8239397 }
      }
      this.setState({
        googlePlace,
        updatedPlace: true,
        googleMapProps: {
          zoom: 16,
          center: googlePlace.location,
          description: googlePlace.description
        }})
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      console.log(e);
    }
  }

  handleChange (e, key) {
    this.state[key] = e.target.type === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value;
  }

  async uploadMapToS3(body){
    const obj = {
      extension: 'png',
      title: 'location',
      fileType: 'image/png',
      source: `https://maps.google.com/maps/api/staticmap?sensor=false&center=${body.location.lat},${body.location.lng}&zoom=16&size=400x400&style=feature:poi|visibility:off&key=${googlePlacesApi}`,
      type: 'image',
      mediaGroup: mediaGroup.location
    };
    const imageBlob = await makeABlob(obj, this.props.user.public_id, obj.source)
    const s3Response = await handleUpload(this, imageBlob,'Locations')
    const media = await saveUploadedFiles([s3Response]);
    return media;
    // return aspire media object
  }

  async updateLocation(event){
    event.preventDefault();
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let body = {}
      if ( this.state.editMode ){
         body = { ...this.props.location };
      } else {
        body = { ...this.state.googlePlace };
      }
      this.state.inputs.forEach((input) => {
        body[input.name] = this.state[input.name] ? this.state[input.name] : ( this.state.editMode ? body[input.name] : this.state.googlePlace[input.name]);
      })
      body.thumbnail = `https://maps.google.com/maps/api/staticmap?sensor=false&center=${body.location.lat},${body.location.lng}&zoom=16&size=400x400&style=feature:poi|visibility:off&key=${googlePlacesApi}`;
      // uploading google image to S3
      if (this.state.updatedPlace && !this.state.editMode) {
        const location = await this.uploadMapToS3(body)
        if (!body.media) body.media = [];
        body.media = [...body.media , ...location]
      }
      body.hasChanged = true;
      this.props.alertProps.saveLocation.onClick(body);
      console.log(body)
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.isLoading = false;
    }
  }

  render () {
    const { googleMapProps, inputs, googlePlace, location} = this.state;

    return (
      <Modal isOpen toggle={() => this.toggle()} backdrop className="modalCenterScreen">
        { this.state.isPageReady && (
          <ModalBody className="width100 layout-row layout-wrap layout-align-start-start">
            <form name="ProfessionForm" onSubmit={(event) => { this.updateLocation(event); }} className="flex-100 layout-row layout-wrap layout-align-start-start">
              <div className="flex-gt-md-40 flex-md-100 flex-sm-100 flex-xs-100 layout-row layout-wrap layout-align-start-start GooglePlacesAutocomplete mb-4">
                <GoogleMapUI
                  height="400px"
                  width="100%"
                  id="map"
                  zoom={googleMapProps.zoom}
                  lan={(location && location.location && location.location.lng) ||  googleMapProps.center.lng}
                  lat={(location && location.location && location.location.lat) || googleMapProps.center.lat}
                  center={(location && location.location) || googleMapProps.center}
                  description={googleMapProps.description}
                  onClick={(values) => this.reLocateMarker(values)}
              />
              </div>
              <div className="flex-gt-md-60 flex-md-100 flex-sm-100 flex-xs-100 layout-row layout-wrap layout-align-start-start pl-lg-3 pl-xs-0">
                <div className="flex-100 layout-row layout-wrap layout-align-start-start GooglePlacesAutocomplete mb-2">
                  <label className="formBoxInput gradientText text-capitalize valued rendered fontWeight400 valued">
                    <Trans i18nKey="SearchAPlace" />
                  </label>
                  <GooglePlacesAutocomplete
                    minLengthAutocomplete={3}
                    selectProps={{
                      className: "reactSelect-style",
                      onChange: (value) => { this.setValue(value)},
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '0.1rem',
                        border: '1px solid #d7d7d7',
                        height: '50px',
                      })
                    }
                  }}
                />
                </div>
                { inputs.map(input => (
                  <div key={input.label} className={input.classes}>
                    <Input
                      key={input.name}
                      {...input}
                      initValue={location[input.name] || googlePlace[input.name] || ''}
                      locale={this.props.locale}
                      className="width100 mb-2"
                      handleChange={(event, key) => { this.handleChange(event, key); }} />
                  </div>
              ))}
                <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                  <div className="flex-100 layout-row layout-wrap layout-align-center-center pt-3">
                    <button id="location_submitBtn" className="btn btn-primary flex-gt-md-40 flex-md-50 flex-sm-50 flex-xs-100 text-center fontWeight600" type="submit"><Trans i18nKey="saveChanges" /></button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationModal);
