import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { ReactSVG } from 'react-svg'
import nanoid from 'nanoid';
import { showAlert2, checkFBPermissions, refreshFacebookAccess } from '../../store/actions';
import FacebookLogin from '../SocialButtons/FacebookLogin';
import { mediaGroup } from '../../services/enums';
import { eventActions } from '../../store/actions/actionTypes';
import aspireLine from '../../assets/svg/aspire_line_logo.svg';
import '../SocialButtons/SocialButtons.css'
import MediaUploadBox from '../MediaUpload/MediaUploadBox';

class MediaDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlInput: null,
      hasPermissions: false,
      usePermissions: ['instagram_basic', 'user_photos', 'pages_show_list'],
      permissions: null,
      instagram: [],
      mediaUploadProps: {
        isMulti: this.props.isMulti || false,
        folder: this.props.folder || 'Other',
        mediaBoxId: this.props.mediaBoxId || `uploadMedia_${nanoid(5)}`,
        className: this.props.mediaUploadClasses || "smallSquare",
        allowedFileTypes: this.props.allowedFileTypes || ['image/*', 'video/*'],
        mediaGroup:  this.props.mediaGroup || mediaGroup.other
      }
    };
  }

  componentDidMount() {
    try {
      if (this.props.facebookSocial) this.checkFBPermissions();
    } catch (e) {
      console.log('handle error');
    }
  }

  async getProfileInfo() {
    console.log('getInstagramProfileInfo ');
  }

  async checkFBPermissions() {
    try {
      const data = await this.props.checkFBPermissions();
      const { permissions, instagram } = data;
      let hasPermissions = true;
      this.state.usePermissions.forEach((name) => {
        if (!permissions[name]) hasPermissions = false;
      });
      this.setState({ isLoading: false, permissions, hasPermissions, instagram });
    } catch (e) {
      console.log('handle error');
      this.setState({ isLoading: false, hasPermissions: false });
    }
  }

  async responseFacebook(response) {
    try {
      await this.props.refreshFacebookAccess(response);
      await this.checkFBPermissions()
    } catch (e) {
      console.log('handle error');
    }
  }

  openFacebookImages () {
    const data = {
      isOpen: true,
      isModal: true,
      isFacebook: true,
      allowImages: this.props.allowImages,
      allowVideos: this.props.allowVideos,
      isMulti: this.props.isMulti,
      id: eventActions.GET_SOCIAL_MEDIA,
      className: 'modalPage',
      handleSelectedImages: {
        onClick: (media) => {
          this.updateMedia(media);
        }
      }
    };
    this.props.showAlert2(data);
  }

  openInstagramImages () {
    const data = {
      isOpen: true,
      isModal: true,
      isInstagram: true,
      allowImages: this.props.allowImages,
      allowVideos: this.props.allowVideos,
      isMulti: this.props.isMulti,
      accounts: this.state.instagram,
      id: eventActions.GET_SOCIAL_MEDIA,
      className: 'modalPage',
      handleSelectedImages: {
        onClick: (media) => {
          this.updateMedia(media);
        }
      }
    };
    this.props.showAlert2(data);
  }

  openAspireLibrary () {
    const data = {
      isOpen: true,
      isModal: true,
      myMedia: true,
      isMulti: this.props.isMulti,
      allowImages: this.props.allowImages,
      allowVideos: this.props.allowVideos,
      id: eventActions.GET_ASPIRE_LIBRARY,
      className: 'modalPage',
      handleSelectedImages: {
        onClick: (media) => {
          this.updateMedia(media);
        }
      }
    };
    this.props.showAlert2(data);
  }

  updateMedia(media) {
    const mediaIds = media.map((media) => media._id);
    if (this.props.updateMedia) this.props.updateMedia(media, mediaIds);
  }

  renderAspireLibrary(){
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-center-start mb-3">
        <button type="button" onClick={() => this.openAspireLibrary()} className="aspireLibraryBtn aspireBg flex layout-align-center-center text-center">
          <ReactSVG src={aspireLine} className="aspireLine_svg mr-3" />
          <Trans i18nKey="selectMyMedia" />
        </button>
      </div>
    )
  }

  renderFacebookSocial() {
    const { usePermissions, hasPermissions, instagram } = this.state;
    return (
      <Fragment>
        <div className="flex-100 layout-row layout-wrap layout-align-center-start mb-3">
          <FacebookLogin
            dummyButton={hasPermissions}
            onClick={() => { this.openFacebookImages(); }}
            permissions={usePermissions.join(',')}
            responseFacebook={(res) => this.responseFacebook(res)} />
        </div>
        { hasPermissions && instagram.length > 0 && (
          <div className="flex-100 layout-row layout-wrap layout-align-center-start mb-3">
            <button
              type="button"
              onClick={() => this.openInstagramImages()}
              className="instagramLogin flex layout-align-center-center text-center">
              <i className="simple-icon-social-instagram mr-3" />
              <Trans i18nKey="selectInstagramPhotos" />
            </button>
          </div>
        )}
      </Fragment>
    )
  }

  render() {
    const { mediaUploadProps } = this.state;
    const { aspireLibrary, mediaUpload, model, modelId, facebookSocial, bottomButtons } = this.props;
    const aspireLibraryBody = (aspireLibrary && this.renderAspireLibrary()) || null;
    const facebookSocialBody = (facebookSocial && this.renderFacebookSocial()) || null;
    return (
      <div className={`MediaDashboard flex-100 layout-row layout-wrap layout-align-center-center ${this.props.className ? this.props.className : ''}`}>
        { !bottomButtons && aspireLibraryBody }
        { !bottomButtons && facebookSocialBody }
        { mediaUpload && (
          <div className="flex-100 layout-row layout-wrap layout-align-center-start mb-1">
            <div className="flex-100 layout-row swiper-slide layout-wrap layout-align-center-center">
              <MediaUploadBox
                model={model}
                modelId={modelId}
                isMulti={mediaUploadProps.isMulti}
                mediaGroup={mediaUploadProps.mediaGroup}
                folder={mediaUploadProps.folder}
                mediaBoxId={mediaUploadProps.mediaBoxId}
                className={`smallSquare ${mediaUploadProps.className}`}
                allowedFileTypes={mediaUploadProps.allowedFileTypes}
                onMediaUpload={(mediaArray) => {this.updateMedia(mediaArray);}} />
            </div>
          </div>
        )}
        { bottomButtons && aspireLibraryBody }
        { bottomButtons && facebookSocialBody }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  modalMinimized: state.appR.modalMinimized,
});

const mapDispatchToProps = dispatch => ({
  showAlert2: data => dispatch(showAlert2(data)),
  checkFBPermissions: () => dispatch(checkFBPermissions()),
  refreshFacebookAccess: body => dispatch(refreshFacebookAccess(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDashboard);
