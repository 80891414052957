import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse } from './index';


// Products
export const getProducts = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.products.getProductsList(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getProductDetails = id => async (dispatch) => {
  try {
    return await apiMethods.products.getProductDetails(id);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateProduct = body => async (dispatch) => {
  try {
    return await apiMethods.products.update(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

// Subscriptions
export const getSubscriptionList = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.products.getSubscriptionList(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getSubscriptionDetails = publicId => async (dispatch) => {
  try {
    return await apiMethods.products.getSubscriptionDetails(publicId);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

// Transactions
export const getTransactionList = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.products.getTransactionList(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getTransactionDetails = publicId => async (dispatch) => {
  try {
    return await apiMethods.products.getTransactionDetails(publicId);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};


export const cancelSubscription = () => async (dispatch) => {
  try {
    return await apiMethods.products.cancelSubscription();
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
