import {actionTypes, eventActions} from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse, showAlert } from './index';

const setAchievements = (data, type) => {
  switch (type) {
    case 'active':
      return { type: actionTypes.SET_ACTIVE_ACHIEVEMENTS, payload: data };
    case 'completed':
      return { type: actionTypes.SET_COMPLETED_ACHIEVEMENTS, payload: data };
    default:
      return { type: actionTypes.SET_ACHIEVEMENTS, payload: data };
  }
};

const setAchievementData = data => ({
  type: actionTypes.SET_ACHIEVEMENT_DATA,
  payload: data
});

const setAchievementProgress = data => ({
  type: actionTypes.SET_ACHIEVEMENT_PROGRESS,
  payload: data
});

export const showCongratsModal = data => ({
  type: actionTypes.SHOW_ALERT,
  payload: {
    isOpen: true,
    isModal: true,
    id: eventActions.CONGRATS_PAGE,
    className: 'PageModal CongratsModal',
    achievements: data, // can be one...can be 3
    buttonRight: {
      text: 'what?'
    }
  }
});

export const splitAchievement = publicId => ({
  type: actionTypes.REMOVE_ACHIEVEMENT,
  payload: publicId
});

export const getAchievements = (queryObj, type) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.achievements.getAchievements(query);
    dispatch(setAchievements(data, type));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getAchievementData = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.achievements.getAchievementData(publicId);
    dispatch(setAchievementData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getAchievementProgress = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.achievements.getAchievementProgress(query);
    dispatch(setAchievementProgress(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createAchievement = (body, refreshData) => async (dispatch) => {
  try {
    let result = true;
    await apiMethods.achievements.create(body);
    if (refreshData) {
      const query = { limit: 20 };
      result = await dispatch(getAchievements(query));
      dispatch(setAchievements(result));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateAchievement = (body, refreshData) => async (dispatch) => {
  try {
    let result = true;
    await apiMethods.achievements.update(body);
    if (refreshData) {
      const query = { limit: 20 };
      result = await dispatch(getAchievements(query));
      dispatch(setAchievements(result));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeAchievement = publicId => async (dispatch) => {
  try {
    const body = [publicId];
    await apiMethods.achievements.remove(body);
    dispatch(splitAchievement(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getAchievementsForSession = body => async (dispatch) => {
  try {
    const result = await apiMethods.achievements.getAchievementsForSession(body);
    dispatch(setAchievements(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getCompletedAchievements = body => async (dispatch) => {
  try {
    return await apiMethods.achievements.getCompletedAchievements(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const assignAchievementAsCompleted = publicId => async (dispatch) => {
  try {
    const body = { publicId };
    const data = await apiMethods.achievements.assignAchievementAsCompleted(body);
    dispatch(setAchievementData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateBodyStatsAchievement = body => async (dispatch) => {
  try {
    await apiMethods.achievements.updateBodyStatsAchievement(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const checkForCompletedGoals = () => async (dispatch) => {
  try {
    const achievements = await apiMethods.achievements.checkForCompletedGoals();
    if (achievements && achievements.length > 0) {
      dispatch(showCongratsModal(achievements));
    }
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
