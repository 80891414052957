/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import nanoid from 'nanoid/generate';
import { showAlert2 } from '../../store/actions';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import BodyMapThumb from '../BodyMap/BodyMapThumbnail';
import { langs, equipmentLangs } from '../../services/translationLangs';
import { nanoidOption } from '../../services/storeDoll';
import Image from '../UI/Image';
import './StripExercise.css';

class StripExerciseBasic extends Component {
  handleClickOnStripe(exercise) {
    if (this.props.disableClick || this.props.equipmentSelect) return;
    if (this.props.clicked) {
      this.props.clicked();
      return;
    }
    if (this.props.disableModal) return;
    this.handleExerciseDetails(exercise);
  }

  handleEquipmentLang(exercise) {
    const { locale } = this.props;
    const chosenEquipment = exercise.chosenEquipment && exercise.chosenEquipment.title ? `${exercise.chosenEquipment.title[locale] ? exercise.chosenEquipment.title[locale] : exercise.chosenEquipment.title.en}` : null;
    const equipment = equipmentLangs[locale][chosenEquipment && chosenEquipment.replace(' ', '_').toUpperCase()] || (exercise.equipment && exercise.equipment.title && exercise.equipment.title.en ? equipmentLangs[locale][exercise.equipment.title.en.toUpperCase()] : chosenEquipment);
    return equipment;
  }

  handleClickOnImage(exercise) {
    if (this.props.disableClick) return;
    if (this.props.clickedImage) {
      this.props.clickedImage();
      return;
    }
    if (this.props.disableModal) return;
    this.handleExerciseDetails(exercise);
  }

  handleExerciseDetails(exercise) {
    if (this.props.disableModal) return;
    const data = {
      isOpen: true,
      isModal: true,
      id: eventActions.EXERCISE_DETAILS,
      className: 'PageModal',
      exercises: exercise, // can be one...can be 3
      buttonRight: {
        text: <i className="la la-arrow-left iconFontSize30 goBackIconColor " />,
        onClick: () => {
          this.props.hideAlert2();
        },
      },
    };
    this.props.showAlert2(data);
  }

  handleSquareThumbnail(exercise) {
    return exercise.chosenEquipment && exercise.chosenEquipment.thumbnailSquare ? exercise.chosenEquipment.thumbnailSquare : (exercise.defaultEquipment && exercise.defaultEquipment.thumbnailSquare) || 'none';
  }

  selectEquipment(e, exercise) {
    let equipment = {};
    exercise.equipments.map((equip) => {
      if (equip.equipment === e.currentTarget.value) {
        equipment = equip;
        equipment.equipmentId = equip._id;
      }
    });
    this.props.selectClicked(equipment, exercise);
  }

  renderInitValueOfSelect(exercise) {
    if (this.props.initValue) return this.props.initValue;
    return exercise.chosenEquipment
      ? exercise.chosenEquipment.equipment
      : (exercise.defaultEquipment && exercise.defaultEquipment.equipment) || 'none';
  }

  renderSelectEquipment(exercise, ExProps, index) {
    const { locale, isMulti } = this.props;
    if (this.props.equipmentSelect && exercise && exercise.equipments) {
      const selectValue = this.renderInitValueOfSelect(exercise);
      return (
        <Fragment>
          <span className="flex-initial text-nowrap paddEnd5px">{`${langs[locale].againstEquipment}`}</span>
          <div className="flex layout-row layout-align-start-start positionRelative selectContainer equipmentSelect colorEquipment">
            <select
              className="flex form-control select-eq"
              required
              defaultValue={selectValue}
              key="chosenEquipment"
              onChange={(event) => {this.selectEquipment(event, exercise);}}>
              {exercise.equipments.length > 0 &&
                exercise.equipments.map((option) => (
                  <option key={`${option.equipment}_exSelect`} value={option.equipment}>
                    {option && option.title[locale]
                      ? option.title[locale]
                      : equipmentLangs[locale][
                          option.title &&
                            option.title.en &&
                            option.title.en.replace(' ', '_').toUpperCase()
                        ] ||
                        (option.title && option.title.en) ||
                        ''}
                  </option>
                ))}
            </select>

          </div>
        </Fragment>
      );
    }
    if (this.props.showChosenEquipment) {
      if (isMulti) {
        return (
          <span className="flex text-nowrap">
            <span className="flex-initial">{`${langs[locale].againstEquipment}`}</span>
            <span className="colorEquipment flex-initial text-nowrap">{` ${ExProps.equipment[index]}`}</span>
          </span>
        );
      }
      return (
        <span className="flex text-nowrap">
          <span className="flex-initial">{`${langs[locale].againstEquipment}`}</span>
          <span className="colorEquipment flex-initial text-nowrap">{` ${ExProps.equipment}`}</span>
        </span>
      );
    }
    return null;
  }

  renderBodyMapThumb() {
    const { exercise, isMulti, locale } = this.props;
    const ExProps = { images: [], title: [], equipment: [] };
    if (isMulti) {
      for (let i = 0; i < exercise.length; i++) {
        const squareThumbnail = this.handleSquareThumbnail(exercise[i]);
        ExProps.images.push(
          exercise[i].thumbnail ? (
            <div onClick={() => {this.handleClickOnImage(exercise[i]);}}
              key={`${nanoid(nanoidOption, 6)}_key`}
              className={`flex-initial layout-row layout-wrap layout-align-start-start exercise-Thumbnail exercise_${exercise[i].order}`}
              style={{ zIndex: exercise.length - i }}>
              { squareThumbnail  && squareThumbnail !== 'none' && (
                <div className="flex-initial layout-row layout-wrap layout-align-start-start positionRelative">
                  <Image
                    src={squareThumbnail}
                    height={60}
                    fallbackSrc="https://aspire123.s3.eu-central-1.amazonaws.com/assets/blank.png"
                    className="positionAbsolute zIndex10 thumbnailSquareImg" />
                </div>
              )}
              <BodyMapThumb
                position={exercise[i].thumbnail && exercise[i].thumbnail.previewImg ? exercise[i].thumbnail.previewImg : 1}
                classPosition={exercise[i].thumbnail && exercise[i].thumbnail.classPosition ? exercise[i].thumbnail.classPosition : ''}
                chosenMuscle={(exercise[i].thumbnail && exercise[i].thumbnail.className) || []}
              />
            </div>
          ) : null,
        );
        const title = exercise[i].customName ? exercise[i].customName : exercise[i].title[locale] || exercise[i].title.en || '???';
        const equipment = this.handleEquipmentLang(exercise[i]);
        ExProps.title.push(title);
        ExProps.equipment.push(equipment);
      }
    } else {
      ExProps.title = exercise.customName ? exercise.customName : exercise.title[locale] || exercise.title.en || '???';
      // ExProps.equipment = (exercise.chosenEquipment || exercise.equipment) ? `${exercise.chosenEquipment && exercise.chosenEquipment.title && exercise.chosenEquipment.title[locale] ? exercise.chosenEquipment.title[locale] : exercise.equipment.title[locale]}` : null;
      ExProps.equipment = this.handleEquipmentLang(exercise);
      const squareThumbnail = this.handleSquareThumbnail(exercise);
      ExProps.images = exercise.thumbnail ? (
        <div
          onClick={() => {this.handleClickOnImage(exercise);}}
          className="flex-initial layout-row layout-wrap layout-align-start-start positionRelative">
            { squareThumbnail  && squareThumbnail !== 'none' && (
                <Image
                  src={squareThumbnail}
                  height={60}
                  fallbackSrc="https://aspire123.s3.eu-central-1.amazonaws.com/assets/blank.png"
                  className="positionAbsolute zIndex10 thumbnailSquareImg" />
            )}
            <BodyMapThumb
              position={exercise.thumbnail && exercise.thumbnail.previewImg ? exercise.thumbnail.previewImg : 1}
              classPosition={exercise.thumbnail && exercise.thumbnail.classPosition ? exercise.thumbnail.classPosition : ''}
              chosenMuscle={(exercise.thumbnail && exercise.thumbnail.className) || []}
            />
        </div>
      ) : null;
    }
    return ExProps;
  }

  render() {
    const {
      exercise,
      text,
      textBottom,
      editMode,
      textClassName,
      textBottomClassName,
    } = this.props;
    const ExProps = this.renderBodyMapThumb();
    return (
      <div
        id={exercise.public_id}
        className={`StripExercise StripExerciseBasic transition03 ${
          this.props.className || 'flex-100 layout-row layout-align-start-center'
        }`}>
        {editMode && this.props.checked && (
          <div className="flex-initial layout-row layout-wrap layout-align-start-center checkBox">
            <div className="checkbox-container">
              <input
                type="checkbox"
                onChange={(event) => {
                  this.props.checked(event);
                }}
              />
            </div>
          </div>
        )}
        <div
          className={`flex layout-row layout-wrap layout-align-start-center exerciseStripe ${this.props.chosen || ' '} order-${this.props.index}`}>
          <div
            className={`flex-initial layout-row layout-wrap layout-align-start-start exerciseImages ${this.props.isMulti ? 'multiImages' : ''} exerciseImages-${(ExProps.images && ExProps.images.length) || 1}`}>
            {ExProps.images}
          </div>
          <div
            className="flex layout-column layout-wrap layout-align-start-start sidePadd5px"
            onClick={() => { this.handleClickOnStripe(exercise); }}>
            {text && ( <span className="margin0 font13 comment">{' '}<Trans i18nKey={text} /></span> )}
            <label className={`exerciseTitles fontWeight600 propsName textLimitHeight text-capitalize layout-row layout-wrap ${this.props.titleClass ? this.props.titleClass : ''}`}>
              {this.props.isMulti ? (
                ExProps.title.map((title, index) => (
                  <span
                    className={`width100 height50px layout-row layout-wrap layout-align-start-center content-center title exercise_${exercise[index].order}`}
                    key={`${title}_key`}
                    style={{ zIndex: ExProps.title.length - index, opacity: index === 0 ? 1 : 0 }}>
                    <span className={`paddEnd5px ${title.length < 16 ? 'flex-initial layout-row layout-wrap layout-align-start-start' : ''}`}>
                      <span className="flex-100 textLimitHeight titleLimit paddEnd5px">{`${title}`}</span>
                      {this.renderSelectEquipment(exercise[index], ExProps, index)}
                    </span>
                  </span>
                ))
              ) : (
                <span className="width100 layout-row layout-wrap layout-align-start-center content-center title">
                  <span
                    className={`paddEnd5px ${ExProps.title.length < 16 ? 'flex-initial layout-row layout-wrap layout-align-start-start' : ''}`}>
                    <span className="textLimitHeight flex-100 titleLimit paddEnd5px">{`${ExProps.title}`}</span>
                    {this.renderSelectEquipment(exercise, ExProps)}
                  </span>
                </span>
              )}
            </label>
            {textBottom && (
              <p className="fontWeight400 propsName textLimitHeight text-capitalize margin0">
                <span className={`${textBottomClassName || 'text-muted'}`}>
                  <Trans i18nKey={textBottom} />
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.appR.locale,
});

const mapDispatchToProps = (dispatch) => ({
  showAlert2: (data) => dispatch(showAlert2(data)),
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripExerciseBasic);
