/* eslint-disable no-trailing-spaces,max-len */
import React, { Component } from 'react';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const quillModules = {
  toolbar: [
    [{ 'header': 1 }, { 'header': 2 }, "blockquote"],
    ["bold", "italic", "underline", "strike"],
    [{ 'color': ['#3c4889', '#5954a0', '#b3387e', '#e24585', '#e85c3c', '#54a42a', '#5a5a5a', ] }, { 'background': [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"]
  ]
};

class QuillTextEditor extends Component {
  constructor (props) {
    super(props);
    this.state = {
      content: this.props.value || ""
    };
  }

  handleChangeQuillStandard = (content) => {
    this.setState({ content }, () => {
      if (this.props.onChange) this.props.onChange(this.state.content);
    });
  };

  render () {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
        <ReactQuill
            className="width100"
            theme="snow"
            value={this.state.content}
            onChange={this.handleChangeQuillStandard}
            modules={quillModules}/>
      </div>
    );
  }
}

export default QuillTextEditor
