/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import AlertModal from './AlertModal';
import GetSocialMediaModal from './GetSocialMediaModal'
import EditEventOptionsModal from '../CalendarPages/EditEventOptionsModal'
import AspireLibraryModal from './AspireLibraryModal'
const ChooseEquipmentModal = React.lazy(() => import('../../Components/Alerts/ChooseEquipmentModal'));
// const MediaPickerModal = React.lazy(() => import('../../Components/Alerts/MediaPickerModal'));
const PopupNotification = React.lazy(() => import('../../Components/UI/PopupNotification'));
const ExerciseDetailsPage = React.lazy(() => import('../../Containers/ExercisePages/ExerciseDetailsPage'));
const DynamicListSearchPage = React.lazy(() => import('./DynamicListSearchPage'));

class ModalsContainerSecond extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  openModal () {
    switch (this.props.alert2Props.id) {
      case eventActions.ALERT_MODAL:
        return <AlertModal alertProps={this.props.alert2Props} hideAlertBtn={() => this.props.hideAlert2()} />;
      case eventActions.EVENT_UPDATE_OPTIONS:
        return <EditEventOptionsModal alertProps={this.props.alert2Props} {...this.props.alert2Props} hideAlertBtn={() => this.props.hideAlert2()} />;
      case eventActions.GET_SOCIAL_MEDIA:
        return <GetSocialMediaModal {...this.props.alert2Props} />;
      case eventActions.GET_ASPIRE_LIBRARY:
        return <AspireLibraryModal {...this.props.alert2Props} />;
      case eventActions.EXERCISE_DETAILS:
        return <ExerciseDetailsPage isModalPage locale={this.props.locale} hideAlertBtn={() => this.props.hideAlert2()} />;
      case eventActions.CHOOSE_EQUIPMENT:
        return <ChooseEquipmentModal {...this.props.alert2Props} exercise={this.props.alert2Props.exercise} hideAlertBtn={() => this.props.hideAlert2()} />;
      case eventActions.DYNAMIC_SEARCH_LIST:
        return (
          <DynamicListSearchPage
            isModalPage
            alertProps={this.props.alert2Props}
            title={this.props.alert2Props.title}
            description={this.props.alert2Props.description}
            locale={this.props.locale}
            config={this.props.alert2Props.config}
            itemsList={this.props.alert2Props.itemsList}
            filterResults={(query) => { this.props.alert2Props.filterResults(query); }}
            onSelect={array => this.props.alert2Props.onSelect(array)}
            closeSearchList={() => { this.props.alert2Props.closeSearchList(); }}
            hideAlertBtn={() => this.props.hideAlert2()}
          />
        );
      default:
        return <AlertModal alertProps={this.props.alert2Props} hideAlertBtn={() => this.props.hideAlert2()} />;
    }
  }

  render () {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start alert2Props">
        <Suspense fallback={<div className="gogo-loading" />}>
          { this.props.alert2Props && this.props.alert2Props.isOpen && this.openModal() }
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  alert2Props: state.appR.alert2Props,
  notificationProps: state.appR.notificationProps
});

const mapDispatchToProps = dispatch => ({
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 })
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsContainerSecond);
