import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import BackSVG from '../../assets/images/Back.svg';
import frontSVG from '../../assets/images/Front.svg';
import './BodyMap.css';
import LoadingDots from '../UI/LoadingDots';

const BodyMapThumb = (props) => {
  let classes = '';
  let svgSource = frontSVG;
  if (props.chosenMuscle && Array.isArray(props.chosenMuscle) && props.chosenMuscle.length > 0) {
    classes = props.chosenMuscle.join(' ');
  } else {
    classes = ' ';
  }
  if (props.position && props.position === 2) {
    svgSource = BackSVG;
  }

  return (
    <div className={`BodyMapThumb flex-100 layout-row layout-wrap layout-align-center-center positionRelative ${classes} ${props.classPosition ? props.classPosition : ''}`}>
      <div className="flex-100 layout-row layout-wrap layout-align-center-center mapContainer">
        <div className="flex-100 layout-row layout-wrap layout-align-center-center scrollerPosition">
          <ReactSVG
            className="mb-3"
            src={svgSource}
            fallback={() => <span><img alt="bodyMap thumb" src="https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/bodyPlaceHolder_mx8cjo.png" /></span>}
            loading={() => (
              <LoadingDots active />
            )} />
        </div>
      </div>
    </div>
  );
};

BodyMapThumb.defaultProps = {
  position: 1,
  classPosition: '',
  chosenMuscle: ''
};

BodyMapThumb.propTypes = {
  position: PropTypes.number,
  classPosition: PropTypes.string,
  chosenMuscle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

export default BodyMapThumb;
