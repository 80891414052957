export const envs = {
  PROD: 'PROD',
  PRE_PROD: 'PRE_PROD',
  TEST: 'TEST',
  DEV: 'DEV',
  LOCAL: 'LOCAL',
};

export const host = {
  local: ['http://localhost:8080', 'http://localhost:8081'],
  dev: 'trainer-dev.aspire.co.il',
  aspire: ['trainer.aspire.co.il', 'trainer.aspiretrainers.com'],
};

export const serverUrl = {
  LOCAL: 'http://localhost:2020',
  DEV: 'https://aspire-server-prod.herokuapp.com/', // heroku is dev env
  PROD: 'https://aspireserver-prod-eu-central-1.aspire.co.il', // 'https://aspireserver-prod.eu-central-1.elasticbeanstalk.com'
};

export const GTM = {
  PROD: 'GTM-WT3WGRN',
  DEV: '',
  TEST: '',
};

export const assetsDomain = {
  PROD: 'thumbs.aspire.co.il',
  DEV: 'thumbs-dev.aspire.co.il',
  TEST: 'thumbs-dev.aspire.co.il',
};

export const subscriptionEnv = {
  PROD: 'TEST', // 'PRODUCTION',
  TEST: 'TEST',
};

window.globals = window.globals || {};
if (window.location.host && host.aspire.indexOf(window.location.host) !== -1) {
  window.globals.ENV = envs.PROD;
  window.globals.assetsDomain = assetsDomain.PROD;
  window.globals.subscriptionEnv = subscriptionEnv.PROD;
} else if (window.location.host === host.dev) {
  window.globals.ENV = envs.DEV;
  window.globals.assetsDomain = assetsDomain.DEV;
  window.globals.subscriptionEnv = subscriptionEnv.TEST;
} else {
  window.globals.ENV = envs.LOCAL;
  window.globals.assetsDomain = assetsDomain.DEV;
  window.globals.subscriptionEnv = subscriptionEnv.TEST;
}

window.globals.ENV = window.globals.ENV || envs.DEV;
window.globals.assetsDomain = window.globals.assetsDomain || assetsDomain.DEV;
window.globals.subscriptionEnv = window.globals.subscriptionEnv || subscriptionEnv.TEST;

window.globals.isProd = () =>
  window.globals.ENV === envs.PROD || window.globals.ENV === envs.PRE_PROD;
