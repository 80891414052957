import React from 'react';
import Confetti from 'react-confetti';
import { Trans } from 'react-i18next';
import { styleUtils } from '../../services/utilsService';

const DummyUserSection = (props) => {
  const { section } = props;
  const windowSize = { height: window.outerHeight, width: window.innerWidth };
  const confetti = (
    <Confetti
      numberOfPieces={100}
      width={windowSize.width}
      height={windowSize.height}
      colors={['#384ab4', '#5855a0', '#824187', '#eb4b93', '#d74c3b', '#e1774a', '#eea04f']} />
  );
  return (
    <div
      key={props.id || 'section_dummyUser'}
      className="DummyUserSection swiper-slide flex-100 layout-row layout-wrap layout-align-start-start content-start positionRelative"
      style={{ backgroundColor: (section && section.backgroundColor) || '#fff' }}>
      <div className="width100 layout-row layout-wrap layout-align-start-start height50px mb-4" />
      <div className="width100 layout-row layout-wrap layout-align-start-start fullSizeWindow positionAbsoluteTopLeft content-start zIndex5">
        {confetti}
      </div>
      <div className="width100 layout-row layout-wrap layout-align-center-center positionRelative zIndex10">
        <div className="flex-80 layout-row layout-wrap layout-align-center-center mb-4 height220px">
          <div className="circle backgroundImage big" style={styleUtils.imageBackground(props.user.thumbnail.uri)} />
        </div>
      </div>
      <div className="width100 layout-row layout-wrap layout-align-center-start sp-3 positionRelative zIndex10 text-center">
        <div className="flex-initial width100 layout-row layout-wrap layout-align-center-start mb-2">
          {props.title && (
            <div className="flex-100 width100 layout-row layout-wrap layout-align-center-start mb-2">
              <label
                className="gradientText text-capitalize font20 fontWeight600">{props.title}
              </label>
            </div>
          )}
          {props.description && (
            <div className="flex-100 width100 layout-row layout-wrap layout-align-center-start paddBottom10px">
              <p className="mb-2 font15">{props.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DummyUserSection;
