/* eslint-disable react/jsx-first-prop-new-line,no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { uniq } from 'lodash-es';
import nanoid from 'nanoid/generate';
import moment from 'moment';
import SwiperCarousel from '../SwiperCarousel/SwiperCarousel';
import { addClass, removeClass } from '../../services/utilsService';
import Input from '../UI/Input';
import ThumbnailUI from '../UI/ThumbnailUI';
import { nanoidOption } from '../../services/storeDoll';
import NavBottom from '../NavBottom/NavBottom';
import { langs } from '../../langs';
import { actionTypes } from '../../store/actions/actionTypes';
import { optionAnswerType } from '../../services/enums';
import './Survey.css';

class Survey extends Component {
  constructor (props) {
    super(props);
    this.state = {
      carouselOptions: {
        autoHeight: true,
        centeredSlides: true,
        isLoading: false,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      questionsKV: {},
      saveErrors: []
    };
  }

  onSlideChange (value, isEnd) {
    const { firstPages, survey } = this.props;
    this.state.allowSlideNext = !firstPages ? !(survey.questions[value] && survey.questions[value].isRequired && !this.state.questionsKV[survey.questions[value]._id]) : !(survey.questions[value - 1] && survey.questions[value - 1].isRequired && !this.state.questionsKV[survey.questions[value - 1]._id]);
    if (!this.state.allowSlideNext) {
      addClass('.Survey .bottomSection .nextBtn', 'displayNone');
      this.state.carouselRef.allowTouchMove = false;
    } else {
      removeClass('.Survey .bottomSection .nextBtn', 'displayNone');
      this.state.carouselRef.allowTouchMove = true;
    }
    if (this.props.onSlideChange) this.props.onSlideChange(value, isEnd, this.state.questionsKV);
  }

  getCarouselRef (carousel) {
    this.state.carouselRef = carousel;
  }

  handleChange (e, option, questionObj, key) {
    const { label, value } = option;
    if (e.target.type === 'checkbox' || e.target.type === 'radio') {
      this.state.questionsKV[questionObj._id] = questionObj;
      if (!this.state.questionsKV[questionObj._id].answers) this.state.questionsKV[questionObj._id].answers = {};
      this.state.questionsKV[questionObj._id].answers[option._id] = { label, value };
      if ((e.target.type === 'checkbox' && e.target.checked) || (e.target.type === 'radio' && e.target.checked)) {
        if (e.target.type === 'radio') {
          this.state.questionsKV[questionObj._id].answers = {};
          this.state.questionsKV[questionObj._id].answers[option._id] = { label, value };
        }
        this.state.questionsKV[questionObj._id].answers[option._id].isSelected = true;
        this.state.questionsKV[questionObj._id].answers[option._id].value = true;
      } else {
        delete this.state.questionsKV[questionObj._id].answers[option._id];
      }
      // in case option has a condition to elaborate after check
      if (option.condition && option.condition === e.target.checked) {
        this.state.openContent = `#option_${option._id}_content`;
        addClass(`#option_${option._id}_content`, 'show');
      } else if (this.state.openContent) {
        removeClass(this.state.openContent, 'show');
        this.state.openContent = null;
      }
    } else if (key && key === 'content') {
      // in case user needs to elaborate after check
      if (this.state.questionsKV[questionObj._id] && this.state.questionsKV[questionObj._id].answers && this.state.questionsKV[questionObj._id].answers[option._id]) this.state.questionsKV[questionObj._id].answers[option._id].content = e.currentTarget.value;
    } else {
      // in case usual input field
      this.state.questionsKV[questionObj._id] = questionObj;
      this.state.questionsKV[questionObj._id].answers = {};
      this.state.questionsKV[questionObj._id].answers[option._id] = { label, value };
      this.state.questionsKV[questionObj._id].answers[option._id].content = e.currentTarget.value;
    }
    // check if Required Section and change accordingly in carousel
    if (this.props.isSwiperCarousel && questionObj.isRequired && Object.keys(this.state.questionsKV[questionObj._id].answers).length > 0) {
      removeClass('.Survey .bottomSection .nextBtn', 'displayNone');
      this.state.carouselRef.allowTouchMove = true;
    } else if (this.props.isSwiperCarousel && questionObj.isRequired && Object.keys(this.state.questionsKV[questionObj._id].answers).length === 0) {
      addClass('.Survey .bottomSection .nextBtn', 'displayNone');
      this.state.carouselRef.allowTouchMove = false;
      this.state.carouselRef.update();
    }
  }

  validateSurveyFill () {
    if (this.isValidating) return;
    this.isValidating = true;
    this.state.saveErrors = [];
    const { questionsKV } = this.state;
    this.state.surveyQsKV = {};
    this.props.survey.questions.forEach((question) => {
      this.state.surveyQsKV[question._id] = question;
      this.state.surveyQsIds = Object.keys(this.state.surveyQsKV);
    });
    if (Object.entries(questionsKV) && Object.entries(questionsKV).length > 0) {
      this.state.surveyQsIds.forEach((key, index) => {
        const { isRequired } = this.state.surveyQsKV[key];
        if (isRequired && (!questionsKV[key] || !Object.keys(questionsKV[key].answers).length > 0)) {
          const errorTrans = langs[this.props.locale].fillRequiredAnswers;
          this.state.saveErrors.push(errorTrans);
        }
      });
    } else {
      const errorTrans = langs[this.props.locale].noAnswers;
      this.state.saveErrors.push(errorTrans);
    }
    // show errors on topNav
    if (this.state.saveErrors && this.state.saveErrors.length > 0) {
      const errors = uniq(this.state.saveErrors);
      this.props.validationError(errors);
    } else {
      // all Good!
      this.props.saveSurveyFill(questionsKV);
    }
    this.validateTimeout = setTimeout(() => {
      this.isValidating = false;
      clearTimeout(this.validateTimeout);
    }, 400);
  }

  clickBack () {
    this.state.carouselRef.slidePrev(600);
  }

  clickNext () {
    this.state.carouselRef.slideNext(600);
  }

  clickDone () {
    const { questionsKV } = this.state;
    if (this.props.isSurveyFill) {
      this.validateSurveyFill((surveyFillObj) => {
        if (this.props.saveSurveyFill) this.props.saveSurveyFill(surveyFillObj);
      });
    }
    if (this.props.clickDone) this.props.clickDone(questionsKV);
  }

  duplicateSurvey () {
    this.setState({ isLoading: true });
    if (this.props.duplicateSurvey) this.props.duplicateSurvey();
  }

  renderOptionTemplate (option, questionObj, isAnswers) {
    const { isSwiperCarousel, language, locale } = this.props;
    const surveyLang = language || locale;
    return (
      <div key={`option_${option._id}`} className="flex-100 width100 layout-row layout-wrap layout-align-start-start">
        { isAnswers && (option.elementType === optionAnswerType.inputOption ||
        option.elementType === optionAnswerType.textarea ||
        option.elementType === optionAnswerType.numberOption ||
        option.elementType === optionAnswerType.dateOption) ? (
            <p className="mb-2 font16">{ option.elementType === optionAnswerType.dateOption ? moment(option.value).format('DD.MM.YYYY') : option.value}</p>
          ) : (
            <div className={`flex-100 layout-row layout-wrap layout-align-center-start mb-1 ${isSwiperCarousel ? 'height50px' : ''}`}>
              <Input
                name={`option_${option._id}`}
                placeholderAsIs={option.label[surveyLang]}
                label={option.label[surveyLang]}
                elementType={option.elementType}
                type={option.type}
                group={questionObj.group ? questionObj.group : false}
                noLabel
                clickText
                valueAlwaysTrue={isAnswers}
                initialValue={isAnswers}
                validation={option.validation}
                locale={surveyLang}
                className="width100 flex-100"
                classes="flex-100"
                checkBoxClass="font16 sidePadd5px"
                handleChange={(event, key) => { this.handleChange(event, option, questionObj); }}>
                {option.label[surveyLang]}
              </Input>
            </div>
          )}
        { isAnswers && option.content && option.content.length > 0 ? (
          <p className="mb-2 font16">{option.content}</p>
        ) : (
          <div id={`option_${option._id}_content`} className="optionContent flex-100 layout-row layout-align-start-start">
            <Input
              label={option.label[surveyLang]}
              elementType="textarea"
              noLabel
              rows="4"
              validation={option.validation}
              locale={surveyLang}
              className="width100 flex-100"
              classes="flex-100"
              handleChange={(event) => { this.handleChange(event, option, questionObj, 'content'); }}
            />
          </div>
        )}
      </div>
    );
  }

  renderQuestionTemplate (questionObj) {
    const {
      isSwiperCarousel, language, locale, isSurveyCompleted
    } = this.props;
    const surveyLang = language || locale;
    return (
      <div key={`section_${nanoid(nanoidOption, 4)}`}
        className={`${isSwiperCarousel ? '' : 'mb-3'} flex-100 swiper-slide layout-row layout-wrap layout-align-start-start content-start ${questionObj.isRequired ? 'isRequired' : ''}`}
        style={{ backgroundColor: questionObj.backgroundColor || '#fff' }}>
        { isSwiperCarousel && <div className="width100 layout-row layout-wrap layout-align-center-start height50px mb-4" /> }
        { questionObj.thumbnail && questionObj.thumbnail.uri && (
          <ThumbnailUI src={questionObj.thumbnail.uri}
            className={`flex-initial ${questionObj.thumbnail.className ? questionObj.thumbnail.className.join(' ') : ''}`} />
        )}
        <div className="flex-100 layout-row layout-wrap layout-align-start-start sp-3">
          <div className="flex-initial width100 layout-row layout-wrap layout-align-start-start mb-2">
            { questionObj.question && questionObj.question[surveyLang] && surveyLang && (
              <div className="flex-100 width100 layout-row layout-wrap layout-align-start-start mb-2">
                <label className="gradientText text-capitalize font16 fontWeight600 margin0">{questionObj.question[surveyLang]}
                  { questionObj.isRequired ? '*' : '' }
                </label>
              </div>
            )}
            { questionObj.description && questionObj.description[surveyLang] && surveyLang && (
              <div className="flex-100 width100 layout-row layout-wrap layout-align-start-start mb-2">
                <label className="text-capitalize font16 fontWeight600 margin0">{questionObj.description[surveyLang]}</label>
              </div>
            )}
            { questionObj.comment && questionObj.comment[surveyLang] && (
              <div className="flex-100 width100 layout-row layout-wrap layout-align-start-start paddBottom10px">
                <p className="mb-2 colorGrey font15">{questionObj.comment[surveyLang]}</p>
              </div>
            )}
            { !isSurveyCompleted && questionObj.options.map(option => this.renderOptionTemplate(option, questionObj)) }
            { isSurveyCompleted && questionObj.answers && questionObj.answers.map(option => this.renderOptionTemplate(option, questionObj, true)) }
          </div>
        </div>
      </div>
    );
  }

  renderSwiperCarouselSurvey () {
    const {
      pagination, navigationClasses, survey, firstPages, sumPages, navigation, wrapperClassName
    } = this.props;
    const sections = survey.questions && survey.questions.map((question, index) => this.renderQuestionTemplate(question, index));
    return (
      <div className={`flex-100 layout-row layout-wrap layout-align-center-start ${wrapperClassName || ''}`}>
        <SwiperCarousel
          id="SurveySwiper"
          swiperIndex={0}
          options={this.state.carouselOptions}
          pagination={pagination}
          navigationClasses={navigationClasses || 'bottom text'}
          carouselRef={carousel => this.getCarouselRef(carousel)}
          onSlideChange={(value, isEnd) => this.onSlideChange(value, isEnd)}>
          { firstPages }
          { sections }
          { sumPages }
        </SwiperCarousel>
        <div className="bottomSection positionRelative zIndex10 flex-100 layout-row layout-wrap layout-align-space-between-center directionChange">
          { navigation && (
            <div className="width100 layout-row layout-wrap layout-align-space-between-center">
              <div className="flex-initial layout-row layout-wrap layout-align-center-center">
                <button
                  type="button"
                  className="width100 textIcon btn btn-transparent p-3 text-center nextBtn"
                  onClick={() => this.clickNext()}>
                  <p className="margin0 font16"><Trans i18nKey="next" /></p>
                </button>
              </div>
              <div className="flex-initial layout-row layout-wrap layout-align-center-center">
                <button
                  type="button"
                  className="width100 textIcon btn btn-transparent height50px p-3 text-center backBtn"
                  onClick={() => this.clickBack()}>
                  <p className="margin0 font16"><Trans i18nKey="prev" /></p>
                </button>
              </div>
            </div>
          )}
          <div className="positionAbsoluteTopLeft width100 layout-row layout-wrap layout-align-center-center doneBtn sp-2">
            <button
              type="button"
              className="width100 textIcon aspireLibraryBtn aspireBg text-center flex layout-align-center-center"
              onClick={() => this.clickDone()}>
              <p className="margin0"><Trans i18nKey="done" /></p>
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderSurveyFullPage () {
    const {
      survey, firstPages, sumPages, isSurveyFill, isAspireTemplate, wrapperClassName, isSurveyCompleted, surveyFillData
    } = this.props;
    const surveyData = isSurveyCompleted ? surveyFillData : survey;
    const sections = surveyData.questions && surveyData.questions.map((question, index) => this.renderQuestionTemplate(question, index));
    return (
      <div className={`flex-100 layout-row layout-wrap layout-align-start-start ${wrapperClassName || ''}`}>
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          { !isSurveyCompleted && firstPages }
          { sections }
          { !isSurveyCompleted && sumPages }
          <p className="width100 sp-3"><Trans i18nKey="starRequiredFields" /></p>
        </div>
        { !isSurveyCompleted && isSurveyFill && (
          <div className="height50px width100 navBottomRelative">
            <NavBottom config={[{ key: 'send', text: <Trans i18nKey="send" /> }]} chosenOption={() => { this.clickDone(); }} />
          </div>
        )}
        { !isSurveyFill && isAspireTemplate && (
          <div className="height50px width100">
            <NavBottom config={[{ key: 'copySurvey', text: <Trans i18nKey="copySurvey" /> }]}
              isLoading={this.state.isLoading}
              chosenOption={() => { this.duplicateSurvey(); }} />
          </div>
        )}
      </div>
    );
  }

  render () {
    const { className, isSwiperCarousel } = this.props;
    return (
      <div className={`Survey flex-100 layout-row layout-wrap layout-align-start-start content-start ${className || ''}`}>
        { isSwiperCarousel ? (
          this.renderSwiperCarouselSurvey()
        ) : (
          this.renderSurveyFullPage()
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale
});

const mapDispatchToProps = dispatch => ({
  validationError: title => dispatch({ type: actionTypes.VALIDATION_ERROR, payload: title }),
  clearValidationError: () => dispatch({ type: actionTypes.CLEAR_VALIDATION_ERROR })
});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
