/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { utils } from '../../services/utilsService';

import { Modal, ModalBody } from 'reactstrap';

class MediaLibrary extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isPageReady: false
    };
  }

  async componentDidMount () {
    this.setState({ isPageReady: true });
  }

  render () {
    return (
      <Modal isOpen={true} backdrop="static">
        { this.state.isPageReady && (
          <ModalBody className="width100">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start">


            </div>
          </ModalBody>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  mediaDetails: state.mediaR.mediaDetails,
  alert2Props: state.appR.alert2Props
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibrary);
