import { exercisesTypes } from '../actions/actionTypes';

const initialState = {
  exerciseIds: [],
  exerciseListKV: {},
  myExerciseList: {},
  myExercisePublicId: null
  // equipmentFilter: []
  // musclesFilter: []
  // exCategoriesFilter: []
  // muscleTargetFilter: []
  // exerciseDetails: []
  // exerciseDetailsKV: {},
  // myExerciseList: {},
  // myExerciseListIds: []
  // exerciseEquipments: []
  // exerciseEquipmentDefault: {}
};

const exerciseReducer = (state = initialState, action) => {
  switch (action.type) {
    case exercisesTypes.SET_POPULAR_EXERCISES: {
      return { ...state, exerciseListKV: action.nextPage ? {...state.exerciseListKV, ...action.payload.exerciseList } : {...action.payload.exerciseList },
        exerciseIds: action.nextPage ? [ ...state.exerciseIds, ...action.payload.exerciseIds] : [...action.payload.exerciseIds] };
    }
    /*
        case exercisesTypes.SET_POPULAR_EXERCISES:
          return { ...state, exerciseIds: action.payload.exerciseIds, exerciseListKV: action.payload.exerciseList };
    */
    case exercisesTypes.UPDATE_EXERCISE_LIST:
      return { ...state, exerciseListKV: action.payload };
    case exercisesTypes.SET_EXERCISE_FILTERS:
      return {
        ...state, equipmentFilter: action.payload.equipment, musclesFilter: action.payload.muscles, exCategoriesFilter: action.payload.exCategories,
        muscleTargetFilter: action.payload.storeMuscleTarget
      };
    case exercisesTypes.SET_EXERCISE_TO_TOP: {
      const exercise = action.payload;
      const exerciseIds = [exercise.public_id, ...state.exerciseIds];
      const exerciseListTemp = Object.entries(state.exerciseListKV).map(([key, exercise]) => exercise);
      exerciseListTemp.unshift(exercise);
      const exerciseListKV = {}
      exerciseListTemp.forEach((ex) => {
        exerciseListKV[ex.public_id] = ex;
      });
      exerciseListKV[exercise.public_id] = exercise;
      return { ...state, exerciseIds, exerciseListKV };
    }
    /*
        case exercisesTypes.SET_MY_EXERCISES:
          return { ...state, myExerciseList: action.payload.myExerciseList, myExerciseListIds: action.payload.myExerciseIds };
    */
    case exercisesTypes.SET_MY_EXERCISES: {
      return { ...state, myExerciseList: action.nextPage ? {...state.myExerciseList, ...action.payload.myExerciseList } : {...action.payload.myExerciseList },
        myExerciseListIds: action.nextPage ? [ ...state.exerciseIds, ...action.payload.myExerciseIds] : [...action.payload.myExerciseIds] };
    }
    case exercisesTypes.SET_EXERCISE_DETAILS:
      return {
        ...state,
        exerciseDetails: action.payload.details ? action.payload.details : action.payload,
        exerciseDetailsKV: action.payload.keyValueObj ? action.payload.keyValueObj : null,
        exerciseEquipments: action.payload.equipments ? action.payload.equipments : null,
        exerciseEquipmentDefault: action.payload.defaultEquipment ? action.payload.defaultEquipment : null
      };
    case exercisesTypes.SET_EXERCISES_EQUIPMENTS:
      return { ...state, exerciseEquipments: action.payload.equipments, exerciseEquipmentDefault: action.payload.defaultEquipment };
    case exercisesTypes.SET_MY_EXERCISE_PUBLIC_ID:
      return { ...state, myExercisePublicId: action.payload };
    case exercisesTypes.CLEAR_EXERCISE_DETAILS:
      return {
        ...state, exerciseDetails: null, myExercisePublicId: null, exerciseDetailsKV: null, exerciseEquipments: null, exerciseEquipmentDefault: null
      };
    case exercisesTypes.CLEAR_EXERCISE_FILTERS:
      return { ...state, equipmentFilter: [], musclesFilter: [] };
    default:
      return state;
  }
};

export default exerciseReducer;
