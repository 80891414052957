import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import SimpleTimer from '../Timers/SimpleTimer';

const TimerToResend = props => {
  const [toggle, setToggle] = useState(props.showButtonFirst || false);
  const [tries, setTry] = useState(0);

  const showButton = (time) => {
    if (time=== 0) setToggle(true);
  }

  const toggleResendSMS = () => {
    if (props.tryLimit && (tries < props.tryLimit)) {
      const tryAttempt = tries + 1
      setTry(tryAttempt);
      setToggle(false);
      props.resendSms()
    }
  }

  return (
    <div className={`flex-100 layout-row layout-wrap layout-align-center-center ${props.className}`}>
      { !toggle && (
        <SimpleTimer
          showFragments={['minutes', 'seconds']}
          initialTime={props.initialTime || (90*1000)}
          lastUnit="m"
          handleSessionTime={(time) => { showButton(time)}}
        />
      )}
      { toggle && (
        <button type="button" className="mb-3 font15 sideMargin10px resendSMS fontWeight600 btn-transparent" onClick={() => {toggleResendSMS()}}>
          <Trans i18nKey={props.btnText || 'resendSMS'} defaults="resendSMS" />
        </button>
      )}
    </div>
  )
};

export default TimerToResend;
