/* eslint-disable no-trailing-spaces,max-len */
import React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { utils } from '../../services/utilsService';
import ClientList from './ClientList';
import { userTypes } from '../../services/enums';
import './ClientList.css';

function ClientListWrapper(props) {
  let usersAssignedList = null, chosenClientsList = null, title = null;
  const { user, clients, chosenClients, usersAssigned, className, singleSelect, editMode, assignMode, isReadOnly, showTrainer } = props;
  title = (user.userType === userTypes.CLIENT && props.trainerInfo) ? 'chooseTrainer' : 'chooseClients';
  if ((clients && clients.length > 0) || user.userType === userTypes.TRAINER) {
    if (chosenClients) {
      // if in editMode
      if (chosenClients.length > 0) { // if is array -> go to utils to make KV & Ids
        chosenClientsList = utils.arrayToKeyValue(chosenClients, 'public_id');
      } else if (chosenClients.public_id) { // else send as the object it is
        chosenClientsList = chosenClients;
      }
    }
    if (usersAssigned && usersAssigned.length > 0) {
      // if in assignMode
      usersAssignedList = utils.arrayToKeyValue(usersAssigned, 'public_id');
    }
    title = props.title || (singleSelect ? 'chooseClient' : 'chooseClients');
  }
  return (
    <div className={`ClientListWrapper flex-100 layout-row layout-wrap layout-align-start-start ${className}`}>
      <div className="flex-100 layout-row layout-wrap layout-align-start-start mb-1">
        <p className={`margin0 fontWeight600 title text-capitalize ${props.titleClassName || ''}`}><Trans i18nKey={title} /></p>
      </div>
      <ClientList
        chosenClients={chosenClientsList}
        singleSelect={singleSelect}
        showTrainer={showTrainer}
        horizontalBar
        editMode={editMode}
        assignMode={assignMode}
        isReadOnly={isReadOnly}
        usersAssigned={usersAssignedList}
        clientClicked={(client, isTrainer) => props.chooseUser(client, isTrainer)} />
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  trainerInfo: state.userR.trainerInfo,
  clients: state.userR.userInfo.clients,
  locale: state.appR.locale
});

export default connect(mapStateToProps)(ClientListWrapper);
