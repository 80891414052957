/* eslint-disable max-len */
import React from 'react';
import StripExercisePlaceHolder from '../Components/StripExercise/StripExercisePlaceHolder';
import ExerciseLogPlaceHolder from '../Components/ExerciseLog/ExerciseLogPlaceHolder';

export const componentUtils = {
  organizeWorkoutLogsData: (workout, showLogsDisplay, showChosenEquipment) => {
    let exercises;
    let isMulti = false;
    if (workout && workout.exercises && workout.exercises.length > 1) {
      exercises = workout.exercises.map((exercise, index) => ({
        public_id: exercise.public_id,
        thumbnail: exercise.thumbnail || null,
        title: exercise.title,
        exerciseType: exercise.exerciseType,
        notes: exercise && exercise.notes ? exercise.notes : null,
        customName: exercise.customName || null,
        chosenEquipment: showChosenEquipment && exercise && exercise.chosenEquipment ? exercise.chosenEquipment : null,
        workLogs: showLogsDisplay ? workout.workLogs[`${exercise.public_id}_${exercise.order}`] : null,
        allowedFields: exercise.allowedFields,
        order: index + 1
      }));
      isMulti = true;
    } else {
      if (!(workout && workout.exercises && workout.exercises[0] && workout.exercises[0].public_id)) return null;
      exercises = {
        public_id: workout.exercises[0].public_id,
        thumbnail: workout.exercises[0].thumbnail || '',
        title: workout.exercises[0].title,
        exerciseType: workout.exercises[0].exerciseType,
        notes: workout.exercises[0] && workout.exercises[0].notes ? workout.exercises[0].notes : null,
        customName: workout.exercises[0].customName || null,
        chosenEquipment: showChosenEquipment && workout.exercises[0] && workout.exercises[0].chosenEquipment ? workout.exercises[0].chosenEquipment : null,
        workLogs: showLogsDisplay ? workout.workLogs[`${workout.exercises[0].public_id}_${workout.exercises[0].order}`] : null,
        allowedFields: workout.exercises[0].allowedFields,
        order: 1
      };
    }
    return { exercises, isMulti };
  },
  renderStripExercisePlaceHolder: showLogsDisplay => (
    <div className="flex-100 layout-row layout-wrap layout-align-start-center">
      <StripExercisePlaceHolder />
      {showLogsDisplay && (
        <div className="flex-100 layout-row layout-wrap layout-align-start-center">
          <ExerciseLogPlaceHolder />
          <ExerciseLogPlaceHolder />
          <ExerciseLogPlaceHolder />
          <ExerciseLogPlaceHolder />
        </div>
      )}
    </div>
  )
};

export default componentUtils;
