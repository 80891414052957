import axios from 'axios';
import nanoid from 'nanoid/generate';
import FileType from 'file-type/browser';
import { nanoidOption } from './storeDoll';
import { utils } from './utilsService';
import { apiMethods } from './apiService';
import { mediaGroup, mediaType, sourceType } from './enums';

const facebook = {
  extension: 'jpg',
  file: {
    name: 'media_USR-jmIvYe7DuZy5k3s_10207411365266679_GGuEZdFdFh.jpg',
    lastModified: 1605983090603,
    lastModifiedDate: 'Sat Nov 21 2020 20:24:50 GMT+0200 (Israel Standard Time)',
    size: 500126,
  },
  title: 'media_USR-jmIvYe7DuZy5k3s_10207411365266679_GGuEZdFdFh.jpg',
  fileType: 'image/jpeg',
  id: '10207411365266679',
  key: 'Users/USR-jmIvYe7DuZy5k3s/Gallery/1605983090748_media_usr_jmivye7duzy5k3s_10207411365266679_gguezdfdfh.jpg',
  size: 500126,
  source: 'https://aspire-users-dev.s3.eu-central-1.amazonaws.com/Users/USR-jmIvYe7DuZy5k3s/Gallery/1605983090748_media_usr_jmivye7duzy5k3s_10207411365266679_gguezdfdfh.jpg',
  thumbnail: 'https://aspire-users-dev.s3.eu-central-1.amazonaws.com/Users/USR-jmIvYe7DuZy5k3s/Gallery/1605983090748_media_usr_jmivye7duzy5k3s_10207411365266679_gguezdfdfh.jpg',
  type: 'image',
};

const instagram = {
  extension: 'mp4',
  file: {
    name: 'media_USR-jmIvYe7DuZy5k3s_2371094971116525295_RgVMPe3L8c.mp4',
    lastModified: 1605983000714,
    lastModifiedDate: 'Sat Nov 21 2020 20:23:20 GMT+0200 (Israel Standard Time)',
    size: 1005870,
  },
  title: 'media_USR-jmIvYe7DuZy5k3s_2371094971116525295_RgVMPe3L8c.mp4',
  fileType: 'video/mp4',
  id: '2371094971116525295',
  key: 'Users/USR-jmIvYe7DuZy5k3s/Gallery/1605983000867_media_usr_jmivye7duzy5k3s_2371094971116525295_rgvmpe3l8c.mp4',
  size: 1005870,
  source: 'https://aspire-users-dev.s3.eu-central-1.amazonaws.com/Users/USR-jmIvYe7DuZy5k3s/Gallery/1605983000867_media_usr_jmivye7duzy5k3s_2371094971116525295_rgvmpe3l8c.mp4',
  thumbnail: 'https://aspire-users-dev.s3.eu-central-1.amazonaws.com/Users/USR-jmIvYe7DuZy5k3s/Gallery/1605983000858_media_usr_jmivye7duzy5k3s_2371094971116525295_gcvrusikte.jpg',
  timestamp: '2020-08-08T08:49:43+0000',
  type: 'video',
  username: 'aspire.community',
};

const configAwsFile = (obj) => {
  return {
    title: obj.title,
    source: obj.source,
    thumbnail: obj.thumbnail || obj.source,
    sourceId: obj.key.split('/').pop(),
    size: obj.size,
    name: obj.title,
    type: obj.type,
    sourceType: obj.sourceType || sourceType.amazonS3,
    metadata: {
      id: obj.id || undefined,
      timestamp: obj.timestamp || undefined,
      username: obj.username || undefined,
    },
    extension: obj.extension || obj.key.split('.').pop(),
    format: obj.extension || obj.key.split('.').pop(),
    mediaGroup: obj.mediaGroup || mediaGroup.other
  };
}

const modifyFileName = (userId, file, fileType) => {
  return `media_${userId}_${file.ig_id || file.id || ''}_${nanoid(nanoidOption, 5)}.${fileType.ext}`
}
const getType = (ext) => {
  const image = ['jpg', 'jpeg', 'gif', 'png'];
  const video = ['mp4', 'mpeg', 'avi', 'webm', 'mov', 'wmv'];
  if(image.indexOf(ext) !== -1) return mediaType.image;
  else if (video.indexOf(ext) !== -1) return mediaType.video;
  return null;
}

export const makeABlob = (urlObj, userId, source) => {
  return new Promise((resolve, reject) => {
    try {
      const allowedTypes = ['jpg', 'jpeg', 'gif', 'png', 'mp4', 'mpeg', 'avi', 'webm', 'mov', 'wmv'];
      fetch(source)
        .then(r => r.blob())
        .then(async (blobFile) => {
          const fileType = await FileType.fromBlob(blobFile);
          // console.log(fileType);
          if (allowedTypes.indexOf(fileType.ext) !== -1) {
            const fileName = modifyFileName(userId, urlObj, fileType );
            const sourceType = getType(fileType.ext);
            const fileSource = new File([blobFile], fileName, { type: fileType.mime })
            const newObj =  { ...urlObj, file: fileSource, fileType: fileType.mime, extension: fileType.ext, size: fileSource.size, title: fileSource.name, type: sourceType }
            resolve(newObj)
          } else {
            console.log('media not allowed');
            reject()
          }
        });
    } catch (e) {
      console.log(`ERROR ${JSON.stringify(e)}`);
      reject();
    }
  })
}

export const handleUpload = (that, media, folder, modelId) => {
  return new Promise((resolve, reject) => {
    try {
      const params = { folder: folder || 'Other', modelId: modelId || 'none', fileName: media.file.name, fileType: media.file.type };
      const query = utils.buildQuery(params);
      apiMethods.media.getPreSignedUrl(query)
        .then( async (response) => {
          const { signedRequest } = response;
          const { CancelToken } = axios;
          const source = CancelToken.source();
          const options = {
            headers: { 'Content-Type': media.file.type },
            cancelToken: source.token,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if(that.updateProgress) that.updateProgress(media.id, percentCompleted)
            }
          };
          if(that && that.state && that.state.cancelUpload) that.state.cancelUpload[media.id] = source;
          const s3response = await axios.put(signedRequest, media.file, options)
          // console.log(`Response from s3`, s3response);
          resolve({ ...media , source: signedRequest.split('?')[0], key: response.key })
        })
        .catch((e) => {
          console.log(`handleUpload error 1:  ${JSON.stringify(e)}`);
          reject()
        })
    } catch (e) {
      console.log(`handleUpload error 2:  ${JSON.stringify(e)}`);
      reject()
    }
  })
};

export const saveUploadedFiles = async (results) => {
  try {
    const body = {
      completedUploads: results.map(result => configAwsFile(result))
    };
    console.log('result uploaded: ', body);
    return await apiMethods.media.saveUploadedFiles(body);
  } catch (e) {
    console.log(`ERROR ${  JSON.stringify(e)}`);
    return null
  }
}
