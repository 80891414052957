/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import nanoid from 'nanoid';
import localStorage from "local-storage";
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import Webcam from '@uppy/webcam';
import AwsS3 from '@uppy/aws-s3';
import uppyLocales from '../../services/uppyPlugins/locales';
import { apiMethods } from '../../services/apiService';
import { addMediaTo } from '../../store/actions';
import { utils } from '../../services/utilsService';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import './MediaUpload.css';
import { mediaGroup } from '../../services/enums';

const variables = {
  folder: 'example',
  modelId: 'EXMP-1234567'
};
const theme = localStorage.get('__theme_color')

class MediaUploadBox extends Component {
  constructor (props) {
    super(props);
    this.state = {
      uppy: null,
      completedUploads: [],
      isPageReady: false,
      mediaBoxId: `mediaBoxId_${nanoid(6)}`
    };
    variables.folder = this.props.folder || 'example';
    variables.modelId = this.props.modelId;
      this.state.uppy = new Uppy({
        id: 'uppy1',
        autoProceed: true,
        locale: uppyLocales[(this.props.locale || 'en')],
        theme: theme.indexOf('dark') === -1 ? 'light' : 'dark',
        restrictions: {
          maxNumberOfFiles: this.props.isMulti ? 20 : 1,
          minNumberOfFiles: 1,
          allowedFileTypes: this.props.allowedFileTypes
        }
      })
        .use(Webcam, {
          modes: ['picture', 'video-audio']
        })
        .use(AwsS3, {
          async getUploadParameters (file) {
            // Send a request to our PHP signing endpoint.
            const params = {
              modelId: variables.modelId,
              folder: variables.folder,
              fileName: file.name,
              fileType: file.type
            };
            const query = utils.buildQuery(params);
            const response = await apiMethods.media.getPreSignedUrl(query);
            return {
              method: 'PUT',
              url: response.signedRequest,
              headers: {
                'Content-Type': response.fileType
              }
            };
          }
        })
        .on('upload', () => {
          if (this.props.onUpload) {
            this.props.onUpload()
          }
        })
        .on('complete', (result) => {
          if (result.successful.length > 0) {
            this.uploadImagesAutomatically(result.successful);
          }
        });
  }

  async componentDidMount () {
    this.setState({ isPageReady: true }, () => {
      const uppyElem = document.querySelector(`#${this.state.mediaBoxId} .uppy-Root`)
      if (theme.indexOf('dark') !== -1 && uppyElem) uppyElem.dataset.uppyTheme = 'dark'
    });
  }

  componentWillUnmount () {
    this.state.uppy.close();
  }

  stringToHTML = (str) => {
    const dom = document.createElement('div');
    dom.innerHTML = str;
    return dom;
  };


  addButtonMediaLibraryToBox () {
    const element = document.querySelector(`#${this.props.mediaBoxId} .uppy-Dashboard-AddFiles-list`);
    let btnElement = document.querySelector(`#${this.props.mediaBoxId} #aspireMediaLibraryButtonUppy`);
    if (!btnElement){
      const btn = this.stringToHTML(`<span role="presentation" style="whiteSpace: nowrap">
          <div class="uppy-DashboardTab" role="presentation">
            <button type="button" id="aspireMediaLibraryButtonUppy" class="uppy-DashboardTab-btn" role="tab">
             <svg aria-hidden="true" focusable="false" width="32" height="32" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><rect fill="#FF753E" width="32" height="32" rx="16"></rect><path d="M22.788 15.389l-2.199 2.19a3.184 3.184 0 0 1-.513.437c-.806.584-1.686.876-2.638.876a4.378 4.378 0 0 1-3.519-1.752c-.22-.292-.146-.802.147-1.021.293-.22.806-.146 1.026.146.953 1.313 2.785 1.532 4.105.583a.571.571 0 0 0 .293-.292l2.199-2.189c1.1-1.167 1.1-2.992-.073-4.086a2.976 2.976 0 0 0-4.105 0l-1.246 1.24a.71.71 0 0 1-1.026 0 .703.703 0 0 1 0-1.022l1.246-1.24a4.305 4.305 0 0 1 6.083 0c1.833 1.605 1.906 4.451.22 6.13zm-7.183 5.035l-1.246 1.24a2.976 2.976 0 0 1-4.105 0c-1.172-1.094-1.172-2.991-.073-4.086l2.2-2.19.292-.291c.66-.438 1.393-.657 2.2-.584.805.146 1.465.51 1.905 1.168.22.292.733.365 1.026.146.293-.22.367-.73.147-1.022-.733-.949-1.76-1.532-2.859-1.678-1.1-.22-2.272.073-3.225.802l-.44.438-2.199 2.19c-1.686 1.75-1.612 4.524.074 6.202.88.803 1.979 1.241 3.078 1.241 1.1 0 2.199-.438 3.079-1.24l1.246-1.241a.703.703 0 0 0 0-1.022c-.294-.292-.807-.365-1.1-.073z" fill="#FFF" fill-rule="nonzero"></path></g></svg>
              <div class="uppy-DashboardTab-name">ASPIRE Library</div>
            </button>
          </div>
        </span>`)
      element.appendChild(btn);
      btnElement = document.querySelector(`#${this.props.mediaBoxId} #aspireMediaLibraryButtonUppy`);
      btnElement.addEventListener("click", function(){
        // alert('Ive been clicked!')
      });
    }
  }


  getResourceType (fileType) {
    if (fileType.match(/^image\//)) {
      return 'image';
    } if (fileType.match(/^video\//)) {
      return 'video';
    } if (fileType.match(/^application\//)) {
      return 'form';
    }
    return 'auto';
  }

  configAwsFile (obj) {
    return {
      source: obj.uploadURL,
      sourceId: obj.uploadURL.split('/').pop(),
      size: obj.size,
      name: obj.name,
      type: this.getResourceType(obj.type),
      extension: obj.type.split('/').pop(),
      format: obj.type,
      mediaGroup: this.props.mediaGroup || mediaGroup.other,
      searchKeywords: obj.name.toLowerCase().replace('.', ' ')
      // duration: 0 // milliseconds
    };
  }

  async uploadImagesAutomatically (results) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const body = {
        completedUploads: results.map(result => this.configAwsFile(result))
      };
      console.log('result uploaded: ', body);
      const mediaArray = await apiMethods.media.saveUploadedFiles(body);
      if (this.props.model && this.props.modelId && (mediaArray && mediaArray.length > 0)) {
        await this.props.addMediaTo(this.props.model, this.props.modelId, mediaArray);
      }
      const Aelements = document.querySelectorAll('a.uppy-Dashboard-Item-previewLink');
      Aelements.forEach((element) => {
        element.href = '#';
        element.target = ''
      })
      this.props.onMediaUpload(mediaArray);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }


  render () {
    /*    if (this.state.isPageReady && this.props.aspireLibraryBtn && !this.props.disableAspireLibraryBtn) {
      this.timeoutId = setTimeout(() => {
        this.addButtonMediaLibraryToBox()
      }, 1000)
    } */
    return (
      <Fragment>
        { this.state.isPageReady && (
          <Fragment>
            <div id={this.state.mediaBoxId} className={`MediaUploadBox flex-100 layout-row layout-wrap layout-align-center-center ${this.props.className || ''}`}>
              <Dashboard
                uppy={this.state.uppy}
                allowMultipleUploads
                showProgressDetails
                proudlyDisplayPoweredByUppy={false}
                plugins={['Webcam']}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  mediaDetails: state.mediaR.mediaDetails,
  alert2Props: state.appR.alert2Props
});

const mapDispatchToProps = dispatch => ({
  addMediaTo: (model, publicId, body) => dispatch(addMediaTo(model, publicId, body))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaUploadBox);
