import React, { Component } from 'react';
import Swiper from 'swiper';
import './SwiperCarousel.css';

let mySwiper = null;
class SwiperCarousel extends Component {
  constructor (props) {
    super(props);
    this.state = {
      options: this.props.options
    };
  }

  componentDidMount () {
    // more options on swiper https://swiperjs.com/api/
    if (!this.props.options) return;
    mySwiper = new Swiper(`#${this.props.id}`, {
      ...this.state.options,
      on: {
        init: () => {
          if (this.props.init) this.props.init();
        }
      }
    });
    if (Array.isArray(mySwiper)) {
      mySwiper = mySwiper[this.props.swiperIndex];
    }
    if (this.props.onSlideChange) {
      mySwiper.on('slideChange', () => {
        this.props.onSlideChange(mySwiper.realIndex, mySwiper.isEnd);
      });
    }
    if (this.props.onReachBeginning) {
      mySwiper.on('reachBeginning', () => {
        this.props.onReachBeginning();
      });
    }
    if (this.props.onSliderMove) {
      mySwiper.on('sliderMove', (event) => {
        // console.log('sliderMove:', event);
        this.props.onSliderMove(event);
      });
    }
    if (this.props.onProgress) {
      mySwiper.on('progress', (progress) => {
        this.props.onProgress(progress);
        // console.log('progress:', progress); -> from 0 to 1 divide by numb of sliders
      });
    }
    this.setState({ ...this.state });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.carouselRef && !this.props.hasCarouselRef) {
      this.props.carouselRef(mySwiper);
    }
  }

  render () {
    const {
      className, pagination, navigation, scrollbar, children, id, navigationClasses, classNameWrapper
    } = this.props;
    return (
      <div id={id} className={`swiper-container carousel-${id} ${className || ''}`}>
        <div className={`swiper-wrapper ${classNameWrapper}`}>
          {/* Slides -> <div className="swiper-slide">Slide 1</div> */}
          {children}
        </div>
        {/* If we need pagination */}
        {pagination && (<div className="swiper-pagination" />)}
        {/* If we need navigation buttons */}
        {navigation && (<div className={`swiper-button-prev ${navigationClasses || ''}`} />)}
        {navigation && (<div className={`swiper-button-next ${navigationClasses || ''}`} />)}
        {/* If we need scrollbar */}
        {scrollbar && (<div className="swiper-scrollbar" />)}
      </div>
    );
  }
}

export default SwiperCarousel;
