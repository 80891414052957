import localStorage from 'local-storage';
import { actionTypes } from '../actions/actionTypes';
import { utils } from '../../services/utilsService';
import { units } from '../../services/enums';
import subscriptionAccessControl from '../../constants/accessControl_subscription';
import userAccessControl  from '../../constants/accessControl_userType';

const initialState = {
  auth: localStorage.get('Aspire-auth'),
  userInfo: {},
  settings: {
    weightUnits: units.weightUnits.KG,
    distanceUnits: units.distanceUnits.CM
  },
  clientsListKV: {},
  profileInfo: {},
  userData: {},
  userList: [],
  response: null,
  // userType
  chosenClientsKV: {},
  chosenClientsIds: [],
  userCalendarSettings: {},
  myCalendarSettings: {},
  accessControl: {
    allAccess: true
  },
  userTypeAccess: {
    allAccess: true
  },
  subscription: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_USER_CREDENTIALS: {
      const { public_id, userType, gender, firstName, lastName, about, birthDate, trainer, name, thumbnail, clients } = action.payload.user;
      let clientsList = {};
      if (clients && clients.length > 0) clientsList = utils.arrayToKeyValue(clients, 'public_id');
      // for Client Type purposes => SET_CHOSEN_CLIENT_BY_ID
      else {
        clientsList.keyValueObj = { public_id, name, thumbnail, order: 0 };
      }
      return {
        ...state,
        userInfo: action.payload.user,
        settings: action.payload.user.settings,
        profileInfo: { public_id, userType, gender, firstName, lastName, about, birthDate },
        auth: action.payload.user.token || action.payload.token,
        userType: action.payload.user.userType,
        clientsListKV: clientsList.keyValueObj,
        trainerInfo: trainer ? trainer[0] : null,
        myCalendarSettings: action.payload.user && action.payload.user.settings && action.payload.user.settings.calendarSettings || {},
        accessControl: subscriptionAccessControl.trial, // ['trial' || user.subscriptionAccess],
        userTypeAccess: userAccessControl[userType]
      };
    }
    case actionTypes.SAVE_USER_TOKEN_INFO: {
      const { public_id, userType, gender, firstName, lastName, about, birthDate } = action.payload.user;
      return {
        ...state,
        userInfo: action.payload.user,
        settings: action.payload.user.settings,
        profileInfo: { public_id, userType, gender, firstName, lastName, about, birthDate },
        userType: action.payload.user.userType
      };
    }
    case actionTypes.SAVE_USER_PROFILE_INFO:
      return {
        ...state, profileInfo: action.payload
      };
    case actionTypes.SAVE_USER_PROFILE_GALLERY:
      return {
        ...state, profileGallery: action.payload
      };
    case actionTypes.SET_CHOSEN_CLIENT:
      return { ...state, chosenClientsKV: action.payload.keyValueObj, chosenClientsIds: action.payload.dataIds };
    case actionTypes.SET_CHOSEN_CLIENT_BY_ID: {
      const obj = {};
      if (state.clientsListKV && state.clientsListKV[action.payload]) {
        obj[action.payload] = { ...state.clientsListKV[action.payload] };
      } else {
        obj[action.payload] = {
          public_id: action.payload, name: state.userInfo.name, thumbnail: state.userInfo.backgroundImage, order: 0
        };
      }
      return { ...state, chosenClientsKV: obj, chosenClientsIds: [action.payload] };
    }
    case actionTypes.CLEAR_CHOSEN_CLIENT:
      return { ...state, chosenClientsKV: {}, chosenClientsIds: [] };
    case actionTypes.SET_DEFAULT_CHOSEN_USER: {
      const obj = {};
      if (!(state.chosenClientsIds && state.chosenClientsIds.length > 0) || !(state.chosenClientsKV && Object.keys(state.chosenClientsKV).length > 0)) {
        obj[state.userInfo.public_id] = {
          public_id: state.userInfo.public_id, name: state.userInfo.firstName, thumbnail: state.userInfo.backgroundImage, order: 0
        };
      }
      return { ...state, chosenClientsKV: obj, chosenClientsIds: [state.userInfo.public_id] };
    }
    case actionTypes.SAVE_MY_CALENDAR_SETTINGS:
      return { ...state, myCalendarSettings: action.payload.calendarSettings };
    case actionTypes.SAVE_USER_CALENDAR_SETTINGS: {
      const { userCalendarSettings } = state;
      action.payload.forEach((user) => {
        userCalendarSettings[user.public_id] = user.calendarSettings
      })
      return { ...state, userCalendarSettings };
    }
    case actionTypes.SET_USER_SUBSCRIPTION_DETAILS: {
      return { ...state, subscription: action.payload.subscription, accessControl: action.payload.accessControl };
    }
    case actionTypes.HANDLE_ERROR_RESPONSE:
      return { ...state, response: action.payload };
    case actionTypes.CLEAR_ERROR:
      return { ...state, response: null };
    case actionTypes.UPDATE_CLIENT_LIST: {
      const user = state.userInfo;
      user.clients = action.payload;
      let clientsList = {};
      if (user.clients && user.clients.length > 0) clientsList = utils.arrayToKeyValue(user.clients, 'public_id');
      else {
        clientsList.keyValueObj = {
          public_id: state.userInfo.public_id, name: state.userInfo.name, thumbnail: state.userInfo.thumbnail, order: 0
        };
      }
      return { ...state, userInfo: user, clientsListKV: clientsList.keyValueObj };
    }
    case actionTypes.FB_DATA_REGISTRATION:
      return { ...state, fbData: action.payload };
    case actionTypes.CLEAR_FB_DATA_REGISTRATION:
      return { ...state, fbData: null };
    case actionTypes.SET_USERS:
      return { ...state, userList: action.payload };
    case actionTypes.SET_USER_DATA:
      return {
        ...state, userData: action.payload
      };
    case actionTypes.CLEAR_USER_DETAILS:
      return {
        ...state, userData: {}
      };
    case actionTypes.CLEAR_CREDENTIALS:
      return {};
    default:
      return state;
  }
};

export default userReducer;
