import { workoutProgramsTypes } from '../actions/actionTypes';
import { storeUtils } from '../../services/utilsService';
import { programTypes } from '../../services/enums';

const initialState = {
  workoutLogsData: [],
  workoutProgramsKV: {},
  workoutProgramsIds: [],
  // workoutProgramData {}
  // chosenPublicId
  // workoutLogsData []
  // workoutLogsDetails
  // workoutStats
  workoutPlanKV: {},
  workoutPlanIds: [],
  // planPrograms: [],
  workoutPlanData: {},
  // workoutPlansList
  // newWorkoutProgram: {},
  // workoutIsChanged: false
  // creationWorkoutId
  // creationWorkoutPlanId
  // workoutLogsDataOriginal: []
  workoutCreationPage: 1,
};

const workoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case workoutProgramsTypes.SET_WORKOUT_PROGRAMS: {
      return { ...state, workoutProgramsKV: action.nextPage ? {...state.workoutProgramsKV, ...action.payload.keyValueObj } : {...action.payload.keyValueObj },
        workoutProgramsIds: action.nextPage ? [ ...state.workoutProgramsIds, ...action.payload.dataIds] : [...action.payload.dataIds] };
    }
    case workoutProgramsTypes.UPDATE_PROGRAM_LIST:
      return { ...state, workoutProgramsKV: action.payload };
    case workoutProgramsTypes.SAVE_CURRENT_WORKOUTLOGS:
      return { ...state, workoutLogsData: action.payload };
    case workoutProgramsTypes.SET_WORKOUT_PROGRAM_DETAILS:
      return {
        ...state,
        workoutProgramData: action.payload.program,
        chosenPublicId: action.payload.program.public_id,
        workoutLogsData: action.payload.workoutLogs,
        workoutLogsDataOriginal: Number(action.payload.program.type) === programTypes.group ? action.payload.workoutLogs : null,
        workoutLogsDetails: action.payload.workoutLogs,
        workoutStats: action.payload.stats
      };
    case workoutProgramsTypes.SET_USER_LATEST_WORKOUTLOGS_SESSION:
      return {
        ...state,
        latestSessionData: action.payload.session,
        workoutLogsData: action.payload.workoutLogs,
      };
    case workoutProgramsTypes.SET_ORIGINAL_WORKOUTLOGS_PROGRAM:
      return {
        ...state,
        latestSessionData: null,
        workoutLogsData: state.workoutLogsDataOriginal || state.workoutLogsData,
      };
    case workoutProgramsTypes.SET_CREATED_WORKOUT_ID:
      return { ...state, creationWorkoutId: action.payload };
    case workoutProgramsTypes.REMOVE_WORKOUT_PROGRAM: {
      const workoutProgramsKV = storeUtils.removeFromObjectInStore(state.workoutProgramsKV, action.payload);
      const workoutProgramsIds = storeUtils.removeFromArrayInStore(state.workoutProgramsIds, action.payload);
      return { ...state, workoutProgramsIds, workoutProgramsKV };
    }
    case workoutProgramsTypes.CLEAR_PROGRAM:
      return {
        ...state, workoutProgramData: {},
        chosenPublicId: null,
        workoutLogsData: [],
        workoutLogsDetails: [],
        workoutStats: {},
        creationWorkoutId: null,
        latestSessionData: null,
        workoutLogsDataOriginal: null
      };
    case workoutProgramsTypes.CLEAR_ALL_WORKOUTS_PAGE_DATA: {
      return {
        ...state, workoutPlanKV: {}, workoutPlanIds: [], workoutProgramsKV: {}, workoutProgramsIds: []
      };
    }
    case workoutProgramsTypes.SET_NEW_WORKOUT_PROGRAM: {
      return { ...state, workoutProgramData: action.payload };
    }
    case workoutProgramsTypes.WORKOUT_HAS_CHANGED: { return { ...state, workoutIsChanged: true }; }
    case workoutProgramsTypes.SET_WORKOUT_CREATION_PAGE: {
      return { ...state, workoutCreationPage: action.payload };
    }
    // WORKOUT PLAN
    case workoutProgramsTypes.SET_WORKOUT_PLANS: {
      return { ...state, workoutPlanKV: action.nextPage ? {...state.workoutPlanKV, ...action.payload.keyValueObj } : {...action.payload.keyValueObj },
        workoutPlanIds: action.nextPage ? [ ...state.workoutPlanIds, ...action.payload.dataIds] : [...action.payload.dataIds] };
    }
    case workoutProgramsTypes.SET_NEW_WORKOUT_PLAN: {
      return { ...state, workoutPlanData: action.payload.workoutPlan, workoutProgramData: action.payload.workoutProgram };
    }
    case workoutProgramsTypes.SET_WORKOUT_PLAN_DATA:
      return { ...state, workoutPlanData: action.payload.workoutPlan, planPrograms: action.payload.workoutPrograms || [] };
    case workoutProgramsTypes.SET_WORKOUT_PLAN_PAGE_DATA: {
      const { workoutPlan, workoutPrograms } = action.payload;
      const isProgramsArray = workoutPrograms && workoutPrograms.programs && Array.isArray(workoutPrograms.programs);
      const programs = isProgramsArray ? workoutPrograms.programs : [];
      return { ...state,
        workoutPlanData: workoutPlan,
        planPrograms: isProgramsArray ? programs : [],
        workoutProgramData: !isProgramsArray ? workoutPrograms.program : null,
        chosenPublicId: !isProgramsArray ? workoutPrograms.program.public_id : null,
        workoutLogsData: !isProgramsArray ? workoutPrograms.workoutLogs : null,
        workoutLogsDataOriginal: Number(workoutPlan.type) === programTypes.group ? workoutPrograms.workoutLogs : null,
        workoutLogsDetails: workoutPrograms.workoutLogs,
        workoutStats: workoutPrograms.stats ? workoutPrograms.stats : null
      };
    }
    case workoutProgramsTypes.SET_CREATED_WORKOUT_PLAN_ID:
      return { ...state, creationWorkoutPlanId: action.payload };
    case workoutProgramsTypes.SET_WORKOUT_PLAN_PROGRAMS: {
      return { ...state, planPrograms: action.payload };
    }
    case workoutProgramsTypes.SET_SELECTED_PROGRAM_TO_EDIT:
      return { ...state, workoutProgramData: action.payload};
    case workoutProgramsTypes.REMOVE_WORKOUT_PLAN: {
      const workoutPlanKV = storeUtils.removeFromObjectInStore(state.workoutPlanKV, action.payload);
      const workoutPlanIds = storeUtils.removeFromArrayInStore(state.workoutPlanIds, action.payload);
      return { ...state, workoutPlanIds, workoutPlanKV };
    }
    case workoutProgramsTypes.CLEAR_PLAN:
      return {
        ...state, workoutPlanData: {}, chosenPublicId: null, workoutProgramsKV: {}, workoutProgramsIds: [], planPrograms: []
      };
    case workoutProgramsTypes.CLEAR_CHOSEN_PUBLIC_ID:
      return { ...state, chosenPublicId: null };
    case workoutProgramsTypes.SET_WORKOUT_PLAN_LIST:
      return { ...state, workoutPlansList: action.payload };
    default:
      return state;
  }
};

export default workoutReducer;
