// eslint-disable-next-line no-useless-escape

const he = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',
  'welcomeToAspire' : 'ברוכים הבאים ל ASPIRE',

  'login_sub' : 'אנא השתמשו באימייל ובסיסמא כדי להכנס לחשבונכם. \nאם אינך רשומ/ה? ',
  'register_sub' : 'אנא מלא את הפרטים כדי ליצור חשבון חדש,\nאם הנך כבר רשום במערכת, אנא לחץ כאן כדי ל',
  'orSocialLogin': 'או התחבר דרך:',
  'connectThru': 'התחבר דרך:',
  'orRegister': 'או הירשם',
  signUpBy: 'הרשם בעזרת',
  signinBy: 'התחבר בעזרת',
  inviteBy: 'הזמן בעזרת',
  forgotPassword_sub: 'אנא הכנס את כתובת האימייל והמתן לקוד אימות שישלח אל המייל, לחץ "יש לי את הקוד", הכנס את הקוד עם הסיסמא החדשה ולחץ "שמור". \nאם את/ה זוכר/ת את הסיסמא לחץ כאן כדי ל',
  sendCode: 'שלח לי את הקוד',
  misMatchPasswords: 'אי-התאמה בין הסיסמאות, אנא נסה שוב',
  misMatchCode: 'אי-התאמה בקוד השיחזור, אנא נסה שוב',
  credentialMissMatch: 'האימייל/הסיסמא שרשמת אינם נכונים, אנא נסה שנית',
  noResultsFound: 'לא נמצאו תוצאות תואמות לחיפוש',
  pleaseTryAgain: 'אנא נסה שוב',
  addShortDescription: 'הוסף הסבר קצר על עצמך כאן.',
  addExpertise: 'הוסף התמחות אשר ברשותך.',

  noTrainingLocations: 'לא עודכנו אזורי אימונים למאמן זה',
  clickHereToCreateTrainingLocations: 'לחץ כאן לעידכון אזורי אימונים',
  noProfessionCertificates: 'לא עודכנו הסמכות/הכשרות מקצוע למאמן זה',
  clickHereToCreateProfessionCertificates: 'לחץ כאן לעידכון הסמכות המאמן',
  uploadMediaProfile: 'לא נוספו תמונות/סירטונים לגלרית הפרופיל',
  clickHereToUploadMedia: 'לחץ כאן כדי להוסיף תמונות/סירטונים לגלריה',
  uploadMedia: 'העלאת מדיה',
  uploadMedia_sub: 'לחץ כאן כדי להוסיף תמונות/סירטונים לגלריה',

  articles: 'מאמרים',
  updateProfile: 'עדכון פרופיל',
  city: 'עיר',
  expertise: 'מומחיות',
  facebook: 'פייסבוק',
  instagram: 'אינסטגרם',
  youtube: 'יוטוב',
  twitter: 'טוויטר',
  linkedin: 'לינקדאין',
  allowedImagesOnly: 'ניתן להעלות תמונות בלבד',
  allowedVideosOnly: 'ניתן להעלות וידאו בלבד',
  // articles
  articleList: 'רשימת מאמרים',
  myArticles: 'המאמרים שלי',
  createArticle: 'יצירת מאמר',
  updateArticle: 'עדכון מאמר',
  mediaCarousel: 'קרוסלת תמונות/וידאו',
  trainer: 'מאמן',
  app: 'בית',
  profile: 'פרופיל',
  update: 'עדכון',
  addCertificates: 'הוסף הכשרה',
  addLocation: 'הוסף מיקום',
  socialDetails: 'רשתות חברתיות',
  socialDetails_sub: 'הוסף לינקים לפרופילים חברתיים אשר ברשותך',
  userDetails: 'פרטי המאמן',
  userDetails_sub: 'מלא את השדות הרשומים והעלה תמונת פרופיל',
  introductionVideo: 'סרטון קצר על המאמן',
  introductionVideo_sub: 'הוסף סרטון קצר אשר מסביר ומציג את עצמך למען לקוחות פוטנציאליים',
  trainingLocations: 'מיקום אימונים',
  trainingLocations_sub: 'הוסף מקומות המשמשים אותך לאימונים',
  professionCertificates: 'הסמכות והכשרות',
  professionCertificates_sub: 'העלה תעודות הכשרה שברשותך להוכחת מקצועיותך',
  imagesAndVideos: 'תמונות וסרטונים',
  SearchAPlace: 'חפש מקום/איזור:',
  bio: 'ביוגרפיה',
  menu:'תפריט פעולות',
  create: 'יצירת חדש',
  myGallery: 'הגלריה שלי',
  userBiography: 'ביוגרפיה',
  userBiography_sub: 'רשום כאן על עצמך ועל הניסיון האישי שצברת בתחום.',
  potentialClientsList: 'רשימת לקוחות פוטנציאלים',
  potentialClients: 'לקוחות פוטנציאלים',


  makeMediaDefault: 'Make this media as default',
  areYouSure_delete: (name) => (`האם את/ה בטוח שברצונך להסיר את  "${name}"?`),

  albums: 'אלבומים',
  album_photos: (name) => (`תמונות מאלבום ${name}`),
  photos: 'תמונות',
  uploadSelectedImages: 'העלאת קבצים נבחרים',
  saveSelectedImages: 'שמור קבצים נבחרים',
  uploadingFiles: 'תהליך העלאה החל',
  updatingAspireLib: 'מעדכן את ספריית Aspire',
  openAspireLibrary: 'בחר מספריית Aspire',
  selectMyMedia: 'בחר מהמדיה שלי',
  selectInstagramPhotos: 'בחר מדיה מ Instagram',
  selectFacebookPhotos: 'בחר מדיה מ Facebook',
  loginWithFacebook: 'התחבר עם Facebook',
  /* 02.User Login, Logout, Register */
  'login-title': 'התחבר',
  'forgot-password': 'שכחתי סיסמא',
  'forgot-password-question': 'שכחת סיסמא?',
  'fullName': 'שם מלא',
  'login-button': 'התחבר',
  'register-button': 'הרשם',
  'reset-password-button': 'שיחזור סיסמא',
  buy: 'קנה',
  username: 'שם משתמש',

  /* 03.Menu */
  dashboards: 'Dashboards',
  gogo: 'Gogo',
  start: 'התחל',
  'second-menu': 'תפריט שני',
  second: 'שני',
  ui: 'UI',
  charts: 'Charts',
  chat: 'Chat',
  survey: 'Survey',
  todo: 'Todo',
  docs: 'Docs',
  'blank-page': 'Blank Page',
  dashboard: 'Dashboard',
  list: 'רשימה',

  /* 04.Error Page */
  'error-title': 'Ooops... looks like an error occurred!',
  'error-code': 'Error code',
  'go-back-home': 'GO BACK HOME',
  intro: 'קצת עליי',
  media: 'מדיה',
  reviews: 'חוות דעת',
  location: 'מיקום',
  categories: 'קטגוריות',
  achievements: 'מטרות והישגים',
  inviteClient: 'הזמנת לקוח',
  'fill clients details': 'מלא את פרטי הלקוח',
  workouts: 'אימונים',
  personalTrainer: 'מאמן אישי מוסמך',
  workoutsArchive: 'תוכניות, לו"ז ואימונים אישיים',
  createWorkout: 'יצירת תפריט אימונים',
  createProgram: 'יצירת תוכנית אימונים',
  createPlan: 'יצירת לוח זמנים',
  myExercises: 'התרגילים שלי',
  myExercises_sub: 'רשימת תרגילים פרטיים',
  inquiriesAnswers: 'שאלות ותשובות',
  inquiriesAnswers_sub: 'כל מה שתרצו לדעת על ASPIRE',
  createInquiry: 'יש לך שאלה?',
  createInquiry_sub: 'שאל ואנו נענה בהקדם האפשרי',
  createSolution: 'הוספת פיתרון',
  createSolution_sub: 'פיתרון לשאלה',
  exerciseStats_title: 'תרגיל: ',
  programsStats_title: 'תפריט: ',
  createWorkout_sub: 'מלא את פרטי התוכנית',
  chooseExercises: 'בחירת תרגילים',
  'Choose Your Exercises': 'מצא ובחר את התרגילים הרצויים',
  workoutLogs: 'עריכת אימון',
  'Build Exercise Sets, reps & weight': 'עריכת סטים, חזרות ומשקלים',
  createAProgram: 'יצירת תוכנית אימונים',
  programDetails: 'פרטי התוכנית',
  addPrograms: 'הוספת תוכנית אימונים',
  createProgram_sub: 'מלא את פרטי התוכנית',
  'Select Programs for your program': 'בחר תוכניות הכלולים בלו"ז זה',
  sessionStart: 'Session Start',
  'Session Started At': 'Session Started At: ',
  clientsList: 'הלקוחות שלי',
  statistics: 'מדדי גוף וביצועים',
  settings: 'הגדרות',
  applicationSettings: 'הגדרות משתמש',
  recentSessions: 'אימונים אחרונים',
  workoutPrograms: 'תוכניות אימונים',
  workoutPlans: 'לו"ז אימונים',
  calendar: 'יומן',
  programs: 'תוכניות אימונים',
  minutes: 'דקות',
  hours: 'שעות',
  days: 'ימים',
  day: 'יום',
  today: 'היום',
  weeks: 'שבועות',
  week: 'שבוע',
  months: 'חודשים',
  times: 'פעמים',
  of: 'מתוך',
  min: 'דק\'',
  sec: 'שנ\'',
  assignToClient: 'שייך ללקוח',
  'Using this Program': 'משתמשים בתוכנית',
  exercises: 'תרגילים',
  exerciseList: 'רשימת תרגילים',
  CreateExercise: 'יצירת תרגיל חדש',
  description: 'תיאור',
  activityHistory: 'היסטוריית פעילות',
  currentProgram: 'שם התוכנית',
  workoutsPerWeek: 'אימונים בשבוע',
  currentWeek: 'שבוע נוכחי',
  history: 'היסטוריה',
  createBodyStats: 'הוספת מדדים חדשים',
  updateBodyStats: 'עידכון מדדים קיימים',
  loading: 'טוען...',
  bodyStatsInfo_title: 'פרטי המדדים',
  bodyStatsInfo_sub: 'תאריך:',
  'Update your current Body Stats': 'עדכן את מדדי הגוף הנוכחיים',
  startSession: 'התחל אימון',
  createPastSession: 'הוספת אימון שהתקיים',
  updatePastSessions: 'הוספת אימון שהתקיים',
  updatePastSessionsFP: 'הוספת אימון שהתקיים בעבר בתוכנית זו',
  workoutsCompleted: 'אימונים נערכו',
  tonnageLifted: 'משקל הורם',
  exercisesDone: 'תרגילים הושלמו',
  totalSets: 'סטים נעשו',
  totalReps: 'חזרות נעשו',
  active: 'פעיל',
  completed: 'הושלם',
  programsTitle: 'שם התפריט',
  // body stats labels
  height: 'גובה',
  weight: 'משקל',
  muscleMass: 'מסת שריר',
  fat: 'אחוזי שומן',
  calories: 'קלוריות',
  water: 'אחוז נוזלים',
  bmi: 'BMI',
  BMI: 'BMI',
  bodyStats: 'מדדי גוף',
  bodyStats_sub: 'מדדים המעודכנים לפי לקוח',
  bodyStats_sub_client: 'המדדים המעודכנים שלך',
  performance: 'מדדי ביצועים',
  performance_sub: 'מדדים המעודכנים לפי לקוח',
  performance_sub_client: 'המדדים המעודכנים שלך',
  createCategory: 'יצירת קטגוריה',
  categories_sub: 'רשימת כל הקטגוריות',
  straight: 'רגיל',
  superset: 'סופר סט',
  super_set: 'סופר-סט',
  pyramid: 'פירמידה',
  interval: 'אינטרבלים',
  aerobic: 'אירובי',
  kilograms: 'קילוגרם',
  pounds: 'ליברות',
  meter: 'מטר / ס"מ',
  inch: 'אינץ\'',
  yard: 'יַרְד',
  mile: 'מייל',
  group: 'לקבוצה',
  personal: 'לאדם',
  other: 'אחר',
  public_id: 'מס\' זיהוי',
  uri: 'URL',
  order: 'סדר',
  thumbnail: 'תמונה',
  backgroundImage: 'תמונת רקע',
  content: 'תוכן',
  email: 'אימייל',
  title: 'כותרת',
  makeItTopArticle: 'סמן כמאמר מועדף לקריאה (מאמר אשר יופיע בראש הרשימה)',
  category: 'קטגוריה',
  shortDescription: 'מבוא קצר',
  password: 'סיסמא',
  confirmPassword: 'אשר סיסמא',
  oldPassword: 'סיסמא נוכחית',
  newPassword: 'סיסמא חדשה',
  passwordOrInvite: 'סיסמא / קוד הזמנה',
  resetCode: 'קוד איתחול סיסמא',
  forgotPassword: 'שכחת סיסמא?',
  alreadyHaveTheCode: 'יש לי את הקוד',
  inviteCode: 'קוד הזמנה',
  firstName: 'שם פרטי',
  aboutMe: 'קצת עליי',
  lastName: 'שם משפחה',
  birthDate: 'יום הולדת',
  weightUnits: 'יח\' משקל',
  distanceUnits: 'יח\' מרחק',
  language: 'שפה',
  startDate: 'תאריך התחלה',
  endDate: 'תאריך סיום',
  date: 'תאריך',
  phone: 'טלפון',
  duration: 'משך זמן',
  'num. of weeks': 'הערה: רשום מס\' שבועות',
  workoutPerWeek: 'אימונים בשבוע',
  'num. of times': 'הערה: רשום מס\' פעמים',
  updateStats: 'עדכן מדדים',
  type: 'סוג',
  exercise: 'תרגיל',
  set: 'סט',
  sets: 'סטים',
  reps: 'חזרות',
  time: 'זמן',
  speed: 'מהירות',
  distance: 'מרחק',
  intensity: 'עצימות',
  chooseImage: 'בחר תמונה',
  mediaLibrary: 'ספריית תמונות',
  uploadImage: 'העלאת תמונה',
  gallery: 'גלריה',
  myMedia: 'מדיה שלי',
  male: 'זכר',
  female: 'נקבה',
  next: 'הבא',
  prev: 'הקודם',
  save: 'שמור',
  saveChanges: 'שמור שינויים',
  skip: 'דלג',
  cancel: 'ביטול',
  confirm: 'אישור',
  updatePassword: 'עדכן סיסמא',
  done: 'סיום',
  back: 'חזור',
  add: 'הוספה',
  remove: 'הסרה',
  addSet: 'הוסף סט',
  removeSet: 'הסר סט',
  setAdd: 'סט נוסף',
  moreDetails: 'פרטים נוספים',
  details: 'פרטים',
  link: 'לינק',
  edit: 'עריכה',
  preview: 'תצוגה מקדימה',
  delete: 'מחיקה',
  expand: 'הרחבה',
  backToCards: 'חזרה לכרטיסים',
  resetChanges: 'מחק שינויים',
  exitEditMode: 'צא מעריכה',
  register: 'הרשם',
  submit: 'שלח',
  login: 'התחבר',
  dontHaveAnAccount: `היי מאמנ/ת , אין לך חשבון?`,
  muscleId: 'קוד שריר',
  title_he: 'כותרת בעברית',
  title_en: 'כותרת באנגלית',
  description_he: 'הסבר בעברית',
  description_en: 'הסבר באנגלית',

  moreOptions: 'אפשרויות נוספות',
  exerciseNotes: 'הערות לתרגיל',
  clickHereFullScreen: 'לחץ כאן לצפייה במסך מלא',
  close: 'סגירה',
  notes: 'הערות',
  addToList: 'הוסף לרשימה',
  editExerciseName: 'ערוך את שם התרגיל',
  changeExerciseFields: 'שינוי שדות מדידה לתרגיל',
  deleteExercise: 'מחק תרגיל',
  clientInvite: 'לקוח מוזמן',
  info: 'מידע',
  information: 'מידע',
  help: 'עזרה',
  myInquiries: 'שאלות שלי',
  exerciseInfo: 'מידע נוסף',
  enter: 'כניסה',
  logout: 'התנתק',
  myProfile: 'הפרופיל שלי',
  notice: 'שים לב',
  chooseClient: 'בחר לקוח',
  chooseClients: 'בחר לקוחות',
  chooseAchievementType: 'בחר סוג מטרה',
  your: '',
  invitationCode: 'קוד ההזמנה',
  inviteSentInfo: 'בדקות הקרובות הלקוח יקבל את קוד ההזמנה בכתובת הדוא"ל',
  NoWorkoutsToShow: 'לא נמצאו תוכניות אימונים',
  clickHereToCreateProgram: 'צור את תוכנית האימונים הראשונה שלי!',
  NoActivitiesToShow: 'לא נמצאו פעילויות חדשות',
  NoGoalsToShow: 'לא נמצאו מטרות אימונים',
  NoStatsToShow: 'לא נמצאו סטטיסטיקות',
  NoStatsToShow_sub: 'יש לקיים אימון עם הלקוח על מנת שדף זה יתעדכן',
  clickHereToCreateGoal: 'צור מטרת אימונים לך/ללקוחותך כאן',
  NoAchievementToShow: 'לא נמצאו הישגים, עדיין',
  ChangeLogToCompleted: 'סט זה שמור כ"לא הושלם", האם לעדכן ל"הושלם"?',
  CLIENT: 'לקוח',
  TRAINER: 'מאמן אישי',
  NUTRITIONIST: 'תזונאי',
  english: 'אנגלית',
  hebrew: 'עברית',
  'Choose Program type': 'בחר סוג תפריט',
  exercisesSelected: 'תרגילים נבחרו',
  addExercise: 'הוסף תרגיל לתפריט',
  addExerciseToSession: 'הוספת תרגיל לאימון',
  addExerciseInfo: 'לחץ כאן כדי להוסיף תרגיל חדש',
  addSuperSet: 'הוסף סופר סט',
  selectedExercises: 'תרגילים נבחרים',
  moveExercisesInfo: 'גרור את התרגיל הנבחר על מנת לשנות את סדר התרגילים בתוכנית.',
  bodyWeightInfo: `רישום 0 בשדה המשקל יחשב כ'משקל גוף'`,
  aerobicFieldsInfo: `רישום 0 בשדה  יחשב כהתעלמות מהשדה בתוכנית`,
  programsSelected: 'תוכניות נבחרו',
  'notice Changing Current Program': 'הוספה/הסרה של תרגיל מהתפריט יבטל את מצב \'יעוד\' של התפריט וישמור אותו כתפריט חדש, האם הנך מעונין להמשיך?',
  assignAsAchieved: 'המטרה הושגה',
  assignAsAchieved_notice: 'האם המטרה הושגה?',
  assignAsAnswered: 'השאלה נענתה',
  assignAsAnswered_notice: 'האם קיבלת את המענה לשאלתך?',
  resetProgressToThisWeek_notice: 'הנך בטוח/ה שברצונך למחוק את השינויים שנעשו בשבוע זה?',
  more: 'נוספים',
  liftingTime: 'זמן מושקע באימונים',
  avgWorkoutTime: 'ממצוע זמן אימון',
  avgRestTime: 'ממוצע זמן מנוחה',
  showMore: 'הצג עוד',
  saySomething: 'כמה מילים...',
  howWasSession: 'איך הרגיש האימון היום?',
  sessionTime: 'משך זמן האימון',
  preparation: 'הכנה לתרגיל',
  execution: 'ביצוע התרגיל',
  muscles: 'שרירים',
  equipment: 'ציוד',
  defaultEquipment: 'ציוד ברירת מחדל',
  exerciseType: 'סוג תרגיל',
  muscleMap: 'מפת שרירים',
  target: 'שרירי מטרה',
  synergist: 'שרירי עזר',
  stabilizers: 'מייצבים',
  antagonist: 'שרירי ניגוד',
  removeExercise: 'הסרת תרגיל',
  removeExerciseDesc: 'האם את/ה בטוח/ה שברצונך להסיר התרגילים הנבחרים?',
  clickToDelete: 'לחץ כאן למחיקה',
  hi: 'היי',
  sessionSection1: 'למי נעשה אימון היום?',
  sessionSection2: 'אנא בחר את התוכנית הרלוונטית',
  'Choose Client & Program': 'בחר לקוח ותפריט אימונים',
  chooseProgram: 'בחר תפריט אימונים',
  'Are you sure you want to delete': 'האם את/ה בטוח/ה שברצונך למחוק את',
  areYouSureFinishSession: 'האם את/ה בטוח/ה שברצונך לסיים אימון זה?',
  theseBodyStats: ' מדדים אלו',
  me: 'אני',
  isPersonalTrainingSession: 'אימון זה הינו חלק מחבילת אימונים אישיים',
  sessionSummery: 'סיכום אימון',
  bestSets: 'סטים מובחרים',
  current_state: 'מיקום נוכחי',
  target_state: 'מטרה',
  initial_state: 'מדידה ראשונה',
  initialvalue: 'מדידה ראשונה',
  targetvalue: 'מטרה',
  kg: 'ק"ג',
  KG: 'ק"ג',
  lbs: 'ליב\'',
  LBS: 'ליב\'',
  Ft: 'רגל',
  FT: 'רגל',
  Yd: 'יַרְד',
  YD: 'יַרְד',
  T: 'טון',
  cm: 'ס"מ',
  CM: 'ס"מ',
  INCH: 'אינץ\'',
  YARD: 'יַרְד',
  MILE: 'מייל',
  km: 'קמ\'',
  KM: 'קמ\'',
  m: 'מ\'',
  M: 'מ\'',
  kcal: 'קק"ל',
  Kcal: 'קק"ל',
  KCAL: 'קק"ל',
  percent: '%',
  PERCENT: '%',
  M_KM: 'ס"מ / מ\' / ק"מ',
  YARD_MILE: 'אינץ\' / יַרְד / מייל',
  KG_CM: 'ק"ג / ס"מ',
  LBS_INCH: 'ליברות / אינץ\'',
  NoStatsRecorded: 'לא נמצאו נתונים',
  activeAchievements: 'מטרות פעילות',
  completedAchievements: 'רשימת הישגים',
  'type in kg': 'רשום בק"ג',
  'type in cm': 'רשום בס"מ',
  'type in %': 'רושם באחוזים',
  'type in Kcal': 'רשום במס\' קלוריות',
  searchKeywords: 'מילות חיפוש',
  mediaGroup: 'קבוצת מדיה',
  '': '',
  EN: 'English',
  HE: 'עברית',
  image: 'תמונה',
  video: 'וידאו',
  search: 'חיפוש',
  session_of: 'תוצאות האימון של',
  chooseGoal: 'בחירת מטרה ללקוח',
  chooseGoalSub: 'בחירת מטרה לפי מדדי גוף או ביצוע',
  goalDetails: 'פרטי המטרה',
  achievementDetails: 'טוען פרטים...',
  goalDetailsSub: 'מלא את הפרטים הבאים',
  createGoal: 'יצירת מטרה',
  againstEquipment: 'כנגד',
  lastUpdatedIn: 'עודכן לאחרונה ב',
  bestSet: 'הסט הטוב ביותר באימון',
  bestTime: 'הזמן הטוב ביותר לפי מרחק',
  tonnage: 'טונאז\'',
  mostReps: 'מס\' החזרות הגבוהה ביותר',
  highestWeight: 'המשקל הגבוהה ביותר',
  longestTime: 'הזמן הארוך ביותר',
  longestDistance: 'המרחק הארוך ביותר',
  bestByRM: 'המשקל הגבוה ביותר לפי RM',

  BARBELL: 'מוט',
  DUMBBELL: 'משקולות יד',
  MACHINE: 'מכונה',
  SMITH_MACHINE: 'מכונת סמית\'',
  CABLE: 'כבלים',
  BODY_WEIGHT: 'משקל גוף',
  KETTELE_BELL: 'קטלבל',
  TRX: 'TRX',
  FREE_WEIGHTS: 'משקולות חופשי',
  PAUNCH_MITTS: 'לפות אגרוף',
  WEIGHT_BAG: 'שק משקולת',
  bodyWeightShort: 'מ. גוף',
  bodyWeight: 'משקל גוף',
  showInGraph: 'הצג בגרף:',
  nextExercise: 'תרגיל הבא',
  prevExercise: 'תרגיל קודם',
  currentExercise: 'תרגיל נוכחי',
  skipped: 'לא הושלם',
  later: 'יותר מאוחר',
  seeAchievement: 'ראה הישג',
  congratulations_sub: 'השגת את מטרה זו! ההישג יועבר לדף ההישגים',
  wellDone: 'כל הכבוד!',
  'please write your notes here': 'אנא רשום את הערותך כאן...',
  changePassword: 'שינוי סיסמא',
  changePassword_sub: 'אנא עדכן את סיסמתך',
  resendInvitation: 'הזמן לקוח שוב',
  profileDetails: 'פרטי חשבון',
  restDay: 'יום מנוחה',
  createExercise: 'יצירת תרגיל',
  createExerciseAdmin: 'יצירת תרגיל ניהול',
  'fill exercise details': 'מלא את פרטי התרגיל',
  'type old password': 'רשום סיסמא ישנה',
  'type new password': 'רשום סיסמא חדשה',
  'Application Settings': 'הגדרות מערכת',
  'title in english (required)': 'שם התרגיל באנגלית (חובה)',
  onlyEnglishLetters: 'רשום אך ורק באותיות אנגלית',
  'type in exercise title': 'שם התרגיל',
  myActivities: 'פעילות',
  myActivities_sub: 'כל העדכונים באפליקציה ירשמו כאן',
  editName: 'עריכת שם ',
  top: 'עליון',
  center: 'מרכז',
  bottom: 'תחתון',
  processingImage: 'מכווץ תמונה',
  connect: 'מתחבר',
  uploading: 'מעלה תמונה...',
  errorUploadingFile: 'העלאה נכשלה',
  addMedia: 'הוסף מדיה',
  upTo5Imgs: 'עד 5 תמונות',
  skipEx: 'דלג תרגיל',
  change: 'שינוי',
  nextSet: 'סט הבא',
  noExercisesAdded: 'לא נוספו תרגילים לתוכנית',
  pleaseGoBack: 'אנא חזור אחורה',
  session_has_ended: 'האימון הסתיים',
  changeCurrentWeek: 'שינוי שבוע נוכחי',
  changeCurrentSessionNum: 'שינוי מס\' אימון נוכחי',
  statistics_of: name => `סטטיסטיקות של ${name || ''}`,
  history_of: name => `המדדים של ${name || ''}`,
  workouts_of: name => `האימונים של ${name || ''}`,
  activeAchievements_of: name => `המטרות של ${name || ''}`,
  completedAchievements_of: name => `ההישגים של ${name || ''}`,
  bodyStats_of: name => `המדדים של ${name || ''}`,
  bodyStatsDetails_of: name => `המדדים של ${name || ''}`,
  bodyStatsDetails_sub: name => `המדדים של ${name || ''}`,
  bodyStatsDetails: 'טוען מדדים...',
  performanceDetails: 'טוען מדדים...',
  performance_of: name => `הביצועים של ${name || ''}`,
  performanceDetails_of: name => `הביצועים של ${name}`,
  performanceDetails_sub: () => 'טוען מדדים...',
  performanceDetails_exerciseStats_sub: name => `מדדי התרגיל של ${name || ''}`,
  performanceDetails_programsStats_sub: name => `מדדי התפריט של ${name || ''}`,
  achievementDetails_sub: name => `המדדים של ${name || ''}`,
  lastRegisteredStat: 'מדד אחרון שנרשם: ',

  aspireCommentTitle: () => `Aspire Says`,
  HALF_TIME_DOME: (timeLeft, log) => `עברת כבר מחצית מהזמן, זמן שנישאר: ${timeLeft}`,
  TIME_DOME_75: (timeLeft, log) => `אנו מתקרבים אל הסוף, זמן שנישאר: ${timeLeft}`,
  TIME_DOME_90: (timeLeft, log) => `כמעט סיימנו, זמן שנישאר: ${timeLeft}`,
  TIMER_TIME_DOME: (timeLeft, log) => `הזמן נגמר! עבודה טובה!`,
  usingThisProgram: (title, endDate) => `פעילה ע"י ${title} עד תאריך ${endDate}`,
  addProgramToPlan: 'הוסף תוכנית אימונים ללוח זמנים זה',
  myClientsList: 'רשימת המתאמנים שלי',
  professions: 'הכשרות',
  myClientsList_sub: 'עדכון שינוי וניתוק מתאמן מהרשימה',
  refreshInfo: 'עדכן פרטים',
  unlink: 'נתק',
  clientDetails: 'פרטי הלקוח',
  unlink_question: clientName => `האם את/ה בטוח/ה שברצונך לנתק את '${clientName}' מרשימת הלקוחות שלך? `,

  /* validation */
  pleaseChooseClient: 'אנא בחר לקוח',
  isRequired: name => `שדה ${name} הינו חובה`,
  tooShort: (name, value) => `השדה ${name} חייב ליהיות יותר מ${value} אותיות.`,
  tooLong: name => `השדה ${name} ארוך מידי`,
  typeMismatch: name => `בשדה ${name} ישנה אי-התאמה`,
  rangeUnderflow: (name, value) => `${name} חייב להיות גדול\/שווה ל ${value}`,
  rangeOverflow: (name, value) => `${name} חייב להיות קטן\/שווה ל ${value}`,
  rangeBetween: (name, min, max) => `${name} חייב להיות בין ${min} ל${max}`,
  notAllowed: (type) => `סוג ${type} זה אינו מורשה `,
  passwordsMismatch: 'ישנה אי-התאמה בין הסיסמאות',
  "Please include an '@' in the email address": 'אנא הוסף @ לשדה הדוא"ל',
  typeMismatchReps: `בשדות 'חזרות' ישנה אי-התאמה`,
  typeMismatchReps_sub: `בסוג מטרה זה שדות 'חזרות' מחוייב לאותו ערך`,
  typeMismatchWeights: `בשדות 'משקל' ישנה אי-התאמה`,
  typeMismatchWeights_sub: `שדות 'משקל' אינם יכולים להיות 0 ביחד`,
  termsAgreement_1: 'על ידי לחיצה על "הרשמה", אני מסכים ל',
  termsOfService: 'תנאי השימוש',
  termsAgreement_2: ' של Aspire וכי קראתי את ',
  policy: 'מדיניות הפרטיות.',
  privacyPolicy: 'מדיניות פרטיות',
  termsAgreement_3: ' ',
  // error handler text
  emailAlreadyInUse: 'דוא"ל נמצא בשימוש',
  noUserFound: 'לא נמצא משתמש זה במערכת',
  badCredentials: 'אנא בדוק את הסיסמא/דוא"ל שוב',
  emailNotVerified: 'דוא"ל לא אומת',
  accessDenied: 'גישה נדחתה',
  unhandledError: 'אופס שגיאה, נסה שנית',
  wrongPassword: 'סיסמא שגויה, נסה שנית',
  lockForHour: 'החשבון ננעל לשעה',
  sendAgain: 'שלח שוב',
  cantFindVerificationMail: 'הודעת אימות החשבון לא נמצאה בדוא"ל?',
  cantFindVerificationMail_comment:  '(אנא בדוק את תיבת \'קידומים\' ליתר ביטחון).',
  verificationSent: 'הודעת אימות נשלחה',


  /* onBoarding */
  yourBusinessPlatform: 'הסביבה העסקית שלך',
  yourBusinessPlatform_desc: 'הראה את יכולותיך ומקצועיותך על ידי שיתוף מדיה, תוכניות אימונים ותוצאות לקוחותיך אשר יוצגו ללקוחות פוטנציאלים בקרוב.',
  inviteClients: 'בלעדי ללקוחות אישיים',
  inviteClients_desc: 'הזמן את לקוחותיך האישיים לקהילה בילעדית המיועדת ליצירת מסגרת מקצועית ומקיפה ומחוייבות גבוה.',
  // create workout
  creatingaWorkoutProgram: 'יצירת תוכנית אימונים',
  creatingaWorkoutProgram_desc: 'ישנם 3 צעדים ליצירת תוכנית אימונים.\n ' +
      '1. בחר לקוח מרשימת הלקוחות, תמונה מספריית התמונות של ASPIRE או מהנייד, ורשום שם לתוכנית',
  selectTheExercises: 'בחירת תרגילים',
  selectTheExercises_desc: 'בחר את התרגילים הרצויים לתוכנית, ' +
      ' ניתן למצוא תרגילים לפי קטגוריה, שם שריר, וציוד.',
  customizeWorkoutProgram: 'עריכת תרגילים',
  customizeWorkoutProgram_desc: '1. בחירת ציוד לכל תרגיל\n' +
      '2. שינוי הוספת סטים, חזרות, ומשקל.\n' +
      '3. שינוי שם התרגיל - להבנה טובה יותר של הלקוח. \n' +
      '4. הוספת הערות לכל תרגיל למען תזכורת ובהירות מירבית ללקוח.',
  // create a goal
  creatingGoal: 'יצירת מטרה',
  creatingGoal_desc: 'ישנם 3 צעדים ליצירת מטרה.\n' +
      'Firstly, choose a client and a certain body stat/exercise.',
  fillGoalsDetails: 'מלא את פרטי המטרה',
  fillGoalsDetails_desc: 'Fill the goal\'s details including title, description, time frame for the goal.\n' +
      'insert an initial & target states to measure your client\'s progress.\n' +
      '* The app will show you the client\'s latest stats recorded on the chosen exercise / body stats (depends if there is a record in the app)',
  helpful: 'עזר',
  unhelpful: 'לא עזר',
  steps: 'צעדים',
  solutions: 'פיתרונות',
  contactYouFrom: 'בקשה ליצירת קשר דרך ',
  aspireWebsite: 'אתר ASPIRE',
  by: ' ע"י ',
  hereAListOfContactWillAppear: 'כאן תופיע רשימה של לקוחות אשר מנסים ליצור קשר עימך דרך אתר ASPIRE.',
  mediaList: 'רשימת המדיה שלך',
  uploadNewMedia: 'העלאת תמונה/סירטון',
  potentialclients: 'לקוחות פוטנציאלים',
  somethingWrong: `אירעה שגיעה, אנא נסה שוב`,

  welcomeUserToAspire: name => `שלום ${name}!`,
  welcomeUserToAspire_desc: 'הנך עכשיו חלק מקהילת Aspire!\n' +
    'זה הזמן לבנות את הסביבה המקצועית שלך כאן, להזמין לקוחות, ולהנות ממגוון יכולות אשר יעזרו לך ליצור מסגרת חזקה יותר בינך לבין לקוחותך.',
  congratulations: 'מזל טוב!',
  dummyUserAppended: 'הרשמתך לAspire הושלמה! \n' +
    'הכירו את Alice, הלקוחה הראשונה שלך במערכת!.\n' +
    'בעזרתה תוכל/י להבין את יכולות המערכת ולממש את יכולות אלו על לקוחותיך האישיים.',
  addDescription: 'הוסף הסבר קצר',

  // calendar

  eventsHour: 'שעת האירוע',
  allDay: 'כל היום',
  hide: 'הסתר',
  dontShowAgain: 'אל תציג אותי שוב',
  personalProgram: 'תוכנית אישית - לקוח אחד',
  groupProgram: "תוכנית לקבוצה - מס' לקוחות",
  pickAColor: 'בחר צבע',
  chooseDate: 'בחר תאריך',
  selectDate: 'בחר תאריך',
  selectDates: 'בחר תאריכים',
  chooseDateRange: 'בחר טווח תאריכים',
  makeAllDayEvent: 'אירוע זה פעיל לכל אורך היום.',
  createAnEvent: 'יצירת אירוע',
  bookASession: 'קבע אימון חדש',
  editAnEvent: 'עריכת פרטי האימון',
  rescheduleAnEvent: 'דחיית אימון לזמן אחר',
  writeATitle: 'רשום כותרת...',
  participants: 'משתתפים',
  advancedOptions: 'אפשרויות מתקדמות',
  recurringEvent: 'אפשרויות אירוע חוזר',
  openRecurringOptions: 'פתח אפשרויות אירוע חוזר',
  repeatEvery: 'חזרה כל',
  repeatEvery_day_info: 'האירוע יחזור על עצמו בכל יום או בכל X ימים.',
  repeatEvery_week_info: 'האירוע יחזור על עצמו כל שבוע או x שבועות בימים ספציפיים.',
  repeatEvery_month_info: 'האירוע יחזור על עצמו בכל x חודש ביום ספציפי בחודש.',
  stopConditions: 'אפשרויות עצירה',
  neverStop: 'ללא עצירה',
  neverStop_info: 'אירוע זה יחזור על עצמו לעולם.',
  untilDate: 'חזור עד לתאריך',
  untilDate_info: 'אירוע זה יחזור על עצמו עד שיגיע לתאריך מסוים.',
  untilNumber: 'חזור עד ',
  occurrences: 'אירועים',
  untilNumber_info: 'אירוע זה יחזור על עצמו עד שיופיע מספר פעמים.',
  eventDates: 'זמן האירוע',
  confirmArrival: 'אשר הגעה',
  postpone: 'לא אגיע',
  cancelArrival: 'לא אגיע',
  reschedule: 'שנה לזמן אחר',
  repeatedEvent: 'אירוע חוזר',
  thisEventRepeats: 'אירוע זה חוזר על עצמו ',
  for: ' למשך ',
  until: ' עד ',
  weekly2: ' כל שבוע ',
  daily2: ' כל יום ',
  inDays: ' בימים ',
  editRecurringEventSelect: 'בעריכת אירוע חוזר זה ערוך...',
  deleteRecurringEventSelect: 'במחיקת אירוע חוזר זה מחק...',
  thisEventOnly: 'אירוע זה בלבד.',
  thisEventAndFollowing: 'אירוע זה והבאים אחריו.',
  allEvents: 'כל האירועים.',
  pending: 'ממתין לאישור',
  confirmed: 'מגיע',
  canceled: 'לא מגיע',
  noDatesSelected: 'לא נבחרו תאריכים חדשים, אין אפשרות לשנות את זמן האירוע.',
  onGoing: 'מתמשך',
  calendarSettings: 'הגדרות היומן שלי',
  rescheduleReason: 'סיבת השינוי',
  sundayToSunday: 'ראשון עד ראשון',
  mondayToMonday: 'שני עד שני',
  firstDayOfTheWeek: 'יום ראשון בשבוע',
  firstDayOfTheWeek_sub: 'בחר את היום הרצוי שיתחיל השבוע ביומנך:',
  restrictAvailableHours: 'הגדרת שעות פעילות',
  restrictAvailableHours_sub: 'הגדרת שעות פעילות תאפשר ללקוחותך לקבוע אימונים רק בזמנים המותרים בין שני הערכים.',
  timeInputSettings: 'הגדרות שדה זמן',
  timeInputSettings_sub: 'הגדר את שדה הזמן בצורה הנוחה לך ביותר',
  daysOff: 'ימים חופשיים',
  daysOff_sub: 'הגדר ימים חופשיים אשר לקוחותך לא יוכלו קבוע אימונים בהם. לדוגמא: יום שבת',
  timeInputExample: 'לחץ כאן כדי לראות את השינויים על שדה הזמן.',
  timegrid: 'משבצות זמנים',
  timeWheel: 'גלגל זמנים',
  fiveMinutes: '5 דקות',
  tenMinutes: '10 דקות',
  fifteenMinutes:'15 דקות',
  thirtyMinutes: '30 דקות',
  watchYoutubeVideo: 'צפה בסרטון הסברה',
  bookSession: 'קבע אימון',
  waitingForResponse: 'ממתין לתשובה',
  confirmedArrival: 'אישרו הגעה',
  calendar_sub: 'יומן אימונים שבועי',
  routines: 'אימונים',
  hour: 'שעה',
  and: 'ו',
  month: 'חודש',
  daily: 'יומי',
  weekly: 'שבועי',
  monthly: 'חודשי',
  startTime: 'שעת התחלה',
  endTime: 'שעת סיום',


  smsOnItsWay: 'הקוד בדרך אלייך!',
  smsWillBeReceived: 'ברגעים הקרובים תשלח אלייך הודעת SMS עם קוד בן 5 ספרות, אנא המתן.',
  resendSMS: 'שלח SMS בשנית',
  insertTrainerInvite: 'קוד הזמנה לASPIRE',
  insertTrainerInvite_sub: 'אנא הכנס/י את קוד ההזמנה אשר קיבלת בהודעת SMS או ממאמנך.',
  clickNextOrSwipe: "לחץ על 'הבא' או החלק ימינה.",






  myAchievements: 'מטרותי והישגי',
  invite: 'הזמן לקוח',
  resend: 'הזמן לקוח',

  myWorkouts: 'האימונים שלי',
  certifiedPersonalTrainer: 'מאמן אישי מוסמך',
  workoutsArchive_2: 'ארכיון תוכניות אימונים',
  sessionsArchive: 'ארכיון תוכניות אימונים',
  workoutPlanArchive: 'ארכיון אימונים',

  replayInquiry: 'תגובה',
  replayInquiry_sub: 'השאר לנו תגובה, ונחזור אליכם בהקדם!',

  chooseExercises_sub: 'בחר תרגילים מן הרשימה',
  chooseExercise: 'בחר תרגיל',
  chooseExercise_sub: 'בחר תרגיל מן הרשימה',
  workoutLoggerHeader: 'עריכת אימון',
  workoutLoggerHeader_sub: 'עריכת סטים, חזרות ומשקלים',
  routineUpdateDetails: 'עריכת פרטי אימון',
  routineUpdateDetails_sub: 'עריכת כותרת והגדרות אימון',
  createAWProgram: 'יצירת תוכנית אימונים',
  getAFreeProgramTemplate: 'קבל תוכנית אימונים חינמית',
  aspireIntroductionVideoTrainer: 'סרטון מיוחד למאמן',
  aspireIntroductionVideoClient: (name) => `סרטון מיוחד ל${name || 'לקוח'}`,

  modifyProgress: 'בניית התקדמות שבועית',
  modifyProgress_sub: 'בניית התקדמות אוטומטית לכל תוכנית לפי שבוע.',
  modifyProgramsProgressPage_info:
    'לחץ על התוכנית הרצויה, ערוך לכל שבוע את המדדים המתאימים, ומדדים אלו יתעדכנו אצל הלקוח בכל אימון.',
  'Select Programs for your plan': 'בחר תוכניות הכלולים בלו"ז זה',

  MyStatistics: 'מדדי גוף וביצועים שלי',

  sessionsHistory: 'היסטוריית אימונים',

  sendDocToClients: 'שלח מסמך זה ללקוחות',
  copyDocToMe: 'העתק מסמך זה ושלח ללקוחותי',
  sendSurveyToClients: 'שלח שאלון זה ללקוחות',
  copySurveyToMe: 'העתק שאלון זה ושלח ללקוחותי',
  UsingThisProgram: 'משתמשים בתוכנית',

  default: 'ברירת מחדל',
  chooseEquipment: 'בחר ציוד לתרגיל',
  choose: 'בחר',

  UpdateYourCurrentBodyStats: 'עדכן את מדדי הגוף הנוכחיים',

  copyProgramToClient: 'העתק תוכנית ללקוח אחר',
  copyProgram: 'העתק תוכנית',
  copyProgramToProfile: 'העתק אימון לפרופיל שלי',

  eventsTitle: 'כותרת האירוע',
  // body stats labels


  shoulders: 'כתפיים',
  arms: 'היקף זרוע',
  leftArm: 'היקף זרוע שמאל',
  rightArm: 'היקף זרוע ימין',
  chest: 'היקף חזה',
  waist: 'היקף מתניים',
  hips: 'היקף אגן',
  thighs: 'היקף ירך',
  leftThigh: 'היקף ירך שמאל',
  rightThigh: 'היקף ירך ימין',
  lowerThighs: 'היקף ירך תחתון',
  leftLowerThigh: 'היקף ירך תחתון שמאל',
  rightLowerThigh: 'היקף ירך תחתון ימין',
  calves: 'היקף שוק',
  leftCalf: 'היקף שוק שמאל',
  rightCalf: 'היקף שוק ימין',
  isAdvancedMeasurements: 'מדדי היקף מתקדם',
  addBodyMeasurements: 'הוסף מדדי היקפים',
  bodyMeasurements: 'מדדי היקפים',

  strength: 'כוח',
  static: 'סטטי',

  group_option: 'לקבוצה',
  personal_option: 'לאדם',
  public_option: 'פומבי',
  store_option: 'חנות',
  other_option: 'אחר',

  chooseLanguage: 'בחר שפה',

  numOfWeeks: "הערה: רשום מס' שבועות",
  numOfTimes: "הערה: רשום מס' פעמים",

  round: 'סבב',

  rest: 'זמן מנוחה',
  timeDone: 'זמן הושלם',
  totalTimeDone: 'זמן כולל',
  totalDistance: 'מרחק כולל',

  uploadFile: 'העלאת קובץ',

  uploadVideo: 'העלאת וידאו',
  uploadProfileImage: 'העלאת תמונת פרופיל',
  uploadBackgroundImage: 'העלאת תמונת רקע',
  files: 'קבצים',
  thisMedia: 'מדיה זה',
  leave: 'צא',
  send: 'שלח',
  clear: 'נקה',
  assignAsDone: 'סמן כבוצע',
  markAsCompleted: 'סמן כבוצע',
  finishSession: 'סיים אימון',
  finishSession_desc: 'לחץ כאן לסיים אימון זה',
  modifyDoc: 'עריכת המסמך',
  changeRepsWeight: 'שינוי משקל/חזרות',
  register_login: 'התחבר / הרשם',
  signin: 'התחבר',
  signup: 'הרשם',
  invitedClient: 'לקוח מוזמן',
  createAccount: 'צור חשבון מאמן',

  exerciseName: 'שם התרגיל',
  changeExerciseOrder: 'שינוי סדר תרגילים',
  addExerciseToSuperSet: 'הוסף תרגיל לסופר-סט',

  support: 'תמיכה',

  store: 'חנות',
  alert: 'הודעה',
  thisModalCantBeOpened: 'איזור זה לא יפתח עד להשלמת העלאת הקבצים, נסה שנית מאוחר יותר.',
  chooseTrainer: 'בחר מאמן',
  clientsParticipates: 'משתתפים',

  inviteSentInfoByEmail: 'בדקות הקרובות הלקוח יקבל את קוד ההזמנה בכתובת הדוא"ל',
  inviteSentInfoSpam: "אנא בדוק גם את תיבת ה'קידומי מכירות' אם לא נמצא המייל.",
  inviteSentInfoBySMS: 'ההזמנה נשלחה אל הלקוח בהודעת SMS.',
  inviteSentInfoBySMS_sub: (name) =>
    `אם מסיבה מסויימת ${name || 'הלקוח'} לא קיבל/ה את ההזמנה בדקות הקרובות, קוד ההזמנה הינו:`,
  NoWorkoutsToShowFor:(name) => `לא נמצאו תוכניות אימונים ל${name}.`,

  selectProgramType: 'בחר סוג תוכנית',
  selectProgramView: 'בחר תצוגת תוכנית',

  reorderExercises: 'שינוי סדר תרגילים',
  reorderExercises_sub: 'שינוי סדר ומחיקת תרגילים',

  addExercises: 'הוספת תרגילים',
  selectExercises: 'בחר בתרגילים',
  selectExercise: 'בחר בתרגיל',
  noticeChangingCurrentProgram:
    "הוספה/הסרה של תרגיל מהתוכנית תבטל את מצב 'העתקה' והיא תחשב כתוכנית חדשה, האם הנך מעונין להמשיך?",
  noticeUnsavedChanges: (numbers) =>
    `תרגילים ${numbers} נמצאים עדיין בעריכה והשינויים שבוצעו בהם לא ישמרו, האם ברצונך להמשיך?`,
  goBackWithoutSave_notice: "חזרה לאחור ללא שמירת שינויים תגרום למחיקת השינויים שנעשו, האם את/ה בטוח/ה?",

  showLess: 'הצג פחות',
  archive: 'ארכיון',

  difficulty: 'רמת אימון',
  chooseDefaultEquipment: 'בחר ציוד ברירת מחדל',
  filter: 'סינון',
  areYouSureRemoveEquipment: 'האם את/ה בטוח/ה שברצונך להסיר את המדיה מהציוד הנבחר?',
  sessionSummary: 'סיכום אימון',

  'type in lbs': 'רשום בליברות',
  'type in inch': 'רשום באינץ',

  blank: '',

  ES: 'Español',
  EN_short: 'Eng',
  HE_short: 'עב',
  ES_short: 'Es',

  updateUserCredentials: 'עידכון פרטי התחברות',
  updateUserCredentials_sub: "עדכנו את סיסמתכם או הוסיפו מס' טלפון.",
  resendInvitationAgain: 'שלח הזמנה שוב',
  updateResendInvitation: 'עדכן ושלח הזמנה נוספת ללקוח',
  inviteAnotherClient: 'הזמן לקוח נוסף',
  emailSent: 'הודעה נשלחה לדוא"ל',
  invitationSent: 'ההזמנה נשלחה',
  invitationSentError: 'בעיה בשליחת ההזמנה',
  createExercise_sub: 'לחץ כאן כדי ליצור תרגיל חדש',
  typeOldPassword: 'רשום סיסמא ישנה',
  typeNewPassword: 'רשום סיסמא חדשה',
  upToImgs: (number) => `עד ${number} העלאות`,
  fullScreen: 'מסך מלא',
  timer: 'טיימר',
  stopwatch: 'שעון עצר',
  subscription: 'הרשמה לשרותי Aspire',
  subscription_sub: 'עדכון מסלול השירותים לAspire',



  /* validation */
  invalidDate: 'תאריך אינו תקין',
  missingDate: 'אנא בחר תאריך',
  pleaseSelectRoutine: 'אנא בחר אימון',

  askMeAgain: 'שאל אותי שוב בכניסה הבאה לAspire.',
  noThankYou: 'לא תודה',
  letMyTry: 'אנסה בעצמי',
  checkTermsAgreement: 'אנא הסכם למדיניות Aspire',

  // error handler text
  error: 'שגיאה',

  checkEmailFirst: 'המתן לבדיקת כתובת הדוא"ל ואז לחץ כאן',

  'Missing Credentials': 'נתונים חסרים',
  emailAlreadyExist: 'דוא"ל קיים במערכת',
  emailIsGood: 'מצויין! אפשר להמשיך',
  emailNotExist: 'דוא"ל לא קיים',
  invalidName: 'שם שגוי',
  invalidEmail: 'כתובת שגויה',
  invalidPhoneNumber: "מס' טלפון אינו תקין",
  invalidForm: 'טופס שגוי',
  invalidCode: 'קוד שגוי',
  userAlreadyExist: 'משתמש קיים במערכת',
  phoneAlreadyExist: 'המספר קיים במערכת',
  phoneNotExist: 'המספר לא קיים במערכת',
  userSuspended: 'יוזר מושהה',
  alreadyLoggedIn: 'יוזר מחובר למערכת',
  limitReached: 'השהייה קלה, נסה שוב מאוחר יותר',
  progress: 'התקדמות',
  program: 'תוכנית',
  /* onBoarding */
  // create workout
  fillWorkoutDetails: 'מילוי פרטי התוכנית',
  fillWorkoutDetails_desc:
    'ליצירת תוכנית אימנים על המאמן להשלים 3 צעדים פשוטים, הצעד הראשון הוא למלא את פירטי התוכנית:',
  fillWorkoutDetails_list: [
    'בחר לקוח מרשימת הלקוחות.',
    'רשום שם לתוכנית.',
    'בחר תמונה מספריית התמונות של ASPIRE או מהנייד',
  ],

  customizeYourProgram: 'עריכת תרגילים',
  customizeYourProgram_list: [
    'בחירת ציוד לכל תרגיל.',
    'שינוי/הוספת סטים, חזרות, ומשקל.',
    'הוספת הערות לכל תרגיל',
    'שינוי שם התרגיל - להבנה טובה יותר מצד הלקוח.',
    'מחיקת תרגילים מיותרים',
  ],
  // create a goal
  creatingGoal_list: [
    'בחר לקוח מרשימת הלקוחות.',
    'בחר את סוג המטרה: מדד גוף/ביצועים.',
    'בחר את המדד הרצוי מהרשימה',
  ],
  fillGoalsDetails_list: [
    'מילוי שם, תאריך התחלתי לתחילת מדידה, משך הזמן להשיג את מטרה זו',
    'רישום מדד התחלתי (בחלקו התחתון של הדף יופיע העידכון האחרון של המדד במידה ועודכן במערכת)',
    'רישום מדד סופי אליו נרצה להגיע',
    'שים לב: במידה ונבחרה מטרה למדידת ביצועים יש לבחור ציוד לתרגיל וסוג מדידה (משקל הכי גבוה, חזרות...)',
  ],
  fillExerciseDetails: 'מילוי פרטי התרגיל',
  fillExerciseDetails_desc:
    'ליצירת תרגיל עליך לבחור את סוג התרגיל, למלא את שם התרגיל והוראות ביצוע',
  uploadEquipmentMedia: 'העלה תמונה/סירטון לכל ציוד',
  uploadEquipmentMedia_desc: 'אפשרות העלאת מדיה לכל ציוד של התרגיל\n' + '*העלאת מדיה איננה חובה',
  replay: 'השב',
  chooseMediaType: 'בחר סוג מדיה',
  uploadImages: 'העלאת תמונות',
  uploadImages_desc: 'אנא בחר/י את התמונות אשר ברצונך להעלות.',
  uploadVideos: 'העלאת וידאו',
  uploadVideos_desc: 'אנא בחר/י את הסרטונים שברצונך להעלות, אחרת לחץ על החץ לחזרה.',
  uploadCertificates: 'העלאת תעודות הכשרה',
  uploadCertificates_desc:
    'אנא צלם והעלה את התעודות המאשרות את הכשרתך המקצועית בתחומים שבחרת.\n' +
    '(אפשר לדלג על שלב זה)',
  orSkipAndUploadLater: "או דלג בלחיצה על 'הבא' לאישור מאוחר יותר.",
  goodLuck: 'אנחנו כאן לכל שאלה ובקשה,\nבהצלחה!',
  welcomeToAspire_desc:
    'הנך עכשיו חלק מקהילת Aspire!\n' +
    'זה הזמן לבנות את הסביבה המקצועית שלך כאן, להזמין לקוחות, ולהנות ממגוון יכולות אשר יעזרו לך ליצור מסגרת חזקה יותר בינך לבין לקוחותך.',
  welcomeToAspireClient_desc:
    'ברוך הבא אל ASPIRE, מאמנך האישי הזמין אותך אל סביבה אקסקלוסיבית ללקוחותיו אשר יהנו מיחס אישי,' +
    'שיתוף תוכן ואימונים אישיים.',
  noSessionFound: 'לא נמצא מידע על אימון זה',
  documents: 'מסמכים',
  myDocuments: 'המסמכים שלי',
  surveys: 'שאלונים',
  documents_of: (name) => `המסמכים של ${name || ''}`,
  surveys_of: (name) => `השאלונים של ${name || ''}`,
  documentArchive: 'ארכיון מסמכים',
  surveyArchive: 'ארכיון שאלונים',
  createDocument: 'יצירת מסמך חדש',
  createDocument_sub: 'מלא את פרטי המסמך',
  updateDocument: 'עידכון מסמך',
  updateDocument_sub: 'עדכן את פרטי המסמך',
  createSurvey: 'יצירת שאלון חדש',
  createSurvey_sub: 'יצירת שאלון חדש',
  surveyFillDetails: 'השלמת שאלון',
  surveyFillDetails_sub: 'ענה על השאלות בכנות מלאה',
  updateSurvey: 'עדכון שאלון',
  updateSurvey_sub: 'עדכון שינוי או הוספת שאלות חדשות לשאלון',
  filled: 'הושלמו',
  templates: 'תבניות',
  template: 'תבנית',
  forReviewSurvey: 'השלמת שאלון',
  forReviewDoc: 'השלמת מסמכים',
  chooseClients_doc: 'בחר לקוחות לשליחת המסמך לחשבונם',
  addCheckboxToConfirmAgreement: `הוסף צ'ק-בוקס למסמך כדי לאשר שהלקוח קרא ומסכים לתנאים.`,
  isAgreeText: "רשום טקסט בהתאמה אישית לצ'ק-בוקס",
  IAgreeToTerms: 'קראתי את המסמך ואני מסכים לתנאים הרשומים בו.',
  isClientCanModifyDoc: `אפשר ללקוח לשנות/לתקן את הכתוב במסמך.`,
  forClient: 'ללקוח',
  addQuestion: '+ הוסף שאלה',
  requiredAnswer: 'חובה לענות',
  question: 'שאלה',
  options: 'אפשרויות',
  addOption: 'הוסף אפשרות',
  // inputOption
  inputOption: 'שדה טקסט',
  numberOption: 'שדה מספר',
  longInputOption: 'שדה טקסט ארוך',
  checkboxOption: 'בחירה מרובה',
  radioOption: 'בחירה אחת',
  dateOption: 'שדה תאריך',
  optionsPlaceHolderText: 'כאן תירשם תשובת הלקוח...',
  optionsPlaceHolderLabel: 'רשום כאן את האפשרות',
  answer: 'תשובה',
  surveyTitle: 'כותרת השאלון',
  surveyDescription: 'הסבר קצר על השאלון',
  surveyIntro: 'כאן תוכלו לרשום הסבר קצר על השאלון שלקוחותיכם ימלאו...',
  noQuestion: 'הוסף לפחות שאלה 1 לשאלון',
  noAnswers: 'מלא את שדות החובה בשאלון',
  fillRequiredAnswers: 'מלא את שדות החובה בשאלון.',
  missingSurveyTitle: 'כותרת לשאלון הינה שדה חובה',
  noQuestionText: (index) => `חסרה שאלה לשאלה: ${index}`,
  noLabelOption: (name) => `חסר טקסט לאפשרות בשאלה: ${name}`,
  starRequiredFields: '* שדות חובה',
  copySurvey: 'העתק שאלון זה לחשבוני',
  assignDocumentSuccessfully: 'המסמכים נשלחו ללקוחות בהצלחה',
  assignSurveySuccessfully: 'השאלונים נשלחו ללקוחות בהצלחה',
  unfinishedSession_sub: 'האימון האחרון נסגר ללא אישור, שנחזור להתאמן?',
  contactsList: 'רשימת אנשי קשר',
  contactDetails: 'פרטי איש הקשר',
  phoneCopied: "מס' הטלפון הועתק",

  // tutorial overview
  profileOverview: 'פרופיל המאמן',
  inviteClientOverview: 'הזמנת לקוח',
  programOverview: 'תוכנית אימונים',
  planOverview: 'תוכניות אימונים',
  achievementsOverview: 'מטרות והישגים',
  statisticsOverview: 'סטטיסטיקות ומדדים',
  paperworkOverview: 'מסמכים ושאלונים',
  createMyExerciseOverview: 'יצירת תרגילים אישיים',
  welcomeAspireClip: 'סירטון ברוכים הבאים',
  welcome: 'ברוכים הבאים!',
  welcome_sub:
    'תודה שבחרת להתנסות עם ASPIRE, אנו מבטיחים לכם לעשות כל שנוכל כדי שחווית האימונים איתכם תיהיה בלתי נשכחת!.\n' +
    'אך קודם תנו לנו להציג בפינכם כמה מיכולותנו, מה תרצו לדעת?',

  selectFilesToUpload: 'בחר קבצים מהמכשיר',
  wouldYouLikeAWalkThru: 'האם תרצה הדרכה מהירה לבניית תוכנית?',
  programCreationTutorial: 'בניית תוכנית עם הדרכה',
  noThankUTutorial: 'לא תודה, אמשיך לבד',
  // tutorials
  chooseClient_sub: 'בחר בך או בלקוח מהרשימה לשייך את תוכנית האימונים אליו.',
  groupClients_sub: "בחירה במספר לקוחות התוכנית תהפוך ל'תוכנית אימונים קבוצתית'.",

  fillProgramTitle: 'תן כותרת לתוכנית האימונים.',
  afterFillForm: 'לאחר השלמת הפרטים ',
  clickHereToAddExercises: "לחץ על הוספת תרגילים לאימון.",
  saveToProceed: "לחץ על 'שמור' לסיום.",
  nextToProceed: "לחץ על 'הבא' להמשך.",
  nextToProceed2: "לאחר סיום בחירת התרגילים ,לחץ על 'הבא' להמשך.",
  doneToProceed: "לחץ על 'סיום' לשמירת השינויים שנעשו בדף.",
  chooseExercisesTutorial_sub: 'בחר ב3 תרגילים להוספה לתוכנית האימונים.',
  selectedExercisesTutorial_sub:
    'כאן יופיעו התרגילים אשר בחרתם, בלחיצה על התגית תיפתח רשימת התרגילים.',
  exerciseType_categories: 'בחירת סוג תרגיל וקטגוריות',
  filterTutorial_sub: 'כאן ניתן לבחור בתרגיל אחד או לקבץ מספר תרגילים ביחד (סופר-סט).',
  clickOnAlice_sub: "ברשימת הלקוחות לחץ על 'אליס' כדי לצפות במדדיה.",

  // 'ה"קטגוריות" מיועדות לסינון מהיר יותר למציאת התרגילים אותם תרצו לבחור לתוכנית.',
  filterOptions: 'אפשרויות נוספות',
  filterOptions_sub: 'ניתן למצוא תרגילים בחיפוש חופשי, לפי ציוד, שריר מטרה או קטגורית אימונים.',
  aspireTrainerIntroduction_sub:
    '"כלקוחה הראשונה שלך בAspire, הרשה לי לתת לך סיור קצר, אני מבטיחה לך שתבין מהר יותר את המערכת איתי לצדך."\n\nנתחיל ב:',
  aspireClientIntroduction_sub:
    '"בביקורך הראשון בAspire, הרשה לי לתת לך סיור קצר, אני מבטיח לך שתבינ/י מהר יותר את המערכת איתי לצדך."\n\nנתחיל ב:',
  aspireClientIntroductionTwo_sub:
    '"כמאמן האישי שלך, אשמח לתת לך סיור קצר באפליקציה, כדי שנוכל להתמצא בה טוב יותר בעתיד."\n\nנתחיל ב:',

  editBtnTutorial_sub: 'בלחיצה על הכפתור תוכל להוסיף/למחוק סטים ולשנות את השדות לפי יכולות הלקוח.',
  exerciseOptionsTutorial_sub:
    'אפשרויות נוספות לכל תרגיל כגון:\nהוספת הערות, שינוי מדדי התרגיל, עריכת שם וכו...',
  exerciseEquipmentTutorial_sub: 'שינוי ציוד התרגיל במקרה הצורך.',
  squareInfo_hint: 'כאן יוצג כמות אימונים הנעשו בעזרת התוכנית + כמות המשקל שהורם סך הכל.',
  sessionUpdateBtn_hint: 'בלחיצה כאן תוכל להתחיל אימון ולעדכן אותו בזמן-אמת.',
  exerciseDescription_hint:
    'בלחיצה על שם התרגיל יפתח דף סבר ביצוע התרגילת ציוד אפשרי, ומפת שרירים.',
  superset_hint: 'הצגת סופר-סט, החלק ימינה/שמאלה למעבר בין התרגילים.',
  clickToCheck_hint: "בלחיצה על שם הסט כ'הושלם' ויפעיל את הסט הבא.",
  modifyExerciseBtn_hint:
    'בלחיצה על הכפתור הכרטיס יפתח למצב עריכה ותוכל/י לשנות מדדים וסטים לפי הצורך.',
  fillClientInfo: 'מילוי פרטי הלקוח',
  fillClientInfo_sub: 'מלא את פרטי הלקוח לשליחת ההזמנה',
  changeClientsAppSettings: 'הגדרות חשבון הלקוח',
  changeClientsAppSettings_sub: "במידת הצורך שנה את הגדרות חשבון הלקוח, אם לא לחץ על 'הבא'.",
  editProfile: 'עריכת פרופיל',
  editProfile_sub: 'התחל/י לבנות את הפרופיל שלך כאן.',
  uploadImage_sub: 'כאן תופיע תמונת הפרופיל שלך, שנתחיל לבנות?',
  trainersProfile: 'פרופיל המאמן',
  trainersProfile_sub: 'בפינה הימנית נמצא פרופיל המאמן שלך, בלחיצה עליו תוכל/י לבקר בו.',
  notifyUserToVerifyAccount_desc:
    'חשבונך לא אומת עדיין, אם הודעת האימות אינה התקבלה לדוא"ל אנא לחץ "שלח שוב" ' +
    "(יש לבדוק את תיבת 'קידומי מכירות' ליתר ביטחון)",
  beginner: 'מתחיל',
  trained: 'מאומן',
  wellTrained: 'מאומן מנוסה',
  athlete: 'אתלט',
  copyProgramSuccessfully: 'התוכנית הועתקה לחשבונך בהצלחה',
  success: 'הושלם בהצלחה',
  successfullyUpdated: 'העידכון הושלם בהצלחה',
  successfullyUpdatedPhone: 'המספר התעדכן בהצלחה במערכת!',
  successfullyUpdatedPhone2: 'מהיום תוכל/י להתחבר אלינו בהזנת מספר הטלפון בלבד.',
  successfullyUpdatedPassword: 'סיסמתך התעדכן בהצלחה במערכת!',

  somethingWentWrong: 'שגיאה קלה',
  stay: 'השאר',
  articles: 'מאמרים',
  helloUserStore: (name) => `שלום ${name}`,
  helloUserStore_desc: 'ברוכים הבאים לחנות ASPIRE',
  storePrograms: 'ארכיון תוכניות',
  storePrograms_desc:
    'הנכם מוזמנים להעתיק, לעדכן ולשתף את תוכניות האימונים של אספייר עם לקוחותיכם.',
  personal_info:
    'תוכנית אימונים אישית המיודעת ללקוח אחד, ניתן לשייך את התוכנית ללקוחות נוספים לאחר שהכנתה הושלמה.',
  group_info:
    'תוכנית זו נועדה לאימוני קבוצות, בחר מספר לקוחות לתוכנית זו וצפה ביכולותיהם במקום אחד.',
  public_info:
    'זוהי תוכנית אימונים חינמית המיועדת לצפייה ותרגול ע"י לקוחותיך או מבקרים בפרופיל המאמן שלך, כדי להתנסות ולהבין את כישוריך.',
  store_info:
    'זוהי תוכנית אימונים המיועדת לבדיקה ע"י ASPIRE, אם תאושר, התוכנית תעלה לחנות עם שמך ותג מיוחד (בהמשך תוכל לבנות תוכניות בתשלום).',
  circuitTraining: 'אימון מחזורי',
  SET_option: 'סטים',
  CIRCUIT_option: 'מחזורי',
  SET_info:
    'תצוגת ברירת מחדל, מאפשרת למאמן לבנות תוכנית הבנויה לפי סטים, סופר-סטים וכו...',
  CIRCUIT_info: 'תצוגת אימון מחזורי, מאפשרת למאמן לציין מספר סבבים לתרגילים הנבחרים.',
  optionsAreDisabled: 'אין אפשרות לשנות את הבחירה הקיימת, הנך נמצא בהדרכה או במצב עריכה.',
  original: 'המקור',
  newUpdates: 'עידכונים חדשים',
  whatsNew: `מה חדש?!`,
  updateDetails_2: 'עדכן פרטי',
  updateDetails: 'עדכן פרטים',
  signupByPhone: 'הרשם בעזרת קוד טלפוני',
  signupByEmail: 'הרשם בעזרת חשבון דוא"ל',
  insertYourNumberToVerify: 'לקבלת SMS לאימות המכשיר, אנא רשום את מספר טלפונך.',
  verifyEmailBeSent: 'לאחר ההרשמה ישלח אימייל לאימות חשבונך, אנא בדוק גם את איזור ה"קידומי מכירות".',
  sendVerificationCode: 'שלח לי קוד אימות',
  aspireIntro_title_1: 'עידן חדש למאמנים אישיים',
  aspireIntro_desc_1:
    'ASPIRE שמה כמטרה להעצים את המאמן האישי אשר נמצא בכל שלב מקצועי, מכל בחינה, עם כל לקוח אישי.',
  aspireIntro_title_2: 'השארו מחוברים 24/7',
  aspireIntro_desc_2: 'צור סביבה מקצועית ואקסקלוסיבית בשבילך ובשביל לקוחותיך, ועקוב אחר התקדמותם.',
  aspireIntro_title_3: 'חוויה אישית וייחודית',
  aspireIntro_desc_3:
    'כל יכולת שפיתחנו נבחנה בדיקדוק כדי לעזור לך להפוך לגרסה הטובה ביותר שלך: יותר מקצועית ,יותר אישית, יותר עצמך.',
  copied: 'הועתק',
  certainField: 'השדה',
  addWorkoutRoutine: 'הוספת אימון לתוכנית',
  workoutRoutine: 'אימון',
  addExercisesToFirstCreatedProgram: (title) => `ל'${title}' לא התווספו תרגילים, אנא בנה את '${title}' קודם.`,
  addAProgram: 'הוספת תוכנית אימונים',
  saveProgram: 'שמור שינויים בתוכנית',
  clickHereAddExercises: 'לחץ כאן להוספת תרגילים לתוכנית',
  removeFromPlan: 'הסר אימון מהתוכנית',
  display: 'תצוגה',
  removeRoutine: 'הסר אימון זה',
  areYouSureRemoveRoutine: 'האם את/ה בטוח/ה שברצונך להסיר את האימון מהתוכנית?',
  routineDetails: 'פרטי האימון',
  editRoutineDetails: 'עריכת פרטי האימון',
  editProgramDetails: 'עריכת פרטי התוכנית',
  modifyExercises: 'עידכון תרגילים',
  programAdvanceOptions: 'אפשרויות מתקדמות לתוכנית',
  routineAdvanceOptions: 'אפשרויות מתקדמות לאימון',
  attention: 'הודעה חשובה!',
  goBackNotice: 'חזרה לדף הקודם תמחק את השינויים אשר נעשו בדף זה, האם הנך בטוח/ה שברצונך לחזור לדף הקודם?',
  processingMedia: 'העלאה בתהליכי השלמה,\nיתכן והתהליך יקח כמה דקות.\nהנך יכול/ה להמשיך בעבודתך.',
  clientProfileIsPrivate: 'פרופיל זה הינו פרטי ואינו ניתן לצפייה ע"י איש פרט למאמנך האישי.',
  openContactsList: 'פתח רשימת אנשי קשר',
  countryCodes: 'קוד ארצות',
  selectCountry: 'בחר מדינה',
  selectCountryCode: 'אנא בחר קוד מדינה',
  noCountryCodeAttached: 'קוד המדינה לא נמצא במספר הטלפון הנבחר',
  access_denied_pro_up: 'פעולה זו אפשרית למאמנים במסלול Pro ומעלה',
  access_denied_gold_up: 'פעולה זו אפשרית למאמנים במסלול Gold ומעלה',
  access_denied_update_sub: ' ,לעדכון מסלול עבור להגדרות > שרותי Aspire.',
  clickHere: 'לחץ כאן.',

  latestStatClientProgress_info: (name) => `לצפייה בנתוני האימון האחרון של ${name}, `,
  latestStatClientWorkout_info: (name) => `גלול למטה לצפייה בנתוני האימון האחרון של ${name}.`,
  NoSessionsOnWorkout_info: (name) => `${name} עדיין לא עידכנ/ה נתונים לאימון זה.`

};

export default he;
