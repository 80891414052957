import localStorage from 'local-storage';
import momentTZ from 'moment-timezone';
import { apiMethods, aspireClientConfig } from '../../services/apiService';
import { actionTypes } from './actionTypes';
import { handleErrorResponse } from './index';
import { utils } from '../../services/utilsService';

export const saveUserToken = (res) => {
  if (res.token) {
    aspireClientConfig.headers.Authorization = res.token;
    localStorage.set('Aspire-auth', res.token);
  }
  if (res.clients) {
    return {
      type: actionTypes.UPDATE_CLIENT_LIST,
      payload: res.clients
    };
  }
  return {
    type: actionTypes.HANDLE_SUCCESS_RESPONSE,
    payload: 'Handled Successfully'
  };
};

export const saveUserCredentials = (res) => {
  if (res.token) {
    aspireClientConfig.headers.Authorization = res.token;
    localStorage.set('Aspire-auth', res.token);
  }
  return {
    type: actionTypes.SAVE_USER_CREDENTIALS,
    payload: res
  };
};

export const saveUserTokenInfo = (res) => {
  const token = localStorage.get('Aspire-auth');
  aspireClientConfig.headers.Authorization = token;
  return {
    type: actionTypes.SAVE_USER_TOKEN_INFO,
    payload: res
  };
};

export const clearCredentials = () => {
  return {
    type: actionTypes.CLEAR_CREDENTIALS
  };
};


export const saveUserProfileInfo = payload => ({
  type: actionTypes.SAVE_USER_PROFILE_INFO,
  payload
});

export const setUserProfileGallery = payload => ({
  type: actionTypes.SAVE_USER_PROFILE_GALLERY,
  payload
});

export const saveMyCalendarSettings = payload => ({
  type: actionTypes.SAVE_MY_CALENDAR_SETTINGS,
  payload
});

export const saveUserCalendarSettings = payload => ({
  type: actionTypes.SAVE_USER_CALENDAR_SETTINGS,
  payload
});

export const setUserSubscriptionDetails = payload => ({
  type: actionTypes.SET_USER_SUBSCRIPTION_DETAILS,
  payload
});


export const checkIfLoggedIn = () => async (dispatch) => {
  try {
    const timezone = momentTZ.tz.guess();
    const query = `?timezone=${timezone}`;
    const data = await apiMethods.users.checkIfLoggedIn(query);
    dispatch(saveUserTokenInfo(data));
    return data.user;
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const loginByEmail = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.loginByEmail(body);
    console.log(data);
    // const status = await apiMethods.users.checkUserSubscriptionStatus(body);
    if (data.user.userType === 'TRAINER') {
      data.user.profession = true; // for now
    }
    dispatch(saveUserCredentials(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data || { error: 'error'};
  }
};

export const loginByPhone = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.loginByPhone(body);
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data || { error: 'error'};
  }
};

export const verifyUserAndLogin = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.verifyUserAndLogin(body);
    dispatch(saveUserCredentials(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data || { error: 'error'};  }
};

export const UserVerifyInvitationCode = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.verifyInvitationCode(body);
    return data;
  } catch (e) {
    if (e.response.data) return e.response.data;
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data || { error: 'error'};
  }
};


export const sendVerificationCode = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.sendVerificationCode(body);
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const sendVerificationCodeToLogin = body => async (dispatch) => {
  try {
    return await apiMethods.users.sendVerificationCodeToLogin(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const resendInvitationCode = body => async (dispatch) => {
  try {
    return await apiMethods.users.resendInvitationCode(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};



export const signUpUser = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.register(body);
    dispatch(saveUserCredentials(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const resendWelcomeEmail = body => async (dispatch) => {
  try {
    return await apiMethods.users.resendWelcomeEmail(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createClient = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.createClient(body);
    dispatch(saveUserToken(result));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const checkEmailExist = email => async () => {
  try {
    const query = `?email=${email}`;
    const data = await apiMethods.users.checkEmailExist(query);
    return !!(data && data.status === 200);
  } catch (e) {
    return false;
  }
};

export const logoutUser = body => async (dispatch) => {
  try {
    await apiMethods.users.logout(body);
    dispatch(clearCredentials());
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const saveSettings = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.saveSettings(body);
    dispatch(saveUserCredentials(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateUserPassword = body => async (dispatch) => {
  try {
    await apiMethods.users.updatePassword(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateClientPassword = body => async (dispatch) => {
  try {
    await apiMethods.users.updateClientPassword(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const sendResetPasswordEmail = body => async (dispatch) => {
  try {
    await apiMethods.users.sendResetPasswordEmail(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const resendClientInvitation = publicId => async (dispatch) => {
  try {
    const body = { publicId };
    await apiMethods.users.resendClientInvitation(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const resetPassword = body => async (dispatch) => {
  try {
    await apiMethods.users.resetPassword(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateUserAccount = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.updateUserAccount(body);
    dispatch(saveUserToken(result));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const unlinkClient = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.unlinkClient(body);
    dispatch(saveUserToken(result));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserDetailsForClients = body => async (dispatch) => {
  try {
    return await apiMethods.users.getUserDetailsForClients(body);
  } catch (e) {
    return false;
  }
};

export const saveUpdatedClientsList = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.saveUpdatedClientsList(body);
    dispatch(saveUserToken(result));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateTrainerProfession = body => async (dispatch) => {
  try {
    await apiMethods.users.updateTrainerProfession(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserDataByPublicId = body => async (dispatch) => {
  try {
    // await apiMethods.users.updateTrainerProfession(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserProfile = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.profile.getUserProfile(publicId);
    dispatch(saveUserProfileInfo(data));
    return data;
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const getMyProfileGallery = queryObj => async (dispatch) => {
  try {
    const query = utils.buildQuery(queryObj);
    const data = await apiMethods.profile.getMyProfileGallery(query);
    dispatch(setUserProfileGallery(data));
    return data;
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const updateUserProfile = body => async (dispatch) => {
  try {
    const data = await apiMethods.profile.updateUserProfile(body);
    dispatch(saveUserProfileInfo(data));
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const facebookLogin = body => async (dispatch) => {
  try {
    const data = await apiMethods.users.facebookLogin(body);
    if (data && data.code && data.code === 403) return { code: 403 };
    else dispatch(saveUserCredentials(data));
    return data;
  } catch (e) {
    // if (e.response.data) return e.response.data;
    dispatch(handleErrorResponse(e));
    return false;
  }
};


export const checkFBPermissions = () => async (dispatch) => {
  try {
    return await apiMethods.auth.getFacebookPermissions();
  } catch (e) {
    // if (e.response.data) return e.response.data;
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const refreshFacebookAccess = (body) => async (dispatch) => {
  try {
    return await apiMethods.auth.refreshFacebookAccess(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserAlbums = (body) => async (dispatch) => {
  try {
    return await apiMethods.auth.getUserAlbums(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserAlbumPhotos = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : {};
    return await apiMethods.auth.getUserAlbumPhotos(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getIGAccountMedia = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : {};
    return await apiMethods.auth.getIGAccountMedia(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getIGPageMedia = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : {};
    return await apiMethods.auth.getIGPageMedia(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getFBPageMedia = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : {};
    return await apiMethods.auth.getFBPageMedia(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};



export const getCountryCodes = () => async () => {
  try {
    return await apiMethods.users.getCountryCodes();
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

export const getMyCalendarSettings = () => async (dispatch) => {
  try {
    const result = await apiMethods.users.getMyCalendarSettings();
    if (result && result.calendarSettings) dispatch(saveMyCalendarSettings(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateCalendarSettings = body => async (dispatch) => {
  try {
    const result = await apiMethods.users.updateCalendarSettings(body);
    if (result && result.calendarSettings) dispatch(saveMyCalendarSettings(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getUserCalendarSettings = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const result = await apiMethods.users.getUserCalendarSettings(query);
    if (result && Array.isArray(result) && result.length > 0) {
      dispatch(saveUserCalendarSettings(result));
    }
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};


export const checkAccountExistByEmail = email => async () => {
  try {
    const query = `?email=${email}`;
    const data = await apiMethods.users.checkAccountExistByEmail(query)
    console.log('checkAccountExistByEmail: ', data)
    return data;
  } catch (e) {
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};


export const updateSMSInvitation = (publicId, phoneNumber) => async (dispatch) => {
  try {
    const body = { publicId, phoneNumber };
    await apiMethods.users.updateSMSInvitation(body);
    return { success: 'invitationSent' };
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};
export const updateEmailInvitation = (publicId, email) => async (dispatch) => {
  try {
    const body = { publicId, email };
    await apiMethods.users.updateEmailInvitation(body);
    return { success: 'invitationSent' };
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};

export const updateUserCredentials = body => async (dispatch) => {
  try {
    return await apiMethods.users.updateUserCredentials(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    if (e.response.data && e.response.data.text) e.response.data.error = e.response.data.text
    return e.response.data;
  }
};


export const getMySubscription = () => async (dispatch) => {
  try {
    const result = await apiMethods.products.getMySubscription();
    dispatch(setUserSubscriptionDetails(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
