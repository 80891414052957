import React from 'react';
import GoogleMapReact from 'google-map-react';
import { googlePlacesApi } from '../../constants/defaultValues';
import Marker from '../MapMarker/MapMarker'

function GoogleMapUI(props) {
  return (
    // Important! Always set the container height explicitly
    <div className="GoogleMapUI" style={{ height: props.height || 'inherit', width: props.width || '100%' }}>
      <GoogleMapReact
        onClick={(values) => props.onClick ? props.onClick(values) : console.log('values', values)}
        bootstrapURLKeys={{ key: googlePlacesApi }}
        center={props.center}
        defaultZoom={props.zoom}>
        <Marker
          lat={props.lat || 59.955413}
          lng={props.lan || 30.337844} />
      </GoogleMapReact>
    </div>
  );
}

export default GoogleMapUI;
