/* eslint-disable react/no-danger */
import React from 'react';
import { Trans } from 'react-i18next';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';

const Invoice = ({ order, locale }) => {
  // const subscriptionObj = { owner, user, public_id, title, subtitle, paypal, startDate, price, paymentType, subscriptionKey, expiryDate, description };
  const startDate = moment(order.startDate).format('MMM DD, YYYY')
  console.log('order: ', order)
  return (
    <div className=" flex-100 layout-row layout-wrap layout-align-start-start InvoiceTemplate">
      <div className="width100 invoice-react flex-100 layout-row layout-wrap layout-align-start-start mb-3">
          <div className="d-flex flex-column justify-content-between width100">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-between pt-2 pb-2">
                <div className="d-flex align-self-center">
                  <span className="logo-single mb-0" />
                </div>
                <div className="d-flex w-30 text-right align-self-center">
                  <p className="text-small text-semi-muted mb-0">
                    Aspire | Personal training
                    Tel Aviv, IL
                    <br />
                    <span className="text-underline primary">support@aspire.co.il</span>
                  </p>
                </div>
              </div>
              <div className="border-bottom pt-4 mb-3" />
              <div className="flex layout-row layout-wrap">
                <div className="flex-100 layout-row layout-align-center-center mb-2">
                  <h1><Trans i18nKey="Subscribed Successfully!" /></h1>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between mb-3">
                <div className="d-flex flex-column w-50 mr-2 p-4 text-semi-muted bg-semi-muted">
                  <p className="mb-0 font12 fontWeight600">{ order.public_id }</p>
                  <p className="mb-0">{ order.user.name ? order.user.name : `${order.user.firstName} ${order.user.lastName}` }</p>
                  <p className="mb-0">{ startDate }</p>
                </div>
                <div className="d-flex w-50 flex-column p-4 text-semi-muted bg-semi-muted">
                  <p className="mb-0 fontWeight600">Paypal Details:</p>
                  <p className="mb-0 font12 text-nowrap">Plan: { order.paypal.planId }</p>
                  <p className="mb-0 font12 text-nowrap">Subscription: { order.paypal.subscriptionId }</p>
                  <p className="mb-0 font12 text-nowrap">Order: #{ order.paypal.orderId }</p>
                </div>
              </div>

              <Table borderless>
                <thead>
                <tr>
                  <th className="text-muted text-extra-small mb-2">
                    Item name
                  </th>
                  <th className="text-muted text-extra-small mb-2">
                    Type
                  </th>
                  <th className="text-right text-muted text-extra-small mb-2">
                    Price
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{ order.title[locale] } Service</td>
                  <td>{ order.paymentType === 2 ? 'Annual Subscription' : 'Monthly Subscription' }</td>
                  <td className="text-right">{ order.price > 0 ? `$${order.price}` : 'Free' }{ order.price > 0 && (order.paymentType === 2 ? '' : ' / monthly') }</td>
                </tr>
                <tr className="displayNone">
                  <td>Chocolate Cake</td>
                  <td>2 pcs</td>
                  <td className="text-right">$ 8.97</td>
                </tr>
                <tr className="displayNone">
                  <td>Fat Rascal</td>
                  <td>2 pcs</td>
                  <td className="text-right">$ 18.24</td>
                </tr>
                <tr className="displayNone">
                  <td>Cremeschnitte</td>
                  <td>2 pcs</td>
                  <td className="text-right">$ 4.24</td>
                </tr>
                <tr className="displayNone">
                  <td>Cheesecake</td>
                  <td>3 pcs</td>
                  <td className="text-right">$ 6.27</td>
                </tr>
                </tbody>
              </Table>
            </div>
            <div className="d-flex flex-column">
              <div className="border-bottom pt-3 mb-2" />
              <Table borderless className="d-flex justify-content-end">
                <tbody>
                <tr>
                  <td className="text-semi-muted">Subtotal :</td>
                  <td className="text-right">{ order.price > 0 ? `$${order.price}` : 'Free' }</td>
                </tr>
                <tr>
                  <td className="text-semi-muted">Tax :</td>
                  <td className="text-right">Included</td>
                </tr>
                <tr className="displayNone">
                  <td className="text-semi-muted">Shipping :</td>
                  <td className="text-right">$ 3.21</td>
                </tr>
                <tr className="font-weight-bold">
                  <td className="text-semi-muted">Total :</td>
                  <td className="text-right">{ order.price > 0 ? `$${order.price}` : 'Free' }</td>
                </tr>
                </tbody>
              </Table>
              <div className="border-bottom pt-3 mb-2" />
              <p className="text-muted text-small text-center mb-3">
                Invoice was created on a computer and is valid without the
                signature and seal.{' '}
              </p>
              <p className="text-muted text-small text-center mb-3 displayNone">
                subscription can be modified at <span className="text-underline primary">manage my subscription</span> page.{' '}
              </p>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Invoice;
