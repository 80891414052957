import { actionTypes, products } from '../actions/actionTypes';

const initialState = {
  productsList: [],
  subscriptionsList: [],
  // unReadActivitiesList: []
  // searchResults: {},
  // categories: []
  // categoryDetails: {}
  // uploads: {}
  // aspireServices: []
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case products.SET_PRODUCTS_LIST: {
      return { ...state, productsList: action.payload };
    }
    case products.SET_SUBSCRIPTIONS_LIST: {
      return { ...state, subscriptionsList: action.payload };
    }
    case products.SET_TRANSACTIONS_LIST: {
      return { ...state, transactionsList: action.payload };
    }
    default:
      return state;
  }
};

export default appReducer;
