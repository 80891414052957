import React from 'react';
import { Trans } from 'react-i18next';
import { actionTypes, eventActions, products } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';

export const changeAppLocale = (locale) => {
  localStorage.setItem('currentLanguage', locale);
  return ({
    type: actionTypes.SET_APP_LANG,
    payload: locale
  });
};

export const setAllCategories = data => ({
  type: actionTypes.SET_ALL_CATEGORIES,
  payload: data
});

export const setCategoryDetails = data => ({
  type: actionTypes.SET_CATEGORY_DETAILS,
  payload: data
});

export const refreshBottomNav = config => ({
  type: actionTypes.REFRESH_BOTTOM_NAV,
  payload: config
});

export const bottomNavClicked = key => ({
  type: actionTypes.BOTTOM_NAV_CLICKED,
  payload: key
});

export const setDynamicSearchResults = payload => ({
  type: actionTypes.SET_DYNAMIC_SEARCH_RESULTS,
  payload
});

export const handleErrorResponse = err => ({
  type: actionTypes.HANDLE_ERROR_RESPONSE,
  payload: err
});

export const handleSuccessResponse = () => ({
  type: actionTypes.HANDLE_SUCCESS_RESPONSE,
  payload: 'Handled Successfully'
});

export const showAlert = data => ({
  type: actionTypes.SHOW_ALERT,
  payload: data
});

export const showAlert2 = data => ({
  type: actionTypes.SHOW_ALERT2,
  payload: data
});

export const showAlert3 = data => ({
  type: actionTypes.SHOW_ALERT3,
  payload: data
});

export const setAspireServices = data => ({
  type: products.SET_ASPIRE_SERVICES,
  payload: data
});


const hideAlert = () => ({ type: actionTypes.HIDE_ALERT });

export const setActivityList = key => ({
  type: actionTypes.SET_ACTIVITY_LIST,
  payload: key
});

export const setUnReadActivitiesList = payload => ({
  type: actionTypes.SET_UNREAD_ACTIVITY_LIST,
  payload
});

export const openMediaGalleryPage = (media, index) => async (dispatch) => {
  const data = {
    isOpen: true,
    isModal: true,
    id: eventActions.MEDIA_GALLERY,
    className: 'PageModal',
    itemIndex: index || 0,
    media,
    buttonRight: {
      text: <i className="la la-arrow-left iconFontSize30 goBackIconColor " />,
      onClick: () => {
        hideAlert();
      }
    }
  };
  dispatch({ type: actionTypes.SHOW_ALERT, payload: data });
};

export const openAppModal = (title, content) => {
  const data = {
    isOpen: true,
    title,
    content,
    className: 'ModalPage',
    id: eventActions.ALERT_MODAL,
    closeAlertBtn: {
      text: <Trans i18nKey="confirm" />
    }
  };
  showAlert(data);
};

export const getUnReadActivityList = () => async (dispatch) => {
  try {
    const result = await apiMethods.activities.getUnReadActivityList();
    dispatch(setUnReadActivitiesList(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getActivityList = () => async (dispatch) => {
  try {
    const result = await apiMethods.activities.getActivityList();
    dispatch(setActivityList(result));
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getAllCategories = () => async (dispatch) => {
  try {
    const data = await apiMethods.categories.getCategories();
    dispatch(setAllCategories(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getCategoryByPublicId = body => async (dispatch) => {
  try {
    const data = await apiMethods.categories.getCategory(body);
    dispatch(setCategoryDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createCategory = body => async (dispatch) => {
  try {
    const data = await apiMethods.categories.create(body);
    dispatch(setCategoryDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const editCategory = body => async (dispatch) => {
  try {
    const data = await apiMethods.categories.edit(body);
    dispatch(setCategoryDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeCategory = publicId => async (dispatch) => {
  try {
    return await apiMethods.categories.remove([publicId]);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getCategoriesByGroup = body => async (dispatch) => {
  try {
    return await apiMethods.categories.getCategoriesByGroup(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getInstaAccessToken = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : {};
    return await apiMethods.app.getInstaAccessToken(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getAspireServices = queryObj => async (dispatch) => {
  try {
    // const query = queryObj ? utils.buildQuery(queryObj) : {};
    const data = await apiMethods.products.getAspireServices();
    dispatch(setAspireServices(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};



export const createProductsTest = () => async (dispatch) => {
  try {
    const data = await apiMethods.products.createProductsTest();
    //dispatch(setAspireServices(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

const hideAlert3 = () => ({ type: actionTypes.HIDE_ALERT3 });

export const goBackAlert = (props, callback) => async (dispatch) => {
  const title = <Trans i18nKey="notice" />;
  const content = <Trans i18nKey="goBackNotice" />;
  const data = {
    isOpen: true,
    title,
    content,
    className: 'ModalPage',
    id: eventActions.ALERT_MODAL_3,
    closeAlertBtn: {
      text: <Trans i18nKey="cancel" />,
      onClick: () => {
        hideAlert3();
        callback()
      }
    },
    buttonRight: {
      text: <Trans i18nKey="confirm" />,
      onClick: () => {
        hideAlert3();
        callback(true)
      }
    }
  };
  dispatch({ type: actionTypes.SHOW_ALERT3, payload: data });
}

