/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import LoadingDots from '../UI/LoadingDots';
import './NavBottom.css';

const NavBottom = (props) => {
  const buttons = (props.config ? props.config.map(button => (
    <div key={button.key} className="flex layout-row layout-wrap layout-align-center-center">
      <button type="button" onClick={() => props.chosenOption(button.key)} className={`height50px btn-transparent flex layout-row layout-wrap layout-align-center-center ${button.className}`}>
        { button.upperSubtitle && button.upperSubtitle.text && (
          <span className={`upperSubtitle ${button.upperSubtitle.className ? button.upperSubtitle.className : ''}`}>{button.upperSubtitle.text}</span>
        )}
        <span className={`buttonText ${button.className ? button.className : ''}`}>{button.text}</span>
      </button>
    </div>
  )) : null);
  return (
    <div className="NavBottom fadeInBottom-anime flex-100 layout-row layout-wrap layout-align-start-center" style={{ left: `${props.left}px` }}>
      <div className="flex-100 layout-row layout-wrap layout-align-start-center positionRelative height50px">
        { props && props.isLoading ? (
          <div className="width100 layout-column layout-align-center-center positionAbsoluteTopLeft height50px zIndex5">
            <LoadingDots hiddenLoader active />
          </div>
        ) : (
          <div className="flex-100 layout-row layout-wrap layout-align-space-between-center">
            {buttons}
          </div>
        )}
      </div>
    </div>
  );
};

NavBottom.defaultProps = {
  config: []
};

NavBottom.propTypes = {
  config: PropTypes.arrayOf(PropTypes.object),
  chosenOption: PropTypes.func
};

export default NavBottom;
