/* eslint-disable max-len,object-curly-newline */
import {
  categoryGroups, workoutLogTypes, durationType, selectUnits, units, userTypes, gender, locale, profession, chosenLang,
  repeatEventConditions
} from './enums';

export const uploadSvgIcon = 'https://aspire123.s3.eu-central-1.amazonaws.com/assets/upload_image_simple.svg';

export const formConsts = {
  // login page forms
  login: 'userLoginInputs',
  signUp: 'userRegisterInputs',
  clientInvite: 'clientInviteInputs',
  forgotPassword: 'forgotPasswordInputs',
  changePassword: 'changePasswordInputs',
  profileDetails: 'profileDetailsInputs',
  // other forms
  inviteClient: 'inviteClientInputs',
  notes: 'notesInputs',
  workoutProgram: 'workoutProgramInputs',
  workoutLog: 'workoutLogInputs',
  achievement: 'achievementInputs',
  logExercises: 'logExercisesInputs',
  exerciseModel: 'exerciseModelInputs',
  categoryModel: 'categoryModelInputs',
  createPlan: 'createPlanInputs',
  createExercise: 'createExerciseInputs',
  createExerciseAdmin: 'createExerciseAdminInputs',
  createCategory: 'createCategoryInputs',
  settings: 'settingsInputs',
  imageUploadDetails: 'imageUploadDetailsInputs'
};
export const bodyStatsInputsDesc = {
  weight: 'type in kg',
  height: 'type in cm',
  muscleMass: 'type in kg',
  fat: 'type in %',
  calories: 'type in Kcal',
  bmi: '',
  water: 'type in %'
};
export const categoryModelInputs = [
  {
    name: 'group',
    description: 'select category groups',
    options: categoryGroups,
    initValue: 1,
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'public_id',
    description: 'category public_id example: CAT-ABC231',
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddStart10px'
  },
  {
    name: 'uri',
    description: 'category uri for link - required',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'order',
    description: 'order - category order',
    initValue: '0',
    type: 'number',
    elementType: 'input',
    classes: 'flex-50 paddStart10px'
  },
  {
    name: 'title.he',
    description: 'title.he - category name {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'title.en',
    description: 'title.en - category name {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    name: 'subtitle.he',
    description: 'subtitle.he - category subtitle {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'subtitle.en',
    description: 'subtitle.en - category subtitle {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    name: 'description.he',
    description: 'description.he - category description {HE}',
    type: 'text',
    elementType: 'textarea',
    config: {
      rows: 4
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'description.en',
    description: 'description.en - category description {EN}',
    type: 'text',
    elementType: 'textarea',
    config: {
      rows: 4
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    name: 'thumbnail.uri',
    description: 'Category thumbnail',
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'backgroundImage.uri',
    description: 'Category background Image',
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddStart10px'
  },
  {
    name: 'readOnly',
    description: 'Category readOnly',
    initValue: false,
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'muscleId',
    label: 'Muscle Name',
    description: 'muscleId - muscleId for SVG image',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 2
    },
    classes: 'flex-50 paddStart10px'
  }
];
export const createExerciseInputs = [
  {
    label: 'title in english (required)',
    name: 'title.en',
    comment: 'onlyEnglishLetters',
    description: 'title.he - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    noEditMode: true,
    validation: {
      pattern: '^[a-zA-Z0-9_.-]*$',
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'title',
    name: 'title.he',
    placeholder: 'type in exercise title',
    description: 'title.he - exercise title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'description',
    name: 'description.en',
    description: 'description.he - exercise description {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 2
    },
    classes: 'flex-100'
  },
  {
    label: 'preparation',
    name: 'preparation.en',
    description: 'description.he - exercise preparation {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'execution',
    name: 'execution.en',
    description: 'description.he - exercise execution {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'searchKeyWords',
    name: 'searchKeyWords',
    description: 'exercise search Keywords',
    type: 'text',
    elementType: 'input',
    classes: 'flex-100'
  }/*,
  {
    label: 'className',
    name: 'thumbnail.className',
    description: 'className - exercises musckeId classes',
    type: 'text',
    elementType: 'input',
    classes: 'flex-100'
  } */
];
export const exerciseEquipmentCategoryInputs = [
  {
    label: 'public_id',
    name: 'public_id',
    description: 'public_id - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-100'
  },
  {
    label: 'title_en',
    name: 'title.en',
    description: 'title.en - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-100'
  },
  {
    label: 'title_he',
    name: 'title.he',
    placeholder: 'type in exercise title',
    description: 'title.he - exercise title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'description_en',
    name: 'description.en',
    description: 'description.he - exercise description {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 2
    },
    classes: 'flex-100'
  },
  {
    label: 'description_he',
    name: 'description.he',
    description: 'description.he - exercise description {HE}',
    elementType: 'textarea',
    config: {
      rows: 2
    },
    classes: 'flex-100'
  },
  {
    label: 'video',
    name: 'video',
    description: 'description.he - exercise preparation {EN}',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  }
];
export const createExerciseAdminInputs = [
  {
    label: 'public_id',
    name: 'public_id',
    description: 'public_id - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-100'
  },
  {
    label: 'title_en',
    name: 'title.en',
    description: 'title.en - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'title_he',
    name: 'title.he',
    placeholder: 'type in exercise title',
    description: 'title.he - exercise title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    label: 'description_en',
    name: 'description.en',
    description: 'description.he - exercise description {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 2
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'description_he',
    name: 'description.he',
    description: 'description.he - exercise description {HE}',
    elementType: 'textarea', // textarea
    config: {
      rows: 2
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    label: 'preparation_en',
    name: 'preparation.en',
    description: 'description.he - exercise preparation {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'preparation_he',
    name: 'preparation.he',
    description: 'description.he - exercise preparation {HE}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    label: 'execution_en',
    name: 'execution.en',
    description: 'description.he - exercise execution {EN}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'execution_he',
    name: 'execution.he',
    description: 'description.he - exercise execution {HE}',
    elementType: 'textarea', // textarea
    config: {
      rows: 3
    },
    classes: 'flex-50 paddStart10px'
  }
];
export const createCategoryInputs = [
  {
    label: 'public_id',
    name: 'public_id',
    description: 'public_id - Category public_id',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'uri',
    name: 'uri',
    description: 'uri - Category uri',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    label: 'title_en',
    name: 'title.en',
    description: 'title.en - Category title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'title_he',
    name: 'title.he',
    placeholder: 'type in Category title',
    description: 'title.he - Category title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    label: 'order',
    name: 'order',
    description: 'order - Category order',
    type: 'number',
    elementType: 'input',
    config: {
      rows: 2
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'muscleId',
    name: 'muscleId',
    placeholder: 'muscleId',
    description: 'muscleId - Category muscleId',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 2
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    label: 'group',
    name: 'group',
    placeholder: 'group',
    description: 'group - Category group',
    options: categoryGroups,
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-100'
  }
];
export const createMediaAdminInputs = [
  {
    label: 'title',
    name: 'title',
    description: 'title - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'searchKeywords',
    name: 'searchKeywords',
    description: 'searchKeywords',
    type: 'text',
    elementType: 'input',
    classes: 'flex-50 paddStart10px'
  }
];
export const exerciseModelInputs = [
  {
    name: 'title.he',
    description: 'title.he - exercise title {HE}',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    name: 'title.en',
    description: 'title.en - exercise title {EN}',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    name: 'description.he',
    description: 'description.he - exercise description {HE}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4
    },
    classes: 'flex-100'
  },
  {
    name: 'description.en',
    description: 'description.en - exercise description {EN}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4
    },
    classes: 'flex-100'
  },
  {
    name: 'preparation.he',
    description: 'description.he - exercise preparation {HE}',

    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4
    },
    classes: 'flex-100'
  },
  {
    name: 'preparation.en',
    description: 'description.he - exercise preparation {HE}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4
    },
    classes: 'flex-100'
  },
  {
    name: 'execution.he',
    description: 'description.en - exercise execution {HE}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4
    },
    classes: 'flex-100'
  },
  {
    name: 'execution.en',
    description: 'description.en - exercise execution {EN}',
    type: 'text',
    elementType: 'input', // textarea
    config: {
      rows: 4
    },
    classes: 'flex-100'
  },
  {
    label: 'searchKeyWords',
    name: 'searchKeyWords',
    description: 'exercise search Keywords',
    type: 'text',
    elementType: 'input',
    classes: 'flex-100'
  },
  {
    name: 'thumbnail.className',
    description: 'className - exercises musckeId classes',
    type: 'text',
    elementType: 'input',
    classes: 'flex-100'
  },
  {
    name: 'thumbnail.previewImg',
    description: 'SVG image 1 / 2',
    type: 'Number',
    elementType: 'input',
    classes: 'flex-100'
  }
];
export const workoutProgramInputs = [
  {
    label: 'title',
    name: 'title',
    description: 'workoutProgram title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'description',
    name: 'description',
    description: 'workoutProgram description',
    elementType: 'textarea',
    config: {
      rows: 4
    },
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  }
];
export const workoutLogInputs = [
  {
    label: 'type',
    name: 'type',
    description: 'select workoutLog groups',
    options: workoutLogTypes,
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-100'
  },
  {
    label: 'exercise',
    name: 'exercise',
    description: 'exercise Select async!',
    isMulti: true,
    loadOptions: 'exerciseList',
    type: 'multiSelect',
    elementType: 'multiSelectAsync',
    classes: 'flex-100'
  }
];
export const logExercisesInputs = [
  {
    label: 'sets',
    name: 'sets',
    description: 'exercise - sets',
    initValue: 3,
    type: 'number',
    elementType: 'input',
    classes: 'flex paddStart10px'

  },
  {
    label: 'reps',
    name: 'reps',
    description: 'exercise - reps',
    initValue: 8,
    type: 'number',
    elementType: 'input',
    classes: 'flex paddStart10px'

  },
  {
    label: 'weight',
    name: 'weight',
    description: 'exercise - weight',
    initValue: 60,
    type: 'number',
    elementType: 'input',
    classes: 'flex paddStart10px'
  }
  /*     {
            name: 'feels',
            description: 'exercise - feels',
            value: 1, // 1 - 10
            type: 'number',
            elementType: 'input'
        },
        {
            name: 'time',
            description: 'exercise - time (milliseconds)',
            value: 1000,
            type: 'number',
            elementType: 'input'
        } */
  /* need to add - exercise select , notes */
];
export const bodyStatsInputs = {
  height: {
    label: 'height',
    name: 'height',
    description: 'type in cm',
    type: 'number', // in cm or inches
    validation: {
      min: 30,
      step: 0.1
    },
    measurements: units.distanceUnits.CM
  },
  weight: {
    label: 'weight',
    name: 'weight',
    description: 'type in kg',
    type: 'number', // in kg or lbs
    validation: {
      min: 5,
      step: 0.1
    },
    measurements: units.weightUnits.KG
  },
  muscleMass: {
    label: 'muscleMass',
    name: 'muscleMass',
    description: 'type in kg',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 5,
      step: 0.1
    },
    measurements: units.weightUnits.KG
  },
  fat: {
    label: 'fat',
    name: 'fat',
    description: 'Type in %',
    type: 'number', // in %
    validation: {
      min: 1,
      max: 100
    },
    measurements: units.other.PERCENT
  },
  water: {
    label: 'water',
    name: 'water',
    description: 'Type in %',
    type: 'number', // in %
    validation: {
      min: 5,
      max: 100
    },
    measurements: units.other.PERCENT
  },
  calories: {
    label: 'calories',
    name: 'calories',
    description: 'Type in Kcal',
    type: 'number', // in num
    elementType: 'input',
    validation: {
      min: 5
    },
    measurements: units.other.KCAL
  },
  bmi: {
    name: 'bmi',
    label: 'bmi',
    type: 'number', // will calc on its own
    validation: {
      min: 0
    },
    measurements: units.other.BLANK
  }
};
export const userLoginInputs = [
  {
    name: 'email',
    label: 'email',
    description: 'User Email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-100'
  },
  {
    name: 'password',
    label: 'password',
    description: 'User password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-100'
  }
];
export const forgotPasswordInputs = [
  {
    name: 'email',
    label: 'email',
    description: 'User Email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  }
];
export const userRegisterInputs = [
    {
      name: 'firstName',
      label: 'firstName',
      description: 'User - First Name',
      type: 'text',
      elementType: 'input',
      validation: {
        required: true,
        minLength: 2
      },
      classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
    },
    {
      name: 'lastName',
      label: 'lastName',
      description: 'User - Last Name',
      type: 'text',
      elementType: 'input',
      validation: {
        required: true,
        minLength: 2
      },
      classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
    },
    {
      name: 'email',
      label: 'email',
      description: 'User Email',
      type: 'email',
      elementType: 'input',
      validation: {
        required: true,
        minLength: 3
      },
      classes: 'flex-100'
    },
    {
      name: 'password',
      label: 'password',
      description: 'User password',
      type: 'password',
      elementType: 'input',
      noEditMode: true,
      validation: {
        required: true,
        minLength: 8
      },
      classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
    },
    {
      name: 'confirmPassword',
      label: 'confirmPassword',
      description: 'User confirm password',
      type: 'password',
      noEditMode: true,
      elementType: 'input',
      validation: {
        required: true,
        minLength: 8
      },
      classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
    }
];
// userUpadatePage
export const userAccountInputs = [
  {
    name: 'public_id',
    label: 'public_id',
    description: 'User public_id',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    config: {
      readOnly: true
    },
    classes: 'flex-100'
  },
  {
    name: 'email',
    label: 'email',
    description: 'User Email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    config: {
      readOnly: true
    },
    classes: 'flex-100'
  },
  {
    name: 'firstName',
    label: 'firstName',
    description: 'User - First Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'lastName',
    label: 'lastName',
    description: 'User - Last Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  },
  {
    name: 'inviteCode',
    label: 'inviteCode',
    description: 'User inviteCode',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 8
    },
    classes: 'flex-100'
  },
  {
    label: 'aboutMe',
    name: 'about',
    description: 'aboutMe description',
    elementType: 'textarea',
    config: {
      rows: 3
    },
    validation: {
      minLength: 3,
      maxLength: 500
    },
    classes: 'flex-100'
  },
  {
    name: 'abTest',
    label: 'abTest',
    description: 'User abTest',
    type: 'number',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2
    },
    classes: 'flex-100'
  },
  {
    name: 'birthDate',
    label: 'birthDate',
    description: 'User - birthDate',
    elementType: 'datePicker',
    config: {
      placement: 'bottom'
    },
    classes: 'flex-100'
  },
  {
    name: 'thumbnail.uri',
    label: 'thumbnail',
    description: 'User thumbnail',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'backgroundImage.uri',
    label: 'backgroundImage',
    description: 'User backgroundImage',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    name: 'userType',
    label: 'userType',
    description: 'User userType',
    options: userTypes,
    initValue: 'TRAINER',
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    name: 'gender',
    label: 'gender',
    description: 'User gender',
    options: gender,
    initValue: 1,
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-50 paddStart10px'
  },
  {
    name: 'isBetaTester',
    label: 'isBetaTester',
    description: 'User isBetaTester',
    isSelected: false,
    elementType: 'checkBox',
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  }
];
export const userCredentialInputs = [
  {
    name: 'email',
    label: 'email',
    description: 'User Email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    name: 'password',
    label: 'password',
    description: 'User password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    name: 'confirmPassword',
    label: 'confirmPassword',
    description: 'User confirm password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-50 paddStart10px'
  }
];

export const userSettingsInputs = [
  {
    name: 'weightUnits',
    label: 'weightUnits',
    description: 'User - weight Units settings',
    options: selectUnits,
    initValue: units.weightUnits.KG,
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    name: 'locale',
    label: 'locale',
    description: 'User - locale settings',
    options: locale,
    initValue: locale.en,
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  }
];

export const newLogObj = (settings) => ({
  straight: {
    order: 1,
    remove: false,
    reps: 8,
    weight: settings && settings.weightUnits === units.weightUnits.LBS ? 20.41 : 20,
    rest: 90000,
    distance: 0,
  },
  superset: {
    order: 1,
    remove: false,
    reps: 8,
    weight: settings && settings.weightUnits === units.weightUnits.LBS ? 20.41 : 20,
    rest: 90000,
    distance: 0,
  },
  aerobic: {
    order: 1,
    remove: false,
    time: 60000, // milliseconds
    speed: 1,
    intensity: 1,
    distance: settings && settings.distanceUnits === units.distanceUnits.INCH ? 91.43 : 100, // need to be checked -> inches or feet?
    rest: 90000,
  },
  static: {
    order: 1,
    remove: false,
    time: 60000, // milliseconds
    weight: 0,
    rest: 90000,
  },
  custom: {
    order: 1,
    remove: false,
    reps: 8,
    time: 60000, // milliseconds
    weight: 20,
    speed: 1,
    intensity: 1,
    distance: settings && settings.distanceUnits === units.distanceUnits.INCH ? 91.43 : 100, // need to be checked -> inches or feet?
    rest: 90000,
  },
});

export const userProfessionInputs = [
  {
    name: 'type',
    label: 'type',
    description: 'User profession type',
    options: profession,
    initValue: profession.PT,
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-100'
  },
  {
    name: 'title',
    label: 'title',
    description: 'profession title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2
    },
    classes: 'flex-50 paddEnd10px'
  },
  {
    label: 'description',
    name: 'description',
    description: 'profession description',
    elementType: 'textarea',
    config: {
      rows: 3
    },
    validation: {
      minLength: 3,
      maxLength: 500
    },
    classes: 'flex-50 paddStart10px'
  }
];

export const userBiographyInputs = [
  {
    label: 'bio',
    name: 'biography',
    description: 'user biography',
    elementType: 'quill-text',
    config: {
      rows: 6
    },
    validation: {
      minLength: 3,
      maxLength: 2000
    },
    classes: 'flex-100'
  }
];

export const profileDetailsInputs = [
  {
    name: 'firstName',
    label: 'firstName',
    description: 'User - First Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    name: 'lastName',
    label: 'lastName',
    description: 'User - Last Name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  },
  {
    label: 'aboutMe',
    name: 'about',
    description: 'aboutMe description',
    elementType: 'textarea',
    config: {
      rows: 3
    },
    validation: {
      minLength: 3,
      maxLength: 500
    },
    classes: 'flex-100'
  },
  {
    name: 'language',
    label: 'language',
    description: 'Article language',
    options: locale,
    initValue: 'english',
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    name: 'birthDate',
    label: 'birthDate',
    description: 'User - birthDate',
    elementType: 'datePicker',
    config: {
      placement: 'bottom'
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  },
];

export const socialAccountsInputs = [
  {
    name: 'social.facebook',
    label: 'facebook',
    description: 'social facebook',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    name: 'social.instagram',
    label: 'instagram',
    description: 'social instagram',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  },
  {
    name: 'social.youtube',
    label: 'youtube',
    description: 'social youtube',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    name: 'social.twitter',
    label: 'twitter',
    description: 'social twitter',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  },
  {
    name: 'social.linkedin',
    label: 'linkedin',
    description: 'social linkedin',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  }
];

export const introductionUploadInputs = [
  {
    name: 'introduction.title',
    label: 'title',
    description: 'introduction title',
    type: 'text',
    elementType: 'input',
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    name: 'introduction.description',
    label: 'description',
    description: 'introduction description',
    elementType: 'textarea',
    config: {
      rows: 3
    },
    validation: {
      minLength: 3,
      maxLength: 500
    },
    classes: 'flex-100'
  }
]

export const inviteClientInputs = {
  step1: [
    {
      name: 'firstName',
      label: 'firstName',
      description: 'User - First Name',
      type: 'text',
      elementType: 'input',
      validation: {
        required: true,
        minLength: 2
      },
      classes: 'flex-50 paddEnd10px'
    },
    {
      name: 'lastName',
      label: 'lastName',
      description: 'User - Last Name',
      type: 'text',
      elementType: 'input',
      validation: {
        required: true,
        minLength: 2
      },
      classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
    }
    /* {
      name: 'birthDate',
      label: 'birthDate',
      description: 'User - birthDate',
      elementType: 'datePicker',
      config: {
        placement: 'bottom'
      },
      classes: 'flex-100'
    } */
    // email
    // gender
  ],
  step2: [
    {
      name: 'weightUnits',
      label: 'weightUnits',
      description: 'User - weight Units settings',
      options: selectUnits.weightUnits,
      initValue: units.weightUnits.KG,
      elementType: 'select',
      validation: {
        required: true
      },
      classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
    },
    {
      name: 'distanceUnits',
      label: 'distanceUnits',
      description: 'User - Distance Units settings',
      elementType: 'select',
      initValue: units.distanceUnits.CM,
      options: selectUnits.distanceUnits,
      validation: {
        required: true
      },
      classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
    }
  ],
  step3: []
};
export const clientInviteInputs = [
  {
    label: 'email',
    name: 'email',
    description: 'User Email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'inviteCode',
    name: 'inviteCode',
    description: 'client inviteCode',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-100'
  }
];
export const changePasswordInputs = [
  {
    name: 'oldPassword',
    label: 'oldPassword',
    placeholder: 'type old password',
    description: 'User oldPassword',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-100'
  },
  {
    name: 'newPassword',
    label: 'newPassword',
    placeholder: 'type new password',
    description: 'User password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-100'
  },
  {
    name: 'confirmPassword',
    label: 'confirmPassword',
    description: 'User confirm password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-100'
  }
];
export const createPlanInputs = [
  {
    label: 'duration',
    name: 'duration.value',
    comment: 'num. of weeks',
    placeholder: '4',
    labelClasses: 'font15 text-center',
    initValue: 4,
    type: 'number',
    elementType: 'input',
    validation: {
      required: true,
      min: 0,
      max: 106
    },
    labelBottom: true,
    classes: 'flex-50 layout-column layout-align-center-center bigInputContainer text-center sidePadd5px'
  },
  {
    label: 'workoutPerWeek',
    name: 'workoutPerWeek.value',
    placeholder: '3',
    comment: 'num. of times',
    labelClasses: 'font15 text-center',
    initValue: 3,
    type: 'number',
    elementType: 'input',
    validation: {
      required: true,
      min: 0,
      max: 30
    },
    labelBottom: true,
    // comment: 'Times',
    classes: 'flex-50 layout-column layout-align-center-center text-center bigInputContainer sidePadd5px'
  },
  {
    label: 'startDate',
    name: 'startDate',
    description: 'Plan - startDate',
    elementType: 'datePicker',
    // initValue: '2019-09-29',
    config: {
      placement: 'bottom'
    },
    validation: {
      required: true
    },
    classes: 'flex-100'
  },
  {
    label: 'title',
    name: 'title',
    description: 'Plan title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'description',
    name: 'description',
    description: 'Plan description',
    elementType: 'textarea',
    config: {
      rows: 4
    },
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  }
  /*
        {
          name: 'workoutDuration.value',
          config: {
            placeholder: '#minutes'
          },
          initValue: 60,
          type: 'number',
          elementType: 'input',
          validation: {
            min: 0,
            max: 360
          },
          label: 'Workout Duration',
          comment: 'Minutes',
          classes: 'flex-33 layout-column layout-align-center-center text-center'
        }
    */
  // thumbnail
  // reviews
  // category
  // users
];
export const notesInputs = [
  {
    label: 'order',
    name: 'order',
    description: 'note - order',
    initValue: 1,
    type: 'number',
    elementType: 'input',
    validation: {
      step: 0.5,
      max: 99,
      min: 0,
      required: true
    },
    classes: 'flex-30 paddEnd10px'

  },
  {
    label: 'content',
    name: 'content',
    description: 'note - content',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-70 paddStart10px'
  }
];
export const exNotesInputs = [
  {
    label: 'content',
    name: 'content',
    description: 'note - content',
    placeholder: 'please write your notes here',
    type: 'text',
    elementType: 'textarea',
    validation: {
      required: true,
      minLength: 3
    },
    config: {
      id: 'exNotesInputsContent',
      rows: 2
    },
    classes: 'flex-100'
  }
];
export const exNotesInputsNoneRequired = [
  {
    label: 'content',
    name: 'content',
    description: 'note - content',
    placeholder: 'please write your notes here',
    type: 'text',
    elementType: 'textarea',
    validation: {
      minLength: 3
    },
    config: {
      id: 'exNotesInputsContent',
      rows: 2
    },
    classes: 'flex-100'
  }
];
export const achievementInputs = [
  {
    label: 'title',
    name: 'title',
    description: 'achievement title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'description',
    name: 'description',
    description: 'achievement description',
    elementType: 'textarea',
    config: {
      rows: 3,
      maxLength: 80
    },
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'startDate',
    name: 'startDate',
    description: 'achievement - startDate',
    elementType: 'datePicker',
    // initValue: '2019-09-29',
    config: {
      placement: 'bottom'
    },
    validation: {
      required: true
    },
    classes: 'flex-100'
  },
  /* {
    label: 'endDate',
    name: 'endDate',
    description: 'achievement - endDate',
    elementType: 'datePicker',
    // initValue: '2019-09-29',
    config: {
      placement: 'bottom'
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  },
  {
    name: 'none',
    elementType: 'none',
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  }, */
  {
    label: 'duration',
    name: 'duration.value',
    config: {
      placeholder: 'duration'
    },
    initValue: 4,
    type: 'number',
    elementType: 'input',
    validation: {
      min: 0,
      max: 100,
      required: true
    },
    // comment: 'Weeks',
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    label: ' ',
    name: 'duration.durationType',
    options: durationType,
    initValue: 'weeks',
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  }
];
export const goalBestTimeInputs = [
  {
    label: 'minutes',
    name: 'minutes',
    placeholder: '00',
    description: 'minutes',
    type: 'number', // in kg or lbs
    validation: {
      min: 0,
      step: 1,
      max: 999,
      maxLength: 3,
      pattern: '.{0}|.{1,3}',
      required: true
    },
    measurements: units.weightUnits.KG,
    classes: 'flex-50'
  },
  {
    label: 'seconds',
    name: 'seconds',
    placeholder: '00',
    type: 'number', // in kg or lbs
    validation: {
      min: 0,
      step: 1,
      max: 59,
      pattern: '.{0}|.{1,2}',
      maxLength: 2,
      required: true
    },
    classes: 'flex-50'
  }
];
export const achievementsFormInputs = {
  reps: {
    label: 'reps',
    name: 'reps',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 1,
      step: 0,
      required: true
    },
    classes: 'flex paddEnd5px'
  },
  weight: {
    label: 'weight',
    name: 'weight',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 0,
      required: true
    },
    measurements: units.weightUnits.KG,
    classes: 'flex paddStart5px'
  },
  value: {
    name: 'value',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 1,
      required: true
    },
    measurements: units.weightUnits.KG,
    classes: 'flex-100'
  },
  timeDone: {
    label: 'time',
    name: 'timeDone',
    description: 'timeDone timer',
    elementType: 'timeInput',
    classes: 'flex-100'
  },
  distance: {
    label: 'distance',
    name: 'distance',
    type: 'number', // in kg or lbs
    elementType: 'input',
    validation: {
      min: 0,
      required: true
    },
    measurements: 'M',
    classes: 'flex-100'
  },
  setX: {
    name: 'setX',
    elementType: 'setX',
    classes: 'flex-initial'
  }
};
export const settingsInputs = [
  {
    name: 'unitsSettings',
    label: 'weightUnits',
    description: 'User - Units settings',
    options: selectUnits,
    initValue: 'KG_CM',
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-100'
  }
];
export const changePasswordModalInputs = [
  {
    name: 'password',
    label: 'password',
    description: 'User password',
    placeholder: 'password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-100 mb-2'
  },
  {
    name: 'confirmPassword',
    label: 'confirmPassword',
    placeholder: 'confirmPassword',
    description: 'User confirm password',
    type: 'password',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 6
    },
    classes: 'flex-100'
  }
];
export const imageUploadDetailsInputs = [
  {
    name: 'title',
    label: 'title',
    description: 'image - title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 2
    },
    classes: 'flex-100'
  },
  {
    label: 'description',
    name: 'description',
    description: 'image description',
    elementType: 'textarea',
    config: {
      rows: 3,
      maxLength: 500
    },
    validation: {
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    name: 'date',
    label: 'date',
    description: 'image - date',
    elementType: 'datePicker',
    config: {
      placement: 'bottom'
    },
    classes: 'flex-100'
  }
];
export const equipmentCatInputs = [
  {
    label: 'title',
    name: 'title.he',
    description: 'muscle - title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-33 paddStart10px'
  },
  {
    label: 'public_id',
    name: 'public_id',
    description: 'muscle - public_id',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-33 sidePadd10'
  },
  {
    label: 'video',
    name: 'video',
    description: 'video',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true
    },
    classes: 'flex-33'
  }
];

export const stopConditions = [
  {
    containerClass: 'flex-100 mb-1',
    name: 'stopConditions1',
    group: 'stopConditions',
    elementType: 'radio',
    value: false,
    classes: 'flex-initial paddEnd5px',
    text: 'neverStop',
    textInfo: 'neverStop_info',
    conditionType: repeatEventConditions.never,
  },
  {
    containerClass: 'flex-100 mb-1',
    name: 'stopConditions2',
    group: 'stopConditions',
    elementType: 'radio',
    value: false,
    classes: 'flex-initial paddEnd5px',
    text: 'untilDate',
    textInfo: 'untilDate_info',
    textDateInput: true,
    conditionType: repeatEventConditions.untilDate,
  },
  {
    containerClass: 'flex-100 mb-1',
    name: 'stopConditions3',
    group: 'stopConditions',
    elementType: 'radio',
    value: false,
    classes: 'flex-initial paddEnd5px',
    text: 'untilNumber',
    text2: 'times',
    textInfo: 'untilNumber_info',
    textNumberInput: true,
    conditionType: repeatEventConditions.numOfTimes,
  },
];

// article
export const ArticleInputs = [
  {
    name: 'title',
    label: 'title',
    description: 'Article - title',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    name: 'subtitle',
    label: 'shortDescription',
    description: 'Article - subtitle',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-100'
  },
  {
    label: 'content',
    name: 'content',
    description: 'Article content',
    elementType: 'quill-text',
    config: {
      rows: 3
    },
    validation: {
      minLength: 3,
      maxLength: 500
    },
    classes: 'flex-100'
  },
  {
    name: 'language',
    label: 'language',
    description: 'Article language',
    options: locale,
    initValue: chosenLang,
    elementType: 'select',
    validation: {
      required: true
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    name: 'category',
    label: 'category',
    description: 'Article - category',
    isMulti: true,
    elementType: 'ReactSelect',
    options:  null,
    validation: {
      required: true
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pl-xs-0'
  },
  {
    name: 'topArticle.isActive',
    label: 'makeItTopArticle',
    description: 'Article - top article',
    elementType: 'checkBox',
    isSelected: false,
    translate: true,
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  }
];

export const ContactUsInputs = [
  {
    name: 'name',
    label: 'name',
    description: 'Article - name',
    type: 'text',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pr-md-2 pr-xs-0'
  },
  {
    name: 'email',
    label: 'email',
    description: 'Article - email',
    type: 'email',
    elementType: 'input',
    validation: {
      required: true,
      minLength: 3
    },
    classes: 'flex-gt-sm-50 flex-sm-100 flex-xs-100 pl-md-2 pr-xs-0'
  },
  {
    label: 'subject',
    name: 'subject',
    description: 'Article subject',
    type: 'text',
    elementType: 'input',
    classes: 'flex-100'
  },
  {
    name: 'content',
    description: 'content',
    type: 'text',
    elementType: 'textarea',
    config: {
      rows: 4
    },
    classes: 'flex-100'
  }
];

export const storeDoll = {
  categoryModelInputs,
  exerciseModelInputs,
  workoutProgramInputs,
  workoutLogInputs,
  logExercisesInputs,
  bodyStatsInputsDesc,
  bodyStatsInputs,
  notesInputs,
  userLoginInputs,
  userRegisterInputs,
  inviteClientInputs,
  clientInviteInputs,
  createPlanInputs,
  createExerciseInputs,
  createExerciseAdminInputs,
  createCategoryInputs,
  achievementInputs,
  forgotPasswordInputs,
  changePasswordInputs,
  profileDetailsInputs,
  imageUploadDetailsInputs,
  ArticleInputs,
  ContactUsInputs
};
export const performanceCubes = {
  totalTonnage: {
    label: 'tonnageLifted',
    name: 'totalTonnage',
    measurements: units.weightUnits.KG,
    classNames: 'flex-50 padd2 fadeInBottom-anime'
  },
  totalWorkouts: {
    label: 'workoutsCompleted',
    name: 'totalWorkouts',
    classNames: 'flex-50 padd2 fadeInBottom-anime'
  },
  totalReps: {
    label: 'totalReps',
    name: 'totalReps',
    classNames: 'flex-50 padd2 fadeInBottom-anime'
  },
  totalSets: {
    label: 'totalSets',
    name: 'totalSets',
    classNames: 'flex-50 padd2 fadeInBottom-anime'
  }
  /*
  liftTime: {
    label: 'liftingTime',
    name: 'liftTime',
    measurements: 'h/min',
    classNames: 'flex-33 padd2 fadeInBottom-anime'
  },
  avgSessionDuration: {
    label: 'avgWorkoutTime',
    name: 'avgSessionDuration',
    measurements: 'min',
    classNames: 'flex-33 padd2 fadeInBottom-anime'
  },
  avgRestTime: {
    label: 'avgRestTime',
    name: 'avgRestTime',
    measurements: 'min',
    classNames: 'flex-33 padd2 fadeInBottom-anime'
  }
   */
};
export const serverStatus = {
  100: 'Continue',
  101: 'Switching Protocols',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Time-out',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Large',
  415: 'Unsupported Media Type',
  416: 'Requested Range not Satisfiable',
  417: 'Expectation Failed',
  418: 'I\'m a teapot',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Time-out',
  505: 'HTTP Version not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  SWITCH_PROXY: 306,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  REQUEST_ENTITY_TOO_LARGE: 413,
  REQUEST_URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511
};
export const placeholderImages = [

  {
    preview: 'https://images.unsplash.com/photo-1490843524522-ee99e561bb90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1490843524522-ee99e561bb90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'girls shoes close up - jump',
    cat: 'aerobic'
  },
  {
    preview: 'https://images.unsplash.com/photo-1533727937480-da3a97967e95?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1533727937480-da3a97967e95?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'hands, yoga mat',
    cat: 'yogs'
  }, {
    preview: 'https://images.unsplash.com/photo-1527634311077-9943f7df34e1?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1527634311077-9943f7df34e1?ixlib=rb-1.2.1&auto=format&fit=crop&w=1355&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1530822847156-5df684ec5ee1?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1530822847156-5df684ec5ee1?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1507763148222-074d49c7b5c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1507763148222-074d49c7b5c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1516525929193-eeac4c65dc64?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1516525929193-eeac4c65dc64?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1517130038641-a774d04afb3c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1517130038641-a774d04afb3c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'girls doing class - rubbers 1',
    cat: 'rubber band, group'
  },
  {
    preview: 'https://images.unsplash.com/photo-1517637382994-f02da38c6728?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1517637382994-f02da38c6728?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    name: 'girls doing class - rubbers 2',
    cat: 'rubber band, group'
  }, {
    preview: 'https://images.unsplash.com/photo-1527933053326-89d1746b76b9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1527933053326-89d1746b76b9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    name: 'girls doing class - rubbers 3',
    cat: 'rubber band, group'
  },
  {
    preview: 'https://images.unsplash.com/photo-1477332552946-cfb384aeaf1c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1477332552946-cfb384aeaf1c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'girl walking in a road - sunrise',
    cat: 'aerobic'
  },
  {
    preview: 'https://images.unsplash.com/photo-1507761906261-d31a39975ce4?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1507761906261-d31a39975ce4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'girl doing stretches from the back',
    cat: 'stretches'
  }, {
    preview: 'https://images.unsplash.com/photo-1491555103944-7c647fd857e6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1491555103944-7c647fd857e6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    name: 'men on mountain',
    cat: ''
  },
  {
    preview: 'https://images.unsplash.com/photo-1518459031867-a89b944bffe4?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1518459031867-a89b944bffe4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'girls doing yoga 1',
    cat: 'yoga'
  },
  {
    preview: 'https://images.unsplash.com/photo-1518611012118-696072aa579a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1518611012118-696072aa579a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'girls doing yoga 2',
    cat: 'yoga'
  },
  {
    preview: 'https://images.unsplash.com/photo-1518310383802-640c2de311b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1518310383802-640c2de311b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'girls doing yoga 3',
    cat: 'yoga'
  }, {
    preview: 'https://images.unsplash.com/photo-1518644961665-ed172691aaa1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1518644961665-ed172691aaa1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'girls doing yoga 4',
    cat: 'yoga'
  }, {
    preview: 'https://images.unsplash.com/photo-1518310790390-836058cb000b?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1518310790390-836058cb000b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'girls doing yoga 5',
    cat: 'yoga'
  }, {
    preview: 'https://images.unsplash.com/photo-1518622358385-8ea7d0794bf6?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1518622358385-8ea7d0794bf6?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'girls doing yoga 6',
    cat: 'yoga'
  }, {
    preview: 'https://images.unsplash.com/photo-1521804906057-1df8fdb718b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1521804906057-1df8fdb718b7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights - preparing 1',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights - preparing 2',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1556817411-58c45dd94e8c?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1556817411-58c45dd94e8c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights - preparing 3',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1516481265257-97e5f4bc50d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1516481265257-97e5f4bc50d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights - preparing 4',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1556817781-77671f3fe3c7?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1556817781-77671f3fe3c7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights - training',
    cat: 'weights'
  },{
    preview: 'https://images.unsplash.com/photo-1519311965067-36d3e5f33d39?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1519311965067-36d3e5f33d39?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights - done',
    cat: 'weights'
  },
  {
    preview: 'https://images.unsplash.com/photo-1521805103424-d8f8430e8933?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1521805103424-d8f8430e8933?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights',
    cat: 'weights'
  },
  {
    preview: 'https://images.unsplash.com/photo-1544021601-3e5723f9d333?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1544021601-3e5723f9d333?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'men doing squat',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1517344368193-41552b6ad3f5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1517344368193-41552b6ad3f5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1556817411-92f5ec899a55?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1556817411-92f5ec899a55?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights - done 2',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1526401485004-46910ecc8e51?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1526401485004-46910ecc8e51?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1427384906349-30452365b5e8?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1427384906349-30452365b5e8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'couple running on a bridge',
    cat: 'aerobic'
  }, {
    preview: 'https://images.unsplash.com/photo-1483721310020-03333e577078?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1483721310020-03333e577078?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'men preparing to run',
    cat: 'aerobic'
  }, {
    preview: 'https://images.unsplash.com/photo-1517343985841-f8b2d66e010b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1517343985841-f8b2d66e010b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'men pushing a sled',
    cat: 'weights crossfit'
  },
  // new side
  {
    preview: 'https://images.unsplash.com/photo-1536922246289-88c42f957773?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1536922246289-88c42f957773?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'girls do box jumps',
    cat: 'crossfit aerobic'
  }, {
    preview: 'https://images.unsplash.com/photo-1519505907962-0a6cb0167c73?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1519505907962-0a6cb0167c73?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'men doing rows machine',
    cat: 'aerobic crossfit'
  }, {
    preview: 'https://images.unsplash.com/photo-1550259979-ed79b48d2a30?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1550259979-ed79b48d2a30?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'girl doing a side plank',
    cat: 'body weight'
  }, {
    preview: 'https://images.unsplash.com/photo-1434682881908-b43d0467b798?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1434682881908-b43d0467b798?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'womans back',
    cat: 'weights crossfit'
  }, {
    preview: 'https://images.unsplash.com/photo-1546749876-2088f8b19e09?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1546749876-2088f8b19e09?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'group with ropes 1',
    cat: 'aerobic crossfit'
  }, {
    preview: 'https://images.unsplash.com/photo-1547226238-e53e98a8e59d?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1547226238-e53e98a8e59d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: 'group with ropes 2',
    cat: 'aerobic crossfit'
  }, {
    preview: 'https://images.unsplash.com/photo-1534258936925-c58bed479fcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1534258936925-c58bed479fcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'blur girl with ropes',
    cat: 'aerobic'
  }, {
    preview: 'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'gym weights',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1570440828843-ccc432c6fad7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1570440828843-ccc432c6fad7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'trainer helps lifting bar',
    cat: 'weights'
  }, {
    preview: 'https://images.unsplash.com/photo-1517438322307-e67111335449?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1517438322307-e67111335449?ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80',
    name: 'trainer kickbox',
    cat: 'kickbox'
  }, {
    preview: 'https://images.unsplash.com/photo-1551718753-9a1d488b523d?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1551718753-9a1d488b523d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    name: '3 girls dance',
    cat: 'aerobic'
  },
  {
    preview: 'https://images.unsplash.com/photo-1549576490-b0b4831ef60a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1549576490-b0b4831ef60a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'guy doing class - stretches 1',
    cat: 'rubber band, group'
  },
  {
    preview: 'https://images.unsplash.com/photo-1544216717-3bbf52512659?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1544216717-3bbf52512659?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
    name: 'two girls push ups',
    cat: 'bodyweight'
  }, {
    preview: 'https://images.unsplash.com/photo-1520334363269-c1b342d17261?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1520334363269-c1b342d17261?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=637&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1517344800994-80b20463999c?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1517344800994-80b20463999c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1491571786955-fad5b6755f61?ixlib=rb-1.2.1&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1491571786955-fad5b6755f61?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1434494878577-86c23bcb06b9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1434494878577-86c23bcb06b9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    name: '',
    cat: ''
  }, {
    preview: 'https://images.unsplash.com/photo-1523395294292-1fbf0cd2435e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=350&q=80',
    uri: 'https://images.unsplash.com/photo-1523395294292-1fbf0cd2435e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1359&q=80',
    name: '',
    cat: ''
  }
];
export const nanoidOption = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export const nanoidOptionAbc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
