import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import formReducer from './reducers/formReducer';
import userReducer from './reducers/userReducer';
import appReducer from './reducers/appReducer';
import menuReducer from './reducers/menuReducer';
import workoutReducer from './reducers/workoutReducer';
import exerciseReducer from './reducers/exerciseReducer';
import workoutSessionReducer from './reducers/workoutSessionReducer';
import userStatsReducer from './reducers/userStatsReducer';
import achievementReducer from './reducers/achievementReducer';
import mediaReducer from './reducers/mediaReducer';
import infoReducer from './reducers/infoReducer';
import articleReducer from './reducers/articleReducer';
import timerReducer from './reducers/timerReducer';
import calendarReducer from './reducers/calendarReducer';
import productReducer from './reducers/productReducer';
import authFirebaseReducer from './reducers/authFirebaseReducer';

const rootReducer = combineReducers({
  formR: formReducer,
  userR: userReducer,
  appR: appReducer,
  menuR: menuReducer,
  exerciseR: exerciseReducer,
  workoutR: workoutReducer,
  sessionR: workoutSessionReducer,
  userStatsR: userStatsReducer,
  achieveR: achievementReducer,
  mediaR: mediaReducer,
  infoR: infoReducer,
  articleR: articleReducer,
  timerR: timerReducer,
  authR: authFirebaseReducer,
  calR: calendarReducer,
  productR: productReducer
});

const logger = store => next => (action) => {
  const result = next(action);
  return result;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

export default store;
// export default
