import 'regenerator-runtime/runtime';
import "./assets/fonts/iconsminds/iconsminds.css";
import "./assets/fonts/simple-line-icons/simple-line-icons.css";
import "./assets/css/vendor/line-awesome.css";
import 'react-tippy/dist/tippy.css';
import "./assets/css/sass/rootVariables.css";
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "./assets/css/vendor/flex-layout.css";
import "./assets/css/vendor/animations.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-table/react-table.css";
import './assets/css/vendor/reactlLightBox.css';
import "./services/globals";

import { isMultiColorActive, defaultColor,themeColorStorageKey,isDarkSwitchActive } from "./constants/defaultValues";
const color =
  (isMultiColorActive||isDarkSwitchActive) && localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

let render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(x => {
      require('./AppRenderer');
  });
};
render();
