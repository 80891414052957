import { actionTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse, setDynamicSearchResults } from './index';

const setInfos = payload => ({
  type: actionTypes.SET_INFOS_LIST,
  payload
});

const setInfosFilter = payload => ({
  type: actionTypes.SET_INFO_FILTERS,
  payload
});

const setMyInquiries = payload => ({
  type: actionTypes.SET_MY_INFOS,
  payload
});

const setInfoCategories = payload => ({
  type: actionTypes.SET_INFO_CATEGORIES,
  payload
});

export const handleInfos = err => ({
  type: actionTypes.HANDLE_ERROR_RESPONSE,
  payload: err
});

const setInfoDetails = payload => ({
  type: actionTypes.SET_INFO_DETAILS,
  payload
});

const setSolutionDetails = payload => ({
  type: actionTypes.SET_SOLUTION_DETAILS,
  payload
});

export const getFilteredInfos = body => async (dispatch) => {
  try {
    const data = await apiMethods.info.getFilteredInfos(body);
    dispatch(setInfos(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getPopularInfos = () => async (dispatch) => {
  try {
    const data = await apiMethods.info.getPopularInfos();
    dispatch(setInfos(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const filterInfosByCategory = body => async (dispatch) => {
  try {
    if (body.category && body.category.length > 0) {
      const query = utils.buildQuery(body);
      const data = await apiMethods.info.filterInfosByCategory(query);
      dispatch(setInfos(data));
    } else {
      return false;
    }
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getInfoByPublicId = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.info.getInfoByPublicId(publicId);
    dispatch(setInfoDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getInfosFilter = (query = {}, isDynamicSearchFilter) => async (dispatch) => {
  try {
    const body = { group: [8], ...query }; // 8 - info subjects
    const data = await apiMethods.categories.getCategoriesByGroup(body);
    dispatch(setInfosFilter(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getInfosCategories = (query, isDynamicSearchFilter) => async (dispatch) => {
  try {
    const body = { group: [8], ...query }; // 8 - info subjects
    const data = await apiMethods.categories.getCategoriesByGroup(body);
    if (isDynamicSearchFilter) {
      dispatch(setDynamicSearchResults(data.infoSubjects));
    } else dispatch(setInfoCategories(data.infoSubjects));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getMyInquiries = query => async (dispatch) => {
  try {
    const data = await apiMethods.info.getMyInquiries(query);
    dispatch(setMyInquiries(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const createMyInquiry = body => async (dispatch) => {
  try {
    const data = await apiMethods.info.create(body);
    dispatch(setInfoDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createAspireInquiry = body => async (dispatch) => {
  try {
    const data = await apiMethods.info.createAspire(body);
    dispatch(setInfoDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const editMyInquiry = body => async (dispatch) => {
  try {
    const data = await apiMethods.info.edit(body);
    dispatch(setInfoDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeMyInquiry = publicId => async (dispatch) => {
  try {
    return await apiMethods.info.remove([publicId]);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const addSolution = body => async (dispatch) => {
  try {
    // body = {publicId, solution}
    const data = await apiMethods.info.addSolution(body);
    // dispatch(setInfoDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const editSolution = body => async (dispatch) => {
  try {
    // body = {...solution}
    const data = await apiMethods.info.editSolution(body);
    // dispatch(setInfoDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getSolution = body => async (dispatch) => {
  try {
    // body = {publicId ,solutionId}
    const data = await apiMethods.info.getSolution(body);
    dispatch(setSolutionDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const assignInquiryAsAnswered = publicId => async (dispatch) => {
  try {
    return await apiMethods.info.assignInquiryAsAnswered({ publicId });
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const dispatchFilteredInfos = (body, data) => async (dispatch) => {
  try {
    dispatch(setInfos(data));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};
