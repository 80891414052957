/* eslint-disable max-len */
import React from 'react';
import { Trans } from 'react-i18next';

const ExerciseLogPlaceHolder = props => (
  <div className="ExerciseLogDisplay flex-100 layout-row layout-wrap layout-align-start-start stripe set placeHolderContainer positionRelative">
    <div className="flex-100 layout-row layout-wrap layout-align-start-center">
      <div className="flex layout-row layout-wrap layout-align-start-center">
        <div className="flex-initial layout-row layout-wrap layout-align-center-center">
          <div className="set-number layout-row layout-wrap layout-align-center-center sp-3">
            {props.showComment ? (
              <label className="fontWeight600 propsName font15 textLimitHeight borderRadius3 sidePadd5px">
                <span className="commentPlaceHolder positionRelative loading" />
              </label>
            ) : (
              <span className="font38 comment colorGrey placeHolder-gradient positionRelative"><Trans i18nKey="set" /> ?</span>
            )}
          </div>

        </div>
        <div className="flex layout-row layout-align-space-between-start">
          <div className="flex layout-row layout-wrap layout-align-start-center" />
          <div className="layout-row layout-wrap layout-align-start-center flex-70">
            <div className="flex layout-row layout-wrap layout-align-center-center positionRelative">
              <label className="fontWeight600 propsName font15 titlePlaceHolder70px borderRadius3 margin0">
                <span className="titlePlaceHolder positionRelative loading" />
              </label>
            </div>
            <div className="flex-initial layout-row layout-wrap layout-align-center-center positionRelative">
              <p className="set-X font38 placeHolder-gradient positionRelative">x</p>
              <span className="font13 comment colorGrey" />
            </div>
            <div className="flex layout-row layout-wrap layout-align-center-center positionRelative">
              <label className="fontWeight600 propsName font15 titlePlaceHolder70px borderRadius3 margin0">
                <span className="titlePlaceHolder positionRelative loading" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ExerciseLogPlaceHolder;
