/* eslint-disable no-trailing-spaces,max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { orderBy } from 'lodash-es';
import { langs } from '../../langs';
import { utils } from '../../services/utilsService';
import { userTypes } from '../../services/enums';
import ClientIcon from '../ClientIcon/ClientIcon';
import './ClientList.css';

class ClientList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      assignedIds: [],
      selectedIds: [],
      selectedKV: {}
    };
  }

  componentDidMount () {
    if (this.props.editMode) {
      this.handleMountEditMode();
    } else if (this.props.assignMode) {
      this.handleMountAssignMode();
    } else if (this.props.topNavMode) {
      this.handleChosenClients();
    } else {
      this.setState({
        selectedKV: {},
        selectedIds: []
      });
    }
  }

  handleMountEditMode () {
    if (this.props.chosenClients && this.props.chosenClients.dataIds.length > 0) {
      this.setState({ selectedKV: this.props.chosenClients.keyValueObj, selectedIds: this.props.chosenClients.dataIds });
    } else if (this.props.chosenClients && this.props.chosenClients.public_id) {
      const chosen = {};
      this.props.clients.forEach((client) => {
        if (client.public_id === this.props.chosenClients.public_id) {
          chosen[client.public_id] = client;
        }
      });
      this.setState({ selectedKV: chosen, selectedIds: [this.props.chosenClients.public_id] });
    } else {
      this.setState({ selectedKV: {}, selectedIds: [] });
    }
  }

  handleMountAssignMode () {
    if (this.props.usersAssigned && this.props.usersAssigned.dataIds.length > 0) {
      this.setState({ assignedIds: this.props.usersAssigned.dataIds });
    }
  }

  handleChosenClients () {
    if (this.props.chosenClientsIds && this.props.chosenClientsIds.length > 0) {
      this.setState({ selectedIds: this.props.chosenClientsIds, selectedKV: this.props.chosenClientsKV });
    }
  }

  clientClicked (client, isTrainer) {
    delete client._id;
    if (this.props.singleSelect) {
      this.state.selectedKV = {};
      this.state.selectedIds = [client.public_id];
      this.state.selectedKV[client.public_id] = client;
    } else if (this.state.selectedIds.indexOf(client.public_id) === -1) {
      this.state.selectedIds.push(client.public_id);
      this.state.selectedKV[client.public_id] = client;
    } else {
      this.state.selectedIds.splice(this.state.selectedIds.indexOf(client.public_id), 1);
      delete this.state.selectedKV[client.public_id];
    }
    // what to send outside
    if (this.props.clientClicked) {
      const clients = utils.keyValueToArray(this.state.selectedKV);
      this.props.clientClicked(clients, isTrainer);
    }
    // update Component
    this.setState({ ...this.state });
  }

  createClientList () {
    let clients = this.props.customeList || this.props.clients;
    if (clients && clients.length > 0) {
      clients = orderBy(clients, ['order'], ['asc']); // Use Lodash to sort array by 'order'
      return clients.map((client, i) => {
        if (client.public_id) {
          return (
            <div
              key={client.public_id + i}
              className={`${this.props.horizontalBar ? 'flex-initial' : 'flex-25'} ClientIcon-container layout-row layout-wrap layout-align-center-center ${(this.state.selectedIds.indexOf(client.public_id) !== -1) ? 'chosen' : ''} ${(this.state.assignedIds.indexOf(client.public_id) !== -1) ? 'assigned' : ''}`}>
              <ClientIcon user={client} firstName clicked={() => this.clientClicked(client)} />
            </div>
          );
        }
        return null;
      });
    }
    return null;
  }

  renderTrainerCircle () {
    if (this.props.user.userType === userTypes.TRAINER) {
      const { thumbnail, firstName, public_id } = { ...this.props.user };
      const trainer = {
        thumbnail: thumbnail && thumbnail.uri ? thumbnail : '',
        name: firstName,
        public_id
      };
      return (
        <div
          key={trainer.public_id}
          className={`${this.props.horizontalBar ? 'flex-initial' : 'flex-25'} ClientIcon-container layout-row layout-wrap layout-align-center-center ${(this.state.selectedIds.indexOf(trainer.public_id) !== -1) ? 'chosen' : ''} ${(this.state.assignedIds.indexOf(trainer.public_id) !== -1) ? 'assigned' : ''}`}>
          <ClientIcon
            user={trainer}
            nameTag={langs[this.props.locale].me || langs.en.me}
            clicked={() => this.clientClicked(trainer, true)} />
        </div>
      );
    }
    return null;
  }

  renderSkeleton () {
    const skeleton = [1, 2, 3, 4];
    return skeleton.map((client, index) => (
      <div
        key={`${index}_key`}
        className="ClientIcon-container flex-25 layout-row layout-wrap layout-align-center-center">
        <ClientIcon isLoading />
      </div>
    ));
  }

  render () {
    const skeletonLoader = (this.props.isLoading || !this.props.clients) ? this.renderSkeleton() : null;
    const clientList = this.createClientList();
    const trainer = this.props.showTrainer ? this.renderTrainerCircle() : null;
    const customCircle = this.props.children ? this.props.children : null;
    return (
      <div className={`ClientList flex-100 layout-row layout-wrap layout-align-start-start ${this.props.className} ${this.props.largeIcons ? 'largeIcons' : ''}`}>
        <div className={`layout-row layout-align-start-start ${this.props.horizontalBar ? 'horizontal-overflow' : 'flex-100 layout-wrap gridBar'}`}>
          { this.props.isLoading ? (
            <div className={`layout-row layout-align-start-start ${this.props.horizontalBar ? 'horizontal-overflow' : 'flex-100 layout-wrap gridBar'}`}>
              {skeletonLoader}
            </div>
          ) : (
            <div className={`layout-row layout-align-start-start ${this.props.horizontalBar ? 'horizontal-overflow' : 'flex-100 layout-wrap gridBar'}`}>
              {customCircle}
              {trainer}
              {clientList}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  clients: state.userR.userInfo.clients,
  chosenClientsIds: state.userR.chosenClientsIds,
  chosenClientsKV: state.userR.chosenClientsKV,
  locale: state.appR.locale
});

export default connect(mapStateToProps)(ClientList);
