/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import nanoid from 'nanoid/generate';
import { showAlert2 } from '../../store/actions';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { nanoidOption } from '../../services/storeDoll';
import { equipmentLangs, langs } from '../../services/translationLangs';
import BodyMapThumb from '../BodyMap/BodyMapThumbnail';
import Image from '../UI/Image';
import './StripExercise.css';

class StripExercise extends Component {
  handleClickOnStripe(exercise) {
    if (this.props.disableClick) return;
    if (this.props.clicked) {
      this.props.clicked();
      return;
    }
    if (this.props.disableModal) return;
    this.handleExerciseDetails(exercise);
  }

  handleClickOnImage(exercise) {
    if (this.props.disableClick) return;
    if (this.props.clickedImage) {
      this.props.clickedImage();
      return;
    }
    if (this.props.disableModal) return;
    this.handleExerciseDetails(exercise);
  }

  handleExerciseDetails(exercise) {
    if (this.props.disableModal) return;
    const data = {
      isOpen: true,
      isModal: true,
      id: eventActions.EXERCISE_DETAILS,
      className: 'PageModal',
      exercises: exercise, // can be one...can be 3
      buttonRight: {
        text: <i className="la la-arrow-left iconFontSize30 goBackIconColor " />,
        onClick: () => {
          this.props.hideAlert2();
        },
      },
    };
    this.props.showAlert2(data);
  }

  handleChange(e, exercise) {
    exercise.equipments.map((equip) => {
      if (equip.equipment === e.currentTarget.value) {
        equip.equipmentId = equip._id;
        exercise.chosenEquipment = equip;
      }
    });
    this.props.selectClicked(exercise);
  }

  handleEquipmentLang(exercise) {
    const { locale } = this.props;
    const chosenEquipment = exercise.chosenEquipment && exercise.chosenEquipment.title ? `${exercise.chosenEquipment.title[locale] ? exercise.chosenEquipment.title[locale] : exercise.chosenEquipment.title.en}` : null;
    const equipment = equipmentLangs[locale][chosenEquipment && chosenEquipment.replace(' ', '_').toUpperCase()] || (exercise.equipment && exercise.equipment.title && exercise.equipment.title.en ? equipmentLangs[locale][exercise.equipment.title.en.toUpperCase()] : chosenEquipment);
    return equipment;
  }

  handleSquareThumbnail(exercise) {
    return exercise.chosenEquipment && exercise.chosenEquipment.thumbnailSquare ? exercise.chosenEquipment.thumbnailSquare : (exercise.defaultEquipment && exercise.defaultEquipment.thumbnailSquare) || 'none';
  }

  renderInitValueOfSelect(exercise) {
    if (this.props.initValue) return this.props.initValue;
    return exercise.chosenEquipment ? exercise.chosenEquipment.equipment : (exercise.defaultEquipment && exercise.defaultEquipment.equipment) || 'none';
  }

  renderSelectEquipment(exercise, ExProps, index) {
    const { locale, isMulti, equipmentSelect, showChosenEquipment } = this.props;
    if (equipmentSelect && exercise && exercise.equipments) {
      const selectValue = this.renderInitValueOfSelect(exercise);
      return (
        <span className="flex layout-row layout-align-start-start text-nowrap">
          <span className="flex-initial text-nowrap">{`${langs[locale].againstEquipment}`}</span>
          <div className="flex layout-row layout-align-start-start positionRelative selectContainer equipmentSelect colorEquipment">
            <select
              className="flex form-control select-eq"
              required
              defaultValue={selectValue}
              key="chosenEquipment"
              onChange={(event) => {this.selectEquipment(event, exercise);}}>
              {exercise.equipments.length > 0 &&
              exercise.equipments.map((option) => (
                <option key={`${option.equipment}_exSelect`} value={option.equipment}>
                  {option && option.title[locale] ? option.title[locale] : equipmentLangs[locale][option.title && option.title.en && option.title.en.replace(' ', '_').toUpperCase()] || (option.title && option.title.en) || ''}
                </option>
              ))}
            </select>

          </div>
        </span>
      );
    }
    if (showChosenEquipment) {
      if (isMulti) {
        return (
          <span className="flex-initial text-nowrap">
            <span className="flex-initial">{`${langs[locale].againstEquipment}`}</span>
            <span className="colorEquipment flex-initial text-nowrap">{` ${ExProps.equipment[index]}`}</span>
          </span>
        );
      }
      if (ExProps.equipment) {
        return (
          <span className="flex-initial text-nowrap">
            <span className="flex-initial">{`${langs[locale].againstEquipment}`}</span>
            <span className="colorEquipment flex-initial text-nowrap">{` ${ExProps.equipment}`}</span>
          </span>
        );
      }
    }
    return null;
  }

  renderBodyMapThumb() {
    const { exercise, isMulti, locale } = this.props;
    const ExProps = { images: [], title: [], equipment: [] };
    if (isMulti) {
      for (let i = 0; i < exercise.length; i++) {
        const squareThumbnail = this.handleSquareThumbnail(exercise[i]);
        ExProps.images.push(
          exercise[i].thumbnail ? (
            <div
              key={`${nanoid(nanoidOption, 6)}_key`}
              className={`flex-initial layout-row layout-wrap layout-align-start-start exercise-Thumbnail exercise_${exercise[i].order}`}
              style={{ zIndex: exercise.length - i }}>
              { squareThumbnail  && squareThumbnail !== 'none' && (
                <div className="flex-initial layout-row layout-wrap layout-align-start-start positionRelative">
                  <Image
                    src={squareThumbnail}
                    height={60}
                    fallbackSrc="https://aspire123.s3.eu-central-1.amazonaws.com/assets/blank.png"
                    className="positionAbsolute zIndex10 thumbnailSquareImg" />
                </div>
              )}
              <BodyMapThumb
                position={exercise[i].thumbnail && exercise[i].thumbnail.previewImg ? exercise[i].thumbnail.previewImg : 1}
                classPosition={exercise[i].thumbnail && exercise[i].thumbnail.classPosition ? exercise[i].thumbnail.classPosition : ''}
                chosenMuscle={(exercise[i].thumbnail && exercise[i].thumbnail.className) || []}
              />
            </div>
          ) : null
        );
        const title = exercise[i].customName ? exercise[i].customName : exercise[i].title[locale] || exercise[i].title.en || '???';
        const equipment = this.handleEquipmentLang(exercise[i]);
        ExProps.title.push(title);
        ExProps.equipment.push(equipment);
      }
    } else {
      ExProps.title = exercise.customName ? exercise.customName : exercise.title[locale] || exercise.title.en || '???';
      ExProps.equipment = this.handleEquipmentLang(exercise);
      const squareThumbnail = this.handleSquareThumbnail(exercise);
      ExProps.images = exercise.thumbnail ? (
        <div className="flex-initial layout-row layout-wrap layout-align-start-start positionRelative">
          { squareThumbnail  && squareThumbnail !== 'none' && (
            <div className="flex-initial positionAbsolute zIndex10">
              <div className="thumbnailSquareContainer positionRelative">
                <Image
                  src={squareThumbnail}
                  height={60}
                  fallbackSrc="https://aspire123.s3.eu-central-1.amazonaws.com/assets/blank.png"
                  className="thumbnailSquareImg" />
              </div>
            </div>
          )}
          <BodyMapThumb
            position={exercise.thumbnail && exercise.thumbnail.previewImg ? exercise.thumbnail.previewImg : 1}
            classPosition={exercise.thumbnail && exercise.thumbnail.classPosition ? exercise.thumbnail.classPosition : ''}
            chosenMuscle={(exercise.thumbnail && exercise.thumbnail.className) || []} />
        </div>
      ) : null;
    }
    return ExProps;
  }

  render() {
    const { exercise, musclesInvolvedContainer, editMode } = this.props;
    const locale = this.props.locale ? this.props.locale : 'en';
    const ExProps = this.renderBodyMapThumb()
    return (
      <div
        id={exercise.public_id}
        className={`StripExercise ${this.props.className || 'flex-100 layout-row layout-align-start-center'} ${editMode ? 'editMode' : ''}`}>
        {editMode && (
          <div className="flex-initial layout-row layout-wrap layout-align-start-center checkBox">
            <div className="checkbox-container">
              <input
                type="checkbox"
                onChange={(event) => {
                  this.props.checked(event);
                }}
              />
            </div>
          </div>
        )}
        <div className={`flex layout-row layout-align-start-center exerciseStripe ${this.props.chosen || ' '} order-${this.props.index}`}>
          <div
            onClick={() => {this.handleClickOnImage(exercise);}}
            className={`flex-initial layout-row layout-wrap layout-align-start-start exerciseImages ${this.props.isMulti ? 'multiImages' : ''} exerciseImages-${(ExProps.images && ExProps.images.length) || 1}`}>
            {ExProps.images}
          </div>
          <div className="flex layout-row layout-wrap layout-align-start-center" onClick={() => { this.handleClickOnStripe(exercise); }}>
            <div className="flex layout-column layout-wrap layout-align-start-start paddStart10px">
              {!this.props.text && this.props.index && this.props.length && (
                <span className="margin0 font13 comment">{this.props.index}{' '}<Trans i18nKey="of" />{' '}{this.props.length}</span>
              )}
              {this.props.text && (
                <span className="margin0 font13 comment"> {this.props.text} </span>
              )}
              <label className="flex-initial width100 fontWeight600 propsName font15 textLimitHeight text-capitalize">
                {this.props.isMulti ? <span>{ExProps.title.join(', ')}</span> : (
                  <span className="width100 layout-row layout-wrap layout-align-start-center content-center title">
                    <span
                      className={`paddEnd5px ${ExProps.title.length < 16 ? 'flex-initial layout-row layout-wrap layout-align-start-start' : ''}`}>
                      <span className="textLimitHeight flex-100 titleLimit paddEnd5px ">{`${ExProps.title}`}</span>
                      {this.renderSelectEquipment(exercise, ExProps)}
                    </span>
                  </span>
                )}
              </label>
              {this.props.showCategories && !this.props.isMulti && (
                <div
                  className="flex-100 layout-row layout-wrap layout-align-start-start marginBottom5px textLimitHeight musclesInvolvedContainer"
                  style={{ maxHeight: `${musclesInvolvedContainer || 44}px` }}>
                  { this.props.showTarget && exercise.muscles && exercise.muscles.target.map((category, index) => {
                      if (index < 5) {
                        return (
                          <div
                            key={`${index}_target_key`}
                            className="flex-initial layout-row layout-wrap layout-align-center-center text-nowrap categoryTag target_muscle_cat">
                            {category.title[locale] || category.title.en}
                          </div>
                        );
                      }
                    })}
                  {this.props.showSynergist &&
                    exercise.muscles.synergist.map((category, index) => {
                      if (index < 5) {
                        return (
                          <div
                            key={`${index}_synergist_key`}
                            className="flex-initial layout-row layout-wrap layout-align-center-center text-nowrap categoryTag target_synergist_cat">
                            {category.title[locale] || category.title.en}
                          </div>
                        );
                      }
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.appR.locale,
});

const mapDispatchToProps = (dispatch) => ({
  showAlert2: (data) => dispatch(showAlert2(data)),
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripExercise);
