import React, { Component } from 'react';
import { connect } from 'react-redux';
import nanoid from 'nanoid/generate';
import { showAlert } from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import {handleModalFullScreen} from '../../services/utilsService';
import { nanoidOption } from '../../services/storeDoll';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import {aspirePlaceholder} from "../../services/enums";
import SingleLightbox from '../UI/SingleLightbox';
import './MediaDisplay.css';

class MediaDisplay extends Component {
  constructor (props) {
    super(props);
    this.state = {
      thumbnail: 'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/logoScreen.jpg',

    };
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !!(nextProps.shouldUpdate || nextState.shouldUpdate);
  }

  checkMultipleLinks (media) {
    if (media && Array.isArray(media)) {
      return (media.map((mediaObj, index) => {
        if (mediaObj && mediaObj.media && mediaObj.media.source) {
          const mediaKey = mediaObj.id || (mediaObj.media && mediaObj.media._id) || 'null';
          return (
            <div key={mediaKey} className={`width100 layout-column layout-align-center-center positionAbsoluteTopLeft mediaLink mediaLink_${mediaObj.order}`} style={{ minHeight: `${this.props.height}px`, zIndex: media.length - index }}>
              { this.renderVideo(mediaObj.media, mediaKey)}
            </div>
          );
        }
        return this.renderDefaultIcon();
      }));
    }
    if (media && media.media && media.media.source) {
      return this.renderVideo(media.media, media.id || media.media._id);
    }
    return this.renderDefaultIcon();
  }

  goFullScreen (media, isPortrait) {
    if (this.props.handleOrientation) this.props.handleOrientation(isPortrait);
    else {
      const data = handleModalFullScreen(media, isPortrait);
      if(this.props.disableFSLogo) data.disableFSLogo = true;
      this.props.showAlert(data);
    }
  }

  renderDefaultIcon () {
    return (
      <div key={`default_${nanoid(nanoidOption, 4)}`} className="width100 layout-column text-center layout-align-center-center positionAbsoluteTopLeft whiteBackground borderRadius5" style={{ height: `${this.props.height}px` }}>
        <img src="https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogo_250x225.png" alt="aspireLogo" className="aspireIcon" style={{ width: '80px' }} />
        <h2 className="text-uppercase fontWeight600">Video</h2>
      </div>
    );
  }

  renderVideo (mediaObj, mediaElemId) {
    if (mediaObj && mediaObj.source) {
      return (
        <VideoPlayer
          videoClass={this.props.videoClass}
          key={mediaElemId}
          id={mediaElemId}
          height={this.props.height || 230}
          width={this.props.width || window.innerWidth}
          media={mediaObj}
          isMuteBtn={this.props.isMuteBtn}
          controls={this.props.controls}
          playPauseBtn={this.props.playPauseBtn}
          fullScreenBtn={this.props.fullScreenBtn}
          isAutoPlay={this.props.isAutoPlay}
          isLandscape={this.props.isLandscape}
          locale={this.props.locale}
          handleModalFullScreen={(media, isPortrait) => { this.goFullScreen(mediaObj, isPortrait); }} />
      );
    } return this.renderDefaultIcon();
  }

  renderLightBox(media, height) {
    const { lightBoxCarousel, backUpImage, videoPoster, imageClass} = this.props
      const images = lightBoxCarousel && lightBoxCarousel.length > 0 ? [...lightBoxCarousel] : null;
      let mediaObj = { ...media };
      if (Array.isArray(media)) mediaObj = mediaObj[0].media
      return (
        <SingleLightbox
          thumb={(videoPoster || mediaObj.thumbnail || backUpImage || this.state.thumbnail || aspirePlaceholder[0])}
          large={(videoPoster || mediaObj.source || backUpImage || this.state.thumbnail || aspirePlaceholder[0])}
          fallbackThumbnail={mediaObj.sourceOrigin || mediaObj.source}
          height={height}
          navClass="width100"
          images={images}
          className="social-header card-img width100" />
      )
  }

  render () {
    const {
      isImage, media, maxHeight, minHeight, height
    } = this.props;
    const videoDisplay = this.checkMultipleLinks(media);
    const lightBox = isImage ? this.renderLightBox(media, height) : null;
    return (
      <div
        id={this.props.uiId}
        className={`MediaDisplay flex-100 layout-row layout-align-start-start ${this.props.className ? this.props.className : ''} ${isImage ? 'image' : 'video'}`}
        style={{
          maxHeight: `${maxHeight}px`,
          minHeight: `${minHeight}px`,
          height: `${height}px`,
          position: 'relative',
          zIndex: 2
        }}>
        <div className="flex-100 layout-row layout-align-center-center">
          {isImage ? lightBox : videoDisplay}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT })
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDisplay);
