import { mediaActionTypes } from '../actions/actionTypes';
import { storeUtils } from '../../services/utilsService';

const initialState = {
  media: {},
  // mediaKV {}
  // mediaIds []
  // achievementData {}
  mediaList: []
  // instagramMediaList
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case mediaActionTypes.SET_MEDIA_GALLERY:
      return { ...state, mediaList: action.payload };
    case mediaActionTypes.JOIN_MEDIA_LIST:
      return { ...state, mediaList: [ ...state.mediaList, ...action.payload] };
    case mediaActionTypes.SET_MEDIA_DETAILS:
      return { ...state, mediaDetails: action.payload };
    case mediaActionTypes.CLEAR_MEDIA_DATA:
      return { ...state, mediaList: [], instagramMediaList: [] };
    case mediaActionTypes.CLEAR_MEDIA_DETAILS:
      return { ...state, mediaDetails: null };
    case mediaActionTypes.SET_INSTAGRAM_MEDIA:
      return { ...state, instagramMediaList: action.payload };
    case mediaActionTypes.REMOVE_MEDIA: {
      // const mediaKV = storeUtils.removeFromObjectInStore(state.mediaKV, action.payload);
      const mediaList = storeUtils.removeFromArrayInStoreByPublicId(state.mediaList, action.payload);
      return { ...state, mediaList };
    }
    default:
      return state;
  }
};

export default mediaReducer;
