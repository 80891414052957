/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import allSettled from 'promise.allsettled';
import { Button, Modal, ModalBody } from 'reactstrap';
import { actionTypes } from '../../store/actions/actionTypes';
import './modalStyle.css';
import { getUserAlbums, getUserAlbumPhotos, getIGAccountMedia, getIGPageMedia, getFBPageMedia } from '../../store/actions';
import { addClass, removeClass } from '../../services/utilsService';
import { makeABlob, handleUpload, saveUploadedFiles } from '../../services/urlUploadService';
import { mediaType, sourceType } from '../../services/enums';
import Image from '../../Components/SocialModalCmp/Image';
import Album from '../../Components/SocialModalCmp/Album';
import LoadingDots from '../../Components/UI/LoadingDots';

class GetSocialMediaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isMulti: this.props.alert2Props.isMulti,
      sourceType: this.props.alert2Props.isFacebook ? sourceType.facebook : sourceType.instagram,
      media: null,
      albums: null,
      photos: null,
      selectedImagesIds: [],
      selectedImagesKV: {},
      showBackBtn: false,
      startUpload: false,
      cancelUpload: {},
      uploadBtnText: 'uploadSelectedImages',
      completeResults: [],
      resultsIds: [],
      uploadedIds: [], // uploadedFileIdsAlready
      allowImages: this.props.alert2Props.allowImages,
      allowVideos: this.props.alert2Props.allowVideos,
      chooseAccount: false,
      chosenAccount: null
    };
  }

  async componentDidMount() {
    const { alert2Props } = this.props;
    if (alert2Props.isFacebook) {
      await this.getUserAlbums();
    } else if (alert2Props.isInstagram && alert2Props.accounts) {
      if (alert2Props.accounts.length > 1) {
        this.setState({ chooseAccount: true })
      }
      else {
        this.state.chosenAccount = alert2Props.accounts[0].id;
        await this.getIGAccountMedia({ accountId: alert2Props.accounts[0].id});
      }
    }
    this.setState({ isLoading: false });
  }

  async handleSelectedMedia() {
    let { selectedImagesIds, selectedImagesKV, completeResults, resultsIds, uploadedIds} = this.state
    if (this.isLoading || selectedImagesIds.length === 0 ) return;
    this.isLoading = true;
    const publicId = this.props.user.public_id;
    try {
      this.setState({ startUpload: true, uploadBtnText: 'uploadingFiles' })
      const selectedObjs = Object.entries(selectedImagesKV).map(([key, value]) => value)
      const results = await allSettled(selectedObjs.map((media) => this.uploadSelectedMedia(media, publicId)))
      const mediaToUpload = [];
      results.forEach((result) => {
        if (result && result.value && result.value.source) {
          const { value } = result; // media Object
          mediaToUpload.push(value);
        }
      });
      this.setState({ uploadBtnText: 'updatingAspireLib' })
      const medias = await saveUploadedFiles(mediaToUpload);
      // adding to an already uploaded images if there are any
      const uploadedSuccessfully = [];
      medias.forEach(obj => {
        if(obj && obj.metadata && obj.metadata.id) {
          addClass(`#media_${obj.metadata.id} .startUpload`, 'display-none')
          uploadedSuccessfully.push(obj.metadata.id)
        }
      });
      const ids = medias.map(res => res._id);
      completeResults = completeResults.concat(medias)
      resultsIds = resultsIds.concat(ids)
      uploadedIds = uploadedIds.concat(uploadedSuccessfully)
      this.setState({ startUpload: false, selectedImagesKV:{}, selectedImagesIds: [], completeResults, resultsIds, uploadBtnText: 'uploadSelectedImages', uploadedIds }, () => {
        this.props.alert2Props.handleSelectedImages.onClick(completeResults, resultsIds)
        this.props.hideAlert2();
      })
      this.isLoading = false;
    } catch(e) {
      console.log(`handleSelectedMedia error ${  JSON.stringify(e)}`);
      this.isLoading = false;
    }
  }

  async getIGAccountMedia(accountId) {
    try {
      const data = await this.props.getIGAccountMedia(accountId);
      const { accounts } = this.props.alert2Props;
      this.setState({ isLoading: false, media: data.media, paging: data.paging, showBackBtn: (accounts.length > 1) });
    } catch (e) {
      console.log('handle error');
      this.setState({ isLoading: false });
    }
  }

  async getUserAlbums() {
    try {
      const albumsData = await this.props.getUserAlbums();
      this.setState({ isLoading: false, albums: albumsData.albums, albumPage: albumsData.albums.paging });
    } catch (e) {
      console.log('handle error');
      this.setState({ isLoading: false });
    }
  }

  async getUserAlbumPhotos(album) {
    try {
      this.state.chosenAlbum = album;
      const photosData = await this.props.getUserAlbumPhotos({ albumId: album.id });
      this.setState({ isLoading: false, photos: photosData.photos, paging: photosData.paging, showBackBtn: true });
    } catch (e) {
      console.log('handle error');
      this.setState({ isLoading: false });
    }
  }

  async uploadSelectedMedia(media, publicId) {
    try {
      if (media.type === mediaType.video) {
        const videoMediaBlobs = [];
        videoMediaBlobs.push(makeABlob(media, publicId, media.source))
        if (media.thumbnail) videoMediaBlobs.push(makeABlob(media, publicId, media.thumbnail || media.source))
        const videoBlobs = await allSettled(videoMediaBlobs)
        const videoFiles = videoBlobs.filter((x) => x.status === "fulfilled");
        const uploadToAWSFiles = [];
        videoFiles.forEach((mediaFile) => {
          if (mediaFile && mediaFile.value && mediaFile.value.file) {
            const { value } = mediaFile;
            uploadToAWSFiles.push(handleUpload(this, value,'ProfileGallery'));
          }
        })
        const s3Responses = await allSettled(uploadToAWSFiles)
        const amazonObjs = {}
        s3Responses.forEach((res) => {
          const key = res.value.fileType.split('/')[0];
          if (res.status === "fulfilled") amazonObjs[key] = res.value;
        });
        if (amazonObjs.video && amazonObjs.image) amazonObjs.video.thumbnail = amazonObjs.image.source;
        else if (amazonObjs.video && !amazonObjs.image) delete amazonObjs.video.thumbnail
        else if (!amazonObjs.video) return {};
//      console.log('s3Responses video successes: ', amazonObjs)
        return {...amazonObjs.video, sourceType: this.state.sourceType};
      }  // selectedObjs.type === mediaType.image
      const imageBlob = await makeABlob(media, publicId, media.source)
      const s3Response = await handleUpload(this, imageBlob,'ProfileGallery')
      // console.log('s3Responses image successes: ', s3Response)
      if (s3Response && s3Response.source) s3Response.thumbnail = s3Response.source
      else return {};
      return {...s3Response, sourceType: this.state.sourceType};
    } catch (e){
      console.log(`uploadSelectedMedia error || ${JSON.stringify(e)}`)
    }
  }

  async goIGNextPage(key) {
    try {
      const { paging, chosenAccount } = this.state;
      this.setState({ isLoading: true });
      const query = { accountId: chosenAccount }
      query[key] = paging[key];
      const data = await this.props.getIGPageMedia(query)
      this.setState({ isLoading: false, media: data.media, paging: data.paging });
    } catch (e) {
      console.log('handle error', e);
      this.setState({ isLoading: false });
    }
  }

  async goFBNextPage(key) {
    try {
      const { paging, chosenAlbum } = this.state;
      this.setState({ isLoading: true });
      const query = { albumId: chosenAlbum.id }
      query[key] = paging[key];
      const photosData = await this.props.getFBPageMedia(query)
      this.setState({ isLoading: false, photos: photosData.photos, paging: photosData.paging });
    } catch (e) {
      console.log('handle error', e);
      this.setState({ isLoading: false });
    }
  }

  async chooseAccount(id) {
    try {
      this.setState({ isLoading: true, showBackBtn: false, chosenAccount: id });
      await this.getIGAccountMedia({ accountId: id });
      this.setState({ isLoading: false, chooseAccount: false });
    } catch (e) {
      this.setState({ isLoading: false, chooseAccount: true });
    }
  }

  cancelFile(id) {
    if (this.state.cancelUpload[id]){
      this.state.cancelUpload[id].cancel()
    } else console.log('none')
  }

  updateProgress(id, percent){
    const track = document.querySelector(`#media_${id} #progressTrack`)
    const number = document.querySelector(`#media_${id} #number`)
    removeClass(`#media_${id} .startUpload`, 'display-none');
    removeClass(`.fbImageCard`, 'selected');
    addClass(`#media_${id} .play`, 'display-none');
    if (track) track.style.width = `${percent}%`;
    if (number) number.innerHTML = `${percent}%`
  }

  selectImage(image, isAllowed) {
    if (this.state.startUpload || (this.state.uploadedIds.indexOf(image.id) !== -1) || !isAllowed) return;
    let { selectedImagesIds, selectedImagesKV, isMulti } = this.state;
    if (selectedImagesIds.indexOf(image.id) === -1) {
      if (!isMulti && selectedImagesIds.length > 0) return;
      selectedImagesIds.push(image.id);
      selectedImagesKV[image.id] = image;
    } else if (!isMulti && selectedImagesIds.indexOf(image._id) === -1) {
      selectedImagesIds = [image._id]
      selectedImagesKV = {};
      selectedImagesKV[image._id] = image;
    } else {
      selectedImagesIds.splice(selectedImagesIds.indexOf(image.id), 1);
      delete selectedImagesKV[image.id];
    }
    // console.log('selectImage: ', JSON.stringify(selectedImagesKV))
    this.setState({ selectedImagesIds, selectedImagesKV });
  }

  goBack() {
    const { isFacebook } = this.props.alert2Props;
    if (isFacebook) this.setState({ chosenAlbum: null, showBackBtn: false });
    else this.setState({ chooseAccount: true, showBackBtn: false });
  }

  toggle() {
    this.props.hideAlert2();
  }

  getAllowedMediaText() {
    const { allowImages, allowVideos } = this.state;
    if (allowImages && !allowVideos) return 'allowedImagesOnly'
    return 'allowedVideosOnly'
  }

  renderImage (image, thumbnail) {
    const { uploadedIds, startUpload, selectedImagesIds, allowImages, allowVideos } = this.state;
    let isAllowed = false;
    if ((allowImages && !allowVideos && image.type === mediaType.image) || (!allowImages && allowVideos && image.type === mediaType.video) ||
      (allowImages && !allowVideos && image.type === 'carousel_album') || (allowImages && allowVideos && image.type === 'carousel_album') ||
      (allowImages && allowVideos && image.type === mediaType.image) || (allowImages && allowVideos && image.type === mediaType.video)) {
      isAllowed = true
    }
    if (image.type === mediaType.video && !image.source) return null;
    return (
      <div key={image.id} className={`fbImageCard flex-initial layout-row layout-wrap layout-align-start-start pl-md-3 pl-xs-1 ${selectedImagesIds.indexOf(image.id) !== -1 ? 'selected' : ''} ${isAllowed ? '' : 'notAllowed'}`}>
        <Image
          uploadedSuccessfully={(uploadedIds && uploadedIds.length > 0 && uploadedIds.indexOf(image.id) !== -1)}
          id={image.id}
          startUpload={startUpload}
          thumbnail={thumbnail}
          type={image.type === mediaType.video ? mediaType.video : mediaType.image}
          onClick={() => {this.selectImage(image, isAllowed);}}
          onCancel={() => {this.cancelFile(image.id);}}
          isSelected={selectedImagesIds.indexOf(image.id) !== -1} />
      </div>
    );
  }

  renderAlbum(album) {
    return (
      <div key={album.id} className="flex-gt-md-20 flex-md-33 flex-sm-50 flex-xs-50 layout-row layout-wrap layout-align-start-start pl-3 pl-md-3 pl-xs-2">
        <Album
          thumbnail={album.cover_photo.source}
          name={album.name}
          count={album.count}
          onClick={() => this.getUserAlbumPhotos(album)} />
      </div>
    );
  }

  renderAccount(account) {
    return (
      <Button key={account.id}
        color="transparent"
        onClick={() => this.chooseAccount(account.id)}
        className="flex layout-row layout-wrap layout-align-center center">
        <i className="simple-icon-social-instagram mb-1 font32" />
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <p className="mb-2 flex-100 fontWeight600 mb-2">{account.pageName}</p>
        </div>
      </Button>
    );
  }

  renderTitle() {
    const { isFacebook, isInstagram } = this.props.alert2Props;
    const { showBackBtn, chosenAlbum } = this.state;
    return (
      <div className={`flex-100 layout-row layout-wrap layout-align-space-between-center borderRadius5 top p-3 ${ isFacebook ? 'facebookBg' : 'instagramBg'}`}>
        <div className="flex-initial layout-row layout-align-start-start text-uppercase">
          { isFacebook && <p className="fontWeight800 mb-0 whiteColor font16 flex-initial"><Trans i18nKey="facebook" /></p> }
          { isInstagram && <p className="fontWeight800 mb-0 whiteColor font16 flex-initial"><Trans i18nKey="instagram" /></p> }
          { isFacebook && !chosenAlbum && (
          <p className="fontWeight600 mb-0 whiteColor font16 flex-initial pl-2">
            {' - '}
            <Trans i18nKey="albums" />
          </p>
          )}
          { isFacebook && chosenAlbum && (
          <p className="fontWeight600 mb-0 whiteColor font16 flex-initial pl-2">
            {' - '}
            {`"${chosenAlbum.name}"`}
          </p>
          )}
          { isInstagram && (
          <p className="fontWeight600 mb-0 whiteColor font16 flex-initial pl-2">
            {' - '}
            <Trans i18nKey="photos" />
          </p>
          )}
        </div>
        <div className="flex-initial layout-row layout-wrap layout-align-start-start text-uppercase">
          { showBackBtn && <p className="fontWeight600 mb-0 whiteColor font16 flex-initial cursorPointer" onClick={() => { this.goBack()}}><Trans i18nKey="back" /></p> }
          { !showBackBtn && <p className="fontWeight600 mb-0 whiteColor font16 flex-initial cursorPointer" onClick={() => { this.toggle()}}><Trans i18nKey="close" /></p> }
        </div>
      </div>
    )
  }

  facebookModal() {
    const { albums, photos, chosenAlbum, uploadBtnText, startUpload, paging, selectedImagesIds } = this.state;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-center-center minHeight150">
        <div className="flex-100 layout-row layout-wrap layout-align-center-center">
          { !chosenAlbum && albums && albums.length > 0 && albums.map((album) => this.renderAlbum(album))}
          { chosenAlbum && (
            <div className="flex-100 layout-row layout-wrap layout-align-center-center pb-md-3 pb-xs-5">
              { photos && photos.length > 0 ? (
                <div className="flex-100 layout-row layout-wrap layout-align-center-center pb-md-3 pb-xs-5">
                  { photos.map((mediaObj) => this.renderImage(mediaObj, mediaObj.thumbnail))}
                </div>
              ) : (
                <div className="flex-100 layout-row layout-wrap layout-align-center-center pb-md-3 pb-xs-5">
                  <p className="font20 fontWeight600 text-muted"><Trans i18nKey="noResultsFound" /></p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-center">
          <div className="flex-initial">
            { false && paging && paging.previous && (
              <button
                type="button"
                className="page-link"
                onClick={() => this.goFBNextPage('before')}>
                <i className="simple-icon-arrow-left fontWeight600" />
              </button>
            )}
          </div>
          <div className="flex-initial">
            { false && paging && paging.next && (
              <button
                type="button"
                className="page-link next"
                onClick={() => this.goFBNextPage('after')}>
                <i className="simple-icon-arrow-right fontWeight600" />
              </button>
            )}
          </div>
        </div>
        { chosenAlbum && selectedImagesIds.length > 0 && (
          <div className="layout-row layout-wrap layout-align-center-center positionFixed pb-md-3 pb-xs-2" style={{bottom: 0}}>
            <button type="button"
              onClick={() => {this.handleSelectedMedia()}}
              className="flex-initial layout-row btn-shadow layout-align-center-center roundButton bottomUpAnimation btn btn-secondary sp-md-3 sp-xs-2 fontWeight600 text-uppercase">
              <Trans i18nKey={uploadBtnText} />
              { startUpload && (<div className="flex-initial"><LoadingDots active /></div>)}
            </button>
          </div>
        )}
      </div>
    )
  }

  instagramModal(){
    const { media, uploadBtnText, startUpload, chooseAccount, paging, selectedImagesIds } = this.state;
    const { accounts } = this.props.alert2Props;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-center-center minHeight150">
        <div className="flex-100 layout-row layout-wrap layout-align-center-center">
          { chooseAccount && accounts && accounts.length > 0 && accounts.map((account) => this.renderAccount(account))}
          { !chooseAccount && (
            <div className="flex-100 layout-row layout-wrap layout-align-center-center pb-md-3 pb-xs-5">
              { media && media.length > 0 ? media.map((mediaObj) => this.renderImage(mediaObj, mediaObj.thumbnail || mediaObj.source)) :
                (
                  <div className="flex-100 layout-row layout-wrap layout-align-center-center pb-md-3 pb-xs-5">
                    <p className="font20 fontWeight600 text-muted"><Trans i18nKey="noResultsFound" /></p>
                  </div>
                )}
            </div>
          )}
        </div>
        <div className="flex-100 layout-row layout-wrap layout-align-space-between-center">
          <div className="flex-initial">
            { paging && paging.previous && (
              <button
                type="button"
                className="page-link"
                onClick={() => this.goIGNextPage('before')}>
                <i className="simple-icon-arrow-left fontWeight600" />
              </button>
            )}
          </div>
          <div className="flex-initial">
            { paging && paging.next && (
              <button
                type="button"
                className="page-link next"
                onClick={() => this.goIGNextPage('after')}>
                <i className="simple-icon-arrow-right fontWeight600" />
              </button>
            )}
          </div>
        </div>
        { !chooseAccount && media && media.length > 0 && selectedImagesIds.length > 0 && (
          <div className="layout-row layout-wrap layout-align-center-center positionFixed pb-md-3 pb-xs-2" style={{bottom: 0}}>
            <button type="button"
              onClick={() => {this.handleSelectedMedia()}}
              className="flex-initial layout-row btn-shadow layout-align-center-center roundButton bottomUpAnimation btn btn-primary sp-md-3 sp-xs-2 fontWeight600 text-uppercase">
              <Trans i18nKey={uploadBtnText} />
              { startUpload && (<div className="flex-initial"><LoadingDots active /></div>)}
            </button>
          </div>
        )}
      </div>
    )
  }

  renderContent(){
    const { isFacebook } = this.props.alert2Props;
    if (isFacebook) return this.facebookModal();
    return this.instagramModal()
  }

  render() {
    const { isLoading, allowImages, allowVideos } = this.state;
    const title = this.renderTitle()
    const content = this.renderContent()
    return (
      <Modal isOpen toggle={() => this.toggle()} backdrop className="socialModal">
        <Fragment>
          { title }
          { (!allowImages || !allowVideos) && (
            <div className="flex-100 layout-row layout-wrap layout-align-center-center">
              <p className="font13 text-muted mb-1 mt-2"><Trans i18nKey={this.getAllowedMediaText()} /></p>
            </div>
          )}
          <ModalBody className="GetSocialMediaModal width100 layout-row layout-wrap layout-align-start-start overFlowContainer">
            { !isLoading ? content : (
              <div className="flex-100 layout-row layout-wrap layout-align-start-start height200px position-relative">
                <div className="gogo-loading" />
              </div>
            )}
          </ModalBody>
        </Fragment>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  alert2Props: state.appR.alert2Props,
});

const mapDispatchToProps = dispatch => ({
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
  getIGAccountMedia: (account) => dispatch(getIGAccountMedia(account)),
  getUserAlbums: () => dispatch(getUserAlbums()),
  getUserAlbumPhotos: (albumId) => dispatch(getUserAlbumPhotos(albumId)),
  getIGPageMedia: (query) => dispatch(getIGPageMedia(query)),
  getFBPageMedia: (query) => dispatch(getFBPageMedia(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetSocialMediaModal);
