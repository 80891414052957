/* eslint-disable max-len */
import React from 'react';
import './StripExercise.css';

const StripExercisePlaceHolder = props => (
  <div className={`StripExercise placeHolderContainer positionRelative ${props.className || 'flex-100 layout-row layout-align-start-center'}`}>
    {props.editMode && (
      <div className="flex-initial layout-row layout-wrap layout-align-start-center checkBox">
        <div className="checkbox-container">
          <input type="checkbox" />
        </div>
      </div>
    )}
    <div className="flex layout-row layout-wrap layout-align-start-center positionRelative">
      <div className="flex-initial layout-row layout-wrap layout-align-start-start exerciseImages two ">
        <div className={`flex-initial layout-row layout-wrap layout-align-start-start exercise-Thumbnail positionRelative loading  borderRadius3 ${props.loadingClass ? props.loadingClass : ''}`} />
      </div>
      <div className="flex layout-column layout-wrap layout-align-start-start sidePadd20px">
        <label className="fontWeight600 propsName font15 textLimitHeight borderRadius3">
          <span className={`flex-initial layout-row layout-wrap layout-align-start-start commentPlaceHolder positionRelative loading ${props.loadingClass ? props.loadingClass : ''}`} />
        </label>
        <label className="fontWeight600 propsName font15 textLimitHeight borderRadius3 ">
          <span className={`flex-initial layout-row layout-wrap layout-align-start-start titlePlaceHolder positionRelative loading ${props.loadingClass ? props.loadingClass : ''}`} />
        </label>
      </div>
      <div className="flex-initial layout-row layout-wrap layout-align-end-center" />
    </div>
  </div>
);

export default StripExercisePlaceHolder;
