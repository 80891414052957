import nanoid from 'nanoid';
import fitnessTracker from '../assets/onBoarding/undraw_fitness_tracker_3033.svg';
import chooseCertificate from '../assets/onBoarding/choose_certificate.svg';
import welcomeAspire from '../assets/onBoarding/welcom_to_aspire.png';
import uploadFiles from '../assets/onBoarding/upload_files.svg';

const survey = {
  _id: nanoid(10),
  public_id: 'SRVY-12345678',
  title: { en: 'Survey Test Title', he: 'בדיקה כותרת לשאלון' },
  description: { en: 'Survey Test Description about our first survey in the app', he: 'בדיקת הסבר קצר לשאלון לפני שנתחיל עם מספר שאלות.' },
  thumbnail: fitnessTracker,
  type: 'public',
  questions: [
    {
      _id: nanoid(10),
      backgroundColor: 'rgb(236, 237, 254)',
      thumbnail: {
        uri: welcomeAspire,
        className: ['mb-3', 'svg90p', 'ReactSVG', 'height220px', 'text-center']
      },
      question: { en: 'Please wrote your name here', he: 'אנא רשום את שמך' },
      options: [
        {
          _id: nanoid(10),
          order: 1,
          elementType: 'input',
          type: 'text',
          validation: {
            minLength: 3
          },
          label: {
            en: 'Full Name',
            he: 'שם מלא'
          },
          value: null
        }
      ]
    },
    {
      _id: nanoid(10),
      backgroundColor: 'rgb(254, 236, 245)',
      thumbnail: {
        uri: chooseCertificate,
        className: ['mb-4', 'svg90p', 'ReactSVG', 'height220px', 'text-center']
      },
      question: { en: 'Please choose your profession(s)', he: 'אנא בחר הכשרה אחת לפחות' },
      comment: {
        en: 'remember, for each selection you\'ll be asked to upload the appropriate certificate.',
        he: 'שים לב, לכל בחירת הכשרה תצטרך להעלות את המסמך המיועד המאשר את הכשרתך.'
      },
      isRequired: true,
      options: [
        {
          _id: nanoid(10),
          order: 1,
          isSelected: false,
          elementType: 'checkBox',
          label: {
            en: 'Personal Trainer',
            he: 'מאמנ/ת כושר אישי'
          },
          value: 'PT'
        },
        {
          _id: nanoid(10),
          order: 2,
          isSelected: false,
          elementType: 'checkBox',
          label: {
            en: 'Nutritionist',
            he: 'תזונאי/ת'
          },
          value: 'NT'
        },
        {
          _id: nanoid(10),
          order: 3,
          isSelected: false,
          elementType: 'checkBox',
          label: {
            en: 'Physiotherapist',
            he: 'פיזיותרפיסט/ית'
          },
          value: 'PST'
        }]
    },
    {
      _id: nanoid(10),
      backgroundColor: 'rgb(255, 244, 236)',
      thumbnail: {
        uri: uploadFiles,
        className: ['mb-4', 'svg90p', 'ReactSVG', 'height220px', 'text-center']
      },
      question: { en: 'have you heard about "Aspire" from a friend?', he: 'האם שמעת מחבר על האפליקציה?' },
      comment: { en: 'if so, please explain.', he: 'אם כן, אנא פרט' },
      isRequired: false,
      group: 'grouping',
      options: [
        {
          _id: nanoid(10),
          order: 1,
          isSelected: false,
          elementType: 'radio',
          label: {
            en: 'No',
            he: 'לא'
          },
          value: false
        },
        {
          _id: nanoid(10),
          order: 2,
          isSelected: false,
          elementType: 'radio',
          label: {
            en: 'Yes',
            he: 'כן'
          },
          value: true,
          condition: true,
          content: ''
        }
      ]
    }],
  lastUpdated: new Date()
};

const welcomeTrainerPageData = {
  _id: nanoid(4),
  type: 'public',
  questions: [
    {
      _id: nanoid(4),
      backgroundColor: 'rgb(254, 236, 245)',
      thumbnail: {
        uri: chooseCertificate,
        className: ['mb-4', 'svg90p', 'ReactSVG', 'height220px', 'text-center']
      },
      question: { en: 'Please choose your profession your name here', he: 'אנא בחר הכשרה אחת לפחות' },
      comment: {
        en: 'Remember, for each profession you choose, you\'ll eventually need to upload your licensing certificate.',
        he: 'שים לב, לכל בחירת הכשרה תצטרך להעלות את המסמך המיועד המאשר את הכשרתך.'
      },
      isRequired: true,
      options: [
        {
          _id: nanoid(10),
          order: 1,
          isSelected: false,
          elementType: 'checkBox',
          label: {
            en: 'Personal Trainer',
            he: 'מאמנ/ת כושר אישי'
          },
          value: 'PT'
        },
        {
          _id: nanoid(10),
          order: 2,
          isSelected: false,
          elementType: 'checkBox',
          label: {
            en: 'Nutritionist',
            he: 'תזונאי/ת'
          },
          value: 'NT'
        },
        {
          _id: nanoid(10),
          order: 3,
          isSelected: false,
          elementType: 'checkBox',
          label: {
            en: 'Physiotherapist',
            he: 'פיזיותרפיסט/ית'
          },
          value: 'PST'
        }
      ]
    }
  ],
  lastUpdated: new Date()
};
export { survey, welcomeTrainerPageData };
