import { actionTypes, exercisesTypes } from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse, setDynamicSearchResults } from './index';

const setExercises = payload => ({
  type: exercisesTypes.SET_POPULAR_EXERCISES,
  payload
});

const setExercisesFilter = payload => ({
  type: exercisesTypes.SET_EXERCISE_FILTERS,
  payload
});

const setMyExercises = payload => ({
  type: exercisesTypes.SET_MY_EXERCISES,
  payload
});

const setExercisesEquipments = payload => ({
  type: exercisesTypes.SET_EXERCISES_EQUIPMENTS,
  payload
});

export const updateExerciseList = payload => ({
  type: exercisesTypes.UPDATE_EXERCISE_LIST,
  payload
});

export const handleExercises = err => ({
  type: actionTypes.HANDLE_ERROR_RESPONSE,
  payload: err
});

const setExerciseDetails = payload => ({
  type: exercisesTypes.SET_EXERCISE_DETAILS,
  payload
});

export const dispatchFilteredExercises = (body, data) => async (dispatch) => {
  try {
    if (body.isPersonal) {
      const payload = { myExerciseIds: data.Ids, myExerciseList: data.keyValue };
      dispatch(setMyExercises(payload));
    } else {
      const payload = { exerciseIds: data.Ids, exerciseList: data.keyValue };
      dispatch(setExercises(payload));
    }
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getFilteredExercises = body => async (dispatch) => {
  try {
    const data = await apiMethods.exercises.getFilteredExercises(body);
    if (body.isPersonal) {
      const payload = { myExerciseIds: data.Ids, myExerciseList: data.keyValue };
      dispatch(setMyExercises(payload));
      return payload;
    } else {
      const payload = { exerciseIds: data.Ids, exerciseList: data.keyValue };
      dispatch(setExercises(payload));
      return payload;
    }
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getPopularExercises = (queryObj) => async (dispatch) => {
  try {
    const query = utils.buildQuery(queryObj);
    const data = await apiMethods.exercises.getPopularExercises(query);
    const payload = { exerciseIds: data.Ids, exerciseList: data.keyValue };
    dispatch(setExercises(payload));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const filterExercisesByCategory = body => async (dispatch) => {
  try {
    if (body.category && body.category.length > 0) {
      const query = utils.buildQuery(body);
      const data = await apiMethods.exercises.filterExercisesByCategory(query);
      const payload = { exerciseIds: data.Ids, exerciseList: data.keyValue };
      dispatch(setExercises(payload));
    } else {
      return false;
    }
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getExerciseDetails = publicId => async (dispatch) => {
  try {
    let body = publicId;
    if (!Array.isArray(publicId)) body = [publicId];
    const data = await apiMethods.exercises.getExercise(body);
    dispatch(setExerciseDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getExerciseByPublicId = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.exercises.getExerciseByPublicId(publicId);
    dispatch(setExerciseDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getLatestStatsOfExercise = queryObj => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    return await apiMethods.exercises.getLatestStatsOfExercise(query);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return null;
  }
};

export const getExercisesFilter = (query = {}, isDynamicSearchFilter) => async (dispatch) => {
  try {
    const body = { group: [1, 6, 2], isFilter: true }; // 1 - muscles , 6 - equipment , 2 - exerciseType
    const data = await apiMethods.categories.getCategoriesByGroup(body);
    if (isDynamicSearchFilter) {
      dispatch(setDynamicSearchResults(data));
    } else {
      dispatch(setExercisesFilter(data));
    }
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getExercisesCategories = (query, isDynamicSearchFilter) => async (dispatch) => {
  try {
    const data = await apiMethods.categories.getCategoriesByGroup(query);
    if (isDynamicSearchFilter) {
      dispatch(setDynamicSearchResults(data));
    } else {
      dispatch(setExercisesFilter(data));
    }
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getMyExercises = query => async (dispatch) => {
  try {
    const data = await apiMethods.exercises.getMyExercises(query);
    const payload = { myExerciseIds: data.Ids, myExerciseList: data.keyValue };
    dispatch(setMyExercises(payload));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getExercisesEquipments = query => async (dispatch) => {
  try {
    const data = await apiMethods.exercises.getExercisesEquipments(query);
    dispatch(setExercisesEquipments(data));
    return !!data.equipments;
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const createMyExercise = body => async (dispatch) => {
  try {
    const data = await apiMethods.exercises.create(body);
    dispatch(setExerciseDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const editMyExercise = body => async (dispatch) => {
  try {
    const data = await apiMethods.exercises.edit(body);
    dispatch(setExerciseDetails(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeMyExercise = publicId => async (dispatch) => {
  try {
    return await apiMethods.exercises.remove([publicId]);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const saveMediaToEquipment = body => async (dispatch) => {
  try {
    await apiMethods.exercises.saveMediaToEquipment(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeEquipmentMedia = body => async (dispatch) => {
  try {
    await apiMethods.exercises.removeEquipmentMedia(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
