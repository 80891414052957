import { actionTypes } from '../actions/actionTypes';
import { storeUtils } from '../../services/utilsService';

const initialState = {
  achievements: {}
  // newAchievement: null
  // achievementsKV {}
  // achievementsIds []
  // achievementData {}
};

const achievementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEW_ACHIEVEMENT:
      return { ...state, newAchievement: action.payload };
    case actionTypes.SET_ACHIEVEMENTS:
      return { ...state, achievementsKV: action.payload.achievementsKV, achievementsIds: action.payload.achievementsIds };
    case actionTypes.SET_ACTIVE_ACHIEVEMENTS:
      return { ...state, activeAchieveKV: action.payload.achievementsKV, activeAchieveIds: action.payload.achievementsIds };
    case actionTypes.SET_COMPLETED_ACHIEVEMENTS:
      return { ...state, completedAchieveKV: action.payload.achievementsKV, completedAchieveIds: action.payload.achievementsIds };

    case actionTypes.SET_ACHIEVEMENT_DATA:
      return {
        ...state,
        achievementData: action.payload
      };
    case actionTypes.CLEAR_ACHIEVEMENT_DATA:
      return {
        ...state,
        newAchievement: null,
        achievementData: null
      };
    case actionTypes.REMOVE_ACHIEVEMENT: {
      const achievementsKV = storeUtils.removeFromObjectInStore(state.achievementsKV, action.payload);
      const achievementsIds = storeUtils.removeFromArrayInStore(state.achievementsIds, action.payload);
      return { ...state, achievementsIds, achievementsKV };
    }
    default:
      return state;
  }
};

export default achievementReducer;
