import React, { Component, Fragment }  from "react";
import Lightbox from 'react-image-lightbox';
import { styleUtils } from '../../services/utilsService';
import { aspirePlaceholder } from '../../services/enums';
import Image from './Image';

class SingleLightbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
          photoIndex: this.props.index || 0,
          isOpen: false
        };
      }

      onThumbClick() {
        const { images, large } = this.props;
        let index = 0
        if (images && images.length > 0 ){
          index =  images.indexOf(large)
        }
        this.setState({ photoIndex: index, isOpen: true });
      }

    render() {
      const { isOpen, photoIndex} = this.state;
      const { images, large, height, thumb, fallbackThumbnail } = this.props;
       return (
         <Fragment>
           <a onClick={() => this.onThumbClick()} className={`SingleLightbox ${this.props.navClass}`}>
             <Image
               backgroundImage
               src={thumb}
               fallbackSrc={fallbackThumbnail}
               height={height}
               className={`backgroundImage layout-row layout-wrap layout-align-start-start borderRadius ${this.props.className || ''}`}
               style={{...styleUtils.imageBackground((this.props.thumb || this.props.backUpImage || this.state.thumbnail), 'rgba(0, 0, 0, 0.6)', 'rgba(0, 0, 0, 0.3)', null, 0, aspirePlaceholder[0]), height: `${this.props.height}px`}}
               color1="rgba(0, 0, 0, 0.6)"
               color2="rgba(0, 0, 0, 0.3)"
               color3={null}
               backgroundProps={{height: `${height}px`}}
             />
{/*
             <div
               className={`backgroundImage layout-row layout-wrap layout-align-start-start borderRadius ${this.props.className || ''}`}
               style={{...styleUtils.imageBackground((this.props.thumb || this.props.backUpImage || this.state.thumbnail), 'rgba(0, 0, 0, 0.6)', 'rgba(0, 0, 0, 0.3)', null, 0, aspirePlaceholder[0]), height: `${this.props.height}px`}} />
*/}
           </a>
           { isOpen && (images && images.length > 0) && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({ photoIndex: (photoIndex + images.length - 1) % images.length })}
                onMoveNextRequest={() =>
                  this.setState({ photoIndex: (photoIndex + 1) % images.length })} />
            )}
           { isOpen && !(images && images.length > 0) && (
             <Lightbox mainSrc={large} onCloseRequest={() => this.setState({ isOpen: false })} />
           )}
         </Fragment>
        );
    }
}

export default SingleLightbox;
