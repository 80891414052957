/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import { uniq } from 'lodash-es';
import { copyWorkoutPlan, getAllWorkoutPageData } from '../../store/actions';
import { addClass, removeClass } from '../../services/utilsService';
import { actionTypes } from '../../store/actions/actionTypes';
import Input from '../../Components/UI/Input';
import AddDescriptionToggle from '../../Components/UI/AddDescriptionToggle';
import ClientListWrapper from '../../Components/ClientList/ClientListWrapper';
import BtnLoader from '../../Components/UI/BtnLoader';
import ProgramsToAssign from '../../Components/SquareCard/ProgramsToAssign';
import '../Modals/CongratulationsPage.css';
import './CopyPlanPage.css';

class CopyPlanPage extends Component {
  constructor (props) {
    super(props);
    const data = this.props.workoutPlan;
    this.state = {
      public_id: (data && data.public_id) || null,
      title: (data && data.title) || null,
      description: (data && data.description) || null,
      allDay: (data && data.allDay) || false,
      thumbnail: (data && data.thumbnail) || null,
      chosenClients: [],
      selectedRoutines: [],
      advancedOptions: !!(this.props.alertProps && this.props.alertProps.editMode),
      error: {
        selectedRoutines: false,
        selectClients: false,
      }
    };
  }

  closeModal () {
    this.props.hideAlertBtn();
  }

  async componentDidMount() {
    const programs = this.props.planPrograms && this.props.planPrograms.length > 0 ? this.props.planPrograms : [this.props.workoutProgramData];
    if (programs && programs.length > 0) {
      this.state.selectedRoutines = programs.map((program) => program.public_id);
    }
    this.setState({ isPageReady: true })
  }

  handleChange (e, key) {
    this.state.hasChanged = true;
    if (e && e.target && (e.target.type === 'checkbox' || e.target.type === 'radio')) {
      this.state[key] = e.target.checked;
    } else if (e && e.target) {
      this.state[key] = (e.target.type === 'number') ? Number(e.currentTarget.value) : e.currentTarget.value;
    } else {
      this.state[key] = e; // value
    }
  }

  handleProgramCheck(program, isChecked) {
    let { selectedRoutines } = this.state;
    if (isChecked) selectedRoutines.push(program.public_id)
    else selectedRoutines.splice(selectedRoutines.indexOf(program.public_id), 1);
    selectedRoutines = uniq(selectedRoutines);
    this.setState({ selectedRoutines });
  }

  checkAllDayEvent(e) {
    this.state.hasChanged = true;
    this.state.allDay = e.target.checked;
  }

  handleClientSelected (users) {
    this.state.chosenClients = users;
    this.state.hasChanged = true;
  }

  validateElements() {
    const { title, chosenClients, selectedRoutines } = this.state;
    let isValid = true;
    // const errors = [];
    if (!title || (title === "")) {
      addClass(`input[label="title"]`, 'invalid-input')
      isValid = false;
    } else { removeClass(`input[label="title"]`, 'invalid-input') }
    if (chosenClients && chosenClients.length === 0) {
      // errors.push(langs[this.props.locale].pleaseChooseClient);
      this.state.error.selectClients = true;
      isValid = false;
    } else { this.state.error.selectClients = false; }
    if (selectedRoutines && selectedRoutines.length === 0) {
      // errors.push(langs[this.props.locale].pleaseSelectRoutine);
      this.state.error.selectedRoutines = true;
      isValid = false;
    } else { this.state.error.selectedRoutines = false; }
    return isValid;
  }

  async saveChanges () {
    if (this.isLoading) { return; }
    this.isLoading = true;
    try {
      const { title, description, chosenClients, thumbnail, selectedRoutines } = this.state;
      if (this.validateElements()) {
        const plan = { title, description, user: chosenClients, thumbnail };
        const newPlan = await this.props.copyWorkoutPlan({ plan, programIds: selectedRoutines });
        ReactGA.event({ category: 'Workout Plan', action: 'Workout Plan Copied' });
        const query = { isBasic: true, limit: 24 };
        await this.props.getAllWorkoutPageData(query);
        this.isLoading = false;
        this.closeModal();
      } else {
        removeClass('.CopyPlanPage #BtnLoader.BtnLoader', 'isLoading')
        this.isLoading = false;
        this.setState({ ...this.state });
      }
    } catch (e) {
      removeClass('.CopyPlanPage #BtnLoader.BtnLoader', 'isLoading')
      this.isLoading = false;
      ReactGA.exception({
        description: 'An error occurred on saveChanges() on CopyPlanPage',
        fatal: true
      });
    }
  }

  render () {
    const { isPageReady, title, description, advancedOptions, selectedRoutines, error } = this.state;
    const programs = this.props.planPrograms && this.props.planPrograms.length > 0 ? this.props.planPrograms : [this.props.workoutProgramData];
    return (
      <Modal isOpen toggle={() => this.props.hideAlertBtn()} backdrop="static" className={`AlertModal modalCenterScreen CopyPlanPage ${ advancedOptions ? 'advancedOptions' : ''}`}>
        <div className="ExerciseDetailsPage flex-100 layout-row layout-wrap layout-align-start-start content-start ">
          <ModalHeader className="flex-100 layout-row layout-align-center-center p-2">
            <div className="flex-100 layout-row layout-wrap layout-align-start-start">
              <lable className="flex-100 fontWeight600 text-uppercase font15 titleEvent">
                <Trans i18nKey="copyProgram" />
              </lable>
            </div>
          </ModalHeader>
          <ModalBody className="width100 layout-row layout-wrap layout-align-start-start p-2">
            { isPageReady && (
              <div className="flex-100 layout-row layout-wrap layout-align-center-center content-start positionRelative">
                <div className="flex-100 layout-row layout-wrap layout-align-start-start content-start">
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start mb-2">
                    <ClientListWrapper
                      title={error.selectClients ? 'pleaseChooseClient' : false}
                      titleClassName={error.selectClients ? 'colorRed' : ''}
                      assignMode
                      chosenClients={this.props.workoutPlan && this.props.workoutPlan.user ? this.props.workoutPlan.user : []}
                      usersAssigned={(this.props.workoutPlan && this.props.workoutPlan.usersAssigned) || this.props.workoutPlan.user}
                      showTrainer
                      horizontalBar
                      chooseUser={(clients) => this.handleClientSelected(clients)} />
                  </div>
                  <div className="flex-100 layout-row layout-wrap layout-align-start-start sp-3">
                    <Input
                      name="title"
                      label="title"
                      placeholder="writeATitle"
                      initValue={title || ''}
                      type="text"
                      elementType="input"
                      validation={{ required: true, minLength: 2 }}
                      classes="flex-100"
                      locale={this.props.locale}
                      className="width100 mb-2"
                      handleChange={(event, key) => { this.handleChange(event, key); }} />
                    <AddDescriptionToggle
                      rows={3}
                      handleChange={(event, key) => { this.handleChange(event, key); }}
                      locale={this.props.locale}
                      initValue={description}
                      className="mb-2" />
                  </div>
                  <div className="flex-100 layout-row layout-wrap layout-align-start-center ">
                    <div className="flex-100 layout-row layout-align-start-center sp-3">
                      <label className={`flex-initial text-capitalize fontWeight600 ${error.selectedRoutines ? 'colorRed' : '' }`}><Trans i18nKey={error.selectedRoutines ? 'pleaseSelectRoutine' : 'programs'} /></label>
                    </div>
                    <div className="flex-100 layout-row layout-wrap layout-align-start-center p-2" style={{maxHeight: '200px', overflow: 'auto'}}>
                      { programs && programs.map(program => (
                        <div key={program.public_id} className="flex-100 layout-row layout-wrap layout-align-start-start fadeIn-anime">
                          <ProgramsToAssign program={program} locale={this.props.locale} isChecked={selectedRoutines.indexOf(program.public_id) !== -1} checkProgram={(isChecked) => { this.handleProgramCheck(program, isChecked); }} />
                        </div>
                       ))}
                    </div>
                  </div>
                </div>
              </div>
          )}
          </ModalBody>
          <div className="flex-100 layout-row layout-wrap layout-align-center-center">
            <div className="flex-100 layout-row layout-wrap layout-align-center-center bottomBtns">
              <button type="button" className="flex btn-transparent layout-row layout-wrap layout-align-center-center fontWeight600 text-uppercase font15 p-3" onClick={() => this.closeModal()}>
                <Trans i18nKey="cancel" />
              </button>
              <BtnLoader containerClassName="flex layout-row layout-wrap layout-align-center-center"
                         className="btn-transparent flex-100 btn-transparent fontWeight600 text-uppercase font15 p-3 alertBtnBg"
                         type="button"
                         btnText="confirm"
                         btnClicked={() => this.saveChanges()} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  trainerInfo: state.userR.trainerInfo,
  workoutPlan: state.workoutR.workoutPlanData,
  workoutProgramData: state.workoutR.workoutProgramData,
  planPrograms: state.workoutR.planPrograms,
  locale: state.appR.locale,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  copyWorkoutPlan: data => dispatch(copyWorkoutPlan(data)),
  getAllWorkoutPageData: query => dispatch(getAllWorkoutPageData(query)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyPlanPage);
