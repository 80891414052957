import localStorage from 'local-storage';
import { placeholderBlue, placeholderGray } from '../constants/assetsUrls';

export const mediaType = {
  image: 'image',
  video: 'video',
  url: 'url'
};

export const mediaGroup = {
  muscle: 'muscle',
  exercise: 'exercise', // exercise image
  certifications: 'certifications',
  location: 'location',
  food: 'food',
  equipment: 'equipment', // barbell dumbbell machine smith machine cable body weight kettlebell TRX
  bodyStats: 'bodyStats',
  sessions: 'sessions',
  profile: 'profile',
  articles: 'articles',
  achievements: 'achievements',
  aspire: 'aspire',
  aspireExercise: 'aspireExercise', // exercise image
  aspireInformation: 'aspireInformation', // exercise image
  signature: 'signature',
  other: 'other'
};

export const sourceType = {
  amazonS3: 'amazonS3',
  cloudinary: 'cloudinary',
  instagram: 'instagram',
  facebook: 'facebook',
  vimeo: 'vimeo',
  youtube: 'youtube',
  dailymotion: 'dailymotion',
  url: 'url'
};

export const workoutLogTypes = {
  straight: 'straight',
  superset: 'superset',
  circuit: 'circuit',
  aerobic: 'aerobic' // AEROBIC
};

export const categoryGroups = {
  muscles: 1,
  exerciseType: 2, // bodyBuilding, Yoga, StreetWO, powerLifting, Stretch
  certifications: 3,
  locations: 4,
  foodGroups: 5,
  equipment: 6, // Barbell, Cable, Dumbbell, Smith, BodyWeight, TRX,
  infoSubjects: 8
};

export const allowedFieldsByWorkoutTypes = {
  straight: {
    reps: true,
    weight: true,
    time: false
  },
  superset: {
    reps: true,
    weight: true,
    time: false
  },
  aerobic: {
    time: true,
    intensity: true,
    speed: true,
    distance: true,
    weight: false
  }
};

export const workoutLogTypesButtons = [
  {
    title: {
      he: 'רגיל',
      en: 'Normal'
    },
    isChosen: true,
    type: 'straight'
  },
  {
    title: {
      he: 'סופר סט',
      en: 'Super Set'
    },
    isChosen: false,
    type: 'superset'
  }/*,
  {
    title: {
      he: 'אימון מחזורי',
      en: 'Circuit'
    },
    isChosen: false,
    type: 'CIRCUIT'
  } */
];

export const units = {
  weightUnits: {
    KG: 'KG', // KG
    LBS: 'LBS', // LBS
    T: 'T' // TON
  },
  distanceUnits: {
    CM: 'CM', // CM
    M: 'M', // M
    KM: 'KM', // KM
    INCH: 'INCH', // INCH
    FT: 'FT', // FEET
    YARD: 'YD', // YARD
    MILE: 'MILE' // MILE
  },
  other: {
    PERCENT: 'PERCENT',
    KCAL: 'KCAL',
    BMI: 'BMI',
    TIME: '',
    BLANK: ''
  }
};

export const selectUnits = {
  KG_CM: 'KG_CM', // KG
  LBS_INCH: 'LBS_INCH' // LBS
};

export const measurements = {
  weightUnits: 'weightUnits',
  distanceUnits: 'distanceUnits'
};

export const bodyStatsOrder = ['height', 'weight', 'muscleMass', 'fat', 'calories', 'water', 'bmi'];
export const bodyStatsAchiev = ['height', 'weight', 'muscleMass', 'fat', 'water', 'bmi'];

// measurementByType
export const measurementByType = {
  // bodyStats
  bmi: units.other.BMI,
  calories: units.other.KCAL,
  water: units.other.PERCENT,
  height: units.distanceUnits.CM,
  weight: units.weightUnits.KG,
  fat: units.other.PERCENT,
  muscleMass: units.weightUnits.KG,
  timeDone: units.other.TIME,
  // goalType
  bestSet: units.weightUnits.KG, // highest weight / most reps
  mostReps: units.other.BLANK, // most reps
  highestWeight: units.weightUnits.KG, // highest weight
  bestByRM: units.weightUnits.KG, // highest weight / specific num of reps (1RM - 2RM - 6RM...)
  longestTime: units.other.TIME, // longest Time - forward stopper
  bestTime: units.distanceUnits.CM, // shortest Time by distance
  longestDistance: units.distanceUnits.CM, // longest Distance
  tonnage: units.weightUnits.KG // highest tonnage by set
  // mostCaloriesBurned
};

export const propNameByType = {
  // bodyStats
  bmi: 'value',
  calories: 'value',
  water: 'value',
  height: 'value',
  weight: 'value',
  fat: 'value',
  muscleMass: 'value',
  // goalType
  bestSet: 'weight', // highest weight / most reps
  mostReps: '', // most reps
  highestWeight: 'weight', // highest weight
  bestByRM: 'weight', // highest weight / specific num of reps (1RM - 2RM - 6RM...)
  longestTime: 'timeDone', // longest Time - forward stopper
  bestTime: 'distance', // shortest Time by distance
  longestDistance: 'distance', // longest Distance
  tonnage: 'value' // highest tonnage by set
  // mostCaloriesBurned
};

export const language = {
  EN: 'en',
  HE: 'he'
};

export const userTypes = {
  CLIENT: 'CLIENT',
  TRAINER: 'TRAINER',
  NUTRITIONIST: 'NUTRITIONIST'
};

export const profession = {
  PT: 'Personal Trainer',
  NT: 'Nutritionist',
  DT: 'Dietitian',
  PST: 'Physical Therapist',
  SCH: 'Sports Coach',
  AT: 'Athletic Trainer'
};

export const gender = {
  male: 1,
  female: 2
};

export const durationType = {
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  times: 'times'
};

export const planReviewType = {
  duration: 'duration',
  workoutPerWeek: 'workoutPerWeek',
  workoutDuration: 'workoutDuration'
};

export const navigationActions = {
  CHOOSE_EXERCISE: 'CHOOSE_EXERCISE',
  TEXT: 'TEXT',
  SAVE_CHOSEN_LIST: 'SAVE_CHOSEN_LIST',
  SKIP_STEP: 'SKIP_STEP',
  DONE: 'DONE',
  SAVE: 'SAVE',
  CANCEL: 'CANCEL',
  RESEND_INVITE: 'RESEND_INVITE',
  CONFIRM_EVENT: 'CONFIRM_EVENT',
  CANCEL_EVENT: 'CANCEL_EVENT',
  RESCHEDULE_EVENT: 'RESCHEDULE_EVENT',
};

export const locale = {
  english: 'en',
  hebrew: 'he'
};
export const chosenLang = localStorage.get('currentLanguage');

export const achievementType = {
  body: 'body',
  performance: 'performance'
};

export const statsTypes = {
  programsStats: 'programsStats',
  exerciseStats: 'exerciseStats',
  sessionStats: 'sessionStats'
};

export const performanceType = {
  bestSet: 'bestSet', // highest weight / most reps
  mostReps: 'mostReps', // most reps
  highestWeight: 'highestWeight', // highest weight
  bestByRM: 'bestByRM', // highest weight / specific num of reps (1RM - 2RM - 6RM...)
  longestTime: 'longestTime', // longest Time - forward stopper
  bestTime: 'bestTime', // shortest Time by distance
  longestDistance: 'longestDistance', // longest Distance
  tonnage: 'tonnage' // highest tonnage by set
  // mostCaloriesBurned
};

export const inputsByAchievementTypes = {
  bestSet: ['reps', 'setX', 'weight'], // highest weight / most reps
  mostReps: ['reps'], // most reps
  highestWeight: ['weight'], // highest weight
  longestTime: ['timeDone'], // longest Time - forward stopper
  bestTime: ['timeDone', 'distance'], // shortest Time by distance
  longestDistance: ['distance'], // longest Distance
  bestByRM: ['reps', 'setX', 'weight'], // highest weight / specific num of reps (1RM - 2RM - 6RM...)
  tonnage: ['value'], // highest tonnage by set
  body: ['value']
  // mostCaloriesBurned
};

export const goalFormInputs = [
  'initial.reps', 'initial.weight', 'initial.timeDone', 'initial.distance', 'initial.value',
  'target.reps', 'target.weight', 'target.timeDone', 'target.distance', 'target.value'
];

export const modelLinks = {
  achievement: '/achievement',
  bodyStats: '/statistics/bodyStats/info',
  package: '/package',
  event: '/event',
  statistics: '/statistics/performance',
  programs: '/workout/programs',
  plan: '/plan',
  session: '/session',
  user: '/profile'
  // bodyStatsDetails: '/statistics/bodyStats/${stateName}',
};

export const logInputs = ['reps', 'weight', 'feels', 'time', 'speed', 'distance', 'intensity', 'timeDone'];

export const domainWhiteList = ['https://aspire-server-dev.herokuapp.com', 'https://aspire-server-prod.herokuapp.com', 'http://localhost:2020', 'https://application.aspire.co.il'];

export const inputConversionsProps = ['weight', 'distance', 'height', 'muscleMass', 'tonnage', 'totalDistance', 'value'];

export const inputConversionsPropsUnits = {
  weight: 'weightUnits',
  distance: 'distanceUnits',
  height: 'distanceUnits',
  muscleMass: 'weightUnits',
  tonnage: 'weightUnits',
  totalDistance: 'distanceUnits',
  value: 'weightUnits' // tonnage
};

export const activityModels = {
  achievement: 'achievement',
  bodyStats: 'bodyStats',
  package: 'package',
  event: 'event',
  statistics: 'statistics',
  programs: 'programs',
  program: 'program',
  session: 'session',
  user: 'user'
};

export const daysArray = [
  { text: { he: 'א', en: 'Sun' }, value: 0 },
  { text: { he: 'ב', en: 'Mon' }, value: 1 },
  { text: { he: 'ג', en: 'Tue' }, value: 2 },
  { text: { he: 'ד', en: 'Wed' }, value: 3 },
  { text: { he: 'ה', en: 'Thu' }, value: 4 },
  { text: { he: 'ו', en: 'Fri' }, value: 5 },
  { text: { he: 'ש', en: 'Sat' }, value: 6 }
];

export const daysFullNameArray = [
  { text: { he: 'ראשון', en: 'Sunday', es: 'Domingo' }, value: 0, calendar: 'SU' },
  { text: { he: 'שני', en: 'Monday', es: 'Lunes' }, value: 1, calendar: 'MO' },
  { text: { he: 'שלישי', en: 'Tuesday', es: 'Martes' }, value: 2, calendar: 'TU' },
  { text: { he: 'רביעי', en: 'Wednesday', es: 'Miércoles' }, value: 3, calendar: 'WE' },
  { text: { he: 'חמישי', en: 'Thursday', es: 'Jueves' }, value: 4, calendar: 'TH' },
  { text: { he: 'שישי', en: 'Friday', es: 'Sábado' }, value: 5, calendar: 'FR' },
  { text: { he: 'שבת', en: 'Saturday', es: 'Domingo' }, value: 6, calendar: 'SA' },
];


export const optionAnswerType = {
  inputOption: 'input',
  numberOption: 'number',
  longInputOption: 'longInput',
  checkboxOption: 'checkBox',
  radioOption: 'radio',
  dateOption: 'date',
  // for answer purposes
  // textarea: 'textarea'
};

export const aspireColors = [
  // pink
  'rgba(144, 27, 73, 0.4)',
  // orange
  'rgba(121, 52, 22, 0.3)',
  // purple
  'rgba(88, 85, 160, 0.3)',
  // orange
  'rgba(225, 119, 74, 0.3)',
  // pink
  'rgba(212, 64, 123, 0.3)'
];

export const orderBy = {
  date: 'Date',
  creationDate: 'Creation Date',
  lastUpdated: 'Last Updated'
};

export const aspirePlaceholder = [ placeholderGray, placeholderBlue ];

export const categoryColor = {
  fitness: '#3c4889',
  mindset: '#5954a0',
  workouts: '#b3387e',
  yoga: '#e24585',
  research: '#e85c3c',
  health: '#54a42a',
}

export const aspireColorsPicker = [
  '#007bff',
  '#3c4889',
  '#6f42c1',
  '#e24585',
  '#dc3545',
  '#e85c3c',
  '#ffc107',
  '#28a745',
  '#20c997',
  '#17a2b8',
];

export const categorySymbol = {
  fitness: 'iconsminds-male-female',
  mindset: 'iconsminds-brain',
  workouts: 'iconsminds-dumbbell',
  yoga: '#e24585',
  research: 'iconsminds-microscope',
  health: 'iconsminds-apple',
}


export const firstDayOfTheWeek = {
  sundayToSunday: 0,
  mondayToMonday: 1,
};

export const timeInputDisplay = {
  timegrid: 'timegrid',
  timeWheel: 'time',
};

export const timeInputSteps = {
  fiveMinutes: 5,
  tenMinutes: 10,
  fifteenMinutes: 15,
  thirtyMinutes: 30,
};


export const repeatEventType = {
  daily: 'daily',
  weekly: 'weekly',
};

export const repeatEventConditions = {
  never: 'never',
  untilDate: 'untilDate',
  numOfTimes: 'numOfTimes',
};

export const eventTypes = {
  personal: 'personal',
  group: 'group',
  nutrition: 'nutrition',
  publicEvent: 'publicEvent',
  other: 'other',
};

export const eventStatus = {
  pending: 'pending',
  confirmed: 'confirmed',
  canceled: 'canceled',
  completed: 'completed',
  postponed: 'postponed',
};

export const eventResponse = {
  confirmed: 'confirmed',
  canceled: 'canceled',
};

export const programTypes = {
  // other: 0,
  personal: 1,
  group: 2,
  public: 3,
  // paid: 4,
  store: 5,
};

export const allowedFieldsList = {
  timeDone: 'timeDone',
  time: 'time',
  intensity: 'intensity',
  speed: 'speed',
  distance: 'distance',
  weight: 'weight',
  rest: 'rest',
  reps: 'reps',
  tonnage: 'tonnage',
  totalTimeDone: 'totalTimeDone',
  totalDistance: 'totalDistance',
};

export const requestTypes = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
};

export const chooseFilterCallByPage = {
  createWorkout: {
    funcName: 'getFilteredExercises',
    dispatch: 'dispatchFilteredExercises',
    query: {},
  },
  infoPage: {
    funcName: 'getFilteredInfos',
    dispatch: 'dispatchFilteredInfos',
    query: { limit: 30 },
  },
};


export const userDevices = {
  android: 'android',
  chrome: 'chrome',
  ios: 'ios',
  web: 'web',
};

export const aspireLogo = 'https://aspire123.s3.eu-central-1.amazonaws.com/placeholders/logo_exdvpj.jpg';

export const imageExamplesList = [
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_34.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_29.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_10.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_8.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_36.jpg',
  'https://thumbs-dev.aspire.co.il/source/aspireLibrary/aspire_31.jpg',
];

export const programViews = {
  SET: 'SET',
  CIRCUIT: 'CIRCUIT',
};
