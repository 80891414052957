const usersAccessControl = {
  TRAINER: {
    allAccess: true,
    profile: true,
    programs: true,
    workoutSession: true,
    sessions: true,
    statistics: true,
    calendar: true,
    achievements: true,
    inviteClient: true,
    helpCenter: true,
    paperwork: true,
    myExercises: true,

    // settings
    settings: true,
    myClientsList: true,
    subscription: true,
    calendarSettings: true,

    // extra
    activities: true,
    contacts: true
  },
  CLIENT: {
    profile: true,
    programs: true,
    workoutSession: true,
    sessions: true,
    statistics: true,
    calendar: true,
    achievements: true,
    helpCenter: true,
    paperwork: true,

    // settings
    settings: true,
    calendarSettings: true,
  },
  MANAGER: {
    profile: true,
    programs: true,
    workoutSession: true,
    sessions: true,
    statistics: true,
    calendar: true,
    achievements: true,
    inviteClient: true,
    helpCenter: true,

    // settings
    settings: true,
    myClientsList: true,
    calendarSettings: true,

    // extra
    activities: true,
    contacts: true
  }
}

export default usersAccessControl;
