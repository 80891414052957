/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import nanoid from 'nanoid/generate';
import { checkIfLoggedIn } from '../../store/actions';
import { welcomeTrainerPageData } from '../../constants/survey';
import { addClass, removeClass } from '../../services/utilsService';
import { langs } from '../../langs';
import Survey from '../../Components/Survey/Survey';
import DefaultSection from '../../Components/Survey/DefaultSection';
import DummyUserSection from '../../Components/Survey/DummyUserSection';
import welcomeAspire from '../../assets/onBoarding/welcom_to_aspire.png';
import { nanoidOption } from '../../services/storeDoll';
import './TrainerWelcomePage.css';

class TrainerWelcomePage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      media: {},
      isPageReady: false
    };
  }

  async componentDidMount () {
    try {
      if (!this.props.user.public_id) await this.props.checkIfLoggedIn();
      this.setState({ isPageReady: true });
    } catch (e) {
      console.log('handle error');
    }
  }

  onSlideChange (value, isEnd) {
    if (isEnd) {
      addClass('.SurveyPage .doneBtn', 'show');
    } else {
      removeClass('.SurveyPage .doneBtn', 'show');
    }
  }

  clickDone (surveyData) {
    this.props.finishOnBoarding(surveyData)
  }

  saveMedia (data) {
    if (Array.isArray(data)) {
      data.forEach((media) => {
        this.state.media[media._id] = media;
      });
    }
  }

  removeMedia (media) {
    delete this.state.media[media._id];
  }

  renderSurveyInfoPage () {
    return (
      <DefaultSection id={nanoid(nanoidOption, 4)}
        title={langs[this.props.locale].welcomeUserToAspire(this.props.user.firstName)}
        description={langs[this.props.locale].welcomeUserToAspire_desc}
        comment={langs[this.props.locale].clickNextOrSwipe}
        thumbnail={welcomeAspire}
        thumbnailClass="flex-initial"
        backgroundColor="rgb(236, 237, 254)"
        isSwiperCarousel
        locale={this.props.locale} />
    );
  }

  renderSumPage (section) {
    const array = [];
    // adding alice page
    array.push(
      <DummyUserSection section={section}
        backgroundColor={section.backgroundColor}
        title={<Trans i18nKey="congratulations" />}
        description={<Trans i18nKey="dummyUserAppended" />}
        locale={this.props.locale}
        user={this.props.user.clients[0]}
      />
    );
    return array;
  }

  render () {
    const { isPageReady } = this.state;
    const firstPage = isPageReady && welcomeTrainerPageData && this.renderSurveyInfoPage(welcomeTrainerPageData);
    const sumPage = isPageReady && this.renderSumPage(welcomeTrainerPageData);
    return (
      <div className="TrainerWelcomePage SurveyPage flex-100 layout-row layout-wrap layout-align-start-start content-start whiteBackground">
        <div className="flex-100 layout-row layout-wrap layout-align-start-start">
          <div className="flex-100 layout-row layout-wrap layout-align-center-start">
            { isPageReady && (
              <Survey
                survey={welcomeTrainerPageData}
                firstPages={firstPage}
                sumPages={sumPage}
                pagination
                navigation
                isSwiperCarousel
                className=""
                navigationClasses="bottom text"
                onSlideChange={(value, isEnd) => this.onSlideChange(value, isEnd)}
                clickDone={surveyData => this.clickDone(surveyData)} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale
});

const mapDispatchToProps = dispatch => ({
  checkIfLoggedIn: () => dispatch(checkIfLoggedIn()),
  updateTrainerProfession: body => dispatch(updateTrainerProfession(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainerWelcomePage);
