import React from 'react';
import { Card } from 'reactstrap';
import { Trans } from 'react-i18next';
import { styleUtils } from '../../services/utilsService';
import { aspirePlaceholder, mediaType } from '../../services/enums';

function Image(props) {
  const { thumbnail, type, onClick, id, onCancel, uploadedSuccessfully } = props;
  return (
    <Card id={`media_${id}`} className="Image flex-100 layout-row layout-wrap layout-align-start-start bordered mb-md-3 mb-xs-2" onClick={() => {onClick();}}>
      <div className="flex-100 position-relative">
        <div
          className="backgroundImage socialImageSize layout-column layout-wrap layout-align-end-end borderRadius5"
          style={styleUtils.imageBackground(thumbnail, 'rgba(0, 0, 0, 0.6)', 'rgba(0, 0, 0, 0.3)', null, 0, aspirePlaceholder[0])}>
          <div className="flex width100 layout-column layout-align-center-center position-relative">
            <div className="startUpload flex width100 layout-column layout-align-center-center position-relative display-none borderRadius5">
              <div className="width100 positionAbsoluteTopLeft zIndex5 layout-row layout-wrap layout-align-start-center">
                <div id="progressTrack" className="flex-initial layout-row layout-wrap layout-align-start-center borderRadius5" />
              </div>
              <div className="width100 positionAbsoluteTopLeft zIndex10 layout-row layout-wrap layout-align-center-center">
                <div className="width100 layout-column layout-align-center-center uploadText">
                  <div className="width100 layout-row layout-align-center-center p-3" />
                  <p className="font16 fontWeight600 whiteColor mb-0 textShadow" id="number" />
                  <div className="width100 layout-row layout-align-center-center">
                    <button type="button" className="btn width100 whiteColor btn-transparent p-1 textShadow text-center" onClick={() => {onCancel()}}><Trans i18nKey="cancel" /> </button>
                  </div>
                </div>
              </div>
            </div>
            { !uploadedSuccessfully && type === mediaType.video && (<i className="simple-icon-control-play whiteColor font24 textShadow mt-4 play" />)}
            { uploadedSuccessfully && (<i className="simple-icon-check whiteColor font28 textShadow mt-4 play" />)}
          </div>
          <div className="flex-initial width100 layout-column layout-wrap layout-align-end-end">
            <input type="checkbox" checked readOnly />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Image;
