/* eslint-disable linebreak-style,prefer-destructuring,react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import nanoid from 'nanoid/generate';
import { CardHeader } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { showAlert } from '../../store/actions';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import { addClass, removeClass, reorder, utils } from '../../services/utilsService';
import { nanoidOption } from '../../services/storeDoll';
import StripExerciseBasic from '../StripExercise/StripExerciseBasic';
import '../WorkoutList/WorkoutList.css';


class ExercisesDnD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exercises: this.props.exercises || [],
      trashList: [],
      editMode: false,
      selectedListKV: {},
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    try {
      // dropped outside the list
      const { destination, source } = result;
      if (!destination) {
        return;
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      this.state.exercises = reorder(this.state.exercises, source.index, destination.index);
      this.state.exercises = this.state.exercises.map((workoutLog, index) => {
        workoutLog.order = index + 1;
        return workoutLog;
      });
      if (this.props.updateExerciseOrder) this.props.updateExerciseOrder(this.state.exercises);
      if (this.props.hasChanged) this.props.hasChanged(true); // has changes to save & not skip;
    } catch (e) {
      console.log('something went wrong on drag');
    }
  }

  getItemStyle(isDragging, draggableStyle) {
    return {
      userSelect: 'none',
      backgroundColor: 'white',
      WebkitBoxShadow: isDragging ? '0 0 6px 0 rgba(62,57,107,.07)' : ' 0 0 0 0 rgba(62,57,107,0)',
      boxShadow: isDragging ? '0 0 6px 0 rgba(62,57,107,.07)' : ' 0 0 0 0 rgba(62,57,107,0)',
      position: 'relative',
      zIndex: 1,
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  }

  removeSelectedExercise() {
    if (this.props.disableDeleteNotice) {
      this.removeExercise();
    } else {
      let title = <Trans i18nKey="removeExercise" />,
        content = <Trans i18nKey="removeExerciseDesc" />;
      if (this.props.assignMode) {
        title = <Trans i18nKey="notice" />;
        content = <Trans i18nKey="noticeChangingCurrentProgram" />;
      }
      const data = {
        isOpen: true,
        title,
        content,
        className: 'ModalPage',
        id: eventActions.ALERT_MODAL,
        closeAlertBtn: {
          text: <Trans i18nKey="cancel" />,
        },
        buttonRight: {
          text: <Trans i18nKey="confirm" />,
          onClick: () => {
            this.removeExercise();
            this.props.hideAlert();
            if (this.props.assignMode) {
              this.props.exitAssignMode();
            }
          },
        },
      };
      this.props.showAlert(data);
    }
  }

  removeExercise() {
    this.props.updateTrashList(this.state.trashList); // very important for server
    this.state.trashList.forEach((key) => {
      delete this.state.selectedListKV[key];
    });
    this.state.exercises = utils.keyValueToArray(this.state.selectedListKV);
    this.state.trashList = [];
    if (this.props.updateExerciseOrder) this.props.updateExerciseOrder(this.state.exercises);
    if (this.props.hasChanged) this.props.hasChanged(true); // has changes to save & not skip;

    this.setState({...this.state})
  }

  handleRemoveSelectedExercise(exerciseKey) {
    this.state.trashList.push(exerciseKey);
    this.deleteTrashList();
  }

  deleteTrashList() {
    // preparing KV exercises for filtering & deletion ;
    this.state.selectedListKV = utils.arrayToKeyValue(this.state.exercises, 'key').keyValueObj;
    this.removeSelectedExercise();
  }

  renderStripExercise() {
    const { exercises } = this.state;
    return exercises.map((exercise, index) => {
      // const result = componentsService.organizeWorkoutLogsData(workout, false, this.props.showEquipmentSelect);
      if (!exercise.key) exercise.key = exercise.public_id || `${nanoid(nanoidOption, 6)}_key`;
      return (
        <Draggable
          key={exercise.public_id || exercise.chosenKey || exercise.key || index}
          draggableId={exercise.public_id || exercise.chosenKey || exercise.key || index}
          index={index}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              className="flex-100 layout-row layout-align-start-center">
              {!this.state.editMode && (
                <div className="flex-initial paddEnd10px height40px layout-row layout-align-start-center">
                  <i className="las la-braille font24 text-muted" />
                </div>
              )}
              <StripExerciseBasic
                className="flex layout-row layout-wrap layout-align-start-center exHeader"
                titleClass="height50px"
                exercise={exercise}
                index={index + 1}
                showChosenEquipment
                disableClick
                editMode={this.state.editMode}
                selectClicked={(equipment) => {this.handleEquipmentChange(equipment);}}
                locale={this.props.locale}
              />
              <div
                className={`flex-initial paddStart5px layout-row layout-align-start-center height90px`}
                onClick={() => this.handleRemoveSelectedExercise(exercise.key)}>
                <i className="la la-trash font24 text-muted" />
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  }

  renderExercises() {
    return (
      <Droppable droppableId="SelectedExercisesList">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="flex-100 layout-row layout-wrap layout-align-start-start content-start drawerDroppableContainer"
          >
            {this.renderStripExercise()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  render() {
    const exerciseList =
      this.state.exercises && this.state.exercises.length > 0 ? this.renderExercises() : null;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-start content-start">
        <div className="flex-100 layout-row layout-wrap layout-align-start-start drawer-container content-start positionRelative">
          <CardHeader className="width100 pt-3 pb-0 sp-0">
            <div className="flex-100 layout-row layout-wrap layout-align-space-between-start">
              { this.props.showBackBtn && (
                <div className="flex-initial layout-row layout-wrap layout-align-center-center">
                  <button
                    type="button"
                    className="width100 textIcon btn btn-transparent sp-2 text-center nextBtn"
                    onClick={() => this.props.goBack()}>
                    <i className="la la-arrow-left font24" />
                  </button>
                </div>
              )}
              <div className="flex layout-row layout-wrap layout-align-start-start">
                <h4 className="mb-1 text-capitalize"><Trans i18nKey={this.props.title || ''} /></h4>
                { this.props.subtitle && <p className="text-muted font13 fontWeight600 mb-0"><Trans i18nKey={this.props.subtitle || ''} /></p> }
                <p className="text-muted font13 fontWeight600 mb-0"><Trans i18nKey="moveExercisesInfo" /></p>
              </div>
              { this.props.showNextBtn && (
                <div className="flex-initial layout-row layout-wrap layout-align-center-center">
                  <button
                    type="button"
                    className="width100 textIcon btn btn-transparent sp-3 text-center nextBtn"
                    onClick={() => this.clickNext()}>
                    <p className="margin0 font16"><Trans i18nKey="next" /></p>
                  </button>
                </div>
              )}
            </div>
          </CardHeader>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start content-start sp-2">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className="flex-100 layout-row layout-wrap layout-align-start-start drawerDroppableContainer">
                {exerciseList}
              </div>
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }
}

ExercisesDnD.propTypes = {
  workoutLogsData: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string,
  assignMode: PropTypes.bool,
  hasChanged: PropTypes.func,
  exitAssignMode: PropTypes.func,
  handleExercisesDrawer: PropTypes.func,
  updateTrashList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.userR.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  showAlert: (data) => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExercisesDnD);
