/* eslint-disable no-param-reassign,linebreak-style */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import VirtualList from 'react-tiny-virtual-list';
import {
  getPopularExercises, saveCurrentWorkoutLogs, showAlert, updateExerciseList, filterExercisesByCategory
} from '../../store/actions';
import { actionTypes } from '../../store/actions/actionTypes';
import { navigationActions } from '../../services/enums';
import listService from '../../services/listService';
import NavBottom from '../NavBottom/NavBottom';
import StripExercise from '../StripExercise/StripExercise';
import componentsService from '../../services/componentsService';
import { addClass, removeClass } from '../../services/utilsService';
import LoadingDots from '../UI/LoadingDots';
import './WorkoutList.css';

class ExercisesList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      navBottom: { showBottomNav: false },
      workoutLogs: this.props.workoutLogs || [],
      tempChosenExercises: [],
      tempChosenExercisesKV: [],
      shouldUpdate: true,
      workouts: [],
      exercisesList: {},
      exercisesListIds: [],
      exSkeletons: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      isMultiSelect: this.props.isMultiSelect || false
    };
  }

  async componentDidMount () {
    if (this.props.exerciseIds && this.props.exerciseIds.length === 0) {
      await this.props.getPopularExercises();
    }
    window.addEventListener('resize', this.resizeVirtualList);
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !!(nextProps.shouldUpdate || nextState.shouldUpdate || (nextProps.exerciseIds !== this.props.exerciseIds) || (nextProps.exCategoriesFilter !== this.props.exCategoriesFilter));
  }

  componentDidUpdate () {
    this.state.shouldUpdate = false;
  }

  resizeVirtualList () {
    const exerciseList = document.getElementById('exerciseList');
    if (exerciseList) exerciseList.style.height = `${window.innerHeight - 195}px`;
  }

  handleChange (e, key) {
    if (e.target.type === 'checkbox' || e.target.type === 'radio') {
      this[key] = e.target.checked;
    } else {
      this[key] = e.currentTarget.value;
    }
  }

  handleChangeToMultiSelectType () {
    this.state.isMultiSelect = !this.state.isMultiSelect;
    this.state.exercisesList = listService.clearChosenList(this.state.exercisesList, this.state.exercisesListIds);
    const navBottom = { showBottomNav: false };
    this.props.hideBottomNav();
    this.setState({
      ...this.state, navBottom, tempChosenExercises: [], shouldUpdate: true, exercisesList: this.state.exercisesList
    });
  }

  checkExerciseType (data) {
    this.state.activeFilters = data;
    this.state.showMyExercises = this.state.activeFilters.indexOf('CAT-MY_EXERCISES') !== -1;
    this.setState({ shouldUpdate: true });
  }

  handleSingleSelect (publicId) {
    // const elementInput = document.querySelector(`#exerciseOption_${publicId} input`)
    // if (elementInput && elementInput.checked) elementInput.checked = true;
    const result = listService.listSingleSelect(this.state.exercisesList, this.state.tempChosenExercises, publicId);
    const navBottom = {
      showBottomNav: true,
      config: [
        {
          key: navigationActions.CHOOSE_EXERCISE,
          text: <Trans i18nKey="selectExercise" />
        }
      ]
    };
    this.props.showBottomNav();
    this.setState({
      navBottom, tempChosenExercises: result.chosenList, shouldUpdate: true, exercisesList: result.list
    });
  }

  handleMultiSelect (publicId) {
    let navBottom = { showBottomNav: false };
    const result = listService.listMultiSelect(this.state.exercisesList, this.state.tempChosenExercises, publicId);
    if (result.chosenList.length > 1 && result.chosenList.length < 7) { // no more that 6 ex selected at once
      navBottom = {
        showBottomNav: true,
        config: [
          {
            className: 'corner-right',
            key: navigationActions.CHOOSE_EXERCISE,
            upperSubtitle: {
              text: <span className="flex-100 layout-align-center-center layout-row text-center font13">
                <span className="sidePadd5px">{result.chosenList.length}</span>
                <span><Trans i18nKey="exercisesSelected" /></span>
              </span>,
              className: 'flex-100 layout-align-center-center layout-row text-center'
            },
            text: <Trans i18nKey="selectExercises" />,
            btnClassName: 'addSuperSet'
          }
        ]
      };
      this.props.showBottomNav();
    } else {
      navBottom = { showBottomNav: false };
      this.props.hideBottomNav();
    }
    this.props.updateExerciseList(result.list);
    this.setState({
      navBottom, tempChosenExercises: result.chosenList, shouldUpdate: true
    });
  }

  chooseExercise (event, exercise) {
    this.state.tempChosenExercisesKV[exercise.public_id] = exercise;
    if (this.state.isMultiSelect) {
      this.handleMultiSelect(exercise.public_id);
    } else {
      this.handleSingleSelect(exercise.public_id);
    }
  }

  addSelectedExercises (key) {
    const { isMultiSelect, tempChosenExercises } = this.state;
    let exercises = [];
    if (key === navigationActions.CHOOSE_EXERCISE && tempChosenExercises.length > 0) {
      if (isMultiSelect) {
        exercises = tempChosenExercises.map((exId, index) => {
          const currentEx = { ...this.state.tempChosenExercisesKV[exId] };
          currentEx.order = index + 1;
          if (this.props.markSelected) addClass(`#item_${currentEx.public_id}`, 'selected')
          return currentEx;
        });
      } else {
        const exercise = this.state.tempChosenExercisesKV[tempChosenExercises[0]];
        exercise.order = 1;
        if (this.props.markSelected) addClass(`#item_${exercise.public_id}`, 'selected')
        exercises.push(exercise);
      }
      this.state.navBottom = { showBottomNav: false };
      this.state.exercisesList = listService.clearChosenList(this.state.exercisesList, this.state.exercisesListIds);
      this.setState({
        tempChosenExercises: [], navBottom: { showBottomNav: false }, shouldUpdate: true, tempChosenExercisesKV: []
      });
      this.props.hasChanged(true); // has changes to save & not skip;
      this.props.hideBottomNav();
      this.props.getSelectedExercises(exercises);
    }
  }

  async exerciseTypeFilter (data) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      if (data.publicIds && data.publicIds.length > 0) {
        this.checkExerciseType(data.publicIds);
      }
      removeClass('#loadingDots', 'opacity0');
      const body = { category: data.selectedIds };
      await this.props.filterExercisesByCategory(body);
      addClass('#loadingDots', 'opacity0');
      this.isLoading = false;
    } catch (e) {
      console.log('handle error');
      this.isLoading = false;
    }
  }

  renderExercises (data) {
    if (this.state.showMyExercises) data = { ...this.props.myExerciseList, ...data };
    const { isMultiSelect } = this.state;
    const getHeight = this.props.virtualListHieght || window.outerHeight + 20;
    if (data && Object.keys(data).length > 0) {
      const exerciseList = Object.entries(data).map(([key, exercise]) => exercise);
      return (
        <VirtualList
          id="exerciseList"
          width="100%"
          height={getHeight}
          itemCount={exerciseList.length}
          itemSize={90} // Also supports variable heights (array or function getter)
          renderItem={({ index, style }) => (
            <div key={exerciseList[index].public_id} id={`item_${exerciseList[index].public_id}`} style={style} className={`virtualItem flex-100 layout-row layout-align-start-center sp-3 ${exerciseList[index].isPersonal ? 'myExerciseBg' : ''}`}>
              <StripExercise
                locale={this.props.locale}
                isMulti={false}
                showCategories
                showTarget
                musclesInvolvedContainer={21}
                clicked={() => this.chooseExercise(null, exerciseList[index])}
                className="flex layout-row layout-wrap layout-align-start-center exHeader "
                exercise={exerciseList[index]}
              />
              { this.props.showCheckBox && (
                <div className="flex-initial layout-row layout-wrap layout-align-start-center checkBox">
                  <div className="checkbox-container">
                    <input
                      type={isMultiSelect ? 'checkbox' : 'radio'}
                      checked={exerciseList[index].chosen || this.state.tempChosenExercises[exerciseList[index].public_id]}
                      name="chooseExercise"
                      onChange={event => this.chooseExercise(event, exerciseList[index])} />
                  </div>
                </div>
              )}
            </div>
          )}
        />
      );
    }
    return this.state.exSkeletons.map((num, index) => (
      <div key={`key_${num}`} className="flex-100 layout-row layout-align-start-center mb-2 sp-3 exSkeleton" style={{ height: `${68}px` }}>
        { componentsService.renderStripExercisePlaceHolder(false, 'flex layout-row layout-wrap layout-align-start-center') }
        <div className="flex-initial layout-row layout-wrap layout-align-start-center checkBox">
          <div className="checkbox-container">
            <input type="radio" name={`${index}_sk`} />
          </div>
        </div>
      </div>
    ));
  }

  render () {
    this.state.exercisesList = { ...this.props.exerciseListKV };
    this.state.exercisesListIds = [...Object.keys(this.props.myExerciseList), ...Object.keys(this.props.exerciseListKV)];
    const exerciseList = this.renderExercises(this.state.exercisesList);
    const { navBottom } = this.state;
    return (
      <div className="ExercisesListM flex-100 layout-row layout-wrap layout-align-start-start transition03">
        <div className="flex-100 layout-row layout-wrap layout-align-start-center paddTop10px">
          <div className="flex-100 layout-row layout-wrap layout-align-start-center">
            <LoadingDots hiddenLoader />
          </div>
          <div className="flex-100 layout-row layout-wrap layout-align-start-center">
            {exerciseList}
          </div>
        </div>
        { navBottom.showBottomNav && (
          <NavBottom config={navBottom.config} chosenOption={(key) => { this.addSelectedExercises(key); }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  settings: state.userR.settings,
  exerciseListKV: state.exerciseR.exerciseListKV,
  myExerciseList: state.exerciseR.myExerciseList,
  exerciseIds: state.exerciseR.exerciseIds,
  exCategoriesFilter: state.exerciseR.exCategoriesFilter,
  navBottom: state.appR.navBottom
  // locale: state.appR.locale ---> getting from props outside
});

const mapDispatchToProps = dispatch => ({
  showBottomNav: () => dispatch({ type: actionTypes.SHOW_BOTTOM_NAV }),
  hideBottomNav: () => dispatch({ type: actionTypes.HIDE_BOTTOM_NAV }),
  showAlert: data => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  getPopularExercises: (query) => dispatch(getPopularExercises(query)),
  saveCurrentWorkoutLogs: workoutLogs => dispatch(saveCurrentWorkoutLogs(workoutLogs)),
  updateExerciseList: exerciseList => dispatch(updateExerciseList(exerciseList)),
  filterExercisesByCategory: body => dispatch(filterExercisesByCategory(body))
});
export default connect(mapStateToProps, mapDispatchToProps)(ExercisesList);
