import {PopoverBody, PopoverHeader} from "reactstrap";
import {Trans} from "react-i18next";
import {langs} from "../../langs";
import Input from "./Input";
import React from "react";


export const PopoverDeleteContent = ({ onConfirm, onCancel, locale, name }) => {
    return (
        <div>
            <PopoverHeader><Trans i18nKey="deleteImage" /></PopoverHeader>
            <PopoverBody>
                <p>{ langs[locale].areYouSure_delete(name) }</p>
                <button type="button" className="btn btn-transparent-primary" onClick={() => onConfirm()}><Trans i18nKey="yes"/></button>
                <button type="button" className="btn btn-transparent-primary" onClick={() => onCancel()}><Trans i18nKey="no"/></button>
            </PopoverBody>
        </div>
    );
};

export const PopoverEditContent = ({ onConfirm, onCancel, locale, name, chooseDefault }) => {
    return (
        <div>
            <PopoverHeader><Trans i18nKey="editImage" /></PopoverHeader>
            <PopoverBody>
                <div className="flex-100 layout-row layout-wrap layout-align-start-start padd10px">
                    <p>{name}</p>
                    <Input
                        noLabel
                        className="margin0"
                        name="makeMediaDefault"
                        elementType="checkBox"
                        locale={locale}
                        handleChange={(event, key) => { chooseDefault(); }}>
                <span>
                  <Trans i18nKey="makeMediaDefault" />
                </span>
                    </Input>
                </div>
                <button type="button" className="btn btn-transparent-primary" onClick={() => onConfirm()}><Trans i18nKey="confirm"/></button>
                <button type="button" className="btn btn-transparent-primary" onClick={() => onCancel()}><Trans i18nKey="cancel"/></button>
            </PopoverBody>
        </div>
    );
};