import React, { Component, Fragment } from 'react';
import * as queryString from 'query-string';
import { Trans } from 'react-i18next';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebookProps } from '../../constants/defaultValues'
import './SocialButtons.css';

class FacebookButton extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }

  render() {
    const { text, permissions, dummyButton } = this.props
    return (
      <Fragment>
        { dummyButton ? (
          <button type="button" onClick={() => {this.props.onClick()}} className="facebookLogin flex layout-align-center-center text-center">
            <i className="simple-icon-social-facebook mr-3"></i>
            <Trans i18nKey={text || 'selectFacebookPhotos'} />
          </button>
        ) : (
          <FacebookLogin
            appId={facebookProps.facebookAppId}
            autoLoad
            fields="name,email,picture"
            scope={permissions || 'public_profile,email,user_birthday,user_gender'}
            callback={(res) => {this.props.responseFacebook(res)}}
            render={renderProps => (
              <button type="button" onClick={renderProps.onClick} className="facebookLogin flex layout-align-center-center text-center">
                <i className="simple-icon-social-facebook mr-3"></i>
                <Trans i18nKey={text || 'loginWithFacebook'} />
              </button>
            )}
          />
        )}
      </Fragment>
    )
  }
}

export default FacebookButton
