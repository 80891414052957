/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

import React from 'react';

const en = {
  /* 01.General */
  'general.copyright': 'Aspire © 2020 All Rights Reserved.',
  welcomeToAspire : 'Welcome to Aspire',
  login_sub : 'Please use your credentials to login.\n If you are not a member, please ',
  register_sub : 'Please use this form to register.\n If you are a member, please ',
  orSocialLogin: 'Or login with: ',
  connectThru: 'Connect with: ',
  orRegister: 'Or Register',
  FBNotConnected: 'No account Connected to this FB account',
  FBNotConnected_sub: 'No ASPIRE account Connected to this FB account,\n Would you like to register to Aspire with this account?',
  forgotPassword_sub: 'Please use this form to receive a reset code via email, and reset your password with it here.\n If you do remember your password, try to ',
  sendCode: 'Send me the code',

  misMatchPasswords: 'passwords mismatch, check again',
  misMatchCode: 'code mismatch, check again',
  credentialMissMatch: 'The credentials you supplied were not correct, please try again',
  noResultsFound: 'No Results Found',
  pleaseTryAgain: 'Please try again',
  addShortDescription: 'Add a short description about yourself here.',
  addExpertise: 'Add your expertise',

  noTrainingLocations: 'No training locations were updated.',
  clickHereToCreateTrainingLocations: 'Click here to update locations.',
  noProfessionCertificates: 'No profession certificates were updated.',
  clickHereToCreateProfessionCertificates: 'Click here to update certificates.',
  uploadMediaProfile: 'No media was added to profile gallery.',
  clickHereToUploadMedia: 'Click here to update profile gallery',
  uploadMedia: 'Upload media',
  uploadMedia_sub: 'Upload media to your gallery',


  albums: 'Albums',
  album_photos: (name) => (`Photos from album "${name}"`),
  uploadSelectedImages: 'Upload selected media',
  saveSelectedImages: 'Save selected media',
  uploadingFiles: 'Uploading files',
  updatingAspireLib: 'Updating Aspire library',
  openAspireLibrary: 'Open Aspire library',
  selectMyMedia: 'Select From my media',
  selectInstagramPhotos: 'Select Instagram photos',
  selectFacebookPhotos: 'Select Facebook photos',
  loginWithFacebook: 'Login with Facebook',


  /* 02.User Login, Logout, Register */
  'login-title': 'Login',
  'forgot-password': 'Forgot Password',
  'forgot-password-question': 'Forget password?',
  fullName: 'Full Name',
  'login-button': 'Login',
  'register-button': 'Register',
  'reset-password-button': 'Reset',
  buy: 'BUY',
  username: 'Username',

  /* 03.Menu */
  app: 'Home',
  dashboards: 'Dashboards',
  gogo: 'Gogo',
  start: 'Start',
  'second-menu': 'Second Menu',
  second: 'Second',
  ui: 'UI',
  charts: 'Charts',
  chat: 'Chat',
  survey: 'Survey',
  todo: 'Todo',
  search: 'Search',
  docs: 'Docs',
  'blank-page': 'Blank Page',
  dashboard: 'Dashboard',

  /* 04.Error Page */
  'error-title': 'Ooops... looks like an error occurred!',
  'error-code': 'Error code',
  'go-back-home': 'GO BACK HOME',

  /* 05.Dashboards */
  'pending-orders': 'Pending Orders',
  'completed-orders': 'Completed Orders',
  'refund-requests': 'Refund Requests',
  'new-comments': 'New Comments',
  sales: 'Sales',
  refunds: 'Refunds',
  'recent-orders': 'Recent Orders',
  'product-categories': 'Product Categories',
  cakes: 'Cakes',
  tickets: 'Tickets',
  'best-sellers': 'Best Sellers',
  'website-visits': 'Website Visits',
  'unique-visitors': 'Unique Visitors',
  'this-week': 'This Week',
  'last-week': 'Lat Week',
  'this-month': 'This Month',
  'conversion-rates': 'Conversion Rates',
  'per-session': 'Per Session',
  'profile-status': 'Profile Status',
  'payment-status': 'Payment Status',
  'work-progress': 'Work Progress',
  'tasks-completed': 'Tasks Completed',
  'payments-done': 'Payments Done',
  'order-stock': 'Order - Stock',
  'quick-post': 'Quick Post',
  'save-and-publish': 'Save and Publish',
  'top-viewed-posts': 'Top Viewed Posts',
  posts: 'Posts',
  'pending-for-publish': 'Pending for publish',
  users: 'Users',
  'on-approval-process': 'On approval process',
  'waiting-for-notice': 'Waiting for notice',
  files: 'Files',
  'pending-for-print': 'Pending for print',
  logs: 'Logs',
  'magic-is-in-the-details': 'MAGIC IS IN THE DETAILS',
  'yes-it-is-indeed': 'Yes, it is indeed!',
  'advanced-search': 'Advanced Search',
  toppings: 'Toppings',
  keyword: 'Keyword',
  'top-rated-items': 'Top Rated Items',

  articles: 'Articles',
  updateProfile: 'Update Profile',
  birthDate: 'Birth date',
  city: 'City',
  expertise: 'Expertise',
  facebook: 'Facebook',
  instagram: 'Instagram',
  youtube: 'Youtube',
  twitter: 'Twitter',
  linkedin: 'Linkedin',
  allowedImagesOnly: 'Allowed images only',
  allowedVideosOnly: 'Allowed videos only',

  addCertificates: 'Add certificate',
  addLocation: 'Add Location',
  socialDetails: 'User Social Details',
  socialDetails_sub: 'Add your social links',
  userDetails_sub: 'Fill in your details',
  introductionVideo: 'User introduction video',
  introductionVideo_sub: 'Add introduction video to boost your engagement',
  trainingLocations: 'Training locations',
  trainingLocations_sub: 'Add places you train with your clients',
  professionCertificates: 'Profession certificates',
  professionCertificates_sub: 'Upload your professional certificates',
  SearchAPlace: 'Search for a place',
  imagesAndVideos: 'Images & videos',
  myGallery: 'My Gallery',
  bio: 'Biography',
  userBiography: 'User biography',
  userBiography_sub: 'Write here about yourself and your personal experience in fitness.',
  potentialClientsList: 'Potential clients list',
  potentialClients: 'Potential\nClients',

  paymentsList: 'Payments List',
  payments: 'Payments',
  subscriptions: 'Subscriptions',
  transactions: 'Transactions',

  /* 03.Menu */
  default: 'Default',
  analytics: 'Analytics',
  ecommerce: 'Ecommerce',
  content: 'Content',
  thumbnail: 'Thumbnail',
  backgroundImage: 'Background Image',
  pages: 'Pages',
  'data-list': 'Data List',
  'thumb-list': 'Thumb List',
  'image-list': 'Image List',
  details: 'Details',
  link: 'Link',
  login: 'Login',
  register: 'Register',
  error: 'Error',
  applications: 'Applications',
  alerts: 'Alerts',
  badges: 'Badges',
  buttons: 'Buttons',
  cards: 'Cards',
  carousel: 'Carousel',
  collapse: 'Collapse',
  dropdowns: 'Dropdowns',
  editors: 'Editors',
  'form-layouts': 'Form Layouts',
  'form-components': 'Form Components',
  'form-validations': 'Form Validations',
  'form-wizard': 'Form Wizard',
  icons: 'Icons',
  'input-groups': 'Input Groups',
  jumbotron: 'Jumbotron',
  modal: 'Modal',
  navigation: 'Navigation',
  'popover-tooltip': 'Popover & Tooltip',
  sortable: 'Sortable',
  tables: 'Tables',
  menu: 'Menu',
  create: 'Create new',
  subhidden: 'Subhidden',
  hidden: 'Hidden',
  visible: 'Visible',
  maps: 'Maps',
  landingpage: 'Landing Page',
  'multipage-home': 'Multipage Home',
  'singlepage-home': 'Singlepage Home',
  about: 'About',
  'auth-login': 'Auth Login',
  'auth-register': 'Auth Register',
  'reset-password': 'Reset Password',
  blog: 'Blog',
  'blog-list': 'Blog List',
  'blog-detail': 'Blog Detail',
  careers: 'Careers',
  confirmation: 'Confirmation',
  contact: 'Contact',
  features: 'Features',
  prices: 'Prices',
  videos: 'Videos',
  mailing: 'Mailing',
  invoice: 'Invoice',
  types: 'Menu Types',
  levels: 'Menu Levels',
  'third-level-1': 'Third Level 1',
  'third-level-2': 'Third Level 2',
  'third-level-3': 'Third Level 3',
  faq: 'Faq',
  'knowledge-base': 'Knowledge Base',
  authorization: 'Authorization',
  profile: 'Profile',
  product: 'Product',
  media: 'Media',
  aspire: 'Aspire',
  exercises: 'Exercises',
  list: 'List',
  update: 'Update',
  step2: 'Step 2',
  serverWorkers: 'Server Workers',
  miscellaneous: 'Miscellaneous',
  portfolio: 'Portfolio',
  social: 'Social',
  forms: 'Forms',
  components: 'Components',
  layouts: 'Layouts',
  validations: 'Validations',
  wizard: 'Wizard',
  List: 'List',

  /* 05.Pages */
  'add-new': 'ADD NEW',
  addNewItem: 'Add New Item',
  displayOptions: 'Display Options',
  advancedOptions: 'Advanced Options',
  orderby: 'Order By : ',
  'product-name': 'Product Name',
  category: 'Category',
  description: 'Description',
  status: 'Status',
  cancel: 'Cancel',
  submit: 'Submit',
  delete: 'Delete',
  'another-action': 'Another action',
  actions: 'Actions',
  header: 'Header',
  orders: 'Orders',
  rating: 'Rating',
  price: 'Price',
  ingredients: 'Ingredients',
  'is-vegan': 'Is Vegan',
  'order-status': 'Order Status',
  'bake-progress': 'Bake Progress',
  popularity: 'Popularity',
  comments: 'Comments',
  'mailing-info': 'Mailing templates use inline styling and table layout to be displayed well at various service providers. ' +
    'To provide better usability we include it as regular html with dangerouslySetInnerHTML.',
  'invoice-info': 'Invoice template has an inline styled version for usage outside of the project as well as React version.',
  'react-version': 'React Version',
  'inline-version': 'Inline Styled Html Version',
  like: 'Like',
  likes: 'Likes',
  questions: 'Questions',
  send: 'Send',
  addComment: 'Add a comment',
  location: 'Location',
  responsibilities: 'Responsibilities',
  photos: 'Photos',
  follow: 'FOLLOW',
  followers: 'FOLLOWERS',
  recentPosts: 'Recent Posts',
  friends: 'Friends',
  images: 'Images',
  purchase: 'PURCHASE',
  developer: 'DEVELOPER',
  team: 'TEAM',
  enterprise: 'ENTERPRISE',
  twofactorauthentication: 'Two factor authentication',
  teampermissions: 'Team permissions',
  '245Support': '24/5 Support',
  '247Support': '24/7 Support',
  'useractionsauditlog': 'User actions audit log',
  'featurecomparison': 'Feature Comparison',
  'pricecomparison': 'Price Comparison',


  // articles
  articleList: 'Article list',
  myArticles: 'My article',
  createArticle: 'Create an article',
  updateArticle: 'Update article',
  mediaCarousel: 'Media carousel',

  welcome: 'Welcome to React',
  intro: 'Intro',
  reviews: 'reviews',
  calendar: 'Calendar',
  categories: 'Categories',
  achievements: 'Achievements',
  // NavTop
  inviteClient: 'Invite client',
  'fill clients details': 'step 1: fill client\'s details',
  workouts: 'workouts',
  workoutsArchive: 'Workouts archive',
  createWorkout: 'Create Workout',
  createWorkout_sub: 'Fill Workout Program Details',
  chooseExercises: 'Choose Exercises',
  'Choose Your Exercises': 'Choose Your Exercises',
  workoutLogs: 'Edit Workout Menu',
  'Build Exercise Sets, reps & weight': 'Build Exercise Sets, reps & weight',
  createAProgram: 'Create A Program',
  programDetails: 'Program Details',
  createPlan_sub: 'Fill plan details',
  createProgram_sub: 'Fill Program details',
  addPrograms: 'Add Programs',
  'Select Programs for your program': 'Select Programs for your program',
  sessionStart: 'Session Start',
  'Session Started At': 'Session Started At: ',
  clientsList: 'Clients List',
  professions: 'Professions',
  statistics: 'Statistics',
  history: 'data',
  createBodyStats: 'Create Body Stats',
  updateBodyStats: 'Update Body Stats',
  loading: 'loading...',
  bodyStatsInfo_title: 'Stats information',
  bodyStatsInfo_sub: 'date:',
  'Update your current Body Stats': 'Update your current Body Stats',
  startSession: 'Start Session',
  createPastSession: 'Create a past session',
  updatePastSessions: 'Update a past session',
  updatePastSessionsFP: 'Update a session for this program',
  workoutsCompleted: 'Workouts Completed',
  tonnageLifted: 'tonnage Lifted',
  totalSets: 'Total Sets',
  totalReps: 'Total Reps',
  exercisesDone: 'Exercises Done',
  active: 'Active',
  completed: 'Completed',
  programsTitle: 'Program\'s Title',
  settings: 'Settings',
  applicationSettings: 'Application settings',
  recentSessions: 'Recent sessions',
  workoutPrograms: 'Workout Programs',
  workoutPlans: 'Plans',
  workoutsPerWeek: 'Workouts per week',
  currentWeek: 'Current week',
  assignToClient: 'Assign to client',
  of: 'of',
  programs: 'Programs',
  minutes: 'minutes',
  hours: 'hours',
  days: 'days',
  day: 'day',
  today: 'today',
  weeks: 'weeks',
  week: 'Week',
  months: 'months',
  times: 'times',
  chooseClient: 'Choose client',
  chooseClients: 'Choose clients',
  chooseAchievementType: 'Choose achievement type',
  'Using this Program': 'Using this Program',
  exerciseList: 'Exercise list',
  activityHistory: 'Activity history',
  currentProgram: 'Current Program',
  // body stats labels
  height: 'Height',
  weight: 'Weight',
  muscleMass: 'Muscle Mass',
  fat: 'Fat',
  calories: 'Calories',
  water: 'Water',
  bmi: 'BMI',
  BMI: 'BMI',
  bodyStats: 'Body stats',
  bodyStats_sub: 'Latest stats by client',
  bodyStats_sub_client: 'Your latest stats',
  performance: 'Performance',
  performance_sub: 'Latest stats by client',
  performance_sub_client: 'Your latest stats',
  personalTrainer: 'Personal Trainer',
  straight: 'Straight',
  superset: 'Superset',
  super_set: 'Superset',
  pyramid: 'Pyramid',
  interval: 'Interval',
  aerobic: 'Aerobic',

  kilograms: 'Kg / T',
  pounds: 'Lbs / Pound',
  meter: 'Cm / M', // M
  male: 'Male',
  female: 'Female',
  group: 'Group',
  personal: 'Personal',
  other: 'Other',
  public_id: 'Public ID',
  uri: 'URL',
  order: 'Order',
  email: 'Email',
  title: 'Title',
  makeItTopArticle: "sign article as 'Top Article' (will be shown on top of your article list)",
  shortDescription: 'Short Description',
  sourceName: 'Source name',
  sourceLink: 'Source link',
  password: 'Password',
  inquiry: 'Inquiry',
  solution: 'Solution',
  confirmPassword: 'Confirm password',
  oldPassword: 'Current password',
  newPassword: 'New password',
  passwordOrInvite: 'Password / Invite code',
  resetCode: 'Reset password code',
  forgotPassword: 'Forgot password?',
  alreadyHaveTheCode: 'I already have the code',
  inviteCode: 'Invitation code',
  firstName: 'First name',
  lastName: 'Last name',
  BirthDate: 'Birth date',
  aboutMe: 'About me',
  weightUnits: 'Weight units',
  distanceUnits: 'Distance units',
  language: 'Language',
  startDate: 'Starts on',
  endDate: 'Complete on',
  date: 'Date',
  duration: 'Duration',
  phone: 'phone',
  workoutPerWeek: 'Workout per week',
  'num. of weeks': 'Note: count in weeks',
  'num. of times': 'Note: num. of times',
  updateStats: 'Update stats',
  chooseImage: 'Choose image',
  mediaLibrary: 'Media library',
  uploadImage: 'Upload image',
  gallery: 'Gallery',
  myMedia: 'My media',
  type: 'Type',
  exercise: 'Exercise',
  set: 'Set',
  sets: 'Sets',
  reps: 'Reps',
  time: 'Time',
  speed: 'Speed',
  distance: 'Distance',
  intensity: 'Intensity',
  confirm: 'Confirm',
  updatePassword: 'Update Password',
  save: 'Save',
  saveChanges: 'Save changes',
  done: 'Done',
  edit: 'Edit',
  expand: 'Expand',
  backToCards: 'Back to cards',
  back: 'Back',
  skip: 'Skip',
  add: 'Add',
  remove: 'Remove',
  addSet: 'Add set',
  removeSet: 'Remove set',
  setAdd: 'Set Added',
  moreDetails: 'More details',
  skipped: 'Skipped',
  resetChanges: 'Reset Changes',
  exitEditMode: 'Exit edit mode',
  clientInvite: 'Client\'s invite',
  enter: 'Enter',
  info: 'Info',
  information: 'Information',
  help: 'Help',
  helpCenter: 'Help Center',
  myInquiries: 'My inquiries',
  exerciseInfo: 'Exercise info',
  logout: 'Logout',
  dontHaveAnAccount: `Hey trainer, don't have an account?`,
  muscleId: 'Muscle ID',
  title_he: 'Title HEB',
  title_en: 'Title ENG',
  description_he: 'Description HEB',
  description_en: 'Description ENG',
  execution_en: 'execution ENG',
  execution_he: 'execution HEB',
  preparation_en: 'preparation ENG',
  preparation_he: 'preparation HEB',
  moreOptions: 'More options',
  exerciseNotes: 'Exercise notes',
  clickHereFullScreen: 'Click here to view in full-screen',
  close: 'Close',
  notes: 'Notes',
  editExerciseName: 'Change exercise name',
  changeExerciseFields: 'Change exercise fields',
  deleteExercise: 'Delete exercise',
  myProfile: 'My profile',
  notice: 'Notice',
  your: 'Your ',
  invitationCode: 'Invitation code',
  inviteSentInfo: 'In a few minutes your client will be invited to Aspire via email.',
  NoWorkoutsToShow: 'No Registered Workout Programs',
  clickHereToCreateProgram: 'Create your first workout program here!',
  NoActivitiesToShow: 'Couldn\'t find any activities',
  NoGoalsToShow: 'No goals recorded',
  NoStatsToShow: 'No performance stats recorded.',
  NoStatsToShow_sub: 'A training session needs to be recorded in order to update performance stats',
  clickHereToCreateGoal: 'Create a goal for you / your client',
  NoAchievementToShow: 'No goals have been achieved, yet!',
  ChangeLogToCompleted: 'Current set has been declared as "Skipped", should it be updated to "Completed"?',
  CLIENT: 'Client',
  TRAINER: 'Trainer',
  NUTRITIONIST: 'Nutritionist',
  english: 'English',
  hebrew: 'Hebrew',
  'Choose Program type': 'Choose Program type',
  exercisesSelected: 'Exercises selected',
  againstEquipment: 'with',
  addExercise: 'Add exercise',
  addExerciseToSession: 'Add exercise to Session',
  addExerciseInfo: 'Click here to add a new exercise',
  addSuperSet: 'Add super-set',
  selectedExercises: 'Selected exercises',
  moveExercisesInfo: 'Drag your exercises into the order you want for your workout',
  bodyWeightInfo: `insert '0' in the weight field will be consider as 'body weight'`,
  aerobicFieldsInfo: `insert '0' in a field will be considered as ignored`,

  programsSelected: 'Programs selected',
  'notice Changing Current Program': 'Changing current Program will exit \'Assign Mode\' and create a new Program instead, are you sure?',
  assignAsAchieved: 'Assign target as achieved',
  assignAsAchieved_notice: 'Are you sure you want to mark this target as achieved?',
  assignAsAnswered: 'Mark as answered',
  assignAsAnswered_notice: 'was our replay helpful?',
  resetProgressToThisWeek_notice: 'Are you sure you want to delete all changes made to this week\'s progress?',
  total: 'Total',
  more: 'More',
  liftingTime: 'Lifting Time Spent',
  avgWorkoutTime: 'Avg. Workout Time',
  avgRestTime: 'Avg. Rest Time',
  showMore: 'Show More',
  saySomething: 'A few words...',
  howWasSession: 'Rate your training session',
  sessionTime: 'Session time',

  preparation: 'Preparation',
  execution: 'Execution',
  muscles: 'Muscles',
  equipment: 'Equipment',
  defaultEquipment: 'Default Equipment',
  exerciseType: 'Exercise Type',
  muscleMap: 'Muscles Map',
  target: 'Target',
  synergist: 'Synergist',
  stabilizers: 'Stabilizers',
  antagonist: 'Antagonist',
  removeExercise: 'Remove Exercise',
  removeExerciseDesc: 'Are you sure you want to remove selected exercise\'s?',
  clickToDelete: 'Delete exercises',
  hi: 'Hi',
  sessionSection1: 'who are we training today?',
  sessionSection2: 'please choose a workout Program',
  'Choose Client & Program': 'Choose client & Program',
  chooseProgram: 'Choose Program',
  deleteImage: 'Delete Image',
  editImage: 'Edit Image',
  defaultImage: 'Default Image',
  makeMediaDefault: 'Make this media as default',
  areYouSure_delete: (name) => (`Are you sure you want to delete "${name}"?`),
  areYouSureFinishSession: 'Are you sure you want to finish current training session?',
  theseBodyStats: 'these body stats',
  me: 'Me',
  isPersonalTrainingSession: 'Current session is a part of the "personal training" program',
  sessionSummery: 'Session summery',
  bestSets: 'Best sets',
  current_state: 'Current',
  target_state: 'Target',
  initial_state: 'Initial',
  initialvalue: 'Initial',
  targetvalue: 'Target',
  kg: 'kg',
  KG: 'kg',
  Lbs: 'lbs',
  LBS: 'lbs',
  Ft: 'Ft',
  FT: 'Ft',
  Yd: 'Yd',
  YD: 'Yd',
  T: 'T',
  cm: 'cm',
  CM: 'cm',
  inch: 'Inches',
  INCH: 'Inches',
  FEET: 'Feet',
  yard: 'Yard',
  YARD: 'Yard',
  mile: 'Mile',
  MILE: 'Mile',
  km: 'km',
  KM: 'km',
  m: 'm',
  M: 'M',
  kcal: 'kcal',
  Kcal: 'kcal',
  KCAL: 'kcal',
  percent: '%',
  PERCENT: '%',
  '%': '%',
  M_KM: 'Cm / M / Km',
  YARD_MILE: 'Inches / Yards / Miles',
  KG_CM: 'Kg / Cm',
  LBS_INCH: 'Lbs / Inch',
  NoStatsRecorded: 'No stats recorded',
  activeAchievements: 'Active goals',
  completedAchievements: 'Achievements',
  createProgram: 'Create a Program',
  createPlan: 'Create a plan',
  myExercises: 'My exercises',
  myExercises_sub: 'My exercises list',
  inquiriesAnswers: 'Inquiries & Answers',
  inquiriesAnswers_sub: 'All you need to know about ASPIRE',
  createInquiry_sub: 'And we\'ll respond as soon as possible',
  createSolution: 'Create A Solution',
  createSolution_sub: 'Write your solution',
  exerciseStats_title: 'Exercise: ',
  programsStats_title: 'Program: ',
  'type in kg': 'Type in Kg',
  'type in cm': 'Type in Cm',
  'type in %': 'Type in %',
  'type in Kcal': 'Type in Kcal',
  'type in lbs': 'Type in LBS',
  'type in inch': 'Type in inches',
  searchKeywords: 'Search keywords',
  mediaGroup: 'Media Group',
  '': '',
  EN: 'English',
  HE: 'עברית',
  image: 'Image',
  video: 'Video',
  session_of: 'session of',
  chooseGoal: 'Choose one goal',
  chooseGoalSub: 'Choose a performance/body stats goal',
  goalDetails: 'Goal Details',
  goalDetailsSub: 'Fill your goal details',
  createGoal: 'Create a Goal',
  yes: 'Yes',
  no: 'No',
  min: 'min',
  sec: 'sec',
  lastUpdatedIn: 'Updated in',
  showInGraph: 'Show graph for',
  nextExercise: 'Next Ex.',
  prevExercise: 'Prev Ex.',
  currentExercise: 'Current Exercise',
  next: 'Next',
  prev: 'Previous',
  later: 'Later',
  seeAchievement: 'See Achievement',
  congratulations_sub: 'Congratulations! you achieved a Goal you\'ve set!',
  wellDone: 'well Done!',
  'please write your notes here': 'Please write your notes here...',
  addToList: 'Add to list',
  changePassword: 'Change password',
  changePassword_sub: 'Please update your password',
  createCategory: 'Create Category',
  categories_sub: `Aspire's Category List`,
  resendInvitation: 'Resend Invitation',
  profileDetails: 'My Profile Details',
  restDay: 'Rest day',
  'type old password': 'Type old password',
  'type new password': 'Type new password',
  'title in english (required)': 'Title in english (required)',
  onlyEnglishLetters: 'Should only contain english letters',
  'type in exercise title': 'Type in exercise title',
  createExercise: 'Create exercise',
  createExerciseAdmin: 'Create exercise',
  'fill exercise details': 'Fill exercise details',
  achievementDetails: 'Achievement details',
  achievementDetails_loading: 'Load achievement...',
  myActivities: 'My Activities',
  myActivities_sub: 'All your updates are listed here',
  bestSet: 'Best set',
  tonnage: 'Tonnage',
  bestTime: 'Best time',
  mostReps: 'Most reps',
  highestWeight: 'Highest weight',
  longestTime: 'Longest time',
  longestDistance: 'Longest distance',
  bestByRM: 'Best by RM',
  editName: 'Edit ex. name ',
  top: 'Top',
  center: 'Center',
  bottom: 'Bottom',
  BARBELL: 'Barbell',
  DUMBBELL: 'Dumbbell',
  MACHINE: 'Machine',
  SMITH_MACHINE: 'Smith Machine',
  CABLE: 'Cable',
  BODY_WEIGHT: 'Body Weight',
  KETTELE_BELL: 'KettleBell',
  TRX: 'TRX',
  FREE_WEIGHTS: 'Free Weights',
  PAUNCH_MITTS: 'Punch Mitts',
  WEIGHT_BAG: 'Weight Bag',
  bodyWeight: 'Body Weight',
  bodyWeightShort: 'body w.',
  processingImage: 'Processing Image',
  connect: 'Connect',
  uploading: 'Uploading...',
  errorUploadingFile: 'Could not upload image',
  addMedia: 'Add media',
  upTo5Imgs: 'up to 5 images',
  skipEx: 'Skip Ex.',
  change: 'Change',
  nextSet: 'Next set',
  noExercisesAdded: 'No exercises added',
  pleaseGoBack: 'Please go back',
  session_has_ended: 'Session has ended',
  changeCurrentWeek: 'Change current week',
  changeCurrentSessionNum: 'Change current session num',
  statistics_of: name => `${name || ''}'s statistics`,
  history_of: name => `${name || ''}'s history`,
  workouts_of: name => `${name || ''}'s workouts`,
  activeAchievements_of: name => `${name || ''}'s goals`,
  completedAchievements_of: name => `${name || ''}'s achievements`,
  bodyStats_of: name => `${name || ''}'s body stats`,
  bodyStatsDetails: 'Loading...',
  bodyStatsDetails_of: name => `${name || ''}'s body stats`,
  bodyStatsDetails_sub: (name, stat) => `${name || ''}'s ${stat || ''} history`,
  performance_of: name => `${name}'s performance`,
  performanceDetails: `Performance history`,
  performanceDetails_of: name => `${name || ''}'s performance`,
  performanceDetails_sub: (name, stat) => `${name || ''}'s ${stat || ''} performance`,
  performanceDetails_exerciseStats_sub: name => `${name || ''}'s exercise performance`,
  performanceDetails_programsStats_sub: name => `${name || ''}'s Program performance`,
  achievementDetails_sub: name => `Accomplishments by ${name || ''}`,
  lastRegisteredStat: `Last registered stats: `,
  aspireCommentTitle: () => `Aspire Says`,
  HALF_TIME_DOME: (timeLeft, log) => `You're half way there, time left : ${timeLeft}`,
  TIME_DOME_75: (timeLeft, log) => `We're getting closer, time left : ${timeLeft}`,
  TIME_DOME_90: (timeLeft, log) => `Almost Done, time left : ${timeLeft}`,
  TIMER_TIME_DOME: (timeLeft, log) => `Done! Good job!`,
  usingThisProgram: (title, endDate) => `used by '${title}' until ${endDate}`,
  addProgramToPlan: 'Add this program to your plan',
  myClientsList: 'My clients list',
  myClientsList_sub: 'update/unlink a client from your list',
  refreshInfo: 'Refresh info',
  unlink: 'Unlink',
  clientDetails: 'Client\'s details',
  unlink_question: clientName => `are you sure you want to unlink '${clientName}' from your client List?`,

  /* validation */
  pleaseChooseClient: 'Please choose a client',
  isRequired: name => `${name} is required`,
  tooShort: (name, value) => `${name} must be longer than ${value} characters`,
  tooLong: (name, value) => `${name} must be less than ${value} characters`,
  typeMismatch: name => `${name} has a mismatch value`,
  rangeUnderflow: (name, value) => `${name} must be greater/equal than ${value}`,
  rangeOverflow: (name, value) => `${name} must be less/equal than ${value}`,
  rangeBetween: (name, min, max) => `${name} must be between ${min || '0'} to ${max || '9999'}`,
  notAllowed: (type) => `${type} is not allowed`,
  passwordsMismatch: 'passwords do not match',
  'Please include an \'@\' in the email address': `Please include '@' in the email field`,
  typeMismatchReps: `'Reps' has a mismatch values`,
  typeMismatchReps_sub: `Current goal requires equal 'reps' value`,
  typeMismatchWeights: `'Weight' has a mismatch values`,
  typeMismatchWeights_sub: `'Weight' values cannot be equal to 0`,
  termsAgreement_1: 'By clicking Sign Up, you agree to our ',
  termsOfService: 'Terms of Service',
  termsAgreement_2: ' and that you have read our Data Use ',
  policy: 'Policy.',
  termsAgreement_3: ' ',
  privacyPolicy: 'Privacy policy',

  // error handler text
  emailAlreadyInUse: 'Email already in use',
  noUserFound: 'User not in the system',
  badCredentials: 'Please check your Password/email',
  emailNotVerified: 'Email is not verified',
  accessDenied: 'Access Denied',
  unhandledError: 'Something went wrong :(',
  wrongPassword: 'Wrong password, try again',
  lockForHour: 'Lock for 1 hour',
  sendAgain: 'Send again',
  cantFindVerificationMail: 'Cant find your verification mail?',
  cantFindVerificationMail_comment:  '(please check your \'promotions\' inbox as well)',
  verificationSent: 'Verification massage sent',
  signUpBy: 'Sign up by',
  signinBy: 'Sign in by',
  inviteBy: 'Invite by',
  /* onBoarding */
  // profile
  yourBusinessPlatform: 'Your business platform',
  yourBusinessPlatform_desc: 'show your abilities and mindset by sharing media, Programs, and clients results.\n soon to be showcase to potential clients.',
  inviteClients: 'Exclusive to your clients',
  inviteClients_desc: 'Invite your personal clients for an exclusive community made for you and them and stay engaged 24/7.',
  // create workout
  creatingaWorkoutProgram: 'Creating a workout Program',
  creatingaWorkoutProgram_desc: 'There are 3 steps for creating a workout Program.\n' +
    'First, choose a client / yourself from the clients list, choose an Image from ASPIRE library or your mobile and give your workout a title.',
  selectTheExercises: 'Select the exercises',
  selectTheExercises_desc: 'Select the exercises you want in your workout, ' +
    'find exercises by category, muscle & equipment.',
  customizeWorkoutProgram: 'Customize you workout Program',
  customizeWorkoutProgram_desc: '1. Choose equipment to each exercise.\n' +
    '2. Add / edit number of sets, reps and weight.\n' +
    '3. Change exercise name - for client\'s better understanding.\n' +
    '4. Add your own notes to each exercise so the client will remember them.\n' +
    '5. remove exercise from workout Program',
  creatingGoal: 'Create a goal',
  creatingGoal_desc: 'There are 2 steps for goal creation. \n' +
    'Firstly, choose a client and a certain body stat/exercise.',
  fillGoalsDetails: 'Fill Goal Details',
  fillGoalsDetails_desc: 'Fill the goal\'s details including title, description, time frame for the goal.\n' +
    'insert an initial & target states to measure your client\'s progress.\n' +
    '* The app will show you the client\'s latest stats recorded on the chosen exercise / body stats (depends if there is a record in the app)',
  helpful: 'Helpful',
  unhelpful: 'Unhelpful',
  steps: 'Steps',
  solutions: 'Solutions',
  preview: 'Preview',
  makeDefault: 'Make Default',
  mediaList: 'Media list',
  uploadNewMedia: 'Upload New Media',
  mediaDetails: 'Media Details',
  usersList: 'Users list',
  createUser: 'Create User',
  userDetails: 'User Details',
  userCredential: 'User Credential',
  restPassword: 'Reset Password',
  blockUser: 'Block User',
  userSettings: 'User Settings',
  informationList: 'Information List',
  informationDetails: 'Information Details',
  inquiryList: 'Inquiry list',
  InquiryDetails: 'Inquiry Details',
  createInquiry: 'Create inquiry',
  getAuthKey: 'Get User\'s Token',
  helloIm: 'Hello I\'m',
  contactYouFrom: 'A contact request from ',
  aspireWebsite: 'ASPIRE website',
  by: ' by ',
  hereAListOfContactWillAppear: 'Potential clients who will contact you thru ASPIRE website will be enlisted here.',
  somethingWrong: `Something went wrong, please try again`,
  welcomeUserToAspire: name => `Welcome ${name}!`,
  welcomeUserToAspire_desc: 'You are now a part of our community!\n' +
    'its time to create your own professional environment, and build yourself as a brand.',
  congratulations: 'Congratulations!',
  dummyUserAppended: 'Your registration is complete!\n' +
    'Meet Alice, your first client at Aspire!\n' +
    'With Alice, you\'ll get to experience everything that Aspire has to offer!',
  addDescription: 'Add description',
  'Are you sure you want to delete': 'Are you sure you want to remove',

  // calendar
  eventsHour: "Event's time",
  allDay: 'All day',
  hide: 'Hide',
  dontShowAgain: 'Dont show me again',
  personalProgram: 'Personal program - one client',
  groupProgram: 'Group program - num. of clients',
  pickAColor: 'Pick a color',
  selectDate: 'Select date',
  selectDates: 'Select dates',
  chooseDateRange: 'Select date range',
  makeAllDayEvent: 'This event is active all day.',
  createAnEvent: 'Create an event',
  bookASession: 'Book a session',
  editAnEvent: 'Update session details',
  rescheduleAnEvent: 'Reschedule session',
  writeATitle: 'Write a title...',
  participants: 'Participants',
  recurringEvent: 'Repeated event options',
  openRecurringOptions: 'Open repeated event options',
  repeatEvery: 'Repeat every',
  repeatEvery_day_info:
    'The event will be repeated every day or every x days, depending on the value',
  repeatEvery_week_info: 'The event will be repeated every x weeks on specific weekdays',
  repeatEvery_month_info: 'The event will be repeated every x month on specific day of the month',
  stopConditions: 'Stop conditions',
  neverStop: 'Never stop.',
  neverStop_info: 'The event will repeat indefinitely.',
  untilDate: 'Run until a specific date.',
  untilDate_info: 'The event will run until it reaches a specific date.',
  untilNumber: 'Run event',
  untilNumber_info: 'The event will repeat until it reaches a certain amount of times.',
  eventDates: "event's date",
  trainer: 'Trainer',
  confirmArrival: 'Confirm Arrival',
  cancelArrival: 'Cancel',
  postpone: 'Postpone',
  reschedule: 'Reschedule',
  repeatedEvent: 'Repeated event',
  thisEventRepeats: 'This event repeats',
  for: ' for ',
  until: ' until ',
  weekly2: ' every week ',
  daily2: ' every day ',
  inDays: ' on ',
  editRecurringEventSelect: 'Edit recurring event',
  deleteRecurringEventSelect: 'Delete recurring event:',
  thisEventOnly: 'This event only.',
  thisEventAndFollowing: 'This and following events.',
  allEvents: 'All events',
  pending: 'Pending',
  confirmed: 'Confirmed',
  canceled: 'Cancelled',
  noDatesSelected: 'No dates have been selected for reschedule.',
  onGoing: 'Ongoing',
  calendarSettings: 'Calendar settings',
  rescheduleReason: 'Reschedule reason',
  sundayToSunday: 'Sunday to sunday',
  mondayToMonday: 'Monday to monday',
  firstDayOfTheWeek: 'First day of the week',
  firstDayOfTheWeek_sub: 'Set your week to start from Sunday or Monday.',
  restrictAvailableHours: 'Set operating hours',
  restrictAvailableHours_sub: 'Setting operating hours will allow your Clients to schedule workouts only at the time allowed between the two values.',
  timeInputSettings: 'Time Input Settings',
  timeInputSettings_sub: 'Configure the way you want your time input to look and feel.',
  timeInputExample: 'Click here to see the changes you\'ve made on the input.',
  daysOff: 'Days off',
  daysOff_sub: 'Configure days your clients wont be able to schedule workouts. For example: Saturday, Sunday.',
  timegrid: 'Time grid',
  timeWheel: 'Time wheel',
  fiveMinutes: '5 Minutes',
  tenMinutes: '10 Minutes',
  fifteenMinutes: '15 Minutes',
  thirtyMinutes: '30 Minutes',
  watchYoutubeVideo: 'Watch Youtube video',
  bookSession: 'Book',
  waitingForResponse: 'waiting for response',
  confirmedArrival: 'Confirmed arrival',
  routines: 'Routines',
  hour: '1 Hour',
  and: 'and',
  month: 'Month',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  startTime: 'Start',
  endTime: 'End',
  chooseTrainer: 'Choose trainer',
  clientsParticipates: 'Participants',

  smsOnItsWay: 'SMS sent',
  smsWillBeReceived: "In a few seconds you'll receive an SMS with a 5-digit code to enter.",
  resendSMS: 'Resent SMS',
  insertTrainerInvite: 'Invitation code',
  insertTrainerInvite_sub:
    'Please insert your invitation code you got from our SMS or from your trainer.',


  calendar_sub: 'A weekly calendar',

  myAchievements: 'My Achievements',
  // NavTop
  invite: 'Invite client',
  resend: 'Resend',
  myWorkouts: 'My Workouts',
  workoutsArchive_2: 'Workouts archive',
  sessionsArchive: 'Sessions archive',
  workoutPlanArchive: 'Workout plans archive',

  chooseExercises_sub: 'Select exercises from the list',
  chooseExercise: 'Select exercise',
  chooseExercise_sub: 'Select exercise from the list',
  workoutLoggerHeader: 'Edit Workout Menu',
  workoutLoggerHeader_sub: 'Build exercise sets, reps & weight',
  routineUpdateDetails: 'Edit routine details',
  routineUpdateDetails_sub: 'Update routine title & settings',
  createAWProgram: 'Create a workout',
  getAFreeProgramTemplate: 'Get A Free Program',
  aspireIntroductionVideoTrainer: 'Aspire Introduction Video',
  aspireIntroductionVideoClient: () => 'Aspire Introduction Video',

  modifyProgress: 'Modify Weekly Progress',
  modifyProgress_sub: 'build an automated progress per week.',
  modifyProgramsProgressPage_info:
    'Choose a program, modify variables by desired week (reps, weights, rest...).\nThese variables will be updated for the client at the desired week when they start a session with ASPIRE.',
  'Select Programs to Add': 'Select programs to add',
  MyStatistics: 'My Statistics',

  UpdateYourCurrentBodyStats: 'Update your Current Body Stats',

  copyProgramToClient: 'Copy program to another client',
  copyProgram: 'Copy program',
  copyProgramToProfile: 'Copy program to my profile',

  eventsTitle: "Event's Title",

  sessionsHistory: "Session's History",

  sendDocToClients: 'Send document to clients',
  sendSurveyToClients: 'Send survey to clients',
  copySurveyToMe: 'Copy survey & send to clients',
  copyDocToMe: 'Copy Document to my account',

  UsingThisProgram: 'Using this program',

  chooseEquipment: 'Select equipment',
  choose: 'Choose',
  // body stats labels

  shoulders: 'Shoulders',
  arms: 'Arms',
  leftArm: 'Left arm',
  rightArm: 'Right arm',
  chest: 'Chest',
  waist: 'Waist',
  hips: 'Hips',
  thighs: 'Thighs',
  leftThigh: 'Left thigh',
  rightThigh: 'Right thigh',
  lowerThighs: 'Lower thighs',
  leftLowerThigh: 'Left Lower thigh',
  rightLowerThigh: 'Right Lower thigh',
  calves: 'Calves',
  leftCalf: 'Left Calf',
  rightCalf: 'Right Calf',

  isAdvancedMeasurements: 'Advanced measurements',
  addBodyMeasurements: 'add body measurements',
  bodyMeasurements: 'Body measurements',

  certifiedPersonalTrainer: 'Certified Personal Trainer',

  strength: 'Strength',
  static: 'Static',

  group_option: 'Group',
  personal_option: 'Personal',
  public_option: 'Public',
  store_option: 'Store',
  other_option: 'Other',
  chooseLanguage: 'Choose language',
  numOfWeeks: 'Note: Count in weeks',
  numOfTimes: 'Note: num. of times',
  uploadFile: 'Upload file',
  uploadVideo: 'Upload video',
  thisMedia: 'This media',
  round: 'Round',
  rest: 'Rest time',
  timeDone: 'Time done',
  totalTimeDone: 'Total time',
  totalDistance: 'Total distance',
  clear: 'Clear',
  leave: 'Leave',
  assignAsDone: 'Completed',
  markAsCompleted: 'Mark as completed',
  finishSession: 'Finish Session',
  finishSession_desc: 'Click here to finish current session.',
  modifyDoc: 'Modify document',
  changeRepsWeight: 'Change Reps/Weight',
  register_login: 'Sign up / Login',
  invitedClient: 'Invited client',
  signin: 'Sign in',
  signup: 'Sign up',
  createAccount: 'Create trainer account',
  support: 'Support',
  exerciseName: 'Exercise name',
  changeExerciseOrder: 'Change exercise order',
  addExerciseToSuperSet: 'Add exercise to superset',
  store: 'Store',
  alert: 'Alert',
  thisModalCantBeOpened: "This popup can't be opened while uploading, please try again later.",
  inviteSentInfoByEmail: 'In a few minutes, your client will be invited to Aspire via email.',
  inviteSentInfoSpam: "(Please check the 'Promotions' inbox as well)",
  inviteSentInfoBySMS: 'Your client will receive an invite to Aspire via SMS.',
  inviteSentInfoBySMS_sub: (name) =>
    `If ${
      name || 'your client'
    } don't get an SMS from us in the next few minutes, invitation code is:`,
  NoWorkoutsToShowFor: (name) => `No registered workout programs for ${name}`,
  selectProgramType: 'Select Program type',
  selectProgramView: 'Select Program View',
  selectExercises: 'Select exercises',
  selectExercise: 'Select exercise',
  addExercises: 'Add exercises',
  reorderExercises: 'Reorder Exercises',
  reorderExercises_sub: 'Reorder or remove Exercises',
  noticeChangingCurrentProgram:
    "Changing current Program will exit 'Assign Mode' and create a new Program instead, are you sure?",
  noticeUnsavedChanges: (numbers) =>
    `workouts ${numbers} have not been saved and still are in 'Edit Mode', are you sure you want to continue?`,
  goBackWithoutSave_notice: "Going back without saving will reset all your changes, are you sure you want to go back?",
  showLess: 'Show less',
  archive: 'Archive',
  difficulty: 'Difficulty',
  chooseDefaultEquipment: 'Select default equipment',
  filter: 'Filter',
  areYouSureRemoveEquipment: 'Are you sure you want to remove selected media from the equipment?',
  sessionSummary: 'Session summary',
  replayInquiry: 'Replay',
  replayInquiry_sub: 'Write your replay to us.',
  blank: '',
  ES: 'Español',
  EN_short: 'Eng',
  HE_short: 'עב',
  ES_short: 'Es',
  updateUserCredentials: 'Update user credentials',
  updateUserCredentials_sub: 'Update your login credentials',

  resendInvitationAgain: 'Send Again',
  updateResendInvitation: 'Update & resend Invite',
  inviteAnotherClient: 'Invite another client',

  emailSent: 'Email sent',
  invitationSent: 'Invitation sent',
  invitationSentError: 'Error sending invite',
  typeOldPassword: 'Type old password',
  typeNewPassword: 'Type new password',
  createExercise_sub: 'Create your own exercises here.',
  upToImgs: (number) => `Up to ${number} uploads`,
  fullScreen: 'Full Screen',
  timer: 'Timer',
  stopwatch: 'Stopwatch',
  /* session translations */
  subscription: 'Aspire Services',
  subscription_sub: 'Update service subscription for Aspire',


  /* validation */
  pleaseSelectRoutine: 'Please select a routine',
  invalidDate: 'Invalid date',
  missingDate: 'Date is required',

  askMeAgain: 'Ask me again next time.',
  noThankYou: 'No thank you',
  letMyTry: 'Let me try on my own',
  checkTermsAgreement: 'Agree to aspire terms please',
  // error handler text

  checkEmailFirst: 'Check your email before submit',
  emailAlreadyExist: 'Email already exist',
  emailIsGood: 'Email is good',
  invalidName: 'Invalid name',
  invalidEmail: 'Invalid email',
  emailNotExist: 'Email doesnt exist',
  invalidPhoneNumber: 'Invalid phone number',
  limitReached: 'Limit reached',
  invalidForm: 'Invalid form',
  invalidCode: 'Invalid code',
  userAlreadyExist: 'User already exist',
  phoneAlreadyExist: 'Phone number already exist',
  phoneNotExist: 'Phone doesnt exist',
  userSuspended: 'User is suspended',
  alreadyLoggedIn: 'User already logged in',
  progress: 'Progress',
  program: 'Program',
  /* onBoarding */
  // profile
  // create workout
  fillWorkoutDetails: "Fill Program's Details",
  fillWorkoutDetails_desc:
    'For program creation, there are 3 stages a trainer needs to complete.\n' +
    'The fist one is filling in the details on the program:',
  fillWorkoutDetails_list: [
    'Select a client / yourself from the clients list.',
    'Give your workout a title',
    'Choose an Image from ASPIRE library or your mobile',
  ],
  customizeYourProgram: 'Customize your program',
  customizeYourProgram_list: [
    'Select equipment for each exercise.',
    'Add / edit number of sets, reps and weight.',
    'Add your own notes to each exercise',
    "Change exercise name - for client's better understanding.",
    'Remove any exercise.',
  ],
  creatingGoal_list: [
    'Choose a client / yourself from the clients list.',
    'Select goal type: Body/Performance.',
    'Select a certain goal from the list.',
  ],
  fillGoalsDetails_list: [
    "Fill the goal's details: title, description, time frame for the goal.",
    "insert an initial & target states to measure your client's progress.",
    "The app will show you the client's latest stats recorded on the chosen exercise / body stats (depends if there is a record in the app)",
    'Attention: if a performance goal has been selected, you need to Select equipment and performance measure type. \n' +
    'for example: best set, most reps, highest weight etc...',
  ],
  fillExerciseDetails: "Fill Exercise's Details",
  fillExerciseDetails_desc: 'Give your exercise a name & write instructions for execution.',
  uploadEquipmentMedia: 'Upload equipment media',
  uploadEquipmentMedia_desc: 'Upload a media to each equipment\n*It is not a required step.',
  replay: 'replay',
  chooseMediaType: 'Choose media type',
  uploadCertificates: 'Upload certificates',
  uploadCertificates_desc:
    "Please upload documents that verify your training in the fields you've checked.\n" +
    '(you can also skip this stage by swiping)',
  goodLuck: 'Welcome to Aspire - Become the Best You Can Be!',
  orSkipAndUploadLater: "Or skip by clicking 'Next' & upload later.",
  uploadImages: 'Upload Images',
  uploadImages_desc: 'Select & upload your images',
  uploadVideos: 'Upload videos',
  uploadVideos_desc: 'Select & upload your videos or press the back button on top',
  uploadFiles: 'Upload Files',
  uploadFiles_desc: 'Select & upload your files or press the back button on top',
  uploadProfileImage: 'Upload profile image',
  uploadBackgroundImage: 'Upload background image',
  welcomeToAspire_desc:
    'You are now a part of our community!\n' +
    'its time to create your own professional environment, and build yourself as a brand.',
  welcomeToAspireClient_desc:
    'Your personal trainer has invited you to an exclusive environment to enjoy a more personal & professional experience.',
  clickNextOrSwipe: "click 'Next' or swipe to continue.",
  noSessionFound: 'No information found about current session',
  documents: 'Documents',
  myDocuments: 'My Documents',
  documents_of: (name) => `${name || ''}'s documents`,
  surveys_of: (name) => `${name || ''}'s surveys`,
  surveys: 'Surveys',
  documentArchive: 'Document archive',
  surveyArchive: 'Survey archive',
  createDocument: 'Create new document',
  createDocument_sub: 'Fill document details',
  updateSurvey: 'Update survey',
  updateSurvey_sub: 'Change/add questions to current survey',
  updateDocument: 'Update document',
  updateDocument_sub: 'Update document details',
  createSurvey: 'Create new survey',
  createSurvey_sub: 'Fill survey details',
  surveyFillDetails: 'Fill survey',
  surveyFillDetails_sub: 'Answer the following questions.',
  documentsFilled: 'Documents filled',
  filled: 'Filled',
  templates: 'Templates',
  template: 'Template',
  forReviewSurvey: 'unreviewed surveys',
  forReviewDoc: 'unreviewed Documents',
  chooseClients_doc: 'Select clients to send them the document',
  addCheckboxToConfirmAgreement: `Add a check-box to the document to confirm the client has read and agrees to the terms.`,
  isAgreeText: 'add costume text to check-box',
  IAgreeToTerms: 'I have read and agree with the terms and conditions of this document.',
  isClientCanModifyDoc: `Allow the client to modify/change document's content.`,
  forClient: 'For client',
  addQuestion: 'Add a question',
  requiredAnswer: 'Required Answer',
  question: 'Question',
  options: 'Options',
  addOption: 'Add an option',
  // inputOption
  inputOption: 'Text field',
  numberOption: 'Number field',
  longInputOption: 'Long Text field',
  checkboxOption: 'Multi check',
  radioOption: 'Single check',
  dateOption: 'Date field',
  optionsPlaceHolderText: 'The answer will be written here...',
  optionsPlaceHolderLabel: 'Option label',
  answer: 'Answer',
  surveyTitle: 'Survey Title',
  surveyDescription: 'Survey Description',
  surveyIntro: 'Here you can write a short introduction to your survey...',
  noQuestion: 'Add at least 1 question to survey.',
  noAnswers: 'Fill required questions in survey.',
  fillRequiredAnswers: 'Fill required questions in survey.',
  missingSurveyTitle: 'Missing title to survey.',
  noQuestionText: (index) => `Missing question in section ${index}`,
  noLabelOption: (name) => `Missing label to option in the question: ${name}`,
  starRequiredFields: '* requiredFields',
  copySurvey: 'Copy survey to my account',
  assignDocumentSuccessfully: 'All documents have been sent successfully',
  assignSurveySuccessfully: 'All surveys have been sent successfully',
  unfinishedSession_sub: 'Your recent session ended unexpectedly, should we resume it?',
  contactsList: 'Contacts list',
  contactDetails: 'Contact details',

  welcome_sub:
    'Thank you for giving us a chance to be better trainers with ASPIRE. \nLet us introduce to you out capabilities, what would you like to know first?',
  welcomeAspireClip: 'welcome To Aspire - clip',
  profileOverview: 'Profile overview',
  inviteClientOverview: 'Client invitation overview',
  programOverview: 'Program overview',
  planOverview: 'Plan creation & use',
  achievementsOverview: 'Goals & Achievements',
  statisticsOverview: 'Statistics overview',
  paperworkOverview: 'Paperwork overview',
  createMyExerciseOverview: 'My exercises overview',

  // profileCard
  selectFilesToUpload: 'Select & Upload files',
  wouldYouLikeAWalkThru: 'Would you like a quick walk-thru the process?',
  programCreationTutorial: 'yes! guide me thru!',
  noThankUTutorial: "I'll do it myself",

  chooseClient_sub: 'Select a client / yourself to assign this program to.',
  groupClients_sub: "Selecting a number of clients will create a 'Group Program'.",
  fillProgramTitle: 'Give your program a title.',
  afterFillForm: 'After completing the form, ',
  clickHereToAddExercises: "Click in add exercises",
  saveToProceed: "Click 'Save' to proceed",
  nextToProceed: "Click 'Next' to proceed",
  doneToProceed: "Click 'Done' to save all the changes you've made.",
  nextToProceed2: "when you're done choosing the exercises you need, click 'Next' to proceed.",
  chooseExercisesTutorial_sub: 'Select 3 exercises you wish to add to your program.',
  selectedExercisesTutorial_sub: "Here you can see the exercises you've chosen for this program.",
  filterTutorial_sub: 'You can choose between making a super-set / a regular exercise here.',
  clickOnAlice_sub: "Click on 'Alice' in your clients list to view her stats.",
  //    'With "Categories" you can filter the exercises to find ones you\'re looking for.',
  exerciseType_categories: 'Change exercise type',
  filterOptions: 'Search options',
  filterOptions_sub: 'You can find exercises by text or filter by muscle, equipment or categories.',
  aspireTrainerIntroduction_sub:
    "\"As your first client allow me to guide you, I guarantee you'll understand 'Aspire' faster with me by your side.\"\n\nLet's start with:",
  aspireClientIntroduction_sub:
    "\"On your first visit, allow me to guide you, I guarantee you'll understand 'Aspire' faster with me by your side.\"\n\nLet's start with:",
  aspireClientIntroductionTwo_sub:
    "\"As your trainer allow me to guide you through 'Aspire', so we can train better in the future.\"\n\nLet's start with:",
  editBtnTutorial_sub: "Press 'Edit' and you'll be able to modify exercise's sets & fields.",
  exerciseOptionsTutorial_sub:
    'Extended exercise options:\nAdding your comments, changing exercise fields, modify name... ',
  exerciseEquipmentTutorial_sub: "You can also change exercise's selected equipment from here.",
  squareInfo_hint: 'Program Stats on num. of workouts made + overall tonnage lifted.',
  sessionUpdateBtn_hint:
    'Click here will start your workout & update your current state in real-time.',
  exerciseDescription_hint:
    'Click here to view exercise description, muscle map & possible equipments.',
  superset_hint: 'Super-set exercise view, swipe left/right to view them.',
  clickToCheck_hint: 'Click here to update the set to "Completed" and activate the next one.',
  modifyExerciseBtn_hint: 'Click here to modify the sets and update the fields if needed.',

  fillClientInfo_sub: "Fill your client's information and click 'Next'.",
  changeClientsAppSettings_sub: "In case needed, change your client's basic settings.",
  editProfile: 'Build your profile',
  editProfile_sub: 'Click here to create your profile.',
  uploadImage_sub: 'Your profile picture will be displayed here, shall we begin?',
  trainersProfile: "Trainer's profile",
  trainersProfile_sub:
    "You can click on your trainer's profile icon (right corner) if you want to visit their profile page.",
  notifyUserToVerifyAccount_desc:
    'your account has not been verified, if you haven\'t received our verification mail, click on "send again" ' +
    "(please check your 'promotions' inbox as well).",
  beginner: 'Beginner',
  trained: 'Trained',
  wellTrained: 'Well Trained',
  athlete: 'Athlete',
  copyProgramSuccessfully: 'Program has been copied to your account successfully',
  success: 'Success',
  successfullyUpdated: 'successfully Updated',
  successfullyUpdatedPassword: 'Your password has been successfully updated to the system!',
  successfullyUpdatedPhone: 'Your phone number has been successfully updated to the system!',
  successfullyUpdatedPhone2: 'From now on you can login with your phone number.',
  somethingWentWrong: 'Something went wrong',
  stay: 'Stay',
  helloUserStore: (name) => `Hello ${name}`,
  helloUserStore_desc:
    'Welcome to our store, feel free to use and share our templates with your clients.',
  storePrograms: 'Programs archive',
  storePrograms_desc: 'You can copy, change & share our workout templates with your clients.',

  personal_info:
    'A personal training programs, Select ONE client to assign this program to, you can assign this program to others later.',
  group_info: 'A group training program, Select multiple clients to assign this program to.',
  public_info:
    'This Program will serve as a FREE template for your clients and visitors to check out & try from your profile page.',
  store_info:
    'this program is to be reviewed by ASPIRE, if accepted, will be uploaded to the aspire store with your name & a badge.',
  circuitTraining: 'Circuit training',
  SET_option: 'Sets',
  CIRCUIT_option: 'Circuit',
  SET_info: 'Default Program view, enable user to view exercises as sets, supersets etc...',
  CIRCUIT_info: 'Circuit training view, enable user to determine number of rounds for chosen exercises.',
  optionsAreDisabled: 'Selecting an option is disabled.\nYou are either in tutorial or edit mode.',
  original: 'Origin',
  newUpdates: 'New Updates',
  whatsNew: `What’s New`,
  updateDetails: 'Update details',
  updateDetails_2: 'Update',
  signupByPhone: 'Signup by phone',
  signupByEmail: 'Signup by Email',
  insertYourNumberToVerify:
    'To receive a verification code via SMS, please insert your phone number.',
  verifyEmailBeSent:
    'You\'ll receive an email to verify your account, please check your "promotions" tab as well.',
  sendVerificationCode: 'Send Verification Code',
  aspireIntro_title_1: 'A new era for personal training.',
  aspireIntro_desc_1:
    'We empower trainers on every level, giving your clients 100% focus and devotion to their cause.',
  aspireIntro_title_2: 'Stay connected 24/7',
  aspireIntro_desc_2:
    'Create your own professional environment and invite your clients to an exclusive platform, made for progress.',
  aspireIntro_title_3: 'Unique Experience',
  aspireIntro_desc_3:
    'Every feature we develop helps you to become more professional, more personal, more yourself.',
  copied: 'Copied',
  certainField: 'Field',
  addWorkoutRoutine: 'Add a workout routine',
  workoutRoutine: 'Workout Routine',
  addExercisesToFirstCreatedProgram: (title) => `'${title}' has no exercises, please select exercises to '${title}' first.`,
  addAProgram: 'Add a program',
  saveProgram: 'Save a program',
  clickHereAddExercises: 'Click here to add exercises',
  removeFromPlan: 'Remove from program',
  display: 'Display',
  removeRoutine: 'Remove routine',
  areYouSureRemoveRoutine: 'Are you sure you want to remove this routine?',
  routineDetails: 'Routine details',
  editRoutineDetails: 'Update routine details',
  editProgramDetails: 'Update program details',
  modifyExercises: 'Modify exercises',
  routineAdvanceOptions: 'Routine advanced options',
  programAdvanceOptions: 'Program advanced options',
  attention: 'Attention',
  goBackNotice: 'Going back will delete all changes you\'ve made on current page.\n Are you sure you want to go back?',
  processingMedia: 'Your upload is being processed,\nit might take a few minutes.\nfeel free to continue.',
  clientProfileIsPrivate: 'Client\'s profile is private.\nOnly your trainer can see it.',
  openContactsList: 'Open contacts list',
  countryCodes: 'Country codes',
  selectCountry: 'Select country',
  selectCountryCode: 'Please select country code',
  noCountryCodeAttached: 'No country code attached to phone number',
  unverifiedClientAccountInfoEmail: () => (
    <span>A client account created under this address but <strong>has yet to be verified.</strong>
      <br />
      Please check your email <strong>&apos;Inbox&apos; or &apos;Promotions&apos; tab</strong> & get your invitation code.
    </span>
  ),
  unverifiedClientPhoneInfo: () => (
    <span>A client account created under this phone number but <strong>has yet to be verified.</strong>
      <br />
      Please check your SMS inbox & get your <strong>5-digits code</strong>, then click &apos;Sign In&apos;.
    </span>
  ),
  resendSMStoUnverifiedClientPhone: () => (
    <span>In a few moments you&apos;ll receive an SMS with a new invitation code.
      <br />
      Please check your SMS & get your <strong>5-digits code</strong>, then click &apos;Sign In&apos;.
    </span>
  ),
  access_denied_pro_up: `Access available to 'Pro Trainer' and up`,
  access_denied_gold_up: `Access available to 'Gold Trainer' and up`,
  access_denied_update_sub: `, to update subscription go to 'Settings'.`,
  clickHere: 'click here.',
  latestStatClientProgress_info: (name) => `To view ${name}'s latest session stats on current workout, `,
  latestStatClientWorkout_info: (name) => `Scroll down to see ${name}'s latest session stats on current workout.`,
  NoSessionsOnWorkout_info: (name) => `No Session by ${name} were made yet.`
};

export default en;
