import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import '../../Components/UI/UIStyle.css';

class ModalRight extends Component {
  constructor (props) {
    super(props);
    this.state = {
      backdrop: this.props.backdrop || true,
      isLoading: true
    };
  }

  componentDidMount () {
    if (this.props.onInit) {
      this.props.onInit();
    }
    this.setState({ isLoading: false });
  }

  confirm () {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
    this.toggleRight();
  }

  toggleRight () {
    if (this.props.toggleModal) {
      this.props.toggleModal();
    }
  }

  cancel () {
    if (this.props.onCancel) {
      this.props.clickedActive();
    }
    this.toggleRight();
  }

  render () {
    const { title, children, footer, direction, className } = this.props;
    return (
      <Modal
        key={this.props.key}
        isOpen={this.props.isOpen}
        toggle={() => { this.toggleRight(); }}
        backdrop={this.state.backdrop}
        wrapClassName={`${direction === 'ltr' ? 'modal-right' : 'modal-left'} ${className || ''}`}>
        { title && (
          <ModalHeader toggle={() => { this.toggleRight(); }}>
             <Trans i18nKey={title} />
          </ModalHeader>
        )}
        { children }
        { footer && (
          <ModalFooter>
            <Button color="primary" onClick={() => { this.confirm(); }}>
                Do Something
            </Button>
            <Button color="secondary" onClick={() => { this.confirm(); }}>
                Cancel
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default ModalRight;
