import { actionTypes } from '../actions/actionTypes';
import localStorage from 'local-storage';

const INIT_STATE = {
  user: localStorage.get('Aspire-auth'),
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: ''
};

const authFirebaseReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case actionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state, loading: false, user: action.payload.uid, error: ''
      };
    case actionTypes.LOGIN_USER_ERROR:
      return {
        ...state, loading: false, user: '', error: action.payload.message
      };
    case actionTypes.FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state, loading: false, forgotUserMail: action.payload, error: ''
      };
    case actionTypes.FORGOT_PASSWORD_ERROR:
      return {
        ...state, loading: false, forgotUserMail: '', error: action.payload.message
      };
    case actionTypes.RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state, loading: false, newPassword: action.payload, resetPasswordCode: '', error: ''
      };
    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state, loading: false, newPassword: '', resetPasswordCode: '', error: action.payload.message
      };
    case actionTypes.REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case actionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state, loading: false, user: action.payload.uid, error: ''
      };
    case actionTypes.REGISTER_USER_ERROR:
      return {
        ...state, loading: false, user: '', error: action.payload.message
      };
    case actionTypes.LOGOUT_USER:
      return { ...state, user: null, error: '' };
    default: return { ...state };
  }
};

export default authFirebaseReducer;
