import React, { Component, Suspense, Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { actionTypes } from '../../store/actions/actionTypes';
import { checkIfLoggedIn } from '../../store/actions';
import { addClass, securePostMsgUtils } from '../../services/utilsService';
import { facebookProps } from '../../constants/defaultValues';
import ModalsContainer from "../Modals/ModalsContainer";
import ModalsContainerSecond from "../Modals/ModalsContainerSecond";
import ModalsContainerThird from "../Modals/ModalsContainerThird";
// import '../../services/Firebase';
import { getDirection, setTheme} from '../../services/Utils';
import NotificationContainer from '../../Components/common/react-notifications/NotificationContainer';

const AppView = React.lazy(() =>
    import(/* webpackChunkName: "Views-app" */ '../../Views/app')
);

const ViewError = React.lazy(() =>
    import(/* webpackChunkName: "Views-error" */ '../../Views/error')
);
const Login = React.lazy(() =>
    import(/* webpackChunkName: "main-login" */ '../PublicSitePages/login')
);
const Register = React.lazy(() =>
    import(/* webpackChunkName: "main-register" */ '../PublicSitePages/register')
);
const ForgotPassword = React.lazy(() =>
    import(/* webpackChunkName: "main-forgot-password" */ '../PublicSitePages/ForgotPassword')
);

const AuthRoute = ({ component: Component, userAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => userAuth ? ( <Component {...props} /> ) :
        ( <Redirect to={{pathname: '/login', state: { from: props.location }}} /> )} />
  );
};

const configLang = (lang) => {
  const body = document.getElementsByTagName('body')[0];
  body.setAttribute('dir', ((lang && lang === 'he') ? 'rtl' : 'ltr'));
  moment.locale(lang);
};

class App extends Component {
  constructor(props) {
    super(props);
    const direction = getDirection();
    setTheme()
    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    }
  }




  async componentDidMount() {
    try {
      const { i18n } = this.props;
      configLang(i18n.language);
      const deviceType = securePostMsgUtils.detectUserAgent();
      addClass('#root', deviceType);
      this.props.setAppLang(i18n.language);
      this.loadFb();
      const data = await this.props.checkIfLoggedIn();
      if (!data) {
        // this.props.history.push('/login');
      }
    } catch (e) {
      console.log('handle error', e);
      // window.location.href = '/login';
    }
  }

  loadFb = () => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : facebookProps.facebookAppId,
        xfbml      : true,
        version    : 'v8.0'
      });
      window.FB.AppEvents.logPageView();
    };

    (function(d, s, id){
      let js; const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  };

  render() {
    const { userAuth } = this.props;
    return (
      <div className="h-100 width100">
        <Fragment>
          <Suspense fallback={<div className="gogo-loading" />}>
            <Router>
              <Switch>
                <AuthRoute path="/app" userAuth={userAuth} component={AppView} />
                <Route path="/login" exact render={props => <Login {...props} />} />
                <Route path="/register" exact render={props => <Register {...props} />} />
                <Route path="/forgotPassword" exact render={props => <ForgotPassword {...props} />} />
                <Route path="/error" exect render={props => <ViewError {...props} />} />
                <Redirect to="/app" />
                {/* <Route path="/" render={props => <PublicSite {...props} />} /> */}
              </Switch>
            </Router>
          </Suspense>
          <div className="flex-100 layout-row layout-wrap layout-align-start-start">
            <NotificationContainer />
            <ModalsContainer history={this.props.history} />
            <ModalsContainerSecond history={this.props.history} />
            <ModalsContainerThird history={this.props.history} />
          </div>
        </Fragment>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  userAuth: state.userR.auth,
  locale: state.appR.locale
});

const mapDispatchToProps = dispatch => ({
  setAppLang: lang => dispatch({ type: actionTypes.SET_APP_LANG, payload: lang }),
  checkIfLoggedIn: () => dispatch(checkIfLoggedIn())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
