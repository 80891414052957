/* eslint-disable max-len,linebreak-style,no-param-reassign */
import React from 'react';
import {
  domainWhiteList, units, performanceType, achievementType, measurementByType, allowedFieldsByWorkoutTypes, imageExamplesList
} from './enums';
import store from '../store/store';
import { langs } from '../langs';
import { map, get } from 'lodash-es';
import { eventActions } from '../store/actions/actionTypes';
import { defaultDirection } from '../constants/defaultValues';

export const styleUtils = {
  animationDelay: (delay, index) => ({ animationDelay: `${(index * 500) + delay}ms` }),
  imageBackground: (uri, color1, color2, color3, degree, backupUri, props) => ({ background: `linear-gradient(${degree || 0}deg, ${color1 || 'transparent'} 0%, ${color2 || 'transparent'} 30%, ${color3 || 'transparent'} 58%) 50% 50% / cover no-repeat padding-box border-box, url(${uri}) center center / cover no-repeat padding-box border-box, url(${backupUri || ''}) center center`, backgroundSize: 'cover', props }), // return {background: `url(${uri})no-repeat center`, backgroundSize: 'cover'};
  imageBackgroundFallbackToGradient: (uri, color1, color2, color3, degree) => ({ background: `url(${uri}) no-repeat center center, linear-gradient(${degree || 0}deg, ${color1 || 'transparent'} 0%, ${color2 || 'transparent'} 30%, ${color3 || 'transparent'} 58%) 50% 50%`, backgroundSize: 'cover' }), // return {background: `url(${uri})no-repeat center`, backgroundSize: 'cover'};
  groupStyles: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 600
  }),
  groupBadgeStyles: () => ({
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center'
  })
};

export const inputUtils = {
  onInputFocus: (key) => {
    const labelId = document.getElementById(`${key}_label`);
    if (labelId && !labelId.classList.contains('rendered')) {
      labelId.classList.add('rendered');
    }
    if (!labelId.classList.contains('valued')) {
      labelId.classList.add('valued');
    }
  },
  onInputBlur: (key) => {
    const elementId = document.getElementById(key);
    if (elementId && !elementId.value) {
      const labelId = document.getElementById(`${key}_label`);
      labelId.classList.remove('valued');
    }
  },
  onNumberInputFocus (event, query) {
    const input = document.querySelector(query);
    if (input) input.value = '';
  },
  onNumberInputBlur (event, query) {
    const input = document.querySelector(query);
    if (input && input.value === '' && input.placeholder) {
      input.value = Number(input.placeholder) || 0;
    } else if (input && input.value === '' && !input.placeholder) {
      input.value = 0;
    }
  },
  validateNumberInput (element, value, backup) {
    const number = Number(value);
    if (element && element.min && Number(element.min) > number) return Number(backup ? element.defaultValue : element.min);
    if (element && element.max && Number(element.max) < number) return Number(backup ?  element.defaultValue : element.max);
    /*  if (!new RegExp(`([0-9])\\w+`).test(value)) {
          const numOnly = value.replace(/\D+/g, '')
          return Number(numOnly);
        } */
    return Number(value);
  }
};

export const utils = {
  buildObjectFromModel: (model) => {
    const tempObject = { ...model };
    const modelkeys = Object.keys(tempObject);
    const resultObject = {};
    modelkeys.map((key) => {
      if (key.indexOf('.') == -1) { resultObject[key] = tempObject[key]; }
      else {
        const props = key.split('.');
        if (!resultObject[props[0]]) { resultObject[props[0]] = {}; }
        resultObject[props[0]][props[1]] = tempObject[key];
      }
    });
    return resultObject;
  },
  handlePropsIntoInitValues: (data, formInputs) => {
    const model = [...formInputs];
    model.map((modelProp) => {
      if (modelProp.name.indexOf('.') == -1) {
        if (modelProp.elementType === 'datePicker') {
          if (data && data[modelProp.name]) {
            const initDate = (data[modelProp.name]).split('T')[0];
            modelProp.initValue = initDate;
          }
        } else if (data && data[modelProp.name]) {
          modelProp.initValue = data[modelProp.name];
        }
      } else {
        const splitter = modelProp.name.split('.');
        if (data && data[splitter[0]] && data[splitter[0]][splitter[1]]) {
          modelProp.initValue = data[splitter[0]][splitter[1]];
        } else if (modelProp.initValue) {
          delete modelProp.initValue;
        }
      }
    });
    return model;
  },
  clearInitValues: (formInputs) => {
    const model = [...formInputs];
    model.forEach((modelProp) => {
      delete modelProp.initValue;
    });
    return model;
  },
  getOptionsByArray: (options, locale) => {
    if (options && options.length > 0) {
      return options.map((option, index) => (
        <option key={index} value={option.value}>
          {(langs[locale][option.display] || option.display)}
        </option>
      ));
    }
    return <option />;
  },
  getOptionsByObject: (options, locale) => {
    if (options && Object.keys(options).length > 0) {
      return Object.keys(options).map((key, index) => (
        <option key={index} value={options[key]}>
          {((langs[locale] && langs[locale][key]) || key)}
        </option>
      ));
    }
    return <option />;
  },
  buildQuery: (queryObj) => {
    if (typeof queryObj === 'object' && queryObj !== null) {
      const apiQuery = { ...queryObj };
      let newQuery = '';
      let firstQuery = true;
      for (const [key, value] of Object.entries(apiQuery)) {
        if (Array.isArray(value)) { // if value is array
          let arrayQuery = '';
          let firstVal = true;
          value.forEach((val) => {
            arrayQuery = `${arrayQuery + (firstVal ? '' : ',') + val}`;
            firstVal = false;
          });
          newQuery += `${(firstQuery ? '?' : '&') + key}=${arrayQuery}`;
          firstQuery = false;
        } else if (value !== '' || value > 0 || value === 0) { // if value is string / number
          newQuery += `${(firstQuery ? '?' : '&') + key}=${value}`;
          firstQuery = false;
        }
      }
      return newQuery;
    }
    return '';
  },
  arrayToKeyValue: (list, idProp) => {
    const keyValueObj = {};
    const dataIds = [];
    list.forEach((document) => {
      keyValueObj[document[idProp]] = document;
      keyValueObj[document[idProp]].chosen = false;
      dataIds.push(document[idProp]);
    });
    return { keyValueObj, dataIds };
  },
  keyValueToArray: data => Object.entries(data).map(([key, value]) => ({ ...value })),
  makeDataList: (data, groupObj) => groupObj.map(group => ({
    label: group.label,
    options: data.filter(obj => obj[group.prop] == group.value)
  })),
  formatGroupLabel: data => (
    <div style={styleUtils.groupStyles()}>
      <span>{data.label}</span>
      <span style={styleUtils.groupBadgeStyles()}>{data.options.length}</span>
    </div>
  )
};

export const sortArrayByProp = (array, prop) => {
  array.sort((a, b) => ((a[prop] > b[prop]) ? 1 : ((b[prop] > a[prop]) ? -1 : 0)));
  return array;
};

export const securePostMsgUtils = {
  detectIsMobile: () => {
    if (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    return false;
  },
  detectUserAgent: () => {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      android = /android/.test(userAgent),
      ios = /iphone|ipod|ipad|iPhone/i.test(userAgent),
      safari = /safari/i.test(userAgent),
      chrome = /chrome/i.test(userAgent);
    if (android) {
      return 'android';
    } if (chrome) {
      return 'chrome';
    } if (safari || ios) {
      return 'ios';
    }
    return 'web';
  },
  isValidJsonString: (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  isAspireApp: () => !!(window.aspire && window.aspire.app),
  isDomainWhiteList: host => !!(domainWhiteList.indexOf(host) !== -1),
  isValidMessage (event) {
    return !!(securePostMsgUtils.detectIsMobile() && securePostMsgUtils.detectUserAgent() && securePostMsgUtils.isAspireApp() && securePostMsgUtils.isDomainWhiteList(event.origin));
  },
  listenToValidMessage () {
    return !!(securePostMsgUtils.detectIsMobile() && securePostMsgUtils.detectUserAgent() && securePostMsgUtils.isAspireApp());
  }
};

export const calcToFixed = (value, decimalsNum, shouldRound) => {
  if (!value) return 0;
  let result = value;
  let check = 0;
  switch (decimalsNum) {
    case 1:
      result = value.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
      check = Number(result) % 1;
      check = Number(check.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]);
      break;
    case 2:
      result = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      check = Number(result) % 1;
      check = Number(check.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      break;
    case 3:
      result = value.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0];
      check = Number(result) % 1;
      check = Number(check.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]);
      break;
    default:
      return value.toFixed(decimalsNum);
  }
  if (shouldRound) {
    if (check > 0.9 || check < 0.1) {
      // numbers that we write as whole but keeps getting unrounded numbers in LBS
      return Number(result).toFixed();
    } if (check > 0.4 || check < 0.6) {
      // numbers that we write as whole but keeps getting unrounded numbers in LBS
      return (Math.round(Number(result) * 4) / 4).toFixed(2);
    } if (check > 0.1 || check < 0.4 || check > 0.6 || check < 0.8) {
      // numbers that we write as whole but keeps getting unrounded numbers in LBS
      return (Math.round(Number(result) * 4) / 4).toFixed(2);
    } return result;
  }
  return result;
};

export const calcUtils = {
  calcKGtoLBS: (value, shouldRound) => {
    if (value && value > 0) {
      const num = value * 2.2046226;
      return Number(calcToFixed(num, 2, shouldRound));
    }
    return 0;
  },
  calcLBStoKG: (value) => {
    if (value && value > 0) {
      const num = value / 2.2046226;
      return Number(calcToFixed(num, 2));
    }
    return 0;
  },
  calcINCHtoCM: (value) => {
    if (value && value > 0) {
      const num = value * 2.54;
      return Number(calcToFixed(num, 2));
    }
    return 0;
  },
  calcCMtoINCH: (value) => {
    if (value && value > 0) {
      const num = value / 2.54;
      return Number(calcToFixed(num, 2));
    }
    return 0;
  },
  calcLBSToTon: (value, toFix) => {
    if (value && value > 0) {
      if (value > 2000000) {
        const num = value / 2000000;
        return `${Number(calcToFixed(num, toFix || 2))}M`;
      }
      if (value > 200000) {
        const num = value / 2000;
        return `${Number(calcToFixed(num, toFix || 1))}K`;
      } if (value > 2000) {
        const num = value / 2000;
        return `${Number(calcToFixed(num, toFix || 2))}`;
      }
      return Number(calcToFixed(value, toFix || 2));
    }
    return 0;
  },
  calcINCHtoM: (value) => {
    if (value && value > 0) {
      const num = value / 39.3700787;
      return Number(calcToFixed(num, 2));
    }
    return 0;
  },
  calcYardToM: (value, shouldRound) => {
    if (value && value > 0) {
      const num = value / 1.0936133;
      return Number(calcToFixed(num, 2, shouldRound));
    }
    return 0;
  },
  calcMtoINCH: (value) => {
    if (value && value > 0) {
      const num = value * 39.3700787;
      return Number(calcToFixed(num, 2));
    }
    return 0;
  },
  calcMtoYard: (value, shouldRound) => {
    if (value && value > 0) {
      const num = value * 1.0936133;
      return Number(calcToFixed(num, 2, shouldRound));
    }
    return 0;
  },
  calcKGtoTON: (value, toFix) => {
    if (value && value > 0) {
      if (value > 1000000) {
        const num = value / 1000000;
        return `${Number(calcToFixed(num, toFix || 2))}M`;
      }
      if (value > 100000) {
        const num = value / 1000;
        return `${Number(calcToFixed(num, toFix || 1))}K`;
      } if (value > 1000) {
        const num = value / 1000;
        return `${Number(calcToFixed(num, toFix || 2))}`;
      }
      return Number(calcToFixed(value, toFix || 2));
    }
    return 0;
  },
  calcCMtoM: (value, toFix) => {
    if (value && value > 0) {
      if (value > 100) {
        const num = value / 100;
        return Number(calcToFixed(num, toFix || 2));
      }
      return Number(calcToFixed(value, toFix || 2));
    }
    return 0;
  },
  calcMtoKM: (value, toFix) => {
    if (value && value > 0) {
      if (value > 1000) {
        const num = value / 1000;
        return Number(calcToFixed(num, toFix || 2));
      }
      return Number(calcToFixed(value, toFix || 2));
    }
    return 0;
  },
  calcINCHToFoot: (value, toFix) => {
    if (value && value > 0) {
      if (value > 12) { // 12 feet
        const num = value / 12;
        return Number(calcToFixed(num, toFix || 2));
      }
      return Number(calcToFixed(value, toFix || 2));
    }
    return 0;
  },
  calcYardToMile: (value, toFix) => {
    if (value && value > 0) {
      if (value > 1760) { // 1 mile
        const num = value / 1760.0065;
        return Number(calcToFixed(num, toFix || 2));
      }
      return Number(calcToFixed(value, toFix || 2));
    }
    return 0;
  },
  calcMillisToSeconds: (value) => {
    const num = value * 0.001;
    return Number(calcToFixed(num, 1));
  },
  calcMillisToMinutesAndSeconds: (millis, showZeroMin) => {
    if (millis && millis > 0) {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      return `${showZeroMin && minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
    return '0:00';
  },
  calcMillisToHrsMinutes: (millis) => {
    if (millis && millis > 0) {
      let minutes = Math.floor(millis / 60000) ;
      let hours = Math.floor(minutes / 60);
      minutes = hours > 0 ? (minutes - (hours * 60)) : minutes;
      let seconds = Math.ceil((millis % 60000) / 1000);
      if (seconds === 60) {
        minutes += 1;
        seconds = 0
      }
      if (minutes === 60) {
        hours += 1;
        minutes = 0;
      }
      return { hours, minutes, seconds }}
    return null;
  }
};

export const calcBMI = (height, weight, userSettings) => {
  let weightUnits,
    distanceUnits,
    heightM = height,
    weightKG = weight;
  if (!userSettings) {
    weightUnits = units.weightUnits.KG;
    distanceUnits = units.distanceUnits.CM;
  } else {
    weightUnits = userSettings.weightUnits;
    distanceUnits = userSettings.distanceUnits;
  }
  if (distanceUnits === units.distanceUnits.INCH) { heightM = calcUtils.calcINCHtoCM(height); }
  if (weightUnits === units.weightUnits.LBS) { weightKG = calcUtils.calcLBStoKG(weight); }
  // Perform calculation BMI
  return Number(weightKG / ((heightM / 100) * (heightM / 100))).toFixed(2);
};

export const configureInputBySettings = (settings, value, currentType, isPerformance, isBasicMeasure) => {
  if (!settings) return { value: value || 0, display: currentType, basicMeasureDisplay: currentType };
  let valueMeasure = value;
  let measureDisplay = '';
  let basicMeasureDisplay = '';
  if (settings.weightUnits === units.weightUnits.LBS && (currentType === 'kg' || currentType === units.weightUnits.KG)) {
    measureDisplay = units.weightUnits.LBS;
    basicMeasureDisplay = units.weightUnits.LBS;
    valueMeasure = calcUtils.calcKGtoLBS(value, true);
    if (valueMeasure > 2000 && !isBasicMeasure) {
      valueMeasure = calcUtils.calcLBSToTon(valueMeasure);
      measureDisplay = units.weightUnits.T; // short Ton
    }
  } else if (settings.distanceUnits === units.distanceUnits.INCH && (currentType === 'cm' || currentType === units.distanceUnits.CM) && !isPerformance) {
    measureDisplay = units.distanceUnits.INCH;
    basicMeasureDisplay = units.distanceUnits.INCH;
    valueMeasure = calcUtils.calcCMtoINCH(value);
    if (valueMeasure > 12 && !isBasicMeasure) {
      valueMeasure = calcUtils.calcINCHToFoot(valueMeasure);
      measureDisplay = units.distanceUnits.FT;
    }
  } else if (settings.distanceUnits === units.distanceUnits.INCH && (currentType === 'cm' || currentType === units.distanceUnits.CM) && isPerformance) {
    measureDisplay = units.distanceUnits.YARD;
    basicMeasureDisplay = units.distanceUnits.YARD;
    valueMeasure = calcUtils.calcMtoYard(value, true);
    if (valueMeasure > 1760 && !isBasicMeasure) {
      valueMeasure = calcUtils.calcYardToMile(valueMeasure);
      measureDisplay = units.distanceUnits.MILE;
    }
  } else if (settings.weightUnits === units.weightUnits.KG && (currentType === 'kg' || currentType === units.weightUnits.KG)) {
    measureDisplay = units.weightUnits.KG;
    basicMeasureDisplay = units.weightUnits.KG;
    valueMeasure = Number(calcToFixed(valueMeasure, 2));
    if (value > 1000 && !isBasicMeasure) {
      valueMeasure = calcUtils.calcKGtoTON(value);
      measureDisplay = units.weightUnits.T;
    }
  } else if (settings.distanceUnits === units.distanceUnits.CM && (currentType === 'cm' || currentType === units.distanceUnits.CM) && !isPerformance) {
    measureDisplay = units.distanceUnits.CM;
    basicMeasureDisplay = units.distanceUnits.CM;
    valueMeasure = Number(calcToFixed(valueMeasure, 2));
    if (value > 100 && !isBasicMeasure) {
      valueMeasure = calcUtils.calcCMtoM(value);
      measureDisplay = units.distanceUnits.M;
    }
  } else if (settings.distanceUnits === units.distanceUnits.CM && (currentType === 'cm' || currentType === units.distanceUnits.CM) && isPerformance) {
    measureDisplay = units.distanceUnits.M;
    basicMeasureDisplay = units.distanceUnits.M;
    valueMeasure = Number(calcToFixed(valueMeasure, 2));
    if (value > 1000 && !isBasicMeasure) {
      valueMeasure = calcUtils.calcMtoKM(value);
      measureDisplay = units.distanceUnits.KM;
    }
  } else { // % / BMI / ...
    measureDisplay = currentType;
    basicMeasureDisplay = currentType;
    valueMeasure = value;
  }
  return { value: valueMeasure, display: measureDisplay, basicMeasureDisplay };
};

export const getStatsMeasurement = (statsType) => {
  switch (statsType) {
    case 'fat':
      return measurementByType.fat;
    case 'water':
      return measurementByType.water;
    case 'calories':
      return measurementByType.calories;
    case 'height':
      return measurementByType.height;
    case 'bmi':
      return measurementByType.bmi;
    case 'weight':
      return measurementByType.weight;
    case 'distance':
      return measurementByType.height;
    default:
      return measurementByType.weight;
  }
};

export const storeUtils = {
  removeFromArrayInStore: (array, item) => {
    const index = array.indexOf(item);
    return [...array.slice(0, index), ...array.slice(index + 1)];
  },
  removeFromArrayInStoreByPublicId: (array, id) => {
    let num, i, key = '_id';
    if(id.indexOf('-') > -1) key= 'public_id'
    for(i = 0;i < array.length; i++) {
      if (array[i][key] === id) {
        num = i
        break;
      }
    }
    return [...array.slice(0, num), ...array.slice(num + 1)];
  },
  removeFromObjectInStore: (object, item) => {
    const newObject = { ...object };
    delete newObject[item];
    return newObject;
  }
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const toggleClass = (elementQuery, className) => {
  const element = document.querySelector(elementQuery);
  if (element && element.classList.contains(className)) {
    element.classList.remove(className);
  } else {
    element.classList.add(className);
  }
};

export const addClass = (elementQuery, className) => {
  const element = document.querySelector(elementQuery);
  if (element && !element.classList.contains(className)) {
    element.classList.add(className);
  }
};

export const removeClass = (elementQuery, className) => {
  const element = document.querySelector(elementQuery);
  if (element && element.classList.contains(className)) {
    element.classList.remove(className);
  }
};

export const removeElement = (elementQuery, hide) => {
  const element = document.querySelector(elementQuery);
  if (hide) element.classList.add('displayNone')
  else element.remove();
};

export const getParams = (paramsKey, isArray) => {
  const urlParams = new URLSearchParams(window.location.search);
  const values = urlParams.get(paramsKey);
  if (values) {
    if (values.indexOf(',') !== -1) return values.split(',');
    if (isArray) return [values];
    return values;
  }
  return null;
};

export const arrangeObjByPerformanceType = (achievement, statsObj, chosenType) => {
  let statsType = 'body';
  const bodyStatsType = achievement && achievement.bodyStatsType ? achievement.bodyStatsType : 'weight';
  let newObj = null;
  if (achievement.type === achievementType.performance) {
    statsType = chosenType || achievement.performanceType || performanceType.bestSet;
  }
  switch (statsType) {
    case performanceType.tonnage:
    case achievementType.body:
      newObj = { value: statsObj.tonnage ? statsObj.tonnage : statsObj[bodyStatsType] };
      break;
    case performanceType.bestSet:
    case performanceType.bestByRM:
      newObj = {
        reps: statsObj.log.reps || null,
        weight: statsObj.log.weight || 0
      };
      break;
    case performanceType.mostReps:
      newObj = { reps: statsObj.mostRepsDone || null };
      break;
    case performanceType.highestWeight:
      newObj = { weight: statsObj.log.weight || 0 };
      break;
    case performanceType.bestTime:
      newObj = {
        timeDone: statsObj.log.timeDone,
        distance: statsObj.log.distance
      };
      break;
    case performanceType.longestTime:
      newObj = { timeDone: statsObj.totalTimeDone ? statsObj.totalTimeDone : statsObj.log.timeDone };
      break;
    case performanceType.longestDistance:
      newObj = { distance: statsObj.longestDistance ? statsObj.longestDistance : statsObj.log.distance };
      break;
    default:
      newObj = null;
  }
  return newObj;
};

const validateFormInputs = (inputElement, locale) => {
  if (!inputElement.validity.valid) {
    const { validity } = inputElement;
    let inputName = inputElement.labels && inputElement.labels[0] && inputElement.labels[0].innerText;
    if (!inputName) inputName = inputElement.label ? langs[locale][inputElement.label] : langs[locale][inputElement.id];
    if ((inputElement.name && inputElement.name !== '') || (inputElement.id && inputElement.id !== '')) addClass(`input${inputElement.name && inputElement.name !== '' ? `[name='${inputElement.name}']` : `[id='${inputElement.id}']`}`, 'invalid-input');
    if (inputElement.required && validity.valueMissing) {
      return langs[locale].isRequired(inputName);
    }
    if (validity.tooLong) {
      return langs[locale].tooLong(inputName);
    }
    if (validity.tooShort) {
      return langs[locale].tooShort(inputName);
    }
    if (validity.rangeOverflow) {
      return [langs[locale].typeMismatch(inputName),
        langs[locale].rangeOverflow(inputName, inputElement.max)];
    }
    if (validity.rangeUnderflow) {
      return [langs[locale].typeMismatch(inputName),
        langs[locale].rangeUnderflow(inputName, inputElement.min)];
    }
    if (validity.typeMismatch || validity.patternMismatch) {
      return langs[locale].typeMismatch(inputName);
    }
  } else if ((inputElement.name && inputElement.name !== '') || (inputElement.id && inputElement.id !== '')) removeClass(`input${inputElement.name && inputElement.name !== '' ? `[name='${inputElement.name}']` : `[id='${inputElement.id}']`}`, 'invalid-input');
  return null;
};

export const validateFormElements = (locale) => {
  let errors = [];
  let isObjDetailsValid = true;
  const allElements = { inputs: {}, textAreas: {}, selects: {} };
  allElements.inputs = document.getElementsByTagName('input');
  allElements.textAreas = document.getElementsByTagName('textarea');
  allElements.selects = document.getElementsByTagName('select');
  Object.keys(allElements).forEach((inputElement) => {
    if (allElements[inputElement] && allElements[inputElement].length > 0) {
      const element = allElements[inputElement];
      Object.keys(element).forEach((key) => {
        const result = validateFormInputs(element[key], locale);
        if (result && Array.isArray(result)) {
          errors = errors.concat(result);
        } else if (result) errors.push(result);
      });
    }
  });
  if (errors && errors.length > 0) {
    isObjDetailsValid = false;
  }
  if (isObjDetailsValid) return { isValid: true, errors: [] };
  return { isValid: false, errors };
};

export const handleModalIframe = (isPolicy, urlSource, title) => {
  let source = 'https://www.aspire.co.il/termsConditions';
  if (urlSource || isPolicy) {
    source = urlSource || 'https://www.aspire.co.il/privacyPolicy';
  }
  return {
    isOpen: true,
    isModal: true,
    title,
    id: eventActions.IFRAME_PAGE,
    className: 'PageModal',
    source
  };
};

export const handleModalFullScreen = mediaLink => ({
  isOpen: true,
  isModal: true,
  id: eventActions.FULL_SCREEN_PAGE,
  className: 'PageModal',
  mediaLink
});

export const makeNotesMultilang = (notes, chosenLang) => notes.map((note) => {
  let newContent = {};
  if (note && typeof note.content === 'object') {
    newContent = { ...note.content };
    if ((newContent && !newContent[chosenLang]) || (newContent && newContent[chosenLang] && newContent[chosenLang] !== note.content[chosenLang])) {
      newContent[chosenLang] = note.content[chosenLang] || '';
    }
  } else {
    newContent[chosenLang] = (note && note.content) || '';
  }
  return { ...note, content: newContent };
});

export const clearExerciseLogByAllowedFields = (exercise, log, prepareForDisplay, settings) => {
  let allowedFields = { ...allowedFieldsByWorkoutTypes[exercise.exerciseType] };
  if (exercise.allowedFields) allowedFields = exercise.allowedFields;
  const props = 'time reps weight distance speed intensity rest'.split(' ');
  const NewExLog = {};
  props.forEach((prop) => {
    if (allowedFields[prop]) {
      NewExLog[prop] = log[prop];
    }
    if (prop === 'time') {
      NewExLog.timeDone = log.timeDone;
    }
  });
  if (prepareForDisplay) {
    if (NewExLog.time && NewExLog.time > 0) {
      NewExLog.time = calcUtils.calcMillisToMinutesAndSeconds(NewExLog.time);
    }
    if (NewExLog.weight) {
      NewExLog.weight = configureInputBySettings(settings, NewExLog.weight || 0, 'KG', true);
    }
    if (NewExLog.distance) {
      NewExLog.distance = configureInputBySettings(settings, NewExLog.distance || 0, 'CM', true);
    }
  }
  return NewExLog;
};

export const randomIntMinMax = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const secondsToText = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
  const seconds = Math.ceil(totalSeconds - (hours * 3600) - (minutes * 60));
  let result = (hours > 0 ? `0${hours}:` : '');
  result += `${minutes < 10 ? `0${minutes}` : minutes}:`;
  result += `${seconds < 10 ? `0${seconds}` : seconds}`;
  return result;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const sliceArrayIntoPages = (arr, pageSize) => {
  let step = 0,
    sliceArr = [],
    len = arr.length;
  while (step < len) {
    sliceArr.push(arr.slice(step, step += pageSize));
  }
  return sliceArr;
};

export const configureDropDownObj = (data, type) => {
  let array = [];
  if (type === 'keyValue') {
    Object.entries(data).map(([key, value], index) => {
      array.push({ label: key, value: value, key: `key_${key}_${index}` });
    });
  } else if (type === 'array') {
    array = data.map((item, index) => {
      array.push({ label: item, value: item, key: `key_${index}` });
    });
  }
  /*
  array = [
    { label: 'Cakes', value: 'Cakes', key: 0 },
    { label: 'Cupcakes', value: 'Cupcakes', key: 1 },
    { label: 'Desserts', value: 'Desserts', key: 2 }
  ];
  */
  return array;
};

export const configureOrderByObj = (data, type) => {
  let array = [];
  if (type === 'keyValue') {
    Object.keys(data).forEach((key, index) => {
      array.push({ label: data[key] , column: key, key: `key_${key}_${index}` });
    });
  } else if (type === 'array') {
    array = data.map((item, index) => {
      array.push({ label: item, column: item, key: `key_${index}` });
    });
  }
  /*
  array = [
        { column: "title", label: "Product Name" },
        { column: "sourceType", label: "Source Type" },
        { column: "type", label: "Type" }
      ];
  */
  return array;
};

export const modifySimpleArrayForReactSelect = (array) => {
   return array.map((value) => {
    return {
      label: value,
      value
    };
  });
}

export const calcRequiredImageHeight = (height) => {
  const imageSizes = [360, 540, 720];
  try {
    const requiredHeight = height * 2;
    let requiredSizeIndex = 0;
    imageSizes.forEach((size, index) => {
      if (size < requiredHeight) requiredSizeIndex = index + 1;
    });
    return imageSizes[requiredSizeIndex] ? `${imageSizes[requiredSizeIndex]}xAUTO` : 'source';
  } catch (e){
    console.log('calcImageHeight error', e)
    return 'source';
  }
}

export const modifyThumbnailSize = (thumbnail, height) => {
  try {
    const size = calcRequiredImageHeight(height);
    const image = thumbnail;
    if (image.indexOf(window.globals.assetsDomain) !== -1) {
      const extractDomainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:​www\.)?([^:\/\n?]+)/;
      const domain = extractDomainRegex.exec(image)[0];
      const urlArr = image.split(domain);
      if (!urlArr[1]) return thumbnail;
      let key = urlArr[1];
      if (key.indexOf('source') !== -1) {
        const keyArr = key.split('source');
        key = keyArr[1];
      }
      const result = `${domain}/${size}${key}`;
      // console.log('modifyThumbnailSize result: ', result);
      return result;
    }
    return image;
  } catch (e) {
    // console.log('modifyThumbnailSize error: ', e);
    return thumbnail;
  }
};

const regexSpecialChars = /[*&$<>/]/g;

export const cleanInput = (string, type) => {
  if (type === 'number') {
    return string.replace(/e/g, '')
  } else {
    return string.replace(regexSpecialChars, '');
  }
}

export const configCalendarSettingsDateTime = (settings) => {
  const invalid = [];
  if (settings && settings.weekDays && settings.weekDays.length > 0) {
    const weekDays = {
      recurring: {
        repeat: 'weekly',
        weekDays: settings.weekDays.join(',')
      }
    }
    invalid.push(weekDays)
  }
  return invalid;
};

export const validateEmail = (value) => {
  const lowCaseValue = (value && value.toLowerCase()) || '';
  const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|”(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*”)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return emailRegex.test(lowCaseValue);
}


export const checkUserIsExistInDoc = (array, getProp, valueToCheck) => {
  const arrayOfResults = map(array, getProp);
  return (arrayOfResults.indexOf(valueToCheck) !== -1)
}

export const checkAccessControl = (permission) => {
  const { accessControl } = store.getState().userR;
  if (accessControl && accessControl.allAccess) return true;
  const check = get(accessControl, permission, false);
  return check
}

export const checkUserTypeAccess = (permission) => {
  const { userTypeAccess } = store.getState().userR;
  if (userTypeAccess && userTypeAccess.allAccess) return true;
  return get(userTypeAccess, permission, false);
}

export const checkUserType = (types) => {
  const { userType } = store.getState().userR;
  if (!Array.isArray(types)) return userType === types; // if 'types' isn't array of userTypes;
  return (Array.isArray(types) && types.indexOf(userType) !== -1);
}

export const handleRandomThumbnail = (minNum, maxNum) =>{
  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const randomNum = getRandomInt(minNum, maxNum);
  return  {
    uri: imageExamplesList[randomNum],
    source: imageExamplesList[randomNum]
  };
}

export const modifyWorkLogsByExercise = (workLogs) => {
  const logsKV = {};
  Object.keys(workLogs).forEach((key) => {
    const arr = key.split('_');
    arr.splice(-1,1);
    const exId = arr.join('_')
    logsKV[exId] = workLogs[key];
  });
  return logsKV;
}
