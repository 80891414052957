import axios from 'axios';
import localStorage from 'local-storage';
import { serverUrl, host } from './globals';
import { googlePlacesApi } from '../constants/defaultValues';

const token = localStorage.get('Aspire-auth') || null;

const aspireClient = axios.create({
  baseURL: serverUrl[window.globals.ENV],
});

const aspireClientConfig = {
  headers: {
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT',
  },
};

if (token) {
  aspireClientConfig.headers.Authorization = token;
}

aspireClient.interceptors.request.use(
  (request) => {
    console.log('interceptors request: ', request);
    return request;
  },
  (error) => {
    console.log('interceptors request error: ', error.message);
    return Promise.reject(error);
  },
);

aspireClient.interceptors.response.use(
  (response) => {
    console.log('interceptors response: ', response);
    return response.data;
  },
  (error) => {
    console.log('interceptors response error: ', error.message);
    console.log(`code: ${error.response.status}, data: ${error.response.data}`);
    return Promise.reject(error);
  },
);

const apiMethods = {
  app: {
    googleGeoCodeByLanLat(latlng) {
      const api = 'https://maps.googleapis.com/maps/api/geocode/json?';
      const query = `latlng=${latlng}&key=${googlePlacesApi}`; // ?latlng=40.714224,-73.961452&key
      return aspireClient.get(query ? api + query : api);
    },
    googleGeoCodeByAddress(address) {
      const api = 'https://maps.googleapis.com/maps/api/geocode/json?';
      const addressUri = address.replace(' ', '+');
      const query = `address=${addressUri}&key=${googlePlacesApi}`;
      return aspireClient.get(query ? api + query : api);
    },
    getInstaAccessToken: (query) => {
      const platform = window.location.host === host.aspire ? 'trainer' : 'trainerDev';
      const api = `/api/auth/instagram/${platform}`;
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    addMedia: (body) => aspireClient.put('/api/articles/addMedia', body, aspireClientConfig),
    removeMedia: (body) => aspireClient.put('/api/articles/removeMedia', body, aspireClientConfig),
  },
  profile: {
    getUserProfile: (publicId) =>
      aspireClient.get(`/api/profiles/getUserProfile/${publicId}`, aspireClientConfig),
    getMyProfileGallery(query) {
      const api = '/api/profiles/getMyProfileGallery';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    updateUserProfile: (body) =>
      aspireClient.put('/api/profiles/updateUserProfile', body, aspireClientConfig),
  },
  users: {
    checkIfLoggedIn: (query) =>
      aspireClient.get(`/api/users/checkIfLoggedIn${query}`, aspireClientConfig),
    getInviteCode: () => aspireClient.get('/api/users/getInviteCode', aspireClientConfig),
    getUserDetailsForClients: (body) =>
      aspireClient.put('/api/users/getUserDetailsForClients', body, aspireClientConfig),
    register: (body) => aspireClient.post('/public/users/register', body, aspireClientConfig),
    loginByEmail: (body) =>
      aspireClient.post('/public/users/loginByEmail', body, aspireClientConfig),
    checkUserSubscriptionStatus: (query) =>
      aspireClient.get(`/getUserSubscriptionStatus/${query}`, aspireClientConfig), // pass user id here
    facebookLogin: (body) =>
      aspireClient.post('/public/users/facebookLogin', body, aspireClientConfig),
    loginByPhone: (body) =>
      aspireClient.post('/public/users/loginByPhone', body, aspireClientConfig),
    sendVerificationCode: (body) =>
      aspireClient.put('/public/users/sendVerificationCode', body, aspireClientConfig),
    sendVerificationCodeToLogin: (body) =>
      aspireClient.put('/public/users/sendVerificationCodeToLogin', body, aspireClientConfig),
    resendInvitationCode: (body) =>
      aspireClient.put('/public/users/resendInvitationCode', body, aspireClientConfig),
    verifyUserAndLogin: (body) =>
      aspireClient.put('/public/users/verifyUserAndLogin', body, aspireClientConfig),
    verifyInvitationCode: (body) =>
      aspireClient.put('/public/users/verifyInvitationCode', body, aspireClientConfig),
    loginClient: (body) =>
      aspireClient.post('/public/users/loginNewClientAccount', body, aspireClientConfig),
    resendWelcomeEmail: (body) =>
      aspireClient.put('/public/users/resendWelcomeEmail', body, aspireClientConfig),
    createClient: (body) => aspireClient.post('/api/users/createClient', body, aspireClientConfig),
    unlinkClient: (body) => aspireClient.put('/api/users/unlinkClient', body, aspireClientConfig),
    saveUpdatedClientsList: (body) =>
      aspireClient.put('/api/users/saveUpdatedClientsList', body, aspireClientConfig),
    saveSettings: (body) => aspireClient.put('/api/users/saveSettings', body, aspireClientConfig),
    updateUserAccount: (body) =>
      aspireClient.put('/api/users/updateUserAccount', body, aspireClientConfig),
    updateEmailInvitation: (body) =>
      aspireClient.put('/api/users/updateEmailInvitation', body, aspireClientConfig),
    updateUserCredentials: (body) =>
      aspireClient.put('/api/users/updateUserCredentials', body, aspireClientConfig),
    updateSMSInvitation: (body) =>
      aspireClient.put('/api/users/updateSMSInvitation', body, aspireClientConfig),
    resendClientInvitation: (body) =>
      aspireClient.put('/api/users/resendClientInvitation', body, aspireClientConfig),
    updatePassword: (body) =>
      aspireClient.put('/api/users/updateUserPassword', body, aspireClientConfig),
    updateClientPassword: (body) =>
      aspireClient.put('/api/users/updateClientPassword', body, aspireClientConfig),
    sendResetPasswordEmail: (body) =>
      aspireClient.put('/public/users/sendResetPasswordEmail', body, aspireClientConfig),
    resetPassword: (body) =>
      aspireClient.put('/public/users/resetPassword', body, aspireClientConfig),
    updateTrainerProfession: (body) =>
      aspireClient.put('/api/users/updateTrainerProfession', body, aspireClientConfig),
    checkAccountExistByEmail: (query) => {
      const api = '/public/users/checkAccountExistByEmail';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    checkEmailExist: (query) => {
      const api = '/api/users/checkEmailExist';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    checkPhoneExist: (query) => {
      const api = '/api/users/checkPhoneExist';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getCountryCodes: () => aspireClient.get(`/public/users/getCountryCodes`, aspireClientConfig),
    getMyCalendarSettings: () => aspireClient.get(`/api/users/getMyCalendarSettings`, aspireClientConfig),
    updateCalendarSettings: (body) => aspireClient.put('/api/users/updateCalendarSettings', body, aspireClientConfig),
    getUserCalendarSettings: (query) => {
      const api = '/api/users/getUserCalendarSettings';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    logout: () => {
      localStorage.remove('Aspire-auth');
    },
  },
  auth: {
    getFacebookPermissions(query) {
      const api = '/api/auth/facebook/checkPermissions';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    refreshFacebookAccess: (body) =>
      aspireClient.put('/api/auth/facebook/refreshFacebookAccess', body, aspireClientConfig),
    getUserAlbums: () => aspireClient.get('/api/auth/facebook/getUserAlbums', aspireClientConfig),
    getUserAlbumPhotos(query) {
      const api = '/api/auth/facebook/getUserAlbumPhotos';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getFBPageMedia(query) {
      const api = '/api/auth/facebook/getFBPageMedia';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getIGAccountMedia(query) {
      const api = '/api/auth/instagram/getAccountMedia';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getIGPageMedia(query) {
      const api = '/api/auth/instagram/getIGPageMedia';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
  },
  categories: {
    getCategories(query) {
      const api = '/api/categories/getCategories';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getCategoriesByGroup: (body) =>
      aspireClient.post('/api/categories/getCategoriesByGroup', body, aspireClientConfig),
    getCategory: (publicId) =>
      aspireClient.get(`/api/categories/getCategory/${publicId}`, aspireClientConfig),
    create: (body) => aspireClient.post('/api/categories/create', body, aspireClientConfig),
    edit: (body) => aspireClient.put('/api/categories/edit', body, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/categories/remove', body, aspireClientConfig),
  },
  workoutPlans: {
    getWorkoutPlans: (query) => {
      const api = '/api/workoutPlans/getWorkoutPlans';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getWorkoutPlanToModify: (query) => {
      const api = '/api/workoutPlans/getWorkoutPlanToModify';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getWorkoutPlanPageData: (query) => {
      const api = '/api/workoutPlans/getWorkoutPlanPageData';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    create: (body) => aspireClient.post('/api/workoutPlans/create', body, aspireClientConfig),
    edit: (body) => aspireClient.put('/api/workoutPlans/edit', body, aspireClientConfig),
    copy: (body) => aspireClient.post('/api/workoutPlans/copy', body, aspireClientConfig),
    editPrograms: (body) =>
      aspireClient.put('/api/workoutPlans/editPrograms', body, aspireClientConfig),
    addNewProgram: (body) =>
      aspireClient.post('/api/workoutPlans/addNewProgram', body, aspireClientConfig),
    introduceProgramToClient: () =>
      aspireClient.post('/api/workoutPlans/introduceProgramToClient', {}, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/workoutPlans/remove', body, aspireClientConfig),
  },
  workoutProgram: {
    getWorkoutPrograms: (query) => {
      const api = '/api/workoutPrograms/getWorkoutPrograms';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getWorkoutProgramsForPlan: (query) => {
      const api = '/api/workoutPrograms/getPlanPrograms';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getPlanPrograms: (query) => {
      const api = '/api/workoutPrograms/getPlanPrograms';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getWorkoutProgram(query) {
      const api = '/api/workoutPrograms/getWorkoutProgram';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getWorkoutProgramPageData(query) {
      const api = '/api/workoutPrograms/getWorkoutProgramPageData';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    assign: (body) => aspireClient.post('/api/workoutPrograms/assign', body, aspireClientConfig),
    edit: (body) => aspireClient.post('/api/workoutPrograms/edit', body, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/workoutPrograms/remove', body, aspireClientConfig),
  },
  workoutProgress: {
    getFullWorkoutProgress: (query) => {
      const api = '/api/workoutProgress/getFullWorkoutProgress';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getWeeklyWorkoutProgress: (query) => {
      const api = '/api/workoutProgress/getWeeklyWorkoutProgress';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    update: (body) => aspireClient.put('/api/workoutProgress/update', body, aspireClientConfig),
    changeCurrentWeek: (body) =>
      aspireClient.put('/api/workoutProgress/changeCurrentWeek', body, aspireClientConfig),
  },
  exercises: {
    getPopularExercises(query) {
      const api = '/api/exercises/getPopularExercises';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getFilteredExercises: (body) =>
      aspireClient.post('/api/exercises/getFilteredExercises', body, aspireClientConfig),
    filterExercisesByCategory(query) {
      const api = '/api/exercises/filterExercisesByCategory';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getExercise: (body) =>
      aspireClient.post(`/api/exercises/getExercise`, body, aspireClientConfig),
    getExerciseByPublicId: (publicId) =>
      aspireClient.get(`/api/exercises/getExerciseByPublicId/${publicId}`, aspireClientConfig),
    getMyExercises: (query) => {
      const api = '/api/exercises/getMyExercises';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getExercisesEquipments: (publicId) =>
      aspireClient.get(`/api/exercises/getExercisesEquipments/${publicId}`, aspireClientConfig),
    getLatestStatsOfExercise(query) {
      const api = '/api/exercises/getLatestStatsOfExercise';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    create: (body) => aspireClient.post('/api/exercises/create', body, aspireClientConfig),
    edit: (body) => aspireClient.put('/api/exercises/edit', body, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/exercises/remove', body, aspireClientConfig),
    addMedia: (body) => aspireClient.put('/api/exercises/addMedia', body, aspireClientConfig),
    removeMedia: (body) => aspireClient.put('/api/exercises/removeMedia', body, aspireClientConfig),
    createQuickExercise: (body) =>
      aspireClient.post('/api/exercises/createQuickExercise', body, aspireClientConfig),
    saveMediaToEquipment: (body) =>
      aspireClient.post('/api/exercises/saveMediaToEquipment', body, aspireClientConfig),
    removeEquipmentMedia: (body) =>
      aspireClient.post('/api/exercises/removeEquipmentMedia', body, aspireClientConfig),
  },
  media: {
    getAspireGallery(query) {
      const api = '/api/media/getAspireGallery';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getMediaGallery(query) {
      const api = '/api/media/getMyMedia';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getMediaDetails(publicId) {
      return aspireClient.get(`/api/media/getMediaDetails/${publicId}`, aspireClientConfig);
    },
    getPreSignedUrl(query) {
      const api = '/api/media/getPreSignedUrl';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    saveUploadedFiles: (body) =>
      aspireClient.post('/api/media/saveUploadedMedia', body, aspireClientConfig),
    create: (body) => aspireClient.post('/api/media/create', body, aspireClientConfig),
    update: (body) => aspireClient.put('/api/media/update', body, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/media/remove', body, aspireClientConfig),
  },
  activities: {
    getUnReadActivityList: () =>
      aspireClient.get('/api/activities/getUnReadActivityList', aspireClientConfig),
    getActivityList: () => aspireClient.get('/api/activities/getActivityList', aspireClientConfig),
    checkForUpdates: () => aspireClient.get('/api/activities/checkForUpdates', aspireClientConfig),
  },
  info: {
    getPopularInfos: () => aspireClient.get('/api/information/getPopularInfos', aspireClientConfig),
    getFilteredInfos: (body) =>
      aspireClient.post('/api/information/getFilteredInfos', body, aspireClientConfig),
    filterInfosByCategory(query) {
      const api = '/api/information/filterInfosByCategory';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getInfoByPublicId: (publicId) =>
      aspireClient.get(`/api/information/getInfoByPublicId/${publicId}`, aspireClientConfig),
    assignInquiryAsAnswered: (body) =>
      aspireClient.put('/api/information/assignInquiryAsAnswered', body, aspireClientConfig),
    getMyInquiries: (query) => {
      const api = '/api/information/getMyInquiries';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    create: (body) => aspireClient.post('/api/information/create', body, aspireClientConfig),
    createAspire: (body) =>
      aspireClient.post('/api/information/createAspire', body, aspireClientConfig),
    edit: (body) => aspireClient.put('/api/information/edit', body, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/information/remove', body, aspireClientConfig),
    getSolution: (body) =>
      aspireClient.put('/api/information/getSolution', body, aspireClientConfig),
    addMedia: (body) => aspireClient.put('/api/information/addMedia', body, aspireClientConfig),
    removeMedia: (body) =>
      aspireClient.put('/api/information/removeMedia', body, aspireClientConfig),
  },
  articles: {
    getArticles(query) {
      const api = '/api/articles/getTrainerFilteredArticles';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getTrainerFilteredArticles(query) {
      const api = '/api/articles/getTrainerFilteredArticles';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getArticleByPublicId(publicId) {
      return aspireClient.get(`/api/articles/getArticleByPublicId/${publicId}`, aspireClientConfig);
    },
    getArticle(publicId) {
      return aspireClient.get(`/api/articles/getArticle/${publicId}`, aspireClientConfig);
    },
    getDraftedArticles(query) {
      const api = '/api/articles/getDraftedArticles';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    create: (body) => aspireClient.post('/api/articles/create', body, aspireClientConfig),
    update: (body) => aspireClient.put('/api/articles/update', body, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/articles/remove', body, aspireClientConfig),
    unDraftArticle: (body) =>
      aspireClient.put('/api/articles/unDraftArticle', body, aspireClientConfig),
    addMedia: (body) => aspireClient.put('/api/articles/addMedia', body, aspireClientConfig),
    removeMedia: (body) => aspireClient.put('/api/articles/removeMedia', body, aspireClientConfig),
  },
  contacts: {
    getContactsList: (query) => {
      const api = '/api/contacts/getContactsList';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getContactById: (id) =>
      aspireClient.get(`/api/contacts/getContactById/${id}`, aspireClientConfig),
    update: (body) => aspireClient.put('/api/contacts/updateContact', body, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/contacts/removeContact', body, aspireClientConfig),
    updateReadContact: (body) =>
      aspireClient.put('/api/contacts/updateReadContact', body, aspireClientConfig),
  },
  calendar: {
    getEventsList: (query) => {
      const api = '/api/calendar/getEvents';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getEventDetails: (query) => {
      const api = '/api/calendar/getEventDetails';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    create: (body) => aspireClient.post('/api/calendar/create', body, aspireClientConfig),
    updateEvent: (body) => aspireClient.put('/api/calendar/updateEvent', body, aspireClientConfig),
    updateFollowingEvents: (body) =>
      aspireClient.put('/api/calendar/updateFollowingEvents', body, aspireClientConfig),
    updateAllEvents: (body) =>
      aspireClient.put('/api/calendar/updateAllEvents', body, aspireClientConfig),
    remove: (body) => aspireClient.put('/api/calendar/remove', body, aspireClientConfig),
    removeFollowingEvents: (body) =>
      aspireClient.put('/api/calendar/removeFollowingEvents', body, aspireClientConfig),
    confirm: (body) => aspireClient.put('/api/calendar/confirm', body, aspireClientConfig),
    cancel: (body) => aspireClient.put('/api/calendar/cancel', body, aspireClientConfig),
    getEventRescheduleDetails: (eventId) =>
      aspireClient.get(`/api/calendar/getEventRescheduleDetails/${eventId}`, aspireClientConfig),
    reschedule: (body) => aspireClient.post('/api/calendar/reschedule', body, aspireClientConfig),
  },
  products: {
    getAspireServices(query) {
      const api = '/api/products/getAspireServices';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getAspireProductDetails(publicId) {
      return aspireClient.get(
        `/api/products/getAspireProductDetails/${publicId}`,
        aspireClientConfig,
      );
    },
    createProductsTest: (body) =>
      aspireClient.post('/api/products/createProductsTest', body, aspireClientConfig),

    getProductsList(query) {
      const api = '/api/products/getProductsList';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getProductDetails(publicId) {
      return aspireClient.get(`/api/products/getProductDetails/${publicId}`, aspireClientConfig);
    },

    // ---- Subscriptions ----
    getMySubscription: () => aspireClient.get(`/api/products/getMySubscription`, aspireClientConfig),
    getSubscriptionList(query) {
      const api = '/api/products/getSubscriptionList';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getSubscriptionDetails(publicId) {
      return aspireClient.get(`/api/products/getSubscriptionDetails/${publicId}`, aspireClientConfig);
    },
    cancelSubscription() {
      return aspireClient.get(`/api/products/cancelSubscription`, aspireClientConfig);
    },
    // ---- Transactions ----
    getTransactionList(query) {
      const api = '/api/products/getTransactionList';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
    getTransactionDetails(publicId) {
      return aspireClient.get(
        `/api/products/getTransactionDetails/${publicId}`,
        aspireClientConfig,
      );
    },
  },
  payments: {
    getUserSubscriptionStatus: () =>
      aspireClient.get(`/api/payments/getUserSubscriptionStatus`, aspireClientConfig),
    googlePayCheckout: (body) =>
      aspireClient.post('/api/payments/googlePayCheckout', body, aspireClientConfig),
    applePayCheckout: (body) =>
      aspireClient.post('/api/payments/applePayCheckout', body, aspireClientConfig),
    paypalCheckout: (body) =>
      aspireClient.post('/api/payments/paypalCheckout', body, aspireClientConfig),
    recurringPayment: (body) =>
      aspireClient.post('/api/payments/recurringPayment', body, aspireClientConfig),
    completePaypalSubscription(query) {
      const api = '/api/payments/completePaypalSubscription';
      return aspireClient.get(query ? api + query : api, aspireClientConfig);
    },
  },
};

export { apiMethods, aspireClient, aspireClientConfig };
