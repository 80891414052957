/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
import { serverUrl } from '../services/globals';

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const googlePlacesApi = 'AIzaSyA2aoF1OhzxLz1F37k2VvJQGC4gml70WaU';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  // { id: 'es', name: 'Español', direction: 'ltr' },
  // { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  { id: 'he', name: 'Hebrew', direction: 'rtl' }
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563'
};

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = 'light.purple';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

export const mobileLogo = "https://aspire123.s3.eu-central-1.amazonaws.com/assets/logo-mobile.png"
export const LogoW = "https://aspire123.s3.eu-central-1.amazonaws.com/assets/logoHorizontalW_pvkqis.png"
export const LogoB = "https://aspire123.s3.eu-central-1.amazonaws.com/assets/logoHorizontalB_reis9e.png"
export const loginBalloon = "https://aspire-public-website.s3.eu-central-1.amazonaws.com/login-balloon.jpg"
export const balloon = "https://aspire-public-website.s3.eu-central-1.amazonaws.com/balloon.jpg"

/* SEO */
export const siteMetadata =  {
  url: 'https://www.aspire.co.il', // The URL of this page (eg https://google.com/about)
  title: 'ASPIRE, Become the best you can be', // Maximum 70 characters.
  // The original publication date. ISO 8601 timestamp eg "2019-10-06T13:56:03.123Z"
  // Don't know how useful for random webpages. Add it at least for blog posts.
  datePublished: '2020-09-01T08:00:03.123Z',
  description: 'A management Platform with Unique Engagement & Education tools For Personal Trainers their clients , that creates a powerful goal focused environment to strive and achive their goals.',
  // Maximum 200 characters.
  language: 'en-US', // Default "en-US" https://en.wikipedia.org/wiki/IETF_language_tag and https://datahub.io/core/language-codes
  image: 'https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogoName_600_600.jpg', // URL to the image, PNG, JPEG, or GIF recommended.
  site: {
    // "If your object is part of a larger web site, the name which should be displayed for the overall site. e.g., "IMDb"."
    siteName: 'Aspire',
    // If your website has a search functionality, enter the URL with parameter here eg "https://www.google.com/search?q="
    searchUrl: 'https://www.aspire.co.il'
  }
}
export const facebookProps = {
  title: siteMetadata.title, // The title of your article without any branding such as your site name.
  description: siteMetadata.description, // A brief description of the content, usually between 2 and 4 sentences.
  language: siteMetadata.language, // Used for og:locale. Default "en-US"
  // Facebook recommends 1200x630 size, ratio of 1.91:1. PNG, JPEG, or GIF.
  // Note the "minimum size constraint of 200px by 200px".
  image: siteMetadata.image,
  facebookAppId: '3005184189511063', // "Insights lets you view analytics for traffic to your site from Facebook."
}

export const instagramProps = {
  instagramClientId: '667447343848159',
  redirectToProfileUri: `https://trainer.aspire.co.il/app/profile`,
}

export const blogPostProps = {
  // The canonical URL for your page. This should be the undecorated URL, without session
  // variables, user identifying parameters, or counters.
  url: siteMetadata.url,
  title: siteMetadata.title, // Title of the post. Max 70 characters.
  description: siteMetadata.description, // Should be a short description about the topic, <=200 words. Mainly for SEO purposes.
  image: siteMetadata.image, // You should add this. Just use the same image as og/twitter eg 1200x630 with 1.91:1 ratio in PNG, JPEG, or GIF.
  datePublished: siteMetadata.datePublished, // The original publication date. Don't change arbitrarily, Google might downrank you.
  dateModified: siteMetadata.datePublished, // Google prefers recent content in search results and also users are more likely to click a recent article
  tags:  '', // string[],
  /**
   * Technically either Person or Organization, but since it doesn't make any sense to not to credit this content to a human, use person.
   * From schema.org:
   * "Please note that author is special in that HTML 5 provides a special mechanism for indicating authorship
   * via the rel tag. That is equivalent to this and may be used interchangeably."
   * Which means.. something.
   */
  author: 'PersonProps',
  /**
   * You should add this since otherwise Google's structured-data tool will complain...
   * However https://webmasters.stackexchange.com/questions/110332/personal-blog-using-structured-data-fails-validation-on-publisher
   */
  publisher: {
    description: siteMetadata.description,
    name: siteMetadata.title,// Eg "Google"
    logo: siteMetadata.image,// URL to the logo image.
    // List of other webpages referencing this organization, eg Wikipedia, Facebook, Twitter, Instagram etc.
    // Will show these sites alongside your website in Google search results and probably boost your SEO rank too.
    sameAs: ['https://www.facebook.com/aspire.community', 'https://www.instagram.com/aspire.community'],
    url: siteMetadata.url // URL to the organization, eg "https://abc.xyz"
  },
  site: siteMetadata.site,

}
