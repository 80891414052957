import { articleTypes} from './actionTypes';
import { apiMethods } from '../../services/apiService';
import { utils } from '../../services/utilsService';
import { handleErrorResponse } from './index';

const setArticles = data => ({
  type: articleTypes.SET_ARTICLES,
  payload: data
});

const setArticleData = data => ({
  type: articleTypes.SET_ARTICLE_DATA,
  payload: data
});

export const splitArticle = publicId => ({
  type: articleTypes.REMOVE_ARTICLE,
  payload: publicId
});

export const getArticles = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.articles.getArticles(query);
    dispatch(setArticles(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getTrainerFilteredArticles = (queryObj) => async (dispatch) => {
  try {
    const query = queryObj ? utils.buildQuery(queryObj) : null;
    const data = await apiMethods.articles.getTrainerFilteredArticles(query);
    dispatch(setArticles(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const getArticleByPublicId = publicId => async (dispatch) => {
  try {
    const data = await apiMethods.articles.getArticleByPublicId(publicId);
    dispatch(setArticleData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const createArticle = (body) => async (dispatch) => {
  try {
    let result = true;
    await apiMethods.articles.create(body);
    return result;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const updateArticle = (body) => async (dispatch) => {
  try {
    await apiMethods.articles.update(body);
    return true;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const removeArticle = publicId => async (dispatch) => {
  try {
    const body = {publicIds: [publicId]};
    await apiMethods.articles.remove(body);
    dispatch(splitArticle(publicId));
  } catch (e) {
    dispatch(handleErrorResponse(e));
  }
};

export const getDraftedArticles = body => async (dispatch) => {
  try {
    return await apiMethods.articles.getDraftedArticles(body);
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};

export const unDraftArticle = publicId => async (dispatch) => {
  try {
    const body = { publicId };
    const data = await apiMethods.articles.unDraftArticle(body);
    dispatch(setArticleData(data));
    return data;
  } catch (e) {
    dispatch(handleErrorResponse(e));
    return false;
  }
};
