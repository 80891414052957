/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Form, Formik } from 'formik';
import { actionTypes } from '../../store/actions/actionTypes';
import MediaUploadWithLightBox from "../../Components/MediaUpload/MediaUploadWithLightBox"
import './modalStyle.css';
import { Trans } from 'react-i18next';
import { utils } from '../../services/utilsService';
import FormikInputs from '../../Components/FormBox/FormikInputs';
import { mediaGroup } from '../../services/enums';

class ProfessionModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isPageReady: false,
      profession: this.props.alertProps.profession,
      editMode: this.props.alertProps.editMode,
      hasChanged: false,
      media: (this.props.alertProps.profession && this.props.alertProps.profession.media) || null,
      inputs: [
        {
          name: 'title',
          label: 'title',
          description: 'profession title',
          placeholder: 'write profession name',
          type: 'text',
          elementType: 'input',
          validation: {
            required: true,
            minLength: 2
          },
          classes: 'flex-100'
        },
        {
          name: 'description',
          label: 'description',
          placeholder: 'write description',
          description: 'location description',
          elementType: 'textarea',
          validation: {
            rows: 3,
            minLength: 3
          },
          classes: 'flex-100',
        },
        {
          name: 'date',
          label: 'date',
          placeholder: 'choose date',
          description: 'profession date',
          elementType: 'datePicker',
          classes: 'flex-100',
        }
      ]
    };
  }

  async componentDidMount () {
    this.setState({ isPageReady: true });
  }

  toggle() {
    this.props.hideAlert();
  }

  onMediaUpload(media) {
    this.setState({ media, hasChanged: true });
  }

  handleChange (e, key) {
    this.state[key] = e.target.type === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value;
  }

  onKeyDown (keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  formHasChanged() {
    this.state.hasChanged = true;
  }

  async updateProfession(values){
    if (this.isLoading) { return; }
    this.isLoading = true;
    try {
      const { hasChanged , editMode } = this.state;
      const body = {...values};
      body.media = this.state.media;
      if (hasChanged) {
        body.hasChanged = true;
        this.props.alertProps.saveProfession.onClick(body, editMode);
        console.log(body)
      }
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.isLoading = false;
    }
  }

  render () {
    const { inputs, profession} = this.state;
    let formProfessionInputs = [...inputs];
    if (profession && profession._id)
      formProfessionInputs = utils.handlePropsIntoInitValues(profession, [...inputs]);
    return (
      <Modal isOpen toggle={() => this.toggle()} backdrop className="modalCenterScreen">
        { this.state.isPageReady && (
          <ModalBody className="width100 layout-row layout-wrap layout-align-start-start">
              <Formik initialValues={profession} onSubmit={(data, formikActions) => {this.updateProfession(data, formikActions)}}>
                { formProps => (
                  <Form onKeyDown={(event) => this.onKeyDown(event)} className="av-tooltip tooltip-right-top formContainer flex-100 layout-row layout-wrap layout-align-start-start">
                    <div className="flex-gt-md-40 flex-md-100 flex-sm-100 flex-xs-100 layout-row layout-wrap layout-align-start-start GooglePlacesAutocomplete mb-4">
                      <MediaUploadWithLightBox
                        mediaGroup={mediaGroup.profile}
                        modelId={profession && profession.public_id}
                        media={profession && profession.media && profession.media[0]}
                        mediaBoxId="ProfessionCertificates"
                        folder="Profession"
                        aspireLibrary
                        mediaUpload
                        allowImages
                        allowedFileTypes={['image/*']}
                        onMediaUpload={(media) => { this.onMediaUpload(media); }}
                        height={350} />
                    </div>
                    <div className="flex-gt-md-60 flex-md-100 flex-sm-100 paddStart20px flex-xs-100 layout-row layout-wrap layout-align-start-start">
                     <div className="flex layout-row layout-wrap layout-align-start-center">
                       <FormikInputs
                         formId="userProfessionForm"
                         formProps={formProps}
                         model={[...formProfessionInputs]}
                         initialValues={profession}
                         editMode
                         shouldUpdate
                         checkChanges
                         formHasChanged={() => this.formHasChanged()} />
                     </div>
                      <div className="flex-100 layout-row layout-wrap layout-align-start-start">
                        <div className="flex-100 layout-row layout-wrap layout-align-center-center pt-3">
                          <button id="profession_submitBtn" className="btn btn-primary flex-gt-md-40 flex-md-50 flex-sm-50 flex-xs-100 text-center fontWeight600" type="submit"><Trans i18nKey="saveChanges" /></button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
          </ModalBody>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  mediaDetails: state.mediaR.mediaDetails,
  alertProps: state.appR.alertProps
});

const mapDispatchToProps = dispatch => ({
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionModal);
