import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { langs } from '../../langs';
import { utils, inputUtils } from '../../services/utilsService';

class Input extends Component {
  constructor (props) {
    super(props);
    this.state = {
      initialValue: null,
      dateToday: moment().format('YYYY-MM-DD')
    };
  }

  // this is for surveyCreator functionality
  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.clearOnLangUpdate && prevProps.locale !== this.props.locale &&
      (prevProps.elementType === 'input' || prevProps.elementType === 'textarea') &&
    !this.props.initValue) {
      this.state[prevProps.name].value = '';
    } else if (prevProps.clearOnLangUpdate && prevProps.locale !== this.props.locale &&
      (prevProps.elementType === 'input' || prevProps.elementType === 'textarea') &&
      this.props.initValue) {
      this.state[prevProps.name].value = this.props.initValue;
    }
  }

  textClickable (label, key, group) {
    if (this.props.valueAlwaysTrue) return;
    const element = document.querySelector(`input[label="${label || key}"]${group ? `[group=${group}]` : ''}`);
    if (element) {
      const event = {
        type: element.type,
        checked: !element.checked,
        target: {
          type: element.type,
          checked: !element.checked
        }
      };
      element.checked = !element.checked;
      this.props.handleChange(event, key);
    }
  }

  renderInput (prop, key) {
    const elementType = prop.elementType || 'input';
    const type = prop.type || 'text';
    const config = prop.config || null;
    const validation = prop.validation || null;
    this.state.initialValue = prop.initValue ? prop.initValue : null;
    let locale = this.props.locale;
    if (!locale || (locale !== 'en' && locale !== 'he')) locale = 'en';
    const placeholder = this.props.placeholderAsIs ? this.props.placeholderAsIs : (langs && langs[locale] && langs[locale][prop.placeholder || prop.label || prop.name] ? langs[locale][prop.placeholder || prop.label || prop.name] : '');
    switch (elementType) {
      case ('input'):
        return (
          <input
            id={prop.id || key}
            {...config}
            {...validation}
            label={prop.label || key}
            placeholder={placeholder}
            defaultValue={this.state.initialValue}
            type={type}
            onFocus={(event) => {
              !this.props.noLabel && inputUtils.onInputFocus(key);
              this.props.onFocus ? this.props.onFocus(event, key) : null;
            }}
            onBlur={() => {
              !this.props.noLabel && inputUtils.onInputBlur(key);
            }}
            ref={input => this.state[key] = input}
            key={`i_${key}`}
            onChange={(event) => {
              this.props.handleChange(event, key);
            }}
            className={`form-control ${this.props.inputClass}`} />
        );
      case ('textarea'):
        return (
          <textarea
            {...config}
            {...validation}
            label={prop.label || key}
            placeholder={placeholder}
            defaultValue={this.state.initialValue}
            ref={input => this.state[key] = input}
            onFocus={(event) => {
              !this.props.noLabel && inputUtils.onInputFocus(key);
              this.props.onFocus ? this.props.onFocus(event, key) : null;
            }}
            onBlur={() => {
              !this.props.noLabel && inputUtils.onInputBlur(key);
            }}
            key={`i_${key}`}
            onChange={(event) => {
              this.props.handleChange(event, key);
            }}
            className={`form-control ${this.props.inputClass}`} />
        );
      case ('checkBox'):
        return (
          <div key={`i_${key}`} className="flex-100 layout-row layout-wrap layout-align-start-center">
            <div className="flex-100 layout-row layout-align-start-center checkBox">
              <div className="checkbox-container paddTop5px">
                <input
                  type="checkbox"
                  label={prop.label || key}
                  checked={this.props.initialValue}
                  ref={input => this.state[key] = input}
                  {...config}
                  {...validation}
                  onChange={(event) => { this.props.handleChange(event, key); }} />
              </div>
              <p className={`flex paddStart10px margin0 ${this.props.checkBoxClass || ''}`} onClick={(event) => { this.props.clickText && this.textClickable(prop.label, key); }}>
                {this.props.children || this.props.text}
              </p>
            </div>
          </div>
        );
      case ('radio'):
        return (
          <div key={`i_${key}`} className="flex-100 layout-row layout-wrap layout-align-start-center">
            <div className="flex-100 layout-row layout-align-start-center checkBox">
              <div className="checkbox-container">
                <input
                  type="radio"
                  name={this.props.group || this.props.name}
                  group={this.props.group}
                  label={prop.label || key}
                  checked={this.props.initialValue}
                  ref={input => this.state[key] = input}
                  {...config}
                  {...validation}
                  onChange={(event) => { this.props.handleChange(event, key); }} />
              </div>
              <p className={`flex paddStart10px margin0 ${this.props.checkBoxClass || 'paddStart10px'}`} onClick={(event) => { this.props.clickText && this.textClickable(prop.label, key, prop.group); }}>
                {this.props.children || this.props.text}
              </p>
            </div>
          </div>
        );
      case ('select'):
        return (
          <div className="flex-100 layout-row layout-align-center-center positionRelative inputSelect selectContainer">
            <select
              className={`form-control minimal ${this.props.inputClass}`}
              id={this.props.id || prop.name}
              {...config}
              {...validation}
              disabled={prop.disabled || this.props.disabled}
              label={prop.label || key}
              defaultValue={this.state.initialValue}
              ref={input => this.state[key] = input}
              onFocus={(event) => {
                !this.props.noLabel && inputUtils.onInputFocus(key);
                this.props.onFocus ? this.props.onFocus(event, key) : null;
              }}
              onBlur={() => {
                !this.props.noLabel && inputUtils.onInputBlur(key);
              }}
              key={`i_${key}`}
              onChange={(event) => { this.props.handleChange(event, key); }}>
              {prop.options && Array.isArray(prop.options) ? utils.getOptionsByArray(prop.options, this.props.locale) : utils.getOptionsByObject(prop.options, this.props.locale)}
            </select>

          </div>
        );
      case ('datePicker'):
        return (
          <div className="datePickerInput flex-100 layout-row layout-wrap layout-align-start-start positionRelative">
            <input
              {...config}
              {...validation}
              label={prop.label || key}
              onFocus={() => { !this.props.noLabel && inputUtils.onInputFocus(key); }}
              onBlur={() => { !this.props.noLabel && inputUtils.onInputBlur(key); }}
              defaultValue={this.state.initialValue || this.state.dateToday}
              type="date"
              min={(validation && validation.min) || this.props.min}
              max={(validation && validation.max) || this.props.max}
              ref={input => this.state[key] = input}
              key={`i_${key}`}
              onChange={(event) => { this.props.handleChange(event, key); }}
              className={`form-control ${this.props.inputClass}`} />
          </div>
        );
      default:
        return (
          <input
            id={key}
            {...config}
            {...validation}
            label={prop.label || key}
            placeholder={placeholder}
            defaultValue={this.state.initialValue}
            type={type}
            onFocus={() => { !this.props.noLabel && inputUtils.onInputFocus(key); }}
            onBlur={() => { !this.props.noLabel && inputUtils.onInputBlur(key); }}
            ref={input => this.state[key] = input}
            key={`i_${key}`}
            onChange={(event) => { this.props.handleChange(event, key); }}
            className={`form-control ${this.props.inputClass}`} />
        );
    }
  }

  render () {
    const {
      className, initValue, labelClasses, label, name, commentClasses, comment, noLabel, key
    } = { ...this.props };
    return (
      <div key={key || name} className={`${this.props.classes} layout-row layout-wrap layout-align-start-start`}>
        <div className={className}>
          { !noLabel && (
            <label id={`${name}_label`} className={`formBoxInput text-capitalize valued rendered fontWeight600 mb-1 ${initValue ? 'valued' : ''} ${labelClasses}`} htmlFor={name}><Trans i18nKey={label || name} /></label>
          )}
          {this.renderInput(this.props, name)}
          {comment && (
            <span className={`form-comment ${commentClasses}`}><Trans i18nKey={comment} /></span>
          )}
        </div>
      </div>
    );
  }
}

export default Input;
