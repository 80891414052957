import { actionTypes } from '../actions/actionTypes';

const initialState = {
  // **** Body Stats *****
  // bodyStatsKV
  // bodyStatsIds
  // statsChartData
  // statsDetails
  // statInfo
  // equipmentData {}
  statsList: [],
  // **** Performance Stats (statistics) *****
  performanceStatsIds: [],
  performanceStatsKV: {}
  // programsStats []
  // exerciseStats []
  // sessionStats: []
  // overallStats: {}
};

const userStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BODY_STATS:
      return {
        ...state, bodyStatsIds: action.payload.dataIds, bodyStatsKV: action.payload.keyValueObj, statsDetails: action.payload.recentStat
      };
    case actionTypes.SET_STATS_TYPE_HISTORY:
      return {
        ...state, statsList: action.payload.statsList, statsChartData: action.payload.statsChartData
      };
    case actionTypes.CLEAR_BODY_STATS:
      return {
        ...state, statsList: [], statsChartData: {}, bodyStatsIds: [], bodyStatsKV: {}, statsDetails: {}
      };
    case actionTypes.SET_STATS_DATA:
      return { ...state, statsDetails: action.payload };
    case actionTypes.SET_OVERALL_STATISTICS:
      return { ...state, overallStats: action.payload.overallStats };
    case actionTypes.SET_CUBE_STATISTICS:
      return { ...state, overallCubeStats: action.payload.overallStats };
    case actionTypes.SET_PROGRAM_STATISTICS:
      return { ...state, programsStats: action.payload.programsStats };
    case actionTypes.SET_EXERCISE_STATISTICS:
      return { ...state, exerciseStats: action.payload.exerciseStats };
    case actionTypes.CLEAR_STATISTICS:
      return {
        ...state,
        overallStats: {},
        programsStats: [],
        exerciseStats: [],
        sessionStats: {},
        lastId: null,
        canGetMoreStats: true
      };
    case actionTypes.SET_STATISTICS_HISTORY:
      return {
        ...state,
        statInfo: action.payload.statInfo,
        statsList: action.payload.statsList,
        statsChartData: action.payload.statsChartData,
        equipmentData: action.payload.equipmentData, // stats details page only
        lastId: action.payload.lastId,
        canGetMoreStats: !!(action.payload.statsList.length === 30)
      };
    case actionTypes.SET_STATISTICS_HISTORY_PAGE:
      return {
        ...state,
        lastId: action.payload.lastId,
        statsList: state.statsList.concat(action.payload.statsList),
        canGetMoreStats: !!(action.payload.statsList.length === 30)
      };
    case actionTypes.SET_ACHIEVEMENT_PROGRESS:
      return {
        ...state,
        statsList: action.payload.statsList,
        lastId: action.payload.lastId,
        canGetMoreStats: !!(action.payload.statsList.length === 30)
      };
    case actionTypes.CLEAR_STATISTICS_HISTORY:
      return {
        ...state,
        statInfo: null,
        statsList: null,
        statsChartData: null,
        lastId: null,
        canGetMoreStats: true
      };
    case actionTypes.CHANGE_EQUIPMENT_SELECT: {
      return {
        ...state,
        statsList: action.payload.statsList,
        statsChartData: action.payload.statsChartData,
        lastId: action.payload.lastId,
        canGetMoreStats: action.payload.canGetMoreStats
      };
    }
    default:
      return state;
  }
};

export default userStatsReducer;
