/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { actionTypes } from '../../store/actions/actionTypes';
import { getMediaGallery, getAspireGallery } from '../../store/actions';
import Image from '../../Components/SocialModalCmp/Image';
import LoadingDots from '../../Components/UI/LoadingDots';
import './modalStyle.css';
import { utils } from '../../services/utilsService';
import { mediaType } from '../../services/enums';
import Pagination from '../Pages/Pagination';

class GetSocialMediaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isMulti: this.props.alert2Props.isMulti,
      media: [],
      selectedImagesIds: [],
      selectedImagesKV: {},
      showBackBtn: false,
      uploadBtnText: 'saveSelectedImages',
      completeResults: [],
      resultsIds: [],
      uploadedIds: [], // uploadedFileIdsAlready
      allowImages: this.props.alert2Props.allowImages,
      allowVideos: this.props.alert2Props.allowVideos,
      currentPage: 1,
    };
  }

  async componentDidMount() {
    const { alert2Props } = this.props;
    if (alert2Props.aspireLibrary) {
      await this.getAspireLibrary();
    } else if (alert2Props.myMedia) {
      await this.getMyMedia();
    }
    this.setState({ isLoading: false });
  }

  async handleSelectedMedia() {
    let { selectedImagesIds, selectedImagesKV, completeResults, resultsIds, uploadedIds} = this.state
    if (this.isLoading || selectedImagesIds.length === 0 ) return;
    this.isLoading = true;
    try {
      this.setState({ startUpload: true, selectedImagesKV:{}, selectedImagesIds: []})
      const selectedMediaArray = utils.keyValueToArray(selectedImagesKV);
      completeResults = completeResults.concat(selectedMediaArray)
      resultsIds = resultsIds.concat(selectedImagesIds)
      uploadedIds = uploadedIds.concat(selectedImagesIds)
      this.setState({ startUpload: false, selectedImagesKV:{}, selectedImagesIds: [], completeResults, resultsIds, uploadedIds }, () => {
        this.props.alert2Props.handleSelectedImages.onClick(completeResults, resultsIds)
        if (!this.props.isMulti && (completeResults.length > 0)) {
          this.props.hideAlert2()
        }
      })
      this.isLoading = false;
    } catch(e) {
      console.log(`handleSelectedMedia error ${ JSON.stringify(e)}`);
      this.isLoading = false;
    }
  }

  async getAspireLibrary() {
    try {
      const query = { limit: 30 };
      const mediaList = await this.props.getAspireLibrary(query);
      const id = mediaList[mediaList.length - 1]._id;
      this.setState({ isLoading: false, media: [...this.state.media, ...mediaList], lastId: id });
    } catch (e) {
      console.log('handle error');
      this.setState({ isLoading: false });
    }
  }

  async getMyMedia(moreQuery) {
    try {
      const body = moreQuery ? {...moreQuery} : {};
      const query = {...body, limit: 30 };
      const mediaList = await this.props.getMediaGallery(query);
      const id = mediaList[mediaList.length - 1]._id;
      const media = [...this.state.media, ...mediaList];
      const totalPages = media.length / 15;
      this.setState({ isLoading: false, lastId: id, media, totalPages });
    } catch (e) {
      console.log('handle error');
      this.setState({ isLoading: false });
    }
  }

  selectImage(media, isAllowed) {
    if (this.state.startUpload || (this.state.uploadedIds.indexOf(media._id) !== -1) || !isAllowed) return;
    let { selectedImagesIds, selectedImagesKV, isMulti } = this.state;
    if (this.state.isMulti && selectedImagesIds.indexOf(media._id) === -1) {
      if (!isMulti && selectedImagesIds.length > 0) return;
      selectedImagesIds.push(media._id);
      selectedImagesKV[media._id] = media;
    } else if (!isMulti && selectedImagesIds.indexOf(media._id) === -1) {
      selectedImagesIds = [media._id]
      selectedImagesKV = {};
      selectedImagesKV[media._id] = media;
    } else {
      selectedImagesIds.splice(selectedImagesIds.indexOf(media._id), 1);
      delete selectedImagesKV[media._id];
    }
    this.setState({ selectedImagesIds, selectedImagesKV });
  }

  onChangePage = page => {
    this.setState({currentPage: page}, () =>{
      if (this.state.currentPage === this.state.totalPages) {
        let body = {};
        if( this.state.lastId ) body = { lastId: this.state.lastId };
        this.getMyMedia(body);
      }
    });
    // this.props.history.push(`${this.props.match.path}?page=${page}`);
  };

  toggle() {
    this.props.hideAlert2();
  }

  renderImage(media, thumbnail) {
    const { uploadedIds, startUpload, selectedImagesIds, allowImages, allowVideos } = this.state;
    let isAllowed = false;
    if ((allowImages && !allowVideos && media.type === mediaType.image) || (!allowImages && allowVideos && media.type === mediaType.video) ||
      (allowImages && allowVideos && media.type === mediaType.image) || (allowImages && allowVideos && media.type === mediaType.video)) {
      isAllowed = true
    }
    return (
      <div key={media._id} className={`fbImageCard flex-initial layout-row layout-wrap layout-align-start-start pl-md-3 pl-xs-2 ${selectedImagesIds.indexOf(media._id) !== -1 ? 'selected' : ''} ${isAllowed ? '' : 'notAllowed'}`}>
        <Image
          uploadedSuccessfully={(uploadedIds && uploadedIds.length > 0 && uploadedIds.indexOf(media._id) !== -1)}
          id={media._id}
          startUpload={startUpload}
          thumbnail={thumbnail}
          type={media.type}
          onClick={() => {this.selectImage(media, isAllowed);}}
          onCancel={() => {this.cancelFile(media._id);}}
          isSelected={selectedImagesIds.indexOf(media._id) !== -1} />
      </div>
    );
  }

  renderTitle() {
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-space-between-center borderRadius5 top p-3 aspireBg">
        <div className="flex-initial layout-row layout-align-start-start text-uppercase">
          <p className="fontWeight800 mb-0 whiteColor font16 flex-initial"><Trans i18nKey="aspire" /></p>
        </div>
        <div className="flex-initial layout-row layout-wrap layout-align-start-start text-uppercase">
          <p className="fontWeight600 mb-0 whiteColor font16 flex-initial cursorPointer" onClick={() => { this.toggle()}}><Trans i18nKey="close" /></p>
        </div>
      </div>
    )
  }

  renderContent() {
    const { media, uploadBtnText, startUpload, selectedImagesIds, paging } = this.state;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-center-center">
        { media && media.length > 0 ? media.map((mediaObj) => this.renderImage(mediaObj, mediaObj.thumbnail)) : (
          <div className="flex-100 layout-column layout-wrap layout-align-center-center height200px">
            <p className="font20 fontWeight600 text-muted"><Trans i18nKey="noResultsFound" /></p>
          </div>
        )}
        <div className={`flex-100 layout-row layout-wrap layout-align-space-between-center ${media && media.length > 0 && selectedImagesIds.length > 0 ? 'mb-5' : ''}`}>
          <Pagination currentPage={this.state.currentPage} totalPage={this.state.totalPages} onChangePage={i => this.onChangePage(i)} />
        </div>
        <div className="width100 layout-row layout-wrap layout-align-center-center bottomLeft positionFixed p-3 zIndex10">
          { media && media.length > 0 && selectedImagesIds.length > 0 && (
            <button type="button" onClick={() => {this.handleSelectedMedia()}} className="flex-initial layout-row btn-shadow layout-align-center-center roundButton bottomUpAnimation btn btn-primary p-3 fontWeight600 text-uppercase">
              <Trans i18nKey={uploadBtnText} />
              { startUpload && (<div className="flex-initial"><LoadingDots active /></div>)}
            </button>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { isLoading } = this.state;
    const title = this.renderTitle()
    const content = this.renderContent()
    return (
      <Modal isOpen toggle={() => this.toggle()} backdrop className="aspireLibraryModal">
        <Fragment>
          {title}
          <ModalBody className="GetSocialMediaModal width100 layout-row layout-wrap layout-align-start-start overFlowContainer">
            { !isLoading ? content : (
              <div className="flex-100 layout-row layout-wrap layout-align-start-start height200px position-relative">
                <div className="gogo-loading" />
              </div>
            )}
          </ModalBody>
        </Fragment>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
  mediaList: state.mediaR.mediaList,
  alert2Props: state.appR.alert2Props,
});

const mapDispatchToProps = dispatch => ({
  hideAlert2: () => dispatch({ type: actionTypes.HIDE_ALERT2 }),
  getMediaGallery: (query) => dispatch(getMediaGallery(query)),
  getAspireGallery: (query) => dispatch(getAspireGallery(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(GetSocialMediaModal);
