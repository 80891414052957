/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Card, UncontrolledPopover,
} from 'reactstrap';
import MediaDisplay from '../MediaDisplay/MediaDisplay';
import { PopoverDeleteContent } from '../UI/PopoverTemplates';
import { aspirePlaceholder, mediaType } from '../../services/enums';
import '../../Containers/ProfilePages/ProfilePagesCSS.css';
import MediaDashboard from '../MediaUploadDashborads/MediaDashboard';

class MediaUploadWithLightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      public_id: null,
      media: this.props.media,
      popoverEditOpen: {},
      popoverDeleteOpen: {}
    };
  }

  async componentDidMount() {
    try {

    } catch (e) {
      console.log('handle error');
    }
  }

  componentWillUnmount() {

  }

  onMediaUpload(mediaArray) {
    this.setState({ media: mediaArray[0], hasChanged: true}, () =>{
      if (this.props.onMediaUpload) this.props.onMediaUpload(this.state.media)
    });
  }

  togglePopover(key, id) {
    this.state[key][id] = !this.state[key][id];
    this.setState({ ...this.state });
  }

  removeMedia() {
    this.setState({ media: null });
    if (this.props.onMediaUpload) this.props.onMediaUpload()
  }

  showThumbnail(id, media, height) {
    return (
      <Card className="flex-100" style={{height}}>
        <div className="zIndex10 layout-row width100 positionAbsoluteTopLeft">
          <div className="width100 margin1rem">
            <div className="flex-initial zIndex10 position-relative">
              <Button
                id={`remove_${id}`}
                color="white"
                type="Button"
                className="icon-button sideMargin5px btn-shadow"
                onClick={() => { this.togglePopover('popoverDeleteOpen', id); }}>
                <i className="simple-icon-trash" />
              </Button>
              <UncontrolledPopover
                trigger="legacy"
                placement="top"
                isOpen={this.state.popoverDeleteOpen[id]}
                target={`remove_${id}`}>
                <PopoverDeleteContent
                  onConfirm={() => this.removeMedia()}
                  onCancel={() => this.togglePopover('popoverDeleteOpen', id)}
                  locale={this.props.locale}
                  name={media.title} />
              </UncontrolledPopover>
            </div>
          </div>
        </div>
        <MediaDisplay
          imageClass="cardShadow"
          media={[{ media }]}
          width={window.innerWidth}
          shouldUpdate
          height={height}
          backUpImage={aspirePlaceholder[1]}
          locale={this.props.locale}
          isImage={media.type === mediaType.image}
          videoPoster={media.thumbnail}
          controls />
      </Card>
    );
  }

  render() {
    const { media } = this.state;
    const { height, className, modelId, folder, mediaBoxId, model, allowedFileTypes, mediaGroup, allowImages, allowVideos, aspireLibrary, facebookSocial, mediaUpload, isMulti, mediaUploadClasses} = this.props;
    return (
      <div className="flex-100 layout-row layout-wrap layout-align-start-center thumbnailContainer">
        { media && media._id ? (
          <div className="flex-100 layout-row layout-wrap layout-align-start-center">
            { this.showThumbnail(media._id, media, height || 350)}
          </div>
        ) : (
          <MediaDashboard
            isMulti={isMulti}
            folder={folder}
            mediaGroup={mediaGroup}
            className={className}
            mediaUploadClasses={mediaUploadClasses}
            allowedFileTypes={allowedFileTypes || ['image/*']}
            allowImages={allowImages}
            allowVideos={allowVideos}
            aspireLibrary={aspireLibrary}
            facebookSocial={facebookSocial}
            mediaUpload={mediaUpload}
            model={model}
            modelId={modelId}
            mediaBoxId={mediaBoxId}
            updateMedia={(mediaArr, mediaIds) => {this.onMediaUpload(mediaArr, mediaIds)}} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  locale: state.appR.locale,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MediaUploadWithLightBox);
